import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { AuthStateService } from '@slice-services/auth-state.service';
import { NavigationService } from '@slice-services/navigation.service';

import { E_ROUTES } from '@slice-enums/routes.enum';

@Injectable()
export class BaseGuard implements CanActivate {
  constructor(
    private authStateService: AuthStateService,
    private navigationService: NavigationService,
    private router: Router,
  ) {}
  public canActivate(): boolean | UrlTree {
    return this.check();
  }

  private check(): boolean | UrlTree {
    if (this.authStateService.isAuthenticated()) {
      return this.router.parseUrl(
        this.navigationService.getRoutePath(E_ROUTES.HOME),
      );
    } else {
      return this.router.parseUrl(
        this.navigationService.getRoutePath(E_ROUTES.SIGN_IN),
      );
    }
  }
}
