import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconExcoComponent } from './exco.component';

@NgModule({
  declarations: [SvgIconExcoComponent],
  imports: [CommonModule],
  exports: [SvgIconExcoComponent],
})
export class SvgIconExcoModule {}
