import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

// import { BrandCategoryHttpService } from '@slice-services/brand-categories-http.service';
// import { ContentCategoryHttpService } from '@slice-services/content-categories-http.service';
// import { HashtagCategoryHttpService } from '@slice-services/hashtag-categories-http.service';
import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import {
  BrandCategory,
  Category,
  ContentCategory,
  HashtagCategory,
} from '@slice-interfaces/categories/category';
// import { TagsSelectionAddOutput } from '@slice-interfaces/tags/tag-selection';
import { Categories } from '@slice-enums/categories-types';

interface TranslateKeys {
  type: Categories;
  otherPlaceholder: string;
}

export interface CategoriesSelectionChangedOutput {
  item: Category;
  newValue: boolean;
}

@Component({
  selector: 'slice-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() categoryType!: Categories;
  @Input() gridCssClasses? = 'col-sm-6 col-md-6 col-lg-4 col-xl-3';
  @Input() categories: Array<ContentCategory> | null;
  @Input() brands: Array<BrandCategory> | null;
  @Input() hashtags: Array<HashtagCategory> | null;
  @Input() selectedIds: Array<string> = [];
  @Input() maximumCheck?: number | null = null;

  public createLoading: boolean;
  public translateKeys: TranslateKeys;

  @Output() selectionChanged =
    new EventEmitter<CategoriesSelectionChangedOutput>();
  @Output() itemCreated = new EventEmitter<any>(); // todo any

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initTranslateKeys(this.categoryType);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private initTranslateKeys(categoryType: Categories): void {
    const list: Array<TranslateKeys> = [
      {
        type: Categories.CONTENT,
        otherPlaceholder: 'tags-selection.content-categories.other-placeholder',
      },
      {
        type: Categories.BRAND,
        otherPlaceholder: 'tags-selection.brands-categories.other-placeholder',
      },
      {
        type: Categories.HASHTAG,
        otherPlaceholder:
          'tags-selection.hashtags-categories.other-placeholder',
      },
    ];
    this.translateKeys = list.find(
      i => i.type === categoryType,
    ) as TranslateKeys;
  }

  get items(): Array<Category> | null {
    const itemsList: Array<Category> | null = null;
    if (this.categoryType === Categories.CONTENT) {
      return this.categories;
    } else if (this.categoryType === Categories.BRAND) {
      return this.brands;
    } else if (this.categoryType === Categories.HASHTAG) {
      return this.hashtags;
    } else {
      return [];
    }
  }

  changeSelection(val: boolean, item: Category): void {
    this.selectionChanged?.emit({
      item,
      newValue: val,
    });
    setTimeout(() => {
      if (this.maximumCheck) {
        if (this.selectedIds.length >= this.maximumCheck) {
          if (this.items) {
            const dt = this.items.map(item => {
              if (!this.selectedIds.includes(item.id)) {
                return { ...item, isEnabled: false };
              }
              return item;
            });
            this.categories = dt;
          }
        }
        if (this.selectedIds.length < this.maximumCheck) {
          if (this.items) {
            const dt = this.items.map(item => {
              if (!this.selectedIds.includes(item.id)) {
                return { ...item, isEnabled: true };
              }
              return item;
            });
            this.categories = dt;
          }
        }
      }
    }, 200);
  }

  // addTag(e: TagsSelectionAddOutput): void {
  //   this.createLoading = true;
  //   const payload = {
  //     name: e.keyName,
  //     defaultName: e.defaultName,
  //   };
  //   let createRequest;
  //   if (this.categoryType === Categories.CONTENT) {
  //     createRequest = this.contentCategoryHttpS.createContentCategory(payload);
  //   } else if (this.categoryType === Categories.BRAND) {
  //     createRequest = this.brandCategoryHttpS.createBrandCategory(payload);
  //   } else if (this.categoryType === Categories.HASHTAG) {
  //     createRequest = this.hashtagCategoryHttpS.createHashtagCategory(payload);
  //   } else {
  //     throw new Error('Not defined get category request');
  //   }
  //   createRequest.subscribe(r => {
  //     this.createLoading = false;
  //     this.itemCreated?.emit(r);
  //   });
  // }
}
