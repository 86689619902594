import { Component, Input } from '@angular/core';

@Component({
  selector: 'slice-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss'],
})
export class AddItemComponent {
  @Input() label: string;
}
