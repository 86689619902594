<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M13.45 1.7803C13.41 2.0303 13.39 2.2803 13.39 2.5303C13.39 4.7803 15.21 6.5993 17.45 6.5993C17.7 6.5993 17.94 6.5703 18.19 6.5303V14.5993C18.19 17.9903 16.19 20.0003 12.79 20.0003H5.401C2 20.0003 0 17.9903 0 14.5993V7.2003C0 3.8003 2 1.7803 5.401 1.7803H13.45ZM13.651 7.8603C13.38 7.8303 13.111 7.9503 12.95 8.1703L10.531 11.3003L7.76 9.1203C7.59 8.9903 7.39 8.9393 7.19 8.9603C6.991 8.9903 6.811 9.0993 6.69 9.2593L3.731 13.1103L3.67 13.2003C3.5 13.5193 3.58 13.9293 3.88 14.1503C4.02 14.2403 4.17 14.3003 4.34 14.3003C4.571 14.3103 4.79 14.1893 4.93 14.0003L7.44 10.7693L10.29 12.9103L10.38 12.9693C10.7 13.1393 11.1 13.0603 11.33 12.7593L14.22 9.0303L14.18 9.0503C14.34 8.8303 14.37 8.5503 14.26 8.3003C14.151 8.0503 13.91 7.8803 13.651 7.8603ZM17.5901 0C18.9201 0 20.0001 1.08 20.0001 2.41C20.0001 3.74 18.9201 4.82 17.5901 4.82C16.2601 4.82 15.1801 3.74 15.1801 2.41C15.1801 1.08 16.2601 0 17.5901 0Z"
    fill="white"
  />
</svg>
