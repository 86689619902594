import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SwitcherComponent } from './switcher.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SwitcherComponent],
  exports: [SwitcherComponent],
})
export class SwitcherModule {}
