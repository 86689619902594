import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconArrowShortComponent } from './file-detail.component';

@NgModule({
  declarations: [SvgIconArrowShortComponent],
  imports: [CommonModule],
  exports: [SvgIconArrowShortComponent],
})
export class SvgIconFileDetailModule {}
