import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';

import { AuthStateService } from '@slice-services/auth-state.service';
import { NavigationService } from '@slice-services/navigation.service';

import { E_ROUTES } from '@slice-enums/routes.enum';

@Injectable()
export class IsEmailConfirmedGuard implements CanActivate {
  constructor(
    private router: Router,
    private authStateService: AuthStateService,
    private navigationService: NavigationService,
  ) {}
  public canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.check(route);
  }

  private getCurrCleanRoute(route: ActivatedRouteSnapshot): string | UrlTree {
    return (
      '/' +
      route.pathFromRoot
        .filter(v => v.routeConfig)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map(v => v.routeConfig!.path)
        .join('/')
    );
  }
  private check(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const user = this.authStateService.getAuthenticatedUser();
    if (user?.emailConfirmed) {
      const isEmailSentRoute =
        this.getCurrCleanRoute(route) ===
        this.navigationService.getRoutePath(E_ROUTES.EMAIL_SENT);
      if (isEmailSentRoute) {
        return this.router.parseUrl(
          this.navigationService.getRoutePath(E_ROUTES.BASE),
        );
      } else {
        return true;
      }
    } else {
      return this.router.parseUrl(
        this.navigationService.getRoutePath(E_ROUTES.EMAIL_SENT),
      );
    }
  }
}
