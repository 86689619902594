import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconChevronDoubleInCircleComponent } from './chevron-double-in-circle.component';

@NgModule({
  declarations: [SvgIconChevronDoubleInCircleComponent],
  imports: [CommonModule],
  exports: [SvgIconChevronDoubleInCircleComponent],
})
export class SvgIconChevronDoubleInCircleModule {}
