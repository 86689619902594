import { UntypedFormControl } from '@angular/forms';

export function validateEmptyTextarea(c: UntypedFormControl): any {
  return c.value && !c.value.trim()
    ? {
        required: {
          valid: false,
        },
      }
    : null;
}
