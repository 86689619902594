import { Injectable } from '@angular/core';

import { AuthHttpService } from '@slice-services/auth-http.service';
import { LocalStorageService } from '@slice-services/local-storage.service';
import { MixPanelService } from '@slice-services/mix-panel.service';
import { SessionStorageService } from '@slice-services/session-storage.service';
import { StripeService } from '@slice-services/stripe-http.service';
import { UiStateService } from '@slice-services/ui-state.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { environment } from '@slice-env/environment';

@Injectable({ providedIn: 'root' })
export class AppInitializerService {
  constructor(
    private authHttpS: AuthHttpService,
    private lsS: LocalStorageService,
    private ssS: SessionStorageService,
    private stripeS: StripeService,
    private uiStateS: UiStateService,
    private mixPanelS: MixPanelService,
  ) {}
  public initialization(): Observable<any> {
    this._detectAppVersion();
    this.mixPanelS.init();
    this.stripeS.initStripe();
    const rt = this.lsS.getRefreshToken();
    return rt
      ? this.authHttpS
          .getDataByRefreshToken(this.lsS.getRefreshToken() as string)
          // we need receive user for redirect (profile complete ? email confirmed?)
          .pipe(switchMap(() => this.authHttpS.me()))
      : of(true);
  }

  private _detectAppVersion(): void {
    const currVersion = environment.appVersion;
    console.log('App version - ', environment.appVersion);
    const prevVersion = this.lsS.getAppVersion();
    if (prevVersion !== currVersion) {
      this.lsS.clearAfterChangeVersion();
      this.ssS.clearAfterChangeVersion();
      this.lsS.setAppVersion(currVersion);
    }
    this.uiStateS.setAppVersion(currVersion);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function initServiceFactory(appInitService: AppInitializerService): any {
  return (): Observable<any> => appInitService.initialization();
}
