import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'slice-result-modal',
  templateUrl: './result-modal.component.html',
  styleUrls: ['./result-modal.component.scss'],
})
export class ResultModalComponent {
  @Input() iconPath: string;
  @Input() text: string;
  @Input() tmpl: TemplateRef<any>;

  public isVisible = true;

  @Output() closed = new EventEmitter<boolean>();

  onHide(): void {
    this.closed.emit(false);
  }
}
