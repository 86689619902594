import {
  getBrandNameMock,
  getCampaignNameMock,
  getIdMock,
  getNumberMock,
  getRandomSentense,
  getUsernameMock,
} from '@slc-libs/mocks';

import {
  CampaignDetailsModel,
  CampaignDetailsPublic,
} from '@slice-interfaces/brand-campaign-details';
import { CampaignModelStatus } from '@slice-enums/campaign-status';
import { GeographyType } from '@slice-enums/geography';

import { getRandomBrandCampaignStatusMock } from '../_helpers/brand-campaign-statuses';
import { getBriefNameMock } from '../_helpers/brief';
import { getBriefBrandStatsMock } from './brand-campaign-stats';

export const getBrandCampaignDetailsMock = (
  status?: CampaignModelStatus,
): CampaignDetailsModel => {
  return {
    campaignId: getIdMock(),
    author: getUsernameMock(),
    briefId: getIdMock(),
    brandName: getBrandNameMock(),
    dueDate: new Date(
      new Date().getTime() -
        Math.ceil(Math.random() * 31 * 24 * 60 * 60 * 1000),
    ).toString(),
    name: getCampaignNameMock(),
    description:
      Math.random() > 0.5 ? getRandomSentense(50) : getRandomSentense(10),
    status: status ? status : getRandomBrandCampaignStatusMock(),
    startDate: new Date(
      new Date().getTime() -
        Math.ceil(Math.random() * 31 * 24 * 60 * 60 * 1000),
    ).toString(),
    endDate: new Date(
      new Date().getTime() -
        Math.ceil(Math.random() * 31 * 24 * 60 * 60 * 1000),
    ).toString(),
    cities: [
      {
        defaultName: 'Bandar Lampung',
        id: '4d323b00-5ef4-4267-992f-2c85653dab80',
        nameKey: 'bandar_lampung',
        parentId: '4e20d255-44e4-4a86-9c83-76c772fee5c6',
        type: GeographyType.city,
      },
    ],
    funnel: getBriefNameMock(),
    brandStats: getBriefBrandStatsMock(),
  };
};

export const getCampaignDetailsPublicMock = (): CampaignDetailsPublic => {
  return {
    name: getCampaignNameMock(),
    comment: '',
    briefId: getIdMock(),
    estimatedReach: getNumberMock(1000),
    estimatedEngagementRate: getNumberMock(1000),
    desiredBudget: getNumberMock(1000),
    currentBudget: getNumberMock(1000),
    cpe: getNumberMock(1000),
    cpr: getNumberMock(1000),
    cpv: getNumberMock(1000),
  };
};
