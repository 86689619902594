import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import {
  CampaignListTopup,
  PlatformFee,
} from '@slice-interfaces/wallet/wallet-balance';
@Injectable({
  providedIn: 'root',
})
export class ChangePinStateService {
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public isShowModalChangePin$ = new BehaviorSubject<boolean>(false);
  public isShowModalVerification$ = new BehaviorSubject<boolean>(false);
  public forgotPinModal$ = new BehaviorSubject<boolean>(false);
  public isShowResetPin$ = new BehaviorSubject<boolean>(false);
  public verificationCode$ = new BehaviorSubject<string>('');
  public email$ = new BehaviorSubject<string>('');
  // public withdrawalFee$ = new BehaviorSubject<PlatformFee | null>(null);
  // public campaignList$ = new BehaviorSubject<Array<CampaignListTopup>>([]);
}
