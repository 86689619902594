<ng-container [ngSwitch]="currType">
  <img
    *ngSwitchCase="TYPES.image"
    src="{{ sourceUrl }}"
    alt=""
    class="content-img"
  />
  <button
    *ngSwitchCase="TYPES.video"
    (click)="isVideoPopupVisible = true"
    type="button"
    class="video-btn"
  >
    <slc-svg-icon-play></slc-svg-icon-play>
  </button>
</ng-container>

<p-dialog
  *ngIf="isVideoPopupVisible"
  [(visible)]="isVideoPopupVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="hideVideoPopup()"
>
  <p-header
    ><div class="slc-text-ellipsis">
      {{ videoModalTitle }}
    </div></p-header
  >
  <main class="slc-pos-rel">
    <slice-video-player
      class="slc-flex-c-c"
      [ngStyle]="{
        'margin-left': 'auto',
        'margin-right': 'auto'
      }"
      [options]="{
        fluid: false,
        autoplay: true,
        controls: true,
        muted: false,
        sources: [
          {
            src: sourceUrl,
            type: 'video/mp4'
          }
        ]
      }"
      (playStarted)="playStarted()"
    ></slice-video-player>
    <slice-loader *ngIf="!isPlayStarted" class="slc-fluid-box"></slice-loader>
  </main>
  <p-footer>
    <div class="slc-flex-c-c">
      <button
        class="slc-btn slc-btn-outline-blue"
        (click)="hideVideoPopup()"
        type="button"
      >
        <span>{{ 'buttons.close' | transloco }}</span>
      </button>
    </div>
  </p-footer>
</p-dialog>
