<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="false"
  (onHide)="triggerHide()"
>
  <p-header> Confirm Email </p-header>
  <slice-loader *ngIf="isLoading" class="slice-loader-fluid"></slice-loader>
  <div *ngIf="!isLoading" class="lp-main-content">
    <form (ngSubmit)="submit()" [formGroup]="form">
      <div class="d-flex flex-xl-row gap-1">
        <div class="w-100 form-wrapper rounded shadow-sm mt-3">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            formControlName="email"
            placeholder="Type Here"
          />
        </div>
      </div>
      <div class="d-flex flex-row-reverse">
        <button type="submit" class="slc-btn slc-btn-primary mt-4">Save</button>
      </div>
    </form>
  </div>
</p-dialog>
