import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { BreakpointsService } from '@slc-libs/services';

import { DIALOG_RESULT_MODAL_TYPE } from '@slice-shared/components/dialog-result-modal/dialog-result-modal.component';
import { WITHDRAW_MODAL_TYPE } from '@slice-shared/modules/balance/components/withdraw-response-modal/withdraw-response-modal.component';
import { WithdrawAreas } from '@slice-enums/wallet-balance';

@Injectable({
  providedIn: 'root',
})
export class UiStateService {
  private isTouchScreen: boolean;
  private isAppMenuOpen$ = new BehaviorSubject<boolean>(this.bS.getIsDesktop());
  private isAppMenuVisible$ = new BehaviorSubject<boolean>(false);
  private isHeaderNavigationVisible$ = new BehaviorSubject<boolean>(false);
  private isHeaderVisible$ = new BehaviorSubject<boolean>(false);
  // private isHeaderGreen$ = new BehaviorSubject<boolean>(false);
  private _appVersion: string;

  private openSliceModals: DIALOG_RESULT_MODAL_TYPE[] = [];
  private currentSliceModals: DIALOG_RESULT_MODAL_TYPE | null = null;
  private showSliceModal$ =
    new BehaviorSubject<DIALOG_RESULT_MODAL_TYPE | null>(null);
  private showDialogWithdrawResponseModal$ =
    new BehaviorSubject<WITHDRAW_MODAL_TYPE | null>(null);
  private showAgencyPricing$ = new BehaviorSubject<boolean>(false);
  private showModalRateCard$ = new BehaviorSubject<boolean>(false);
  private showCreatorWalletWithdraw$ = new BehaviorSubject<boolean>(false);

  constructor(private bS: BreakpointsService) {
    this.detectTouchScreenDevice();
  }

  private detectTouchScreenDevice(): void {
    this.isTouchScreen =
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      (navigator as any).msMaxTouchPoints > 0;
  }

  get isTouchScreenDevice(): boolean {
    return this.isTouchScreen;
  }

  get appVersion(): string {
    return this._appVersion;
  }
  setAppVersion(v: string): void {
    this._appVersion = v;
  }

  selectIsAppMenuOpen(): Observable<boolean> {
    return this.isAppMenuOpen$.asObservable();
  }
  openAppMenu(): void {
    return this.isAppMenuOpen$.next(true);
  }
  closeAppMenu(): void {
    return this.isAppMenuOpen$.next(false);
  }
  toggleMenu(): void {
    return this.isAppMenuOpen$.next(!this.isAppMenuOpen$.value);
  }

  selectIsHeaderVisible(): Observable<boolean> {
    return this.isHeaderVisible$.asObservable();
  }
  setIsHeaderVisible(val: boolean): void {
    return this.isHeaderVisible$.next(val);
  }

  selectIsMenuVisible(): Observable<boolean> {
    return this.isAppMenuVisible$.asObservable();
  }
  setIsMenuVisible(val: boolean): void {
    return this.isAppMenuVisible$.next(val);
  }

  selectIsHeaderNavigationVisible(): Observable<boolean> {
    return this.isHeaderNavigationVisible$.asObservable();
  }
  setIsHeaderNavigationVisible(val: boolean): void {
    return this.isHeaderNavigationVisible$.next(val);
  }

  selectDialogAgencyPricing(): Observable<boolean> {
    return this.showAgencyPricing$.asObservable();
  }
  showAgencyPricing(value = true): void {
    this.showAgencyPricing$.next(value);
  }

  selectDialogSliceModal(): Observable<DIALOG_RESULT_MODAL_TYPE | null> {
    return this.showSliceModal$.asObservable();
  }
  showSliceModal(type: DIALOG_RESULT_MODAL_TYPE): void {
    this.openSliceModals.push(type);
    this.currentSliceModals = type;

    // Emit the latest dialog type
    setTimeout(() => {
      this.showSliceModal$.next(type);
    }, 0);
  }
  closeSliceModal(reset = false) {
    if (!reset && this.openSliceModals.length > 0) {
      this.openSliceModals.pop();
      this.currentSliceModals =
        this.openSliceModals[this.openSliceModals.length - 1];

      // Emit the currently visible dialog type
      setTimeout(() => {
        this.showSliceModal$.next(this.currentSliceModals);
      }, 500);
    } else {
      this.openSliceModals = [];
      this.currentSliceModals = null;

      this.showSliceModal$.next(null);
    }
  }

  selectDialogCreatorWalletWithdraw(): Observable<boolean> {
    return this.showCreatorWalletWithdraw$.asObservable();
  }

  showCreatorWalletWithdraw(value = true): void {
    this.showCreatorWalletWithdraw$.next(value);
  }

  selectDialogWithdrawResponseModal(): Observable<WITHDRAW_MODAL_TYPE | null> {
    return this.showDialogWithdrawResponseModal$.asObservable();
  }
  showDialogWithdrawResponseModal(type: WITHDRAW_MODAL_TYPE | null): void {
    this.showDialogWithdrawResponseModal$.next(type);
  }
  selectModalRateCard(): Observable<boolean> {
    return this.showModalRateCard$.asObservable();
  }
  getModalRateCard(): boolean {
    return this.showModalRateCard$.value;
  }
  setModalRateCard(val: boolean): void {
    return this.showModalRateCard$.next(val);
  }
}
