import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';
import { TableModule } from 'primeng/table';

import { SvgIconQuestionCircleModule } from '@slc-libs/svg-icons';
import { AppTooltipModule } from '@slc-libs/tooltip';

import { TableBodyRowComponent } from './components/table-body-row/table-body-row.component';
import { TableHeadCellComponent } from './components/table-head-cell/table-head-cell.component';
import { TableSkeletonRowsPipe } from './pipes/table-skeleton-rows.pipe';
@NgModule({
  imports: [
    CommonModule,
    SvgIconQuestionCircleModule,
    AppTooltipModule,
    TranslocoModule,
    TableModule,
  ],
  declarations: [
    TableHeadCellComponent,
    TableSkeletonRowsPipe,
    TableBodyRowComponent,
  ],
  exports: [
    TableHeadCellComponent,
    TableSkeletonRowsPipe,
    TableBodyRowComponent,
  ],
})
export class AppTablesModule {}
