import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AssetsHttpService } from '@slice-services/assets-http.service';
import { MessageService } from 'primeng/api';
import { switchMap, takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { BriefProposalDetailsModel } from '@slice-interfaces/brief-proposal/brief-proposals';
import { AssetsType, NewAssetsType } from '@slice-enums/assets/asset-type';

import { TotalEarningFacadeService } from '../services/total-earning-facades.service';

@Component({
  selector: 'slice-modal-tax-upload',
  templateUrl: './modal-tax.component.html',
  styleUrls: ['./modal-tax.component.scss'],
})
export class ModalTaxComponent
  extends AbstractSubscriberComponent
  implements OnInit
{
  @Output() fetchData = new EventEmitter<any>();

  file: any[] = [];
  isVisible: boolean;
  idEarning = '';
  idPayment = '';
  isLoadingSubmit = false;
  isLoading = false;
  offerData: BriefProposalDetailsModel | null;
  public assetType: NewAssetsType;
  constructor(
    private facadeS: TotalEarningFacadeService,
    private imgHttpS: AssetsHttpService,
    private mS: MessageService,
    private tS: TranslocoService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.assetType = NewAssetsType.PDF;
    this.facadeS
      .selectIsModalTax()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        this.isVisible = r;
      });
    this.facadeS
      .selectPaymentHistoryId()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        this.idPayment = r;
      });
    this.facadeS
      .selectIdEarning()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        this.idEarning = r;
      });
  }

  triggerHide(): void {
    this.facadeS.setIsModalTax(false);
  }
  selected(files: Array<File>): void {
    this.file = files;
    // this.facadeS.setIsModalContract(false);
  }

  //upload contract
  uploadContract() {
    this.isLoadingSubmit = true;
    this.imgHttpS
      .uploadAssets({
        type: AssetsType.AVATAR,
        file: this.file[0],
      })
      .pipe(
        switchMap(res => {
          return this.facadeS.submitTaxDoc(
            this.idEarning as string,
            this.idPayment as string,
            {
              taxCertificateAssetId: res.fileId,
            },
          );
        }),
      )
      .subscribe(
        () => {
          this.isLoadingSubmit = false;
          this.mS.clear();
          this.mS.add({
            summary: 'Success!',
            severity: 'success',
            life: 5 * 1000,
            detail: 'Bukti Potong Pajak Submitted',
          });
          this.facadeS.setIsModalTax(false);
          this.facadeS.setPaymentHistoryId('');
          this.fetchData.emit();
        },
        () => {
          this.isLoadingSubmit = false;
        },
      );
  }

  submit() {
    this.uploadContract();
  }
}
