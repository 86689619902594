import { Component, Input } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-icon-close',
  styles: [
    ':host { display: flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './close.component.html',
})
export class SvgIconCloseComponent extends AbstractSvgIconComponent {
  @Input() height? = 16;
  @Input() width? = 16;
}
