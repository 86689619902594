import { getCampaignNameMock } from '@slc-libs/mocks';
import { getPagebaleResponseMock } from '@slc-libs/mocks';
import { getIdMock } from '@slc-libs/mocks';
import { getBrandNameMock } from '@slc-libs/mocks';
import { getUsernameMock } from '@slc-libs/mocks';
import { getRandomSentense } from '@slc-libs/mocks';

import { CampaignModel } from '@slice-interfaces/campaign-status/campaign-status';
import { CampaignsParams } from '@slice-interfaces/campaign-status/filters';
import { CampaignsResponse } from '@slice-interfaces/campaign-status/get-list';

import { getCampaignModelStatusMock } from '../campaign-status';
// import { CampaignModelStatus } from '@slice-enums/campaign-status';

const _getCampaignStatusBrand = (
  params: CampaignsParams,
  res: CampaignsResponse,
): Array<CampaignModel> => {
  const list = [];
  // const statuses = (
  //   params.status ? params.status : Object.values(CampaignModelStatus)
  // ).filter(i => i !== CampaignModelStatus.COMPLETED);
  // let order: any = [];
  for (
    let i = 0;
    i < (res.last ? res.totalElements - res.number * res.size : params.size);
    i++
  ) {
    list.push({
      campaignId: getIdMock(),
      author: getUsernameMock(),
      briefId: getIdMock(),
      brandName: getBrandNameMock(),
      dueDate: new Date(
        new Date().getTime() -
          Math.ceil(Math.random() * 31 * 24 * 60 * 60 * 1000),
      ).toString(),
      name: getCampaignNameMock(),
      description:
        Math.random() > 0.5 ? getRandomSentense(50) : getRandomSentense(10),
      status: getCampaignModelStatusMock(),
    });
  }
  return list;
};

export const getCampaignsMocks = (
  params: CampaignsParams,
): CampaignsResponse => {
  const res = getPagebaleResponseMock(params, 15);
  res.content = _getCampaignStatusBrand(params, res as any);
  return res as CampaignsResponse;
};
