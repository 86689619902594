import { Component, EventEmitter, Input, Output } from '@angular/core';

import { getUsernameMock } from '@slc-libs/mocks';

@Component({
  selector: 'slice-search-creators',
  templateUrl: './search-creators.component.html',
  styleUrls: ['./search-creators.component.scss'],
})
export class SearchCreatorsComponent {
  @Input() multiple = true;
  public value: string | undefined;
  public suggestions: Array<{ name: string }>;
  @Output() selectedValue = new EventEmitter<string | undefined>();

  search(e: { query: string }): void {
    setTimeout(() => {
      this.suggestions = [
        {
          name: getUsernameMock(),
        },
        {
          name: getUsernameMock(),
        },
        {
          name: getUsernameMock(),
        },
        {
          name: getUsernameMock(),
        },
        {
          name: getUsernameMock(),
        },
        {
          name: getUsernameMock(),
        },
        {
          name: getUsernameMock(),
        },
      ];
    });
    // this.selectedValue?.emit(e.query);
  }

  onSelect(e: { name: string }): void {
    this.selectedValue?.emit(e.name);
    this.value = '';
  }

  onClear(): void {
    this.selectedValue?.emit(undefined);
  }
}
