import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconCircleWavyCheckComponent } from './circle-wavy-check.component';

@NgModule({
  declarations: [SvgIconCircleWavyCheckComponent],
  imports: [CommonModule],
  exports: [SvgIconCircleWavyCheckComponent],
})
export class SvgIconCircleWavyCheckModule {}
