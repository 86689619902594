import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconPlantComponent } from './plant.component';

@NgModule({
  declarations: [SvgIconPlantComponent],
  imports: [CommonModule],
  exports: [SvgIconPlantComponent],
})
export class SvgIconPlantModule {}
