import { Directive, Input, TemplateRef } from '@angular/core';

// eslint-disable-next-line
@Directive({ selector: 'ng-template[typedTemplate]' })
export class TypedTemplateDirective<TypeToken> {
  // eslint-disable-next-line
  @Input('typedTemplate') typeToken: TypeToken;

  constructor(private contentTemplate: TemplateRef<TypeToken>) {}

  // this magic is how we tell Angular the context type for this directive, which then propagates down to the type of the template
  static ngTemplateContextGuard<TypeToken>(
    dir: TypedTemplateDirective<TypeToken>,
    ctx: unknown,
  ): ctx is TypeToken {
    return true;
  }
}
