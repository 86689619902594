import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

import { SwitcherOptions } from './switcher-options';

@Component({
  selector: 'slc-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
})
export class SwitcherComponent {
  @Input() set currValue(val: unknown) {
    this.value = val;
    this.initView();
  }
  @Input() set items(items: Array<SwitcherOptions> | undefined | null) {
    this.list = items || [];
    this.initView();
  }
  @Input() isVertical: boolean;

  public list: Array<SwitcherOptions>;
  public value: unknown;

  public currItem: SwitcherOptions | undefined;
  public currIndex: number;

  private verticalCssClass = 'slc-switcher-vertical';

  @HostBinding('class') get classes(): string {
    return [
      this.isVertical ? this.verticalCssClass : '',
      this.list.length > 2 ? 'slc-switcher-more-than-2' : '',
    ].join(' ');
  }
  @Output() changed = new EventEmitter<unknown>();

  private initView(): void {
    const index = this.list?.findIndex(i => i.value === this.value);
    this.currIndex = typeof index === 'number' ? index : -1;
    this.currItem = this.list?.find(i => i.value === this.value);
  }

  select(i: SwitcherOptions): void {
    this.currItem = i;
    this.currIndex = this.list.findIndex(i => i === this.currItem);
    this.changed?.emit(i.value);
  }
}
