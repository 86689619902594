<div class="row">
  <div *ngFor="let item of items" class="{{ gridCssClasses }}">
    <slc-checkbox
      defaultName="{{ item.defaultName }}"
      [value]="selectedIds?.includes(item.id)"
      [disabled]="!item.isEnabled"
      (changed)="changeSelection($event, item)"
    ></slc-checkbox>
  </div>
</div>

<!-- <slice-tag-create
  [tagType]="categoryType"
  inputPlaceholder="{{ translateKeys.otherPlaceholder | transloco }}"
  [loading]="createLoading"
  (addTag)="addTag($event)"
>
</slice-tag-create> -->
