import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

import { NgSelectComponent } from '@ng-select/ng-select';

import { Select } from '@slice-interfaces/select/select';

@Component({
  selector: 'slice-ng-select-multi-label',
  templateUrl: './ng-select-multi-label.component.html',
  styleUrls: ['./ng-select-multi-label.component.scss'],
})
export class NgSelectMultiLabelComponent {
  @Input() ngSelect!: NgSelectComponent;
  @Input() items: Array<Select> | null;
  @Input() clearable = true;

  @Output() remove = new EventEmitter<any>();

  @HostListener('click') onClick(): void {
    this.ngSelect?.toggle();
  }

  // for toggle ngselect open state
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  @HostListener('mousedown', ['$event']) onMousedown(e: any): void {
    e.stopPropagation();
  }
}
