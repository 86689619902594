import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconCoinsComponent } from './coins.component';

@NgModule({
  declarations: [SvgIconCoinsComponent],
  imports: [CommonModule],
  exports: [SvgIconCoinsComponent],
})
export class SvgIconCoinsModule {}
