import { Pipe, PipeTransform } from '@angular/core';

const PADDING = '000000';

@Pipe({
  name: 'rupiah',
})
export class RupiahPipe implements PipeTransform {
  DECIMAL_SEPARATOR: string;
  THOUSANDS_SEPARATOR: string;

  constructor() {
    this.DECIMAL_SEPARATOR = '.';
    this.THOUSANDS_SEPARATOR = ',';
  }

  transform(
    value: number | string | null | undefined,
    symbol: string = 'IDR ', // 'Rp ',
    fractionSize: number = 0,
  ): any {
    let [integer, fraction] = (value || 0)
      .toString()
      .split(this.DECIMAL_SEPARATOR);

    fraction =
      fractionSize > 0
        ? this.DECIMAL_SEPARATOR +
          (fraction + PADDING).substring(0, fractionSize)
        : '';

    integer = integer.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      this.THOUSANDS_SEPARATOR,
    );

    if (integer !== 'NaN') {
      return symbol + integer + fraction;
    } else if (integer === 'NaN') {
      return symbol + '-';
    }
  }

  parse(value: string, fractionSize: number = 2): string {
    let [integer, fraction = ''] = (value || '').split(this.DECIMAL_SEPARATOR);

    integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, 'g'), '');

    fraction =
      parseInt(fraction, 10) > 0 && fractionSize > 0
        ? this.DECIMAL_SEPARATOR +
          (fraction + PADDING).substring(0, fractionSize)
        : '';

    return integer + fraction;
  }
}
