import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconFacebookComponent } from './facebook.component';

@NgModule({
  declarations: [SvgIconFacebookComponent],
  imports: [CommonModule],
  exports: [SvgIconFacebookComponent],
})
export class SvgIconFacebookModule {}
