import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

import { SortMeta } from 'primeng/api';
import { Table } from 'primeng/table';

import { BreakpointsService } from '@slc-libs/services';
import { TableColumn } from '@slc-libs/tables';
import { TABLES_MOBILE_BREAKPOINT } from '@slc-libs/tables';

import { TableColumnAlignment } from '../../interfaces/column';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[slc-table-head-cell]',
  templateUrl: './table-head-cell.component.html',
  styleUrls: ['./table-head-cell.component.scss'],
})
export class TableHeadCellComponent implements OnInit {
  @Input() pTable: Table;
  @Input() column!: TableColumn;
  @Input() sort?: Array<SortMeta>;

  public alignmnets = {
    [TableColumnAlignment.center]: 'slc-flex-c-c',
    [TableColumnAlignment.left]: 'slc-flex-fs-c',
    [TableColumnAlignment.right]: 'slc-flex-fe-c',
  };
  public TABLES_MOBILE_BREAKPOINT = TABLES_MOBILE_BREAKPOINT;

  @HostListener('click') onClick(): void {
    if (this.column.isSortable) {
      if (this.pTable.sortMode === 'single') {
        this.pTable.sortField = this.column.field as string;
        this.pTable.sortOrder = this.pTable.sortOrder === 1 ? -1 : 1;
        const data = [
          {
            field: this.pTable.sortField,
            order: this.pTable.sortOrder,
          },
        ];
        this.pTable.multiSortMeta = data;
        //modified the sorting. make the sort look alike multisort so the controller will handle it
        //TODO: Change all the table into tableDynamic. try not use this libs
        this.pTable.sortSingle();
      } else if (this.pTable.sortMode === 'multiple') {
        const item = this.pTable.multiSortMeta.find(
          i => i.field === this.column.field,
        );
        if (item) {
          if (item.order === 1) {
            item.order = -1;
          } else {
            this.pTable.multiSortMeta = this.pTable.multiSortMeta.filter(
              i => i.field !== this.column.field,
            );
          }
        } else {
          this.pTable.multiSortMeta.push({
            field: this.column.field as string,
            order: 1,
          });
        }
        this.pTable.sortMultiple();
      }
    }
  }

  constructor(public bS: BreakpointsService, private elRef: ElementRef) {}

  ngOnInit(): void {
    const el = this.elRef.nativeElement as HTMLElement;
    if (this.column.isSortable) {
      el.setAttribute('pSortableColumn', this.column.field as string);
    }
    if (this.column.width) {
      el.setAttribute('width', this.column.width as string);
    }
    if (el.tagName === 'DIV') {
      el.classList.add('slc-flex-fs-c');
    }
  }
}
