import { Channels } from '@slc-libs/enums';
import { getNumberMock, getUsernameMock } from '@slc-libs/mocks';
import { getPagebaleResponseMock } from '@slc-libs/mocks';
import { getIdMock } from '@slc-libs/mocks';

import { PublicClientsRosterFilters } from '@slice-interfaces/public-client-roster/public-client-roster-filters';
import { PublicClientsRosterItem } from '@slice-interfaces/public-client-roster/public-client-roster-item';
import { PublicClientsRosterResponse } from '@slice-interfaces/public-client-roster/public-client-roster-response';

const _getList = (
  params: PublicClientsRosterFilters,
  res: PublicClientsRosterResponse,
): Array<PublicClientsRosterItem> => {
  const list: Array<PublicClientsRosterItem> = [];

  for (
    let i = 0;
    i < (res.last ? res.totalElements - res.number * res.size : params.size);
    i++
  ) {
    list.push({
      creatorId: getIdMock(),
      creatorName: getUsernameMock(),
      followers: {
        [Channels.INSTAGRAM]: getNumberMock(100),
        [Channels.YOUTUBE]: getNumberMock(100),
      },
      recentPartnerships: (() => {
        const res = [];
        const amount = getNumberMock(30);
        for (let i = 0; i < amount; i++) {
          res.push(getUsernameMock());
        }
        return res;
      })(),
    });
  }
  return list;
};

export const getPublicClientsRosterResponseMocks = (
  params: PublicClientsRosterFilters,
): PublicClientsRosterResponse => {
  const res = getPagebaleResponseMock(params);
  res.content = _getList(params, res as any);
  return res as PublicClientsRosterResponse;
};
