import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';

import {
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  TranslocoConfig,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
} from '@ngneat/transloco';
import { TranslocoMissingHandler } from '@ngneat/transloco';
import {
  TRANSLOCO_PERSIST_LANG_STORAGE,
  TranslocoPersistLangModule,
} from '@ngneat/transloco-persist-lang';
import { LocalStorageService } from '@slice-services/local-storage.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IsoLanguages } from '@slc-libs/types';

import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, private lsS: LocalStorageService) {}

  getTranslation(lang: IsoLanguages): Observable<Translation> {
    if (environment.production) {
      const loc = this.lsS.getLocalization(lang);
      if (loc) {
        return of(loc);
      } else {
        return this.getLoc(lang);
      }
    } else {
      return this.getLoc(lang);
    }
  }

  private getLoc(lang: IsoLanguages): Observable<Translation> {
    return this.http
      .get<Translation>(
        `/assets/i18n/${lang}.json?version=${environment.appVersion}`,
        {
          headers: new HttpHeaders({
            'Cache-Control':
              'no-cache, no-store, must-revalidate, post- check=0, pre-check=0',
            Pragma: 'no-cache',
            Expires: '0',
          }),
        },
      )
      .pipe(tap(r => this.lsS.setLocalization(lang, r)));
  }
}

export class CustomHandler implements TranslocoMissingHandler {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handle(key: string, config: TranslocoConfig): string {
    if (!environment.production) {
      console.warn('Transloco. Missing key - ', key);
    }
    return key;
  }
}

export const customMissingHandler = {
  provide: TRANSLOCO_MISSING_HANDLER,
  useClass: CustomHandler,
};

@NgModule({
  imports: [
    TranslocoPersistLangModule.forRoot({
      storage: {
        provide: TRANSLOCO_PERSIST_LANG_STORAGE,
        useValue: localStorage,
      },
    }),
  ],
  exports: [TranslocoModule],
  providers: [
    customMissingHandler,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [
          IsoLanguages.ENGLISH_US,
          IsoLanguages.BAHANA_INDONESIA,
        ],
        defaultLang: IsoLanguages.ENGLISH_US,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
