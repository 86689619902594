import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconInvoicingComponent } from './invoicing.component';

@NgModule({
  declarations: [SvgIconInvoicingComponent],
  imports: [CommonModule],
  exports: [SvgIconInvoicingComponent],
})
export class SvgIconInvoicingModule {}
