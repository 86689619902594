<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.5328 4.48399L9.99663 6.02012C9.75255 6.26419 9.35682 6.26419 9.11275 6.02012C8.86867 5.77604 8.86867 5.38031 9.11275 5.13623L10.6596 3.58936C10.6664 3.58254 10.6734 3.57588 10.6805 3.56938C11.4543 2.86416 12.47 2.48419 13.5166 2.50842C14.5632 2.53265 15.5603 2.95922 16.3006 3.69949C17.0408 4.43976 17.4674 5.43681 17.4916 6.48343C17.5159 7.53005 17.1359 8.54577 16.4307 9.3195C16.4239 9.3269 16.417 9.33414 16.4099 9.34121L14.2006 11.5427C14.2003 11.543 14.2001 11.5432 14.1998 11.5435C13.8233 11.9212 13.3759 12.221 12.8833 12.4256C12.3904 12.6303 11.8619 12.7357 11.3281 12.7357C10.7944 12.7357 10.2659 12.6303 9.77296 12.4256C9.28004 12.2209 8.83238 11.9208 8.45567 11.5427C8.21205 11.2982 8.21279 10.9025 8.45732 10.6588C8.70186 10.4152 9.09758 10.416 9.34121 10.6605C9.60178 10.922 9.91143 11.1296 10.2524 11.2712C10.5934 11.4128 10.9589 11.4857 11.3281 11.4857C11.6973 11.4857 12.0629 11.4128 12.4039 11.2712C12.7448 11.1296 13.0545 10.922 13.315 10.6605L13.3167 10.6589L15.5164 8.4669C15.9989 7.93212 16.2587 7.23281 16.242 6.51236C16.2252 5.78716 15.9296 5.09631 15.4167 4.58337C14.9037 4.07044 14.2129 3.77487 13.4877 3.75808C12.7671 3.7414 12.0676 4.00126 11.5328 4.48399Z"
    fill="black"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8.67144 8.5144C8.30224 8.5144 7.93667 8.5873 7.59571 8.7289C7.25475 8.87051 6.9451 9.07804 6.68452 9.33959L6.68291 9.3412L4.48315 11.5332C4.00065 12.068 3.74091 12.7673 3.75759 13.4877C3.77438 14.2129 4.06995 14.9038 4.58289 15.4167C5.09582 15.9297 5.78667 16.2252 6.51187 16.242C7.2325 16.2587 7.93197 15.9988 8.46681 15.5161L10.0029 13.98C10.247 13.7359 10.6427 13.7359 10.8868 13.98C11.1309 14.2241 11.1309 14.6198 10.8868 14.8639L9.33995 16.4107C9.33313 16.4176 9.32615 16.4242 9.31902 16.4307C8.54528 17.1359 7.52957 17.5159 6.48294 17.4917C5.43632 17.4674 4.43928 17.0409 3.699 16.3006C2.95873 15.5603 2.53216 14.5633 2.50793 13.5167C2.4837 12.47 2.86367 11.4543 3.56889 10.6806C3.57564 10.6732 3.58256 10.6659 3.58966 10.6589L5.79983 8.45651C6.17637 8.07879 6.62372 7.77907 7.11628 7.5745C7.6092 7.36979 8.1377 7.2644 8.67144 7.2644C9.20519 7.2644 9.73368 7.36979 10.2266 7.5745C10.7195 7.77922 11.1672 8.07924 11.5439 8.45736C11.7875 8.7019 11.7868 9.09762 11.5422 9.34125C11.2977 9.58487 10.902 9.58413 10.6584 9.33959C10.3978 9.07804 10.0881 8.87051 9.74717 8.7289C9.40621 8.5873 9.04064 8.5144 8.67144 8.5144Z"
    fill="black"
  />
</svg>
