import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconCheckmarkComponent } from './checkmark.component';

@NgModule({
  declarations: [SvgIconCheckmarkComponent],
  imports: [CommonModule],
  exports: [SvgIconCheckmarkComponent],
})
export class SvgIconCheckmarkModule {}
