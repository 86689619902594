import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconCheckboxPolicyComponent } from './checkbox-policy.component';

@NgModule({
  declarations: [SvgIconCheckboxPolicyComponent],
  imports: [CommonModule],
  exports: [SvgIconCheckboxPolicyComponent],
})
export class SvgIconCheckboxPolicyModule {}
