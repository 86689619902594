export enum PlanTypes {
  Free = 'Free',
  Lite = 'Lite',
  Standard = 'Standard',
  Plus = 'Plus',
  Premium = 'Premium',
}

export enum BillingTypes {
  annual = 'annual',
  monthly = 'monthly',
}
export interface SignUpPageQueryParams
  extends Record<string, string | undefined> {
  lang: string;
  billingPeriod?: BillingTypes;
  invitationId?: string;
  code?: string;
  planType?: PlanTypes;
}

export interface SignUpAgencyPageQueryParams
  extends Record<string, string | undefined> {
  lang: string;
  code?: string;
  email?: string;
  fullName?: string;
}
