import { Component, EventEmitter, Input, Output } from '@angular/core';

import { User } from '@slice-interfaces/users/user.intf';

@Component({
  selector: 'slice-invite-creator-list',
  templateUrl: './invite-creator-list.component.html',
  styleUrls: ['./invite-creator-list.component.scss'],
})
export class InviteCreatorListComponent {
  @Input() insideList: Array<User> = [];
  @Input() outsideList: Array<{ email: string }> = [];
  @Output() clientsRemoved = new EventEmitter<{
    isRoster: boolean;
    userData: User | { email: string };
  }>();

  removeInternalClient(user: User): void {
    this.clientsRemoved.emit({ isRoster: true, userData: user });
  }

  removeExternalClient(user: { email: string }): void {
    this.clientsRemoved.emit({ isRoster: false, userData: user });
  }
}
