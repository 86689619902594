import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { UsersHttpService } from '@slice-services/users-http.service';
import { EMAIL_PATTERN } from 'apps/slice/src/app/_const/email-pattern';
import { MessageService, SelectItemGroup } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

import { environment } from '@slice-env/environment';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { AgencyInvitationFormModel } from '@slice-interfaces/agency-invitation/agency-invite-payload';
import {
  ClientsSelectionModel,
  User,
} from '@slice-interfaces/clients-selector/clients-selector';
import { USER_INVITE_STATUS } from '@slice-enums/user';

@Component({
  selector: 'slice-invite-creator-email',
  templateUrl: './invite-creator-email.component.html',
  styleUrls: ['./invite-creator-email.component.scss'],
})
export class InviteCreatorEmailComponent
  extends AbstractSubscriberComponent
  implements OnDestroy
{
  @Input() selectedUser: AgencyInvitationFormModel;
  @Input() statuses?: Array<USER_INVITE_STATUS>;
  @Input() disabled: boolean;

  public value: string;
  public hideTooltip = true;
  public suggestions: SelectItemGroup[];

  @Output() clientsAdded = new EventEmitter<ClientsSelectionModel>();

  constructor(
    private messageS: MessageService,
    private usersHttpS: UsersHttpService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  search(e: { query: string }): void {
    if (this.isInvalidEmail(e.query)) {
      this.hideTooltip = false;
      this.suggestions = [];
    } else {
      this.hideTooltip = true;
      this.usersHttpS
        .searchEmail(
          {
            page: 0,
            size: 2,
            email: e.query,
            status: this.statuses || [],
          },
          environment.mocks?.agency.clientsRoster.addClents.searchInnerUser,
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          user => {
            if (user === null) {
              this.suggestions = [
                {
                  label: 'This creator is not in Slice Database',
                  items: [
                    {
                      label: 'external',
                      value: {
                        email: e.query,
                      },
                    },
                  ],
                },
              ];
              return;
            }

            if (user?.roster) {
              this.suggestions = [
                {
                  label: 'This creator is already in your roster',
                  items: [],
                },
              ];
              return;
            }

            this.suggestions = [
              {
                label: 'This creator is already in Slice Database',
                items: [
                  {
                    label: 'internal',
                    value: user,
                  },
                ],
              },
            ];
            return;

            /* for autocomplete below
            const alreadySelectedIds =
              this.selectedUser?.internalCreators?.map(i => i.id) || [];
            const creatorList = r?.content.filter(
              i => !alreadySelectedIds.includes(i.id),
            );
            const userExist = false; // r?.isRoster;
            if (userExist) {
              this.suggestions = [
                {
                  label: 'This creator is already in your roster',
                  items: [],
                },
              ];
            } else {
              if (creatorList?.length) {
                this.suggestions = [
                  {
                    label: 'This creator is already in Slice Database',
                    items: creatorList.map((user: User) => ({
                      label: 'internal',
                      value: user,
                    })),
                  },
                ];
              } else {
                this.suggestions = [
                  {
                    label: 'This creator is not in Slice Database',
                    items: [
                      {
                        label: 'external',
                        value: {
                          email: e.query,
                        },
                      },
                    ],
                  },
                ];
              }
            }*/
          },
          () => {
            this.suggestions = [
              {
                label: 'This creator is not in Slice Database',
                items: [
                  {
                    label: 'external',
                    value: {
                      email: e.query,
                    },
                  },
                ],
              },
            ];
            return;
          },
        );
    }
  }

  onSelect(e: ClientsSelectionModel): void {
    if (e.label === 'external') {
      // check if email is already selected in formCreator
      const alreadySelectedEmails =
        this.selectedUser?.externalCreators?.map(i => i.email) || [];
      if (alreadySelectedEmails?.includes(e.value.email as string)) {
        this.messageS.add({
          summary: 'Cannot Select Creator',
          severity: 'error',
          life: 1 * 1000,
          detail: 'This creator is already selected',
        });
        this.value = '';
        return;
      }

      // check if email is a correct format
      if (this.isInvalidEmail(e.value.email as string)) {
        this.messageS.add({
          summary: 'Cannot Select Creator',
          severity: 'error',
          life: 1 * 1000,
          detail: 'Please enter a valid email format',
        });
        // this.value = e.value.email || '';
        return;
      }
    }

    if (e.label === 'internal') {
      // check if userId is already selected in formCreator
      const alreadySelectedIds =
        this.selectedUser?.internalCreators?.map(i => i.id) || [];
      const selectedUser = e.value as User;
      if (alreadySelectedIds?.includes(selectedUser.id)) {
        this.messageS.add({
          summary: 'Cannot Select Creator',
          severity: 'error',
          life: 1 * 1000,
          detail: 'This creator is already selected',
        });
        this.value = '';
        return;
      }
    }

    if (e.value.email) {
      e.value.email = e.value.email.toLowerCase();
    }

    this.clientsAdded.emit(e);
    this.value = '';
  }

  isInvalidEmail(email: string): boolean {
    if (!email) {
      return true;
    }

    const emailRegex = new RegExp(EMAIL_PATTERN);
    return !emailRegex.test(email);
  }
}
