import { TermOfPayment } from '@slice-interfaces/e-signature/xpress-contract.model';

export enum ContractPercentageEnum {
  '100%' = 100,
  '75%' = 75,
  '50%' = 50,
  '25%' = 25,
}

export enum ContractDaysEnum {
  '90-days' = 90,
  '60-days' = 60,
  '45-days' = 45,
  '30-days' = 30,
  '14-days' = 14,
  '10-days' = 10,
  '9-days' = 9,
  '8-days' = 8,
  '7-days' = 7,
  '6-days' = 6,
  '5-days' = 5,
  '4-days' = 4,
  '3-days' = 3,
  '2-days' = 2,
  '1-day' = 1,
}

export enum ContractPeriodTypeEnum {
  'WORKING_DAY' = 'WORKING_DAY',
  'CALENDAR_DAY' = 'CALENDAR_DAY',
}

export enum ContractTimeframeEnum {
  'BEFORE_CAMPAIGN' = 'BEFORE_CAMPAIGN',
  'AFTER_CAMPAIGN' = 'AFTER_CAMPAIGN',
}

export enum ContractTypesEnum {
  STANDALONE = 'standalone',
  CAMPAIGN = 'campaign',
}

export enum ContractSignStatus {
  'WAITING_FOR_AGENCY' = 'WAITING_FOR_AGENCY',
  'WAITING_FOR_OTHER' = 'WAITING_FOR_OTHER',
  'DRAFT' = 'DRAFT',
  'NO_DATA' = 'NO_DATA',
  COMPLETED = 'COMPLETED',
  PROCESSING = 'PROCESSING',
}

export const getContractDefaultTerm = (
  previousPercent: ContractPercentageEnum = 0,
): TermOfPayment => {
  const remainingPercent = ContractPercentageEnum['100%'] - previousPercent;
  return {
    percentage: remainingPercent,
    days: ContractDaysEnum['2-days'],
    periodType: ContractPeriodTypeEnum['WORKING_DAY'],
    timeframe: ContractTimeframeEnum['AFTER_CAMPAIGN'],
  };
};
