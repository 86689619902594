import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';

import { RolePermissionService } from '@slice/role-user';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { createHttpParams } from '@slc-libs/helpers';

import { environment } from '@slice-env/environment';

import {
  AuthenticatedUser,
  RefreshTokenResponse,
  WhatsNext,
} from '@slice-interfaces/auth/auth-user';
import {
  RequestDemoPayload,
  SignUpAgencyPayload,
  SignUpPayload,
} from '@slice-interfaces/auth/sign-up-payload';
import { CreatorAccountHomeWidget } from '@slice-interfaces/profile/creator-home-widget';
import { CreatorProfile } from '@slice-interfaces/profile/creator-profile';
import { CreatorProfileOnpoardingPayload } from '@slice-interfaces/profile/onboarding-payload';
import { TOKEN_GRANT_TYPES } from '@slice-enums/jwt-token-grant-types.enum';
import { USER_INVITE_STATUS } from '@slice-enums/user';
import { UserTypes } from '@slice-enums/user-types.enum';

import { CustomEncoder } from '../_classes/encoder.class';
import { createNotAuthorizationHttpHeaders } from '../_helpers/create-not-auth-http-options';
import { AuthStateService } from './auth-state.service';
import { ClientsRosterHttpService } from './clients-roster/clients-roster-http.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService {
  public readonly REFRESH_TOKEN_URL = '/api/v1/oauth/token';
  constructor(
    private http: HttpClient,
    private _lsService: LocalStorageService,
    private authStateS: AuthStateService,
    private roleService: RolePermissionService,
    private clientsRosterHttpS: ClientsRosterHttpService,
  ) {}

  me(): Observable<AuthenticatedUser> {
    return this.http.get<AuthenticatedUser>(`/api/v1/users/me`).pipe(
      // mock
      tap(u => {
        if (!environment.production) {
          if (
            typeof environment?.authUserMockData?.emailConfirmed === 'boolean'
          ) {
            u.emailConfirmed = environment.authUserMockData?.emailConfirmed;
          }
          if (
            typeof environment?.authUserMockData?.profileCompleted === 'boolean'
          ) {
            u.profileCompleted = environment.authUserMockData?.profileCompleted;
          }
          if (environment.isAdminBuild) {
            u.userType = UserTypes.ADMIN;
          } else {
            if (environment?.authUserMockData?.userType) {
              u.userType = environment?.authUserMockData?.userType;
            }
          }
          if (
            typeof environment?.authUserMockData?.allowedToBrandHome ===
            'boolean'
          ) {
            u.allowedToBrandHome =
              environment.authUserMockData?.allowedToBrandHome;
          }
          if (
            typeof environment?.authUserMockData?.paymentRequired === 'boolean'
          ) {
            u.paymentRequired = environment.authUserMockData?.paymentRequired;
          }

          if (
            typeof environment?.authUserMockData?.youtubeExpired === 'boolean'
          ) {
            u.youtubeExpired = environment.authUserMockData?.youtubeExpired;
          }

          if (
            typeof environment?.authUserMockData?.instagramExpired === 'boolean'
          ) {
            u.instagramExpired = environment.authUserMockData?.instagramExpired;
          }

          if (typeof environment?.authUserMockData?.creators === 'number') {
            u.creators = environment.authUserMockData?.creators;
          }
        }

        // -----------

        this.authStateS.setAuthenticatedUser(u);
        if (u.userType === UserTypes.CREATOR) {
          if (u.emailConfirmed) {
            if (this.authStateS.isUserStatusNotExist()) {
              this.authStateS.setUserStatusOnboard(u.profileCompleted); // onboarding creator only
            }
          } else {
            this.authStateS.setDefaultUserStatus(); // onboarding default
          }
        }
        if (
          u.userType === UserTypes.AGENCY &&
          u.emailConfirmed &&
          u.agencyOwned
        ) {
          this.fetchAgencyTotalReports();
          this.fetchAgencyTotalCreators();
          this.checkAgencyEligibility(
            this.authStateS.getAuthenticatedUser()?.agencyOwned,
          ).subscribe();
        }
      }),
    );
  }

  fetchAuthUser(): Observable<AuthenticatedUser> {
    return this.me();
  }

  fetchCreatorProfile(): void {
    this.me().subscribe();
  }

  createCustomEncoder(): CustomEncoder {
    return new CustomEncoder();
  }

  private createNotAuthorizationHttpOptions(): {
    headers: HttpHeaders;
    responseType: any;
  } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + btoa('web:KEQ6oLkJu6YO2khL'),
        'Access-Control-Allow-Origin': '*',
      }),
      responseType: 'text' as 'json',
    };
  }

  signUp(payload: SignUpPayload | SignUpAgencyPayload): Observable<void> {
    const headers = {
      headers: createNotAuthorizationHttpHeaders(),
      responseType: 'text' as 'json',
    };

    return this.http.post<void>('/api/v1/users', payload, headers);
  }

  demoUser(payload: RequestDemoPayload): Observable<void> {
    return this.http.post<void>('/api/v1/users/request-demo', payload);
  }

  checkAgencyEligibility(userId?: string): Observable<{
    createReports: boolean;
    inviteCreators: boolean;
    inviteAgency: boolean;
  }> {
    return this.http
      .get<{
        createReports: boolean;
        inviteCreators: boolean;
        inviteAgency: boolean;
      }>(`/api/v1/agencies/${userId}/eligibility`)
      .pipe(
        tap(d => {
          if (d) {
            this.authStateS.setEligibleInviteMembers(d?.inviteAgency);
            this.authStateS.setEligibleCreateReports(d?.createReports);
            this.authStateS.setEligibleInviteCreators(d?.inviteCreators);
          }
        }),
      );
  }
  fetchWhatNext(): Observable<Array<WhatsNext>> {
    return this.http
      .get<Array<WhatsNext>>(`/api/v1/users/onboard-checklist`)
      .pipe(
        tap(d => {
          if (d) {
            this.authStateS.setWhatNext(d);
          }
        }),
      );
  }

  resetPassword(email: string): Observable<void> {
    return this.http.post<void>('/api/v1/users/reset-password', { email });
  }

  setNewPassword(d: {
    userId: string;
    code: string;
    newPassword: string;
  }): Observable<void> {
    // return of({} as any).pipe(delay(1000));
    return this.http.post<void>(
      `/api/v1/users/${d.userId}/password`,
      {
        password: d.newPassword,
      },
      {
        params: createHttpParams({
          id: d.userId,
          code: d.code,
        }),
      },
    );
  }

  private createLoginBody(username: string, password: string): HttpParams {
    return new HttpParams({
      encoder: this.createCustomEncoder(),
      fromObject: {
        grant_type: TOKEN_GRANT_TYPES.PASSWORD,
        username,
        password,
        scope: 'email openid',
      },
    });
  }

  private createRefreshTokenBody(refreshToken: string): HttpParams {
    return new HttpParams({
      encoder: new CustomEncoder(),
      fromObject: {
        grant_type: TOKEN_GRANT_TYPES.REFRESH_TOKEN,
        refresh_token: refreshToken,
        scope: 'email openid',
      },
    });
  }

  login(credentials: {
    email: string;
    password: string;
    encodedInviteRef?: string;
  }): Observable<AuthenticatedUser> {
    return this.http
      .post<string>(
        this.REFRESH_TOKEN_URL,
        this.createLoginBody(credentials.email, credentials.password),
        this.createNotAuthorizationHttpOptions(),
      )
      .pipe(
        map(res => this.handleRefreshTokenResponse(res)),
        switchMap(data => {
          // const data = JSON.parse(datas) as RefreshTokenResponse;
          if (credentials.encodedInviteRef) {
            return this.acceptAgencyInvite(
              data.user_id,
              credentials.encodedInviteRef,
            ).pipe(
              switchMap(() => this.me()),
              catchError(() => {
                return this.me(); // Ensure me() is still called even if acceptAgencyInvite fails
              }),
            );
          } else {
            return this.me();
          }
        }),
      );
  }

  acceptAgencyInvite(id: string, encodedInviteRef: string): Observable<any> {
    return this.http.post<void>(`/api/v1/users/${id}/accept-agency-invite`, {
      encodedInviteRef: encodedInviteRef,
    });
  }

  refreshToken(refreshToken: string): Observable<RefreshTokenResponse> {
    return this.http
      .post<string>(
        this.REFRESH_TOKEN_URL,
        this.createRefreshTokenBody(refreshToken),
        this.createNotAuthorizationHttpOptions(),
      )
      .pipe(map((data: string) => this.handleRefreshTokenResponse(data)));
  }

  private handleRefreshTokenResponse(json: string): RefreshTokenResponse {
    const data = JSON.parse(json) as RefreshTokenResponse;
    this.roleService.setRoleJWT(data.access_token);
    this.authStateS.setAccessToken(data.access_token);
    this._lsService.setRefreshToken(data.refresh_token);
    return data;
  }

  getDataByRefreshToken(rt: string): Observable<RefreshTokenResponse | null> {
    return rt ? this.refreshToken(rt) : of(null);
  }

  sendConfirmEmail(
    userId: string,
    code: string,
  ): Observable<boolean | UrlTree> {
    return this.http.post<boolean | UrlTree>(
      `/api/public/v1/users/${userId}/confirmations/${code}`,
      {},
    );
  }

  resendConfirmation(userId: string): Observable<void> {
    return this.http.post<void>(`/api/v1/users/${userId}/confirmations`, {});
  }

  creatorOnboard(
    payload: CreatorProfileOnpoardingPayload,
  ): Observable<CreatorProfile> {
    return this.http.post<CreatorProfile>(
      `/api/v1/users/${
        this.authStateS.getAuthenticatedUser()?.userId
      }/creator-profile`,
      payload,
      {
        params: createHttpParams({
          id: this.authStateS.getAuthenticatedUser()?.userId as string,
        }),
      },
    );
  }

  fetchDashboardCreator(): Observable<CreatorAccountHomeWidget> {
    return this.http.get<CreatorAccountHomeWidget>(`/api/v1/users/dashboard`);
  }

  fetchAgencyTotalReports(): void {
    /* already been done in AgencyHomeFacadeService.getList() */
    // this.authStateS.setDatabaseReportTotal(200);
  }

  fetchAgencyTotalCreators(): void {
    this.clientsRosterHttpS
      .getAgencyClientsRoster(
        this.authStateS.getAuthenticatedUser()?.agencyOwned as string,
        {
          page: 0,
          size: 1,
          sort: [],
          statuses: [USER_INVITE_STATUS.ACCEPTED, USER_INVITE_STATUS.INVITED],
        },
      )
      .pipe(
        tap(v => {
          if (v && (v?.totalElements !== undefined || null)) {
            // update total current creator roster
            this.authStateS.setDatabaseCreatorTotal(v.totalElements);
          }
        }),
        map(r => r.totalElements < 5),
      )
      .subscribe(
        isLessThan5Creators => {
          if (this.authStateS.isUserStatusNotExist()) {
            this.authStateS.setUserStatusOnboard(!isLessThan5Creators); // onboarding agency only
          }
        },
        () => {
          this.authStateS.setDefaultUserStatus(); // onboarding default
        },
      );
  }
}
