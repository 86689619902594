<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  (onHide)="triggerHide()"
>
  <slice-loader *ngIf="isLoading" class="slice-loader-fluid"></slice-loader>
  <div *ngIf="!isLoading" class="lp-main-content">
    <div class="slc-flex-c-c">
      <slc-switcher
        [currValue]="currBilling"
        [items]="billings$ | async"
        (changed)="billingChanged($event)"
      ></slc-switcher>
    </div>
    <div class="container d-flex align-items-center justify-content-center">
      <div class="save-msg slc-mt-24 text-center p-1 rounded">
        {{ 'pricing.save-msg' | transloco }}
      </div>
    </div>

    <div class="mt-5">
      <div class="row">
        <div
          *ngFor="let plan of plans"
          class="col-xl-3 col-lg-6 col-xs-12 px-0 bg-white"
        >
          <div [ngClass]="{ 'current-plan': plan.isCurrentPlan }">
            <div [ngClass]="{ 'ribbon-badge': plan.isPopular }">
              <slice-pricing-plan
                [subsData]="subscriptionPlan"
                [data]="plan"
                [billingType]="currBilling"
                (hide)="triggerHide()"
              ></slice-pricing-plan>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="faq">
      <div class="slc-flex-c-c header">FAQ</div>
      <div class="slc-flex-c-c wrap">
        <div class="card">
          <div class="title">
            {{ 'pricing.faq.message_1.title' | transloco }}
          </div>
          <div class="content">
            {{ 'pricing.faq.message_1.content' | transloco }}
          </div>
        </div>
        <div class="card">
          <div class="title">
            {{ 'pricing.faq.message_2.title' | transloco }}
          </div>
          <div class="subtitle">
            {{ 'pricing.faq.message_2.content' | transloco }}
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="exchange-msg slc-mt-40">
      {{ 'pricing.exchange-msg.1' | transloco }}
    </div> -->
  </div>
</p-dialog>
