import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  SvgIconInstagramModule,
  SvgIconLinkedinModule,
  SvgIconMailModule,
  SvgIconRssModule,
  SvgIconSpotifyModule,
  SvgIconTelegramModule,
  SvgIconTiktokModule,
  SvgIconTwitterModule,
} from '@slc-libs/svg-icons';

import { LandingFooterComponent } from './footer.component';
@NgModule({
  declarations: [LandingFooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    SvgIconLinkedinModule,
    SvgIconSpotifyModule,
    SvgIconTwitterModule,
    SvgIconRssModule,
    SvgIconInstagramModule,
    SvgIconTelegramModule,
    SvgIconTiktokModule,
    SvgIconMailModule,
  ],
  exports: [LandingFooterComponent],
})
export class LandingFooterModule {}
