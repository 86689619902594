<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="false"
  (onHide)="triggerHide()"
>
  <p-header>
    <div class="w-100">
      <div class="slc-mt-18 rounded">Submit Tax Document</div>
    </div>
  </p-header>
  <slice-loader *ngIf="isLoading" class="slice-loader-fluid"></slice-loader>
  <div *ngIf="!isLoading" class="lp-main-content pt-3">
    <slice-file-upload-default
      (filesSelected)="selected($event)"
      [multiple]="false"
      [newAssetType]="assetType"
      [inputMessage]="
        'Upload Bukti Potong Pajak (Bupot) File for this payment. It will be shared with the content creator.'
      "
    >
    </slice-file-upload-default>
    <button
      [disabled]="file?.length === 0"
      (click)="submit()"
      [ngClass]="{ 'slc-btn-loading': isLoadingSubmit }"
      style="color: #fff"
      class="slc-btn slc-btn-primary w-100 my-3"
    >
      {{ 'buttons.submit' | transloco }}
    </button>
  </div>
</p-dialog>
