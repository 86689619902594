<button
  *ngIf="!hiddenBtns?.includes(ACTIONS.prev)"
  [disabled]="disabledBtns?.includes(ACTIONS.prev)"
  title="{{ 'buttons.previous' | transloco }}"
  type="button"
  class="slc-btn slc-btn-outline-grey"
  (click)="prev()"
>
  <span>{{ 'buttons.previous' | transloco }}</span>
</button>

<button
  *ngIf="!hiddenBtns?.includes(ACTIONS.next)"
  title="{{ 'buttons.next-step' | transloco }}"
  type="button"
  [disabled]="disabledBtns?.includes(ACTIONS.next)"
  class="slc-btn slc-btn-primary"
  [ngClass]="[nextBtnCssClass ? nextBtnCssClass : 'slc-btn-primary']"
  (click)="action.emit(ACTIONS.next)"
>
  <span>{{ 'buttons.next-step' | transloco }}</span>
</button>

<button
  *ngIf="!hiddenBtns?.includes(ACTIONS.submit)"
  title="{{ 'buttons.submit' | transloco }}"
  type="button"
  [disabled]="disabledBtns?.includes(ACTIONS.submit)"
  [ngClass]="[loading ? 'slc-btn-loading' : '']"
  class="slc-btn slc-btn-primary"
  (click)="action.emit(ACTIONS.submit)"
>
  <span>{{ 'buttons.submit' | transloco }}</span>
</button>
