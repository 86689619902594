import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { RolePermissionService } from '@slice/role-user';
import { NavigationService } from '@slice-services/navigation.service';

import { E_ROUTES } from '@slice-enums/routes.enum';
import { UserTypes } from '@slice-enums/user-types.enum';

@Injectable()
export class AgencyGuard implements CanActivate {
  constructor(
    private roleService: RolePermissionService,
    private navigationService: NavigationService,
    private router: Router,
  ) {}
  public canActivate(): boolean | UrlTree {
    return this.check();
  }

  private check(): boolean | UrlTree {
    if (this.roleService.checkUserType(UserTypes.AGENCY)) {
      return true;
    } else {
      return this.router.parseUrl(
        this.navigationService.getRoutePath(E_ROUTES.BASE),
      );
    }
  }
}
