/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';

@Component({
  selector: 'slice-radio',
  templateUrl: 'radio.component.html',
  styleUrls: ['radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: RadioComponent,
    },
  ],
})
export class RadioComponent implements ControlValueAccessor, OnInit {
  @Input() hideDefaultTmpl = false;
  @Input() label: string;
  @Input() value: any;

  public innerValue: any = null;
  public outerValue: any;
  public currValue: any;

  touched = false;
  disabled = false;

  @Output() changed = new EventEmitter<any>();

  onChange = (model: any): void => {};
  onTouched = (): void => {};

  ngOnInit(): void {
    this.currValue = this.value;
  }

  select(): void {
    this.markAsTouched();
    if (!this.disabled) {
      this.innerValue = this.value;
      this.onChange(this.innerValue);
      this.changed?.emit(this.innerValue);
    }
  }

  writeValue(v: any): void {
    this.innerValue = v;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const model = control.value;
    this.outerValue = model;
    if (model <= 0) {
      return {
        mustBePositive: {
          model,
        },
      };
    }
    return null;
  }
}
