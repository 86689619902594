import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

import { CardBalanceFacadeService } from '../services/card-balance-facade.service';

@Component({
  selector: 'slice-move-balance',
  templateUrl: './move-balance.component.html',
  styleUrls: ['./move-balance.component.scss'],
})
export class MoveBalanceComponent extends AbstractSubscriberComponent {
  isVisible: boolean;
  @Input() moveFrom?: string = 'general'; //general, campaign
  @Input() dataCampaign?: any; //general, campaign
  @Input() disabled?: boolean;
  @Output() closed = new EventEmitter<any>();
  isLoadingSubmit: boolean;

  constructor(
    public facadeS: CardBalanceFacadeService,
    private mS: MessageService,
  ) {
    super();
    this.facadeS
      .selectModalPin()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        this.isVisible = r;
      });
  }

  closeModalPin() {
    this.facadeS.setModalPin(false);
  }
  openMoveBalance(): void {
    this.facadeS.setIsModalMoveBalance(true);
    this.facadeS.fetchCampaignList().subscribe();
  }

  submitMoveBalance(pin: string) {
    this.isLoadingSubmit = true;
    this.facadeS.setPayload({
      ...this.facadeS.getPayload(),
      transactionPin: pin,
    });
    this.facadeS
      .moveBalanceService()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        dt => {
          if (dt) {
            this.mS.add({
              severity: 'success',
              life: 5 * 1000,
              detail: `Success move the balance`,
            });
          } else {
            this.mS.add({
              severity: 'error',
              life: 5 * 1000,
              detail: 'Failed move the balance',
            });
          }
          this.facadeS.setModalPin(false);
          this.isLoadingSubmit = false;
          this.facadeS.fetchBalanceDetails().subscribe();
          this.closed?.emit();
        },
        () => {
          this.isLoadingSubmit = false;
          this.mS.add({
            severity: 'error',
            life: 5 * 1000,
            detail: 'Failed move the balance',
          });
        },
      );
  }
}
