import {
  getCampaignNameMock,
  getNumberMock,
  getUsernameMock,
} from '@slc-libs/mocks';
import { getPagebaleResponseMock } from '@slc-libs/mocks';
import { getIdMock } from '@slc-libs/mocks';
import { getBrandNameMock } from '@slc-libs/mocks';

import { AdminHomeCampaign } from '@slice-interfaces/admin/home-campaigns/campaign';
import { AdminHomeCampaigsFilters } from '@slice-interfaces/admin/home-campaigns/filters';
import { AdminHomeCampaigsResponse } from '@slice-interfaces/admin/home-campaigns/get-list';

import { getAdminCampaignStatusMock } from './campaign-status';

const _getCampaignStatus = (
  params: AdminHomeCampaigsFilters,
  res: AdminHomeCampaigsResponse,
): Array<AdminHomeCampaign> => {
  const list: Array<AdminHomeCampaign> = [];
  for (
    let i = 0;
    i < (res.last ? res.totalElements - res.number * res.size : params.size);
    i++
  ) {
    list.push({
      id: getIdMock(),
      brandName: getBrandNameMock(),
      campaignName: getCampaignNameMock(),
      endDate: new Date().toString(),
      status: getAdminCampaignStatusMock(),
      budget: getNumberMock(12000000),
      PIC: getUsernameMock(),
    });
  }
  return list;
};

export const getAdminHomeCampaignsMocks = (
  params: AdminHomeCampaigsFilters,
): AdminHomeCampaigsResponse => {
  const res = getPagebaleResponseMock(params);
  res.content = _getCampaignStatus(params, res as any);
  return res as AdminHomeCampaigsResponse;
};
