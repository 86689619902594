<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [modal]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  position="{{ bS.winWidth > 768 ? 'right' : '' }}"
  (onHide)="onHide()"
>
  <p-header>
    <button
      class="btn btn-outline-light btn-back"
      type="button"
      (click)="onHide()"
    >
      <i class="pi pi-arrow-left"></i>
      <span>&nbsp;&nbsp;{{ 'common.back' | transloco }}</span>
    </button>
  </p-header>

  <!--<p-autoComplete [(ngModel)]="formKondisiObjek.kondisiTanahSelect" id="kondisiTanah" name="kondisiTanah"
                  (onBlur)="formKondisiObjek.kondisiTanahId= formKondisiObjek?.kondisiTanahSelect?.id" [required]="true"
                  [suggestions]="filteredDropdownKondisiTanah" [dropdown]="true" [forceSelection]="true"
                  [showEmptyMessage]="true"
                  (completeMethod)="filteredDropdownKondisiTanah = filterCountry($event, dropdownKondisiTanah, 'kondisiTanah')"
                  field="kondisiTanah"
                  inputStyleClass="w-full bg-white appearance-none border-b border-solid border-blue-50 text-gray-700 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-purple-500 py-2">
  </p-autoComplete>-->
  <slice-search-creators
    hidden
    [multiple]="false"
    (selectedValue)="addingCreatorFromSearch($event)"
    style="width: 100%"
  ></slice-search-creators>
  <div class="slc-search-field-wrap">
    <slc-svg-icon-search></slc-svg-icon-search>
    <input
      type="text"
      class="slc-input"
      autocomplete="off"
      placeholder="Search Creator"
      name="searchForm"
      #input
      [(ngModel)]="searchForm"
      (keyup.enter)="searchCreator(searchForm)"
    />
    <i class="pi pi-spin pi-spinner" *ngIf="isLoading"></i>
  </div>

  <p class="title">{{ 'page-titles.client-roster' | transloco }}</p>
  <div *ngFor="let creator of creatorSearchArray" style="margin-bottom: 10px">
    <p-card>
      <div class="float-right">
        <button
          type="button"
          class="slc-btn slc-btn-primary slc-btn-h-36 mt-2"
          (click)="addingCreatorFromSelect(creator)"
        >
          <span>Add</span>
        </button>
      </div>
      <div class="row">
        <div
          class="col-4 d-inline-flex justify-content-center align-items-center"
        >
          <i class="pi pi-star mr-2" style="font-size: 20px"></i>
          <slc-avatar
            class="slc-avatar-56"
            [userFullName]="creator.name"
            [imagePath]="creator.avatar"
          ></slc-avatar>
        </div>
        <div class="col-8 d-flex align-items-center">
          <h5>{{ creator.name }}</h5>
        </div>
      </div>
      <div class="mt-4" *ngIf="!checkIfChannelExists(creator)">
        <p class="subtitle font-black font-weight-bold">
          This influencer has not connect {{ channel }} account yet.
        </p>
        <p class="subtitle mt-2">
          Once you add and start a report, it automatically send an invitation
          to connect social media account
        </p>
      </div>
    </p-card>
  </div>

  <p class="title">Selected Names</p>
  <div class="card-subtitle slc-flex-c-c">
    You can delete these names in your table
  </div>

  <div class="d-inline-flex flex-wrap mt-4">
    <div class="slc-mr-16 slc-mb-16" *ngFor="let creator of creatorFormArray">
      <p-chip
        [style]="{
          color: '#5a64e1',
          'background-color': '#f5f3fe',
          'box-shadow':
            '-1px -1px 9px rgba(255, 255, 255, 0.45), 1px 1px 9px rgba(94, 104, 121, 0.3)'
        }"
        [label]="creator.username"
        [removable]="false"
        (onRemove)="deleteCreatorId(creator.userId)"
      ></p-chip>
    </div>
  </div>

  <p-footer>
    <button
      class="slc-btn slc-btn-h-56 slc-btn-primary w-100 m-0"
      type="button"
      (click)="onSubmit()"
    >
      <span>{{ buttonTitle }}</span>
    </button>
  </p-footer>
</p-dialog>
