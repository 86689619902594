const users = [
  'Angel Egotrip',
  'Made Savage',
  'Binary Bark',
  'The Deal',
  'Fiddle Pie',
  'Raid Brigade',
  'Geez God',
  'Mindhack Diva',
  'Sugar Lump',
  'K For Kun',
  'Armor of Odd',
  'Loop Hole Mindset',
  'Asterism Zeevine',
  'Droolbug',
  'Starry Divinity',
  'Zig Wagon',
  'Blu Zoo',
  'Lens Patriot',
  'Doll Throne',
  'Sweetielicious',
  'Krazy Encounter',
  'Strife Life',
  'Ice Minister',
  'Twinkle Doll',
  'Meat Mojo',
  'Evil Rage',
  'Apogee Point',
  'Cluster of Hope',
  'Angel Berry',
  'Mind Pixell',
  'It Was Me',
  'Marker Dee',
  'Ahem Girl',
  'Emoster Pink',
  'Diva Comet',
  'Prep Station',
  'Whack Stack',
  'Cutefest Fizzle',
  'Him Again',
  'Dread Monster',
  'Exit Hound',
  'Mind Trick Poodle',
  'Prom Doll',
  'Rainbow Passion',
  'Cislunar Doll',
  'Bright Nut',
  'Fruit Loop Diva',
  'Grimster',
  'Cynic Poet',
  'Illustrious Doom',
  'Hippo Thump',
  'Cosmotech Junkie',
  'Doppler Thing',
  'Sleep Walker Swag',
  'Take Away Step',
  'Azimuth Mindspace',
  'Black Hole Thing',
  'Singular Desire',
  'Size Does Splatter',
  'Dark Disaster',
  'New Pole Meteorite',
  'Beyond This',
  'Free Fall Matter',
  'Peace Pangs',
  'Shout Out Facts',
  'Prom Storm Diva',
  'Choc O Block',
  'Hug Me Tight',
  'Egoflash',
  'Bold Bazooka',
  'Mind Dweller',
  'Psycho Poodle',
  'Monk Doodles',
  'Star Bit Angel',
  'Chub Bubbly',
  'High Pink',
  'Lunar Doll',
  'Here There and Everywhere',
  'Sweet Pandora',
  'Pill Thinker',
  'I Squad Solace',
  'Just My Thing',
];
export const getUsernameMock = (): string => {
  return users[Math.floor(Math.random() * users.length)];
};
