import { Component, Input } from '@angular/core';

import { NUMBERS_DECIMAL_VIEW } from '@slc-libs/constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[sliceNumberView]',
  templateUrl: './number-view.component.html',
  styleUrls: ['./number-view.component.scss'],
})
export class NumberViewComponent {
  public readonly NUMBERS_DECIMAL_VIEW = NUMBERS_DECIMAL_VIEW;
  @Input() sliceNumberView: number | undefined | null;
}
