import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { environment } from '@slice-env/environment';

import { PostProposalSubmitContentPayload } from '@slice-interfaces/post-proposals/post-proposal-submit-content-payload';

@Injectable({
  providedIn: 'root',
})
export class PostProposalsHttpService {
  constructor(private http: HttpClient) {}

  submitContent(
    postProposalId: string,
    payload: PostProposalSubmitContentPayload,
    useMocks?: boolean,
  ): Observable<void> {
    // mock
    const mocks = of({} as any);

    const req = this.http.post<void>(
      `/api/v1/post-proposals/${postProposalId}/submit-content`,
      payload,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMocks ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMocks ? mocks : req;
    }
  }

  submitContentLink(
    postProposalId: string,
    payload: { contentId: string },
    useMocks?: boolean,
  ): Observable<void> {
    // mock
    const mocks = of(true as any).pipe(delay(700));

    const req = this.http.post<void>(
      `/api/v1/post-proposals/${postProposalId}/submit-content-link`,
      payload,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMocks ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMocks ? mocks : req;
    }
  }
}
