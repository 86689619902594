import { Component } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-icon-question-circle',
  styles: [
    ':host { display: flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './question-circle.component.html',
})
export class SvgIconQuestionCircleComponent extends AbstractSvgIconComponent {}
