<div>
  <div class="icon-wrap slc-flex-c-c" [ngSwitch]="data.type">
    <slc-svg-icon-plant *ngSwitchCase="PlanTypes.Free"></slc-svg-icon-plant>
    <slc-svg-icon-store *ngSwitchCase="PlanTypes.Lite"></slc-svg-icon-store>
    <slc-svg-icon-agency
      *ngSwitchCase="PlanTypes.Standard"
    ></slc-svg-icon-agency>
    <slc-svg-icon-city *ngSwitchCase="PlanTypes.Plus"></slc-svg-icon-city>
  </div>
  <!-- <lp-coming-soon *ngIf="data.isComingSoon" class="slc-ml-16"></lp-coming-soon> -->
</div>

<span *ngIf="subsData">
  <div *ngIf="data.subsPlanData || data.nameKey">
    <div class="plan-name slc-mt-8">
      {{ data.subsPlanData?.defaultName || data.nameKey | transloco }}
    </div>
  </div>
</span>

<div class="{{ data.isComingSoon ? 'with-opacity' : '' }}">
  <span *ngIf="subsData">
    <div
      class="plan-price slc-mt-4"
      *ngIf="billingType === BillingTypes.monthly"
    >
      {{ '$' + data.subsPlanData?.priceMonthly + '/mo' }}
    </div>
    <div
      class="plan-price slc-mt-4"
      *ngIf="billingType === BillingTypes.annual"
    >
      {{ '$' + data.subsPlanData?.priceYearlyPerMonth + '/mo' }}
    </div>
  </span>

  <div class="plan-billing">
    <ng-container *ngIf="data.type === PlanTypes.Free; else withPriceTmpl">
      {{ 'pricing.free-trial' | transloco }}
    </ng-container>
    <ng-template #withPriceTmpl>
      <ng-container *ngIf="billingType === BillingTypes.annual">{{
        'pricing.billed-annually' | transloco
      }}</ng-container>
      <ng-container *ngIf="billingType === BillingTypes.monthly">{{
        'pricing.billed-monthly' | transloco
      }}</ng-container>
    </ng-template>
  </div>

  <button
    class="action-btn {{ data.btnCssStyles }} slc-btn-h-48 slc-mt-12"
    type="button"
    (click)="selectPlan(data.subsPlanData)"
    [ngClass]="{ 'slc-btn-loading': loadingButton }"
    [disabled]="data.isComingSoon || data.type === PlanTypes.Free"
  >
    <span>{{ data.btnTitleKey | transloco }}</span>
  </button>

  <hr />

  <div class="feature-list">
    <div class="feature-list-title">{{ 'pricing.includes' | transloco }}:</div>
    <div class="feature-items">
      <div
        *ngFor="let feature of data.features"
        class="slc-flex-fs-c feature-item"
      >
        <slc-svg-icon-checkmark></slc-svg-icon-checkmark>
        <div class="feature-item-title-wrap slc-flex-fs-c slc-flex-wrap-wrap">
          <div class="feature-item-title">
            {{ feature.titleKey | transloco }}
          </div>
          <div class="feature-item-subtitle" *ngIf="feature?.subtitleKey">
            {{ feature.subtitleKey | transloco }}
          </div>
          <span *ngIf="feature.isComingSoon" class="feature-item-soon">
            <span>{{ 'coming-soon' | transloco }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
