<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="10"
  viewBox="0 0 16 10"
  fill="none"
>
  <path
    d="M7.99984 0C5.0988 0 2.43994 1.10156 0.43734 2.89583C-0.148597 3.35156 -0.257972 4.19531 0.197757 4.78125C0.653486 5.36719 1.49724 5.47656 2.08317 5.02083C2.57015 7.84115 5.04411 10 7.99984 10C10.9634 10 13.4373 7.83073 13.9165 5C14.4998 5.36719 15.2655 5.23438 15.6899 4.6901C16.1144 4.14844 16.0597 3.3724 15.5623 2.89583C13.5597 1.10156 10.9009 0 7.99984 0ZM4.72901 3.33333C4.68474 3.54948 4.66651 3.77083 4.66651 4C4.66651 5.83854 6.1613 7.33333 7.99984 7.33333C9.83838 7.33333 11.3332 5.83854 11.3332 4C11.3332 3.77083 11.3149 3.54948 11.2707 3.33333C11.7577 3.53385 12.229 3.75781 12.6665 4.04167C12.6431 6.60677 10.5702 8.66667 7.99984 8.66667C5.42953 8.66667 3.35661 6.60677 3.33317 4.04167C3.77067 3.75781 4.24203 3.53385 4.72901 3.33333Z"
    fill="#C2C4C7"
  />
</svg>
