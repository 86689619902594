import { getAgencyNameMock, getEmailMock } from '@slc-libs/mocks';
import { getPagebaleResponseMock } from '@slc-libs/mocks';
import { getUsernameMock } from '@slc-libs/mocks';

import { AgencyInvitationModel } from '@slice-interfaces/agency-invitation/agency-invitation-model';
import { AgencyInvitationsParams } from '@slice-interfaces/agency-invitation/agency-invitations-params';
import { AgencyInvitationsResponse } from '@slice-interfaces/agency-invitation/agency-invitations-response';

const _getList = (
  params: AgencyInvitationsParams,
  res: AgencyInvitationsResponse,
): Array<AgencyInvitationModel> => {
  const list: Array<AgencyInvitationModel> = [];
  for (
    let i = 0;
    i < (res.last ? res.totalElements - res.number * res.size : params.size);
    i++
  ) {
    const id = String(params.page * params.size + i + 1);
    list.push({
      id: id,
      agencyName: getAgencyNameMock(),
      picName: getUsernameMock(),
      picEmail: getEmailMock(),
    });
  }
  return list;
};

export const getAgencyInvitationsResponseMocks = (
  params: AgencyInvitationsParams,
): AgencyInvitationsResponse => {
  const res = getPagebaleResponseMock(params);
  res.content = _getList(params, res as AgencyInvitationsResponse);
  return res as AgencyInvitationsResponse;
};
