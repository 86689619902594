<slice-instagram-connect
  *ngIf="channel === Channels.INSTAGRAM"
  [isConnected]="isExpiredFlow ? true : isConnected"
  [accountId]="accountId"
  (actionInProgress)="actionInProgress($event)"
  (disconnected)="disconnected($event)"
  [isExpiredFlow]="isExpiredFlow"
  (connected)="connected($event)"
>
  <div
    class="slc-btn slc-btn-h-44 facebook"
    [ngClass]="[
      !isInited || isActionInProgress ? 'disabled-btn' : '',
      isActionInProgress ? 'slc-btn-loading' : ''
    ]"
  >
    <slc-svg-icon-facebook></slc-svg-icon-facebook>

    <span>{{ 'buttons.continue-with-facebook' | transloco }}</span>
  </div>
</slice-instagram-connect>

<slice-google-connect
  *ngIf="channel === Channels.YOUTUBE"
  [isConnected]="isExpiredFlow ? true : isConnected"
  [disabled]="isActionInProgress || !isInited"
  [accountId]="accountId"
  (actionInProgress)="actionInProgress($event)"
  (connected)="connected($event)"
  [isExpiredFlow]="isExpiredFlow"
  (disconnected)="disconnected($event)"
>
  <div
    class="slc-btn slc-btn-h-44 youtube"
    [ngClass]="[
      isActionInProgress ? 'slc-btn-loading' : '',
      !isInited || isActionInProgress ? 'disabled-btn' : ''
    ]"
  >
    <slc-svg-icon-youtube></slc-svg-icon-youtube>
    <span>{{ 'buttons.connect-with-youtube' | transloco }}</span>
  </div>
</slice-google-connect>
