import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconClockComponent } from './clock.component';

@NgModule({
  declarations: [SvgIconClockComponent],
  imports: [CommonModule],
  exports: [SvgIconClockComponent],
})
export class SvgIconClockModule {}
