import { Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable } from 'rxjs';

import { PlanTypes } from '@slc-libs/enums';
import { BasicPlanData, PlanUi } from '@slc-libs/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  private basicPlan$ = new BehaviorSubject<Array<BasicPlanData>>(
    PlansService.createBasicPlans(),
  );
  private fullPlan$ = new BehaviorSubject<Array<PlanUi>>(
    PlansService.createFullPlans(),
  );

  static createBasicPlans(): Array<BasicPlanData> {
    return [
      // {
      //   id: '0',
      //   type: PlanTypes.Premium,
      //   priceMonthUSD: 790,
      //   priceAnnualUSD: 549,
      // },
      {
        id: '1',
        type: PlanTypes.Plus,
        priceMonthUSD: 600,
        priceAnnualUSD: 449,
      },
      {
        id: '2',
        type: PlanTypes.Standard,
        priceMonthUSD: 180,
        priceAnnualUSD: 149,
      },
      {
        id: '3',
        type: PlanTypes.Lite,
        priceMonthUSD: 36,
        priceAnnualUSD: 29,
      },
      {
        id: '4',
        type: PlanTypes.Free,
        priceMonthUSD: null,
        priceAnnualUSD: null,
      },
    ];
  }

  static createFullPlans(): Array<PlanUi> {
    return [
      // getPremiumPlan(),
      getPlusPlan(),
      getStandardPlan(),
      getLitePlan(),
      getFreePlan(),
    ];
  }

  constructor(private translocoS: TranslocoService) {}

  public fetchBasicPlans(): Observable<Array<BasicPlanData>> {
    return this.basicPlan$.asObservable();
  }
  public getBasicPlans(): Array<BasicPlanData> {
    return this.basicPlan$.value;
  }

  public fetchFullPlans(): Observable<Array<PlanUi>> {
    return this.fullPlan$.asObservable();
  }
  public getFullPlans(): Array<PlanUi> {
    return this.fullPlan$.value;
  }
}

const getFreePlan = (): PlanUi => {
  return {
    defaultPlanName: 'plan4',
    type: PlanTypes.Free,
    nameKey: 'pricing.plans.free.title',
    priceAnnually: 'pricing.plans.free.price-annual',
    priceMonthly: 'pricing.plans.free.price-month',
    btnTitleKey: 'buttons.select-plan',
    btnCssStyles: 'slc-btn slc-btn-purple-light',
    isComingSoon: false,
    features: [
      { titleKey: 'pricing.plan-features.1-100-creators' },
      { titleKey: 'pricing.plan-features.1-user' },
      { titleKey: 'pricing.plan-features.creator-crm' },
      // { titleKey: 'pricing.plan-features.creator-roster' },
      // { titleKey: 'pricing.plan-features.creator-roster-management' },
      {
        titleKey: 'pricing.plan-features.1-active-report',
        subtitleKey: 'pricing.plan-features.lifetime',
      },
      { titleKey: 'pricing.plan-features.past-performance' },
      { titleKey: 'pricing.plan-features.public-creator-roster' },
      {
        titleKey: 'pricing.plan-features.1-bulk-payment',
        subtitleKey: 'pricing.plan-features.cost-bulk-payment',
      },
      // {
      //   titleKey: 'pricing.plan-features.1-contract-sign',
      //   subtitleKey: 'pricing.plan-features.cost-contract-sign',
      // },
      // {
      //   titleKey: 'pricing.plan-features.emeterai-support',
      //   subtitleKey: 'pricing.plan-features.cost-emeterai',
      // },
      { titleKey: 'pricing.plan-features.mail-support' },
    ],
  };
};

const getLitePlan = (): PlanUi => {
  return {
    defaultPlanName: 'plan3',
    type: PlanTypes.Lite,
    nameKey: 'pricing.plans.lite.title',
    priceAnnually: 'pricing.plans.lite.price-annual',
    priceMonthly: 'pricing.plans.lite.price-month',
    btnTitleKey: 'buttons.select-plan',
    btnCssStyles: 'slc-btn slc-btn-purple-light',
    isComingSoon: false,
    features: [
      { titleKey: 'pricing.plan-features.1-200-creators' },
      { titleKey: 'pricing.plan-features.2-users' },
      { titleKey: 'pricing.plan-features.creator-crm' },
      // { titleKey: 'pricing.plan-features.creator-roster' },
      // { titleKey: 'pricing.plan-features.creator-roster-management' },
      { titleKey: 'pricing.plan-features.2-active-report' },
      { titleKey: 'pricing.plan-features.past-performance' },
      { titleKey: 'pricing.plan-features.public-creator-roster' },
      {
        titleKey: 'pricing.plan-features.5-bulk-payment',
        subtitleKey: 'pricing.plan-features.cost-bulk-payment',
      },
      // {
      //   titleKey: 'pricing.plan-features.5-contract-sign',
      //   subtitleKey: 'pricing.plan-features.cost-contract-sign',
      // },
      // {
      //   titleKey: 'pricing.plan-features.emeterai-support',
      //   subtitleKey: 'pricing.plan-features.cost-emeterai',
      // },
      { titleKey: 'pricing.plan-features.mail-support' },
    ],
  };
};

const getStandardPlan = (): PlanUi => {
  return {
    defaultPlanName: 'plan2',
    type: PlanTypes.Standard,
    nameKey: 'pricing.plans.standard.title',
    priceAnnually: 'pricing.plans.standard.price-annual',
    priceMonthly: 'pricing.plans.standard.price-month',
    btnTitleKey: 'buttons.select-plan',
    btnCssStyles: 'slc-btn slc-btn-primary',
    isComingSoon: false,
    isPopular: true,
    features: [
      { titleKey: 'pricing.plan-features.1-500-creators' },
      { titleKey: 'pricing.plan-features.5-users' },
      { titleKey: 'pricing.plan-features.creator-crm' },
      // { titleKey: 'pricing.plan-features.creator-roster' },
      // { titleKey: 'pricing.plan-features.creator-roster-management' },
      { titleKey: 'pricing.plan-features.10-active-report' },
      { titleKey: 'pricing.plan-features.past-performance' },
      { titleKey: 'pricing.plan-features.public-creator-roster' },
      {
        titleKey: 'pricing.plan-features.10-bulk-payment',
        subtitleKey: 'pricing.plan-features.cost-bulk-payment',
      },
      // {
      //   titleKey: 'pricing.plan-features.10-contract-sign',
      //   subtitleKey: 'pricing.plan-features.cost-contract-sign',
      // },
      // {
      //   titleKey: 'pricing.plan-features.emeterai-support',
      //   subtitleKey: 'pricing.plan-features.cost-emeterai',
      // },
      { titleKey: 'pricing.plan-features.mail-support' },
    ],
  };
};

const getPlusPlan = (): PlanUi => {
  return {
    defaultPlanName: 'plan1',
    type: PlanTypes.Plus,
    nameKey: 'pricing.plans.plus.title',
    priceAnnually: 'pricing.plans.plus.price-annual',
    priceMonthly: 'pricing.plans.plus.price-month',
    btnCssStyles: 'slc-btn slc-btn-purple-light',
    btnTitleKey: 'buttons.select-plan',
    // btnCssStyles: 'slc-btn slc-btn-purple-light',
    isComingSoon: false,
    features: [
      { titleKey: 'pricing.plan-features.1-5000-creators' },
      { titleKey: 'pricing.plan-features.15-users' },
      { titleKey: 'pricing.plan-features.creator-crm' },
      // { titleKey: 'pricing.plan-features.creator-roster' },
      // { titleKey: 'pricing.plan-features.creator-roster-management' },
      { titleKey: 'pricing.plan-features.40-active-report' },
      { titleKey: 'pricing.plan-features.past-performance' },
      { titleKey: 'pricing.plan-features.public-creator-roster' },
      {
        titleKey: 'pricing.plan-features.40-bulk-payment',
        subtitleKey: 'pricing.plan-features.cost-bulk-payment',
      },
      // {
      //   titleKey: 'pricing.plan-features.40-contract-sign',
      //   subtitleKey: 'pricing.plan-features.cost-contract-sign',
      // },
      // {
      //   titleKey: 'pricing.plan-features.emeterai-support',
      //   subtitleKey: 'pricing.plan-features.cost-emeterai',
      // },
      { titleKey: 'pricing.plan-features.mail-support' },
      { titleKey: 'pricing.plan-features.dedicated-support-manager' },
    ],
  };
};

const getPremiumPlan = (): PlanUi => {
  return {
    defaultPlanName: 'plan0',
    type: PlanTypes.Premium,
    nameKey: 'pricing.plans.premium.title',
    priceAnnually: 'pricing.plans.premium.price-annual',
    priceMonthly: 'pricing.plans.premium.price-month',
    btnTitleKey: 'buttons.select-plan',
    btnCssStyles: 'slc-btn slc-btn-primary',
    isComingSoon: false,
    features: [
      { titleKey: 'pricing.plan-features.unlimited-creators' },
      { titleKey: 'pricing.plan-features.30-users' },
      { titleKey: 'pricing.plan-features.creator-roster-management' },
      { titleKey: 'pricing.plan-features.75-active-report' },
      { titleKey: 'pricing.plan-features.creator-discovery' },
      { titleKey: 'pricing.plan-features.public-creator-roster' },
      {
        titleKey: 'pricing.plan-features.unlimited-payment',
        isComingSoon: true,
      },
      {
        titleKey: 'pricing.plan-features.unlimited-AI-query',
        isComingSoon: true,
      },
      { titleKey: 'pricing.plan-features.mail-support' },
      { titleKey: 'pricing.plan-features.dedicated-support-manager' },
      // {
      //   titleKey: 'pricing.plan-features.payment-distribution',
      //   isComingSoon: true,
      // },
      // {
      //   titleKey: 'pricing.plan-features.google-analytics',
      //   isComingSoon: true,
      // },
    ],
  };
};
