<footer>
  <div id="div1" class="navigation">
    <a class="icons-link" [routerLink]="['/']" (click)="home()">
      <slc-svg-icon-logo [isLight]="true"></slc-svg-icon-logo>
    </a>
    <p>
      Slice is an influencer marketing CRM to help brands and agencies better
      manage their creator relationships and reporting.
      <br />Try <a [routerLink]="['/']">Slice</a> for free.
    </p>
  </div>
  <div id="div2" class="navigation">
    <a class="icons-link" [routerLink]="['/']">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9999 2.40002C6.7199 2.40002 2.3999 6.72002 2.3999 12C2.3999 17.28 6.7199 21.6 11.9999 21.6C17.2799 21.6 21.5999 17.28 21.5999 12C21.5999 6.72002 17.2799 2.40002 11.9999 2.40002ZM15.7847 16.8C15.6087 16.8 15.5207 16.712 15.3447 16.624C14.0247 15.8312 12.3527 15.392 10.5927 15.392C9.6247 15.392 8.5687 15.568 7.6887 15.744C7.5127 15.744 7.3367 15.832 7.2487 15.832C6.8967 15.832 6.6327 15.568 6.6327 15.216C6.6327 14.864 6.8087 14.6 7.1607 14.6C8.3047 14.336 9.4495 14.16 10.6815 14.16C12.7055 14.16 14.5535 14.688 16.1375 15.568C16.3135 15.744 16.4895 15.832 16.4895 16.184C16.3119 16.624 16.0479 16.8 15.7847 16.8ZM16.7519 14.3368C16.5759 14.3368 16.3999 14.2488 16.2239 14.1608C14.7279 13.1928 12.6151 12.6648 10.3279 12.6648C9.1839 12.6648 8.1271 12.8408 7.3359 13.0168C7.1599 13.1048 7.0719 13.1048 6.8959 13.1048C6.4559 13.1048 6.1911 12.7528 6.1911 12.4C6.1911 11.96 6.3671 11.784 6.8071 11.6072C7.8631 11.3432 8.9199 11.0792 10.4159 11.0792C12.8799 11.0792 15.1679 11.6952 17.1047 12.8392C17.3687 13.0152 17.5447 13.2792 17.5447 13.544C17.4559 13.984 17.1927 14.3368 16.7519 14.3368ZM17.8959 11.52C17.7199 11.52 17.6319 11.432 17.3679 11.344C15.6079 10.376 13.0559 9.76002 10.5919 9.76002C9.3599 9.76002 8.0399 9.93602 6.8951 10.2C6.7191 10.2 6.6311 10.288 6.3671 10.288C5.8391 10.3768 5.4871 9.93602 5.4871 9.40882C5.4871 8.88162 5.7511 8.61602 6.1031 8.52882C7.5119 8.17602 9.0079 8.00002 10.5919 8.00002C13.3199 8.00002 16.1359 8.61602 18.3367 9.76002C18.6007 9.93602 18.8647 10.2 18.8647 10.64C18.7767 11.168 18.4239 11.52 17.8959 11.52Z"
          fill="#FAFAFA"
        />
      </svg>
    </a>
    <!--<strong> · </strong>-->
    <a class="icons-link" [routerLink]="['/']">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.99834 2.40002C4.91114 2.40002 2.3999 4.91359 2.3999 8.00159V16.0016C2.3999 19.0888 4.91346 21.6 8.00146 21.6H16.0015C19.0887 21.6 21.5999 19.0865 21.5999 15.9985V7.99846C21.5999 4.91126 19.0863 2.40002 15.9983 2.40002H7.99834ZM17.5999 5.60002C18.0415 5.60002 18.3999 5.95842 18.3999 6.40002C18.3999 6.84162 18.0415 7.20002 17.5999 7.20002C17.1583 7.20002 16.7999 6.84162 16.7999 6.40002C16.7999 5.95842 17.1583 5.60002 17.5999 5.60002ZM11.9999 7.20002C14.6471 7.20002 16.7999 9.35282 16.7999 12C16.7999 14.6472 14.6471 16.8 11.9999 16.8C9.3527 16.8 7.1999 14.6472 7.1999 12C7.1999 9.35282 9.3527 7.20002 11.9999 7.20002ZM11.9999 8.80002C11.1512 8.80002 10.3373 9.13717 9.73716 9.73728C9.13704 10.3374 8.7999 11.1513 8.7999 12C8.7999 12.8487 9.13704 13.6626 9.73716 14.2628C10.3373 14.8629 11.1512 15.2 11.9999 15.2C12.8486 15.2 13.6625 14.8629 14.2626 14.2628C14.8628 13.6626 15.1999 12.8487 15.1999 12C15.1999 11.1513 14.8628 10.3374 14.2626 9.73728C13.6625 9.13717 12.8486 8.80002 11.9999 8.80002Z"
          fill="#FAFAFA"
        />
      </svg>
    </a>
    <!--<strong> · </strong>-->
    <a class="icons-link" [routerLink]="['/']">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.7172 10.7252C17.6079 10.7358 17.4983 10.7414 17.3886 10.7419C16.1851 10.742 15.0626 10.1354 14.4034 9.12856V14.6224C14.4034 16.8649 12.5854 18.6829 10.3429 18.6829C8.10034 18.6829 6.28239 16.8649 6.28239 14.6224C6.28239 12.3798 8.10034 10.5619 10.3429 10.5619C10.4277 10.5619 10.5105 10.5695 10.5938 10.5748V12.5757C10.5105 12.5657 10.4286 12.5505 10.3429 12.5505C9.19833 12.5505 8.27048 13.4783 8.27048 14.6229C8.27048 15.7674 9.19833 16.6952 10.3429 16.6952C11.4876 16.6952 12.4986 15.7933 12.4986 14.6486L12.5186 5.31807H14.4329C14.6134 7.03472 15.9976 8.37558 17.7191 8.5014V10.7252H17.7172ZM19.6191 2H4.38096C3.0681 2 2 3.06807 2 4.38093V19.619C2 20.9319 3.0681 22 4.38096 22H19.6191C20.9319 22 22 20.9319 22 19.619V4.38093C22 3.06807 20.9319 2 19.6191 2Z"
          fill="white"
        />
      </svg>
    </a>
    <!--<strong> · </strong>-->
    <a class="icons-link" [routerLink]="['/']">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 4H4C2.895 4 2 4.895 2 6V18C2 19.105 2.895 20 4 20H20C21.105 20 22 19.105 22 18V6C22 4.895 21.105 4 20 4ZM19.601 8.249L12.53 12.669C12.206 12.872 11.794 12.872 11.47 12.669L4.399 8.249C4.151 8.094 4 7.822 4 7.53C4 6.864 4.733 6.458 5.297 6.811L12 11L18.703 6.811C19.267 6.458 20 6.864 20 7.53C20 7.822 19.849 8.094 19.601 8.249Z"
          fill="#FAFAFA"
        />
      </svg>
    </a>
    <p class="icons-link">hello@slice.id</p>
  </div>
  <div id="div3" class="navigation">
    <p class="text-left text-md-right">
      Brands/Agencies - Get better influencer marketing reports, faster with
      Slice.
    </p>
    <div class="text-center text-md-right mt-3">
      <button
        class="slc-btn btn-sm slc-btn-primary"
        type="button"
        [routerLink]="['/']"
      >
        <span> Sign Up </span>
      </button>
    </div>
  </div>
  <div id="div4" class="navigation">
    <p class="copyright">
      © 2024 PT. Sarana Kreator Teknologi All Rights Reserved
    </p>
  </div>
</footer>
