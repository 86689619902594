import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslocoModule } from '@ngneat/transloco';

import {
  SvgIconEyeModule,
  SvgIconQuestionCircleModule,
} from '@slc-libs/svg-icons';
import { AppTooltipModule } from '@slc-libs/tooltip';

import { InputWrapperSpaceBetweenComponent } from './input-wrapper-space-between.component';
@NgModule({
  declarations: [InputWrapperSpaceBetweenComponent],
  imports: [
    CommonModule,
    FormsModule,
    AppTooltipModule,
    ReactiveFormsModule,
    SvgIconEyeModule,
    TranslocoModule,
    SvgIconQuestionCircleModule,
  ],
  exports: [InputWrapperSpaceBetweenComponent],
})
export class InputWrapperSpaceBetweenModule {}
