import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { TranslocoService } from '@ngneat/transloco';
import { RoleMenuEnum, RolePermissionService } from '@slice/role-user';
import { AuthStateService } from '@slice-services/auth-state.service';
import { NavigationService } from '@slice-services/navigation.service';
import { PageTitleService } from '@slice-services/page-title-service';
import { UiStateService } from '@slice-services/ui-state.service';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { PlanTypes } from '@slc-libs/enums';
import { BreakpointsService, Layouts } from '@slc-libs/services';
import { IsoLanguages } from '@slc-libs/types';

import { environment } from '@slice-env/environment';

import { AbstractCommonComponent } from '@slice-shared/abstract-classes/common-component';
import { AuthenticatedUser } from '@slice-interfaces/auth/auth-user';
import { BreadcrumbsWithoutLinksItem } from '@slice-interfaces/breadcrumbs-without-links';
import { UrlSegments } from '@slice-interfaces/routing';
import { E_ROUTES } from '@slice-enums/routes.enum';
import { UserTypes } from '@slice-enums/user-types.enum';

import { getCleanUrl } from '../../../_helpers/get-clean-url';

@Component({
  selector: 'slice-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends AbstractCommonComponent
  implements OnInit, OnDestroy
{
  @HostBinding('class') get class() {
    return this.getHeaderBGClass();
  }
  public isAdminBuild = environment.isAdminBuild;
  public loginPath: UrlSegments;
  public signUpPath: UrlSegments;
  public creatorPath: UrlSegments;
  public advertiserPath: UrlSegments;
  public UserTypes = UserTypes;
  public pageTitle: string | undefined;
  public layouts$: Observable<Layouts>;
  public isAuthMenuVisible$: Observable<boolean>;
  public stream$: Observable<{
    authUser: AuthenticatedUser | null;
    isProKit: boolean;
  }>;
  public isAuthMenuVisible: boolean;
  public isAgencyBasicPlan: boolean;
  public currentUrl: any;
  public isHeaderNavVisible$: Observable<boolean>;
  public customTmpl$: Observable<TemplateRef<any> | null>;
  public breadcrumbsWithoutLinks$: Observable<Array<BreadcrumbsWithoutLinksItem> | null>;
  constructor(
    private bs: BreakpointsService,
    private ts: TranslocoService,
    private router: Router,
    private uiStateS: UiStateService,
    private navigationS: NavigationService,
    private authStateS: AuthStateService,
    private actRoute: ActivatedRoute,
    private pageTitleS: PageTitleService,
    private roleS: RolePermissionService,
  ) {
    super(bs, ts);
  }

  ngOnInit(): void {
    this.customTmpl$ = this.pageTitleS.selectCustomTmpl();
    this.breadcrumbsWithoutLinks$ =
      this.pageTitleS.selectBreadcrumbsWithoutLinks();
    this.stream$ = combineLatest([
      this.authStateS.selectAuthenticatedUser(),
      this.authStateS
        .selectAuthenticatedUser()
        .pipe(map(u => !!u && u.userType === UserTypes.CREATOR)), // creator ProKit
    ]).pipe(
      map(([authUser, isProKit]) => {
        if (authUser && authUser.userType === UserTypes.AGENCY) {
          this.isAgencyBasicPlan =
            authUser.emailConfirmed &&
            authUser.subscriptionDefaultName === PlanTypes.Free;
        } else {
          this.isAgencyBasicPlan = false;
        }
        return { authUser: authUser, isProKit };
      }),
    );
    this.lang$ = this.ts.langChanges$ as Observable<IsoLanguages>;
    this.pageTitleS.selectPageTitle().subscribe(title => {
      this.pageTitle = title;
    });
    this.initMenuVisibility(this.router.url);
    this.initHeaderNavVisibility();
    super.ngOnInit();
    this.layouts$ = this.bs.selectLayout();
    this.initRouteChange();
    this.initPaths();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private getHeaderBGClass(): string {
    return this.isAgencyBasicPlan // && !this.bs.getIsMobile() && !this.pageTitle
      ? 'green-bg'
      : '';
  }

  private initHeaderNavVisibility(): void {
    this.isHeaderNavVisible$ = this.uiStateS.selectIsHeaderNavigationVisible();
  }

  private initRouteChange(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(routeEv => {
      if (routeEv instanceof NavigationEnd) {
        this.initMenuVisibility(routeEv.url);
      }
    });
  }

  private initMenuVisibility(fullUrl: string): void {
    this.currentUrl = getCleanUrl(fullUrl, this.actRoute);
    this.isAuthMenuVisible =
      this.currentUrl !== this.navigationS.getRoutePath(E_ROUTES.EMAIL_CONFIRM);
  }

  private initPaths(): void {
    this.loginPath = this.navigationS.getFullSegmentList(E_ROUTES.SIGN_IN);
    this.signUpPath = this.navigationS.getFullSegmentList(E_ROUTES.SIGN_UP);
  }

  changedLang(l: IsoLanguages): void {
    this.ts.setActiveLang(l);
  }

  goToLogin(): void {
    this.navigationS.goToSignIn();
  }

  goToSignUp(): void {
    this.navigationS.goToSignUp();
  }

  toggleMenu(): void {
    this.uiStateS.toggleMenu();
  }

  allowedToInvite(): boolean {
    return (
      !!this.authStateS.getAuthenticatedUser() &&
      !!this.authStateS.getAuthenticatedUser()?.emailConfirmed &&
      this.roleS.checkUserType(UserTypes.AGENCY) &&
      this.roleS.checkUserPermission(RoleMenuEnum.team_management) &&
      this.currentUrl !== this.navigationS.getRoutePath(E_ROUTES.TEAM_MANAGE)
    );
  }

  goToTeamManage(): void {
    this.navigationS.goToAgencyTeamManage();
  }

  goToUpgradePlan(): void {
    this.uiStateS.showAgencyPricing(true);
  }
}
