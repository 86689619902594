export enum MIX_PANEL_EVENTS {
  agency_signs_up = 'agency_signs_up',
  agency_logs_in = 'agency_logs_in',
  agency_onboard_skip = 'agency_onboard_skip',
  agency_onboard_finish = 'agency_onboard_finish',
  agency_member_signs_up = 'agency_member_signs_up',
  agency_invites_creator = 'agency_invites_creator',
  agency_find_creator = 'agency_find_creator',
  agency_creates_a_report = 'agency_creates_a_report',
  agency_copy_roster_publink = 'agency_copy_roster_publink',
  agency_update_profile = 'agency_update_profile',
  agency_change_plan = 'agency_change_plan',
  agency_payment_success = 'agency_payment_success',
  agency_payment_failed = 'agency_payment_failed',
  agency_change_card = 'agency_change_card',
  agency_change_card_success = 'agency_change_card_success',
  agency_change_card_failed = 'agency_change_card_failed',
  agency_do_agency_markup = 'agency_do_agency_markup',
  agency_open_home_page = 'agency_open_home_page',
  agency_open_roster_page = 'agency_open_roster_page',
  agency_open_discovery_page = 'agency_open_discovery_page',
  agency_open_reporting_list_page = 'agency_open_reporting_list_page',
  agency_open_reporting_detail_page = 'agency_open_reporting_detail_page',
  // - property: $referrer: [homepage, report_list]
  agency_favorite_creator = 'agency_favorite_creator',
  // - property: $referrer: [crm_dialog, crm_page, report_detail]
  agency_open_account_page = 'agency_open_account_page',
  agency_open_billing_page = 'agency_open_billing_page',
  agency_open_mediakit_publink = 'agency_open_mediakit_publink',
  agency_submit_report_recommendation = 'agency_submit_report_recommendation',
  agency_submit_creator_notes = 'agency_submit_creator_notes',
  agency_search_creator_in_roster = 'agency_search_creator_in_roster',
  agency_copy_report_publink = 'agency_copy_report_publink',
  agency_logs_out = 'agency_logs_out',

  creator_signs_up = 'creator_signs_up',
  creator_logs_in = 'creator_logs_in',
  creator_onboard_skip = 'creator_onboard_skip',
  creator_onboard_finish = 'creator_onboard_finish',
  creator_accept_job_offer = 'creator_accept_job_offer',
  creator_declines_job_offer = 'creator_declines_job_offer',
  creator_open_job_offer_page = 'creator_open_job_offer_page',
  creator_clicks_ig_analytics = 'creator_clicks_ig_analytics',
  creator_clicks_tk_analytics = 'creator_clicks_tk_analytics',
  creator_clicks_yt_analytics = 'creator_clicks_yt_analytics',
  creator_update_profile = 'creator_update_profile',
  creator_analytics_page = 'creator_analytics_page',
  creator_changes_photo = 'creator_changes_photo',
  creator_accept_roster_offer = 'creator_accept_roster_offer',
  creator_decline_roster_offer = 'creator_decline_roster_offer',
  creator_open_home_page = 'creator_open_home_page',
  creator_open_roster_offer_page = 'creator_open_roster_offer_page',
  creator_open_analytics_page = 'creator_open_analytics_page',
  creator_open_mediakit_page = 'creator_open_mediakit_page',
  creator_edit_mediakit_page = 'creator_edit_mediakit_page',
  // - property: $referrer: {value of RateCardEditableEnum}
  creator_open_campaign_page = 'creator_open_campaign_page',
  creator_open_contract_page = 'creator_open_contract_page',
  creator_open_profile_page = 'creator_open_profile_page',
  creator_edits_rate_card = 'creator_edits_rate_card',
  creator_open_report_submission_page = 'creator_open_report_submission_page',
  creator_connect_content_for_reporting = 'creator_connect_content_for_reporting',
  creator_copy_mediakit_publink = 'creator_copy_mediakit_publink',
  creator_log_out = 'creator_log_out',
  creator_pay_detail_page = 'creator_pay_detail_page',
  creator_invoice_page = 'creator_invoice_page',
  creator_balance_page = 'creator_balance_page',
  creator_academy_all = 'creator_academy_all',
  creator_academy_list = 'creator_academy_list',
  creator_academy_view = 'creator_academy_view',

  tools_ig_calculator = 'tools_ig_calculator',
  tools_tk_calculator = 'tools_tk_calculator',
}
