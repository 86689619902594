import { Briefs } from '@slc-libs/enums';

export const briefs = [
  Briefs.AWARENESS,
  Briefs.CONSIDERATION,
  Briefs.CONVERSION,
];

export const getBriefNameMock = (): Briefs => {
  return briefs[Math.floor(Math.random() * briefs.length)];
};
