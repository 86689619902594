const campaigns = [
  'ColourPop',
  'Kylie Cosmetics',
  'Fenty Beauty',
  'Glossier',
  'Huda Beauty',
  'The INKEY List',
  'NYX Cosmetics',
  'Essie',
  'OPI',
  'Too Faced',
];

const suffix = ['red', 'blue', 'dark', 'white', 'grey', 'yellow'];

export const getCampaignNameMock = (): string => {
  return (
    campaigns[Math.floor(Math.random() * campaigns.length)] +
    ' ' +
    suffix[Math.floor(Math.random() * suffix.length)]
  );
};
