import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'slice-how-approve-content',
  templateUrl: './how-approve-content.component.html',
  styleUrls: ['./how-approve-content.component.scss'],
})
export class HowApproveContentComponent {
  public isVisible = true;
  @Output() closed = new EventEmitter<void>();
  @Output() approved = new EventEmitter<{ isOffline: boolean }>();

  onHide(): void {
    this.isVisible = false;
    this.closed.emit();
  }

  approve(isOffline: boolean): void {
    this.isVisible = false;
    this.approved.emit({ isOffline: isOffline });
  }
}
