import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { SwitcherOptions } from 'libs/switcher/src/lib/switcher-options';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { BillingTypes, Channels, PostTypes } from '@slc-libs/enums';
import { BreakpointsService } from '@slc-libs/services';

import { AbstractCommonComponent } from '@slice-shared/abstract-classes/common-component';
import {
  CreatorEarning,
  UserDataTotalEarning,
} from '@slice-interfaces/bulk-payment/campaign';
import { CreatorKYCRole } from '@slice-interfaces/profile/creator-profile';

import { TotalEarningFacadeService } from '../total-earning/services/total-earning-facades.service';
import { TotalEarningStateService } from '../total-earning/services/total-earning-state.service';

@Component({
  selector: 'slice-total-earning-shared',
  templateUrl: './total-earning.component.html',
  styleUrls: ['./total-earning.component.scss'],
  providers: [TotalEarningFacadeService, TotalEarningStateService],
})
export class TotalEarningSharedComponent
  extends AbstractCommonComponent
  implements OnInit, OnDestroy
{
  @Input() ids: string;
  @Input() isVisible = false;
  @Input() userData: any; // UserDataTotalEarning | CreatorEarning;
  @Output() closed = new EventEmitter<boolean>();

  public isEditEarningOutside = false;
  earningOutside: number;

  public isLoading = false;
  public isLoadingInvoice = false;
  public currBilling: BillingTypes;
  public billings$: Observable<Array<SwitcherOptions>>;
  public data: any;
  public paymentHistory: any;
  public groupPayment: any;
  public dropdownOpen: string | null = null;
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  monthlyData: any[] = [];
  years: number[] = [];
  selectedYear: number;
  selectedMonth: string;
  monthNumber: number;
  CreatorKYCRole = CreatorKYCRole;

  constructor(
    public bS: BreakpointsService,
    private tS: TranslocoService,
    private mS: MessageService,
    public facadeS: TotalEarningFacadeService,
  ) {
    super(bS, tS);
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      this.years.push(currentYear - i);
    }
  }
  // ngOnDestroy(): void {
  //   throw new Error('Method not implemented.');
  // }

  onYearChange(selectedYear: number) {
    this.facadeS.setFiltersEarning({
      ...this.facadeS.getFiltersEarningNewState(),
      year: selectedYear,
    });
    this.fetchData();
  }

  onMonthChange(selectedMonth: string, selectedIndex: any) {
    this.facadeS.setFiltersEarning({
      ...this.facadeS.getFiltersEarningNewState(),
      month: selectedIndex?.target?.selectedIndex + 1,
    });
    this.monthNumber = selectedIndex?.target?.selectedIndex + 1;
    this.fetchData();
  }

  ngOnInit(): void {
    this.currBilling = BillingTypes.annual;
    const currentDate = new Date();
    const currentDateYear = currentDate.getFullYear() as number;
    this.selectedMonth = this.months[currentDate.getMonth()];
    this.selectedYear = currentDateYear;
    this.initBillings();
    this.facadeS.setFiltersEarning({
      year: this.selectedYear,
    });
    this.getDataDetails();
    this.fetchData();
  }

  groupPaymentDataByMonth(data: any): void {
    this.months.forEach(month => {
      this.monthlyData.push({
        month: month,
        totalAmount: 0,
        total: 0,
        data: [],
      });
    });

    // Group the payment data by month
    data.forEach(
      (payment: { date: string | number | Date; netAmount: any }) => {
        const date = new Date(payment.date);
        const monthName = this.months[date.getMonth()];

        const monthData = this.monthlyData.find(
          data => data.month === monthName,
        );
        if (monthData) {
          monthData.totalAmount += payment.netAmount;
          monthData.total++;
          monthData.data.push(payment);
        }
      },
    );
  }

  getDataDetails(): void {
    this.isLoading = true;
    this.facadeS
      .fetchEarningHistory(this.ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe((r: any) => {
        this.data = r;
        // this.paymentHistory = r.paymentHistory;
        if (r?.paymentHistory?.length > 0) {
          this.isLoading = false;
          this.groupPaymentDataByMonth(r.paymentHistory);
        } else {
          this.isLoading = false;
          this.monthlyData = [];
        }
      });
  }

  fecthWithReset() {
    this.monthlyData = [];
    // this.facadeS.setEarningHistory(null);
    this.getDataDetails();
  }

  fetchData(): void {
    this.isLoading = true;
    this.facadeS
      .fetchEarningHistory(this.ids)
      .pipe(takeUntil(this.destroy$))
      .subscribe((r: any) => {
        this.isLoading = false;
        this.data = r;
        this.earningOutside = r.externalYearlyEarnings;
      });
  }

  private initBillings(): void {
    this.billings$ = this.translateTrigger$.asObservable().pipe(
      map(() => {
        return [
          {
            title: this.translocoService.translate('annually'),
            value: BillingTypes.annual,
          },
          {
            title: this.translocoService.translate('monthly'),
            value: BillingTypes.monthly,
          },
        ];
      }),
    );
  }

  onHide(): void {
    this.closed?.emit(false);
  }
  openInvoice(data: any): void {
    if (data?.invoiceUrl) {
      window.open(data?.invoiceUrl, '_blank');
    } else {
      this.isLoadingInvoice = true;
      this.facadeS
        .fetchInvoiceUrl(this.ids, data?.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe((r: any) => {
          this.isLoadingInvoice = false;
          window.open(r, '_blank');
        });
    }
  }

  billingChanged(val: unknown): void {
    if (val === BillingTypes.monthly) {
      this.facadeS.setFiltersEarning({
        year: this.selectedYear,
        month: this.monthNumber,
      });
    } else {
      this.facadeS.setEarningHistory(null);
      this.facadeS.setFiltersEarning({
        year: this.selectedYear,
      });
    }
    this.fetchData();
    this.currBilling = val as BillingTypes;
  }

  toggleDropdown(id: string) {
    if (this.dropdownOpen === id) {
      this.dropdownOpen = null;
    } else {
      this.dropdownOpen = id;
    }
  }

  openModalTax(id: string): void {
    this.dropdownOpen = null;
    this.facadeS.setIdEarning(this.ids);
    this.facadeS.setPaymentHistoryId(id);
    this.facadeS.setIsModalTax(true);
  }

  openTax(url: any): void {
    this.dropdownOpen = null;
    window.open(url, '_blank');
  }

  getTypePercentage(type: string, isNpwp: boolean): string {
    const percentage = isNpwp ? '' : ' x 120%';

    switch (type) {
      case 'PPH21_5P':
        return `5%${percentage}`;
      case 'PPH21_15P':
        return `15%${percentage}`;
      case 'PPH21_25P':
        return `25%${percentage}`;
      case 'PPH21_30P':
        return `30%${percentage}`;
      case 'PPH21_35P':
        return `35%${percentage}`;
      case 'PPH23_2P':
        return `2%`;
      default:
        return 'Unknown';
    }
  }

  private extractNumber(value: any): number {
    return Number(value.replace(/,/g, ''));
  }

  editEarning(e: any) {
    const value = this.extractNumber(e?.target?.value);

    if (value !== 0 && value !== this.data?.externalYearlyEarnings) {
      this.isLoading = true;
      const id = this.userData?.creatorUserId ?? this.userData?.userId;
      const payload = {
        externalEarnings: value,
        year: this.selectedYear,
      };
      this.facadeS
        .updateEarnings(id as string, payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          dt => {
            this.isLoading = false;
            // this.mS.add({
            //   severity: 'success',
            //   life: 5 * 1000,
            //   detail: 'Success update value',
            // });
            this.fetchData();
            this.isEditEarningOutside = false;
          },
          () => {
            this.isLoading = false;
            this.mS.add({
              severity: 'error',
              life: 5 * 1000,
              detail: 'Failed update value',
            });
          },
        );
    }
  }
}
