import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconMailComponent } from './mail.component';

@NgModule({
  declarations: [SvgIconMailComponent],
  imports: [CommonModule],
  exports: [SvgIconMailComponent],
})
export class SvgIconMailModule {}
