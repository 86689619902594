<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="23"
  viewBox="0 0 24 23"
  fill="none"
>
  <path
    d="M4.79988 0.599609C8.98788 0.599609 12.4595 3.66441 13.0955 7.67361C14.5055 6.26841 16.4519 5.39961 18.5999 5.39961H23.9999V8.39961C23.9999 12.7076 20.5079 16.1996 16.1999 16.1996H13.1999V22.1996H10.7999V12.5996H8.39988C3.76068 12.5996 -0.00012207 8.83881 -0.00012207 4.19961V0.599609H4.79988ZM21.5999 7.79961H18.5999C15.6179 7.79961 13.1999 10.2176 13.1999 13.1996V13.7996H16.1999C19.1819 13.7996 21.5999 11.3816 21.5999 8.39961V7.79961ZM4.79988 2.99961H2.39988V4.19961C2.39988 7.51281 5.08668 10.1996 8.39988 10.1996H10.7999V8.99961C10.7999 5.68641 8.11308 2.99961 4.79988 2.99961Z"
    fill="#434DDB"
  />
</svg>
