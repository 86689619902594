import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { UiStateService } from '@slice-services/ui-state.service';
import { filter, takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { AssetsType } from '@slice-enums/assets/asset-type';
@Component({
  selector: 'slice-submit-content-popup-asset-requirements',
  templateUrl: './submit-content-popup-asset-requirements.component.html',
  styleUrls: ['./submit-content-popup-asset-requirements.component.scss'],
})
export class SubmitContentPopupAssetRequirementsComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() assetType: AssetsType;

  @Output() contentReady = new EventEmitter<string>();

  constructor(private tS: TranslocoService, private uiStateS: UiStateService) {
    super();
  }

  ngOnInit(): void {
    this.tS
      .selectTranslation()
      .pipe(
        filter(v => Boolean(Object.values(v).length)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        if (this.contentReady) {
          const html = this.getTooltipHtmlByAssetType(this.assetType);
          if (html) {
            this.contentReady.emit(html);
          }
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private getTooltipHtmlByAssetType(assetType: AssetsType): string | undefined {
    if (assetType === AssetsType.IG_IMAGE) {
      return this.getIgImageRequirementsHtml();
    } else if (assetType === AssetsType.IG_VIDEO) {
      return this.getIgVideoRequirementsHtml();
    } else {
      return undefined;
    }
  }

  private createWrapper(html: string): string {
    return `<div>
      <div class="${
        this.uiStateS.isTouchScreenDevice
          ? 'slice-submit-content-popup-asset-requirements'
          : ''
      } ${this.uiStateS.isTouchScreenDevice ? 'is-touch-screen' : ''}">
        ${html}
      </div>
    </div>`;
  }

  private getIgImageRequirementsHtml(): string {
    const html = `
      <div class="title">${this.tS.translate(
        'file-upload.image.ig-requirements.title',
      )}</div>
      <ul>
        <li>${this.tS.translate(
          'file-upload.image.ig-requirements.items.1',
        )}</li>
        <li>${this.tS.translate(
          'file-upload.image.ig-requirements.items.2',
        )}</li>
        <li>${this.tS.translate(
          'file-upload.image.ig-requirements.items.3',
        )}</li>
        <li>${this.tS.translate(
          'file-upload.image.ig-requirements.items.4',
        )}</li>
        <li>${this.tS.translate(
          'file-upload.image.ig-requirements.items.5',
        )}</li>
        <li>${this.tS.translate(
          'file-upload.image.ig-requirements.items.6',
        )}</li>
        <li>${this.tS.translate(
          'file-upload.image.ig-requirements.items.7',
        )}</li>
      </ul>`;
    return this.createWrapper(html);
  }

  private getIgVideoRequirementsHtml(): string {
    const html = `
      <div class="title">${this.tS.translate(
        'file-upload.video.ig-requirements.title',
      )}</div>
      <ul class="slc-mt-16">
        <li>${this.tS.translate(
          'file-upload.video.ig-requirements.items.1',
        )}</li>
        <li>${this.tS.translate(
          'file-upload.video.ig-requirements.items.2',
        )}</li>
        <li>${this.tS.translate(
          'file-upload.video.ig-requirements.items.3',
        )}</li>
        <li>${this.tS.translate(
          'file-upload.video.ig-requirements.items.4',
        )}</li>
        <li>
          ${this.tS.translate(
            'file-upload.video.ig-requirements.items.5.title',
          )}
          <ul>
            <li>${this.tS.translate(
              'file-upload.video.ig-requirements.items.5.items.1',
            )}</li>
            <li>${this.tS.translate(
              'file-upload.video.ig-requirements.items.5.items.2',
            )}</li>
            <li>${this.tS.translate(
              'file-upload.video.ig-requirements.items.5.items.3',
            )}</li>
          </ul>
        </li>
        <li>${this.tS.translate(
          'file-upload.video.ig-requirements.items.6',
        )}</li>
        <li>${this.tS.translate(
          'file-upload.video.ig-requirements.items.7',
        )}</li>
        <li>${this.tS.translate(
          'file-upload.video.ig-requirements.items.8',
        )}</li>
        <li>${this.tS.translate(
          'file-upload.video.ig-requirements.items.9',
        )}</li>
      </ul>`;
    return this.createWrapper(html);
  }
}
