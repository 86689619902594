<span>{{ items?.length || 0 }}</span>
&nbsp;
<span class="slc-text-ellipsis"> {{ 'common.selected' | transloco }}</span>
<button
  *ngIf="clearable"
  class="slc-flex-c-c"
  type="button"
  (click)="remove?.emit($event)"
>
  <slc-svg-icon-close></slc-svg-icon-close>
</button>
