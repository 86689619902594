<slice-connect-info-modal
  [isExpired]="showYt"
  [channel]="Channels.YOUTUBE"
  (closed)="close($event, Channels.YOUTUBE)"
  *ngIf="showYt"
></slice-connect-info-modal>
<slice-connect-info-modal
  [isExpired]="showIg"
  [channel]="Channels.INSTAGRAM"
  (closed)="close($event, Channels.INSTAGRAM)"
  *ngIf="showIg"
></slice-connect-info-modal>
