<svg
  *ngIf="!isDisable"
  width="24"
  height="25"
  viewBox="0 0 24 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M20 13.5012V3.00122C20 2.44894 19.5523 2.00122 19 2.00122H5C4.44771 2.00122 4 2.44894 4 3.00122V21.0012C4 21.5535 4.44771 22.0012 5 22.0012H10.5"
    stroke="#434DDB"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8.5 6.00122H15.5"
    stroke="#434DDB"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8.5 10.0012H15.5"
    stroke="#434DDB"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8.5 14.0012H11.5"
    stroke="#434DDB"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M19.5 22.0012L17.7677 20.269M17.7677 20.269C18.2202 19.8166 18.5 19.1916 18.5 18.5012C18.5 17.1205 17.3807 16.0012 16 16.0012C14.6193 16.0012 13.5 17.1205 13.5 18.5012C13.5 19.8819 14.6193 21.0012 16 21.0012C16.6903 21.0012 17.3153 20.7214 17.7677 20.269Z"
    stroke="#434DDB"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
<svg
  *ngIf="isDisable"
  width="24"
  height="25"
  viewBox="0 0 24 25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect y="0.427734" width="24" height="24" rx="3" fill="#EEEEEE" />
  <path
    d="M20 13.9277V3.42773C20 2.87545 19.5523 2.42773 19 2.42773H5C4.44771 2.42773 4 2.87545 4 3.42773V21.4277C4 21.98 4.44771 22.4277 5 22.4277H10.5"
    stroke="#C7C7C7"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8.5 6.42773H15.5"
    stroke="#C7C7C7"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8.5 10.4277H15.5"
    stroke="#C7C7C7"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M8.5 14.4277H11.5"
    stroke="#C7C7C7"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M19.5 22.4277L17.7677 20.6955M17.7677 20.6955C18.2202 20.2431 18.5 19.6181 18.5 18.9277C18.5 17.547 17.3807 16.4277 16 16.4277C14.6193 16.4277 13.5 17.547 13.5 18.9277C13.5 20.3084 14.6193 21.4277 16 21.4277C16.6903 21.4277 17.3153 21.1479 17.7677 20.6955Z"
    stroke="#C7C7C7"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
