import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconTwitterComponent } from './twitter.component';

@NgModule({
  declarations: [SvgIconTwitterComponent],
  imports: [CommonModule],
  exports: [SvgIconTwitterComponent],
})
export class SvgIconTwitterModule {}
