import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { Subject } from 'rxjs';

@Component({
  selector: 'slc-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss'],
})
export class InputWrapperComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  // delete btn
  @Input() showDelBtn: boolean;

  @Input() hintKey: string | undefined;
  @Input() tooltipKey: string | undefined;
  @Input() tooltipMarkup: any;

  // styles for title
  @Input() isInputFocused: boolean;
  @Input() title: string;

  @Input() beforeSvgIconPath?: string;
  @Input() beforeSvgIconTmpl?: TemplateRef<unknown>;

  // validation
  @Input() formCtrl: AbstractControl;
  @Input() isPassword: boolean;
  @Input() passwordValue: boolean;
  @Input() isPasswordRepeat: boolean;
  @Input() showPasswordHint: boolean;
  @Input() formSubmitted: boolean;
  @Input() hideErrors: boolean;

  @Input() patternErrorText: string;

  @Input() isRequired: boolean;
  @Input() requiredErrorText: string;

  @Input() maxLength: number;
  @Input() minLength: number;
  @Input() minLengthErrorText: string;
  @Input() maxLengthErrorText: string;

  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() minValueErrorText: string;
  @Input() maxValueErrorText: string;

  private _destroy$ = new Subject<void>();

  public uniqueDomId: string | undefined;
  public isPasswordVisible = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() deleteBtnClicked = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formSubmitted?.currentValue) {
      this.formCtrl?.markAsTouched();
    }
  }
  ngOnInit(): void {
    this.uniqueDomId = `ug-input-wrapper-${Math.ceil(Math.random() * 1000000)}`;
  }

  ngAfterViewInit(): void {
    if (this.isPassword) {
      this._setPasswVisibility(this.isPasswordVisible);
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onTogglePasswVisibility(): void {
    this._setPasswVisibility(!this.isPasswordVisible);
  }

  private _setPasswVisibility(isVisible: boolean): void {
    if (document) {
      const input = document.querySelector(
        `#${this.uniqueDomId} input`,
      ) as HTMLInputElement;
      if (input) {
        input.type = isVisible ? 'text' : 'password';
        this.isPasswordVisible = isVisible;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types , @typescript-eslint/no-explicit-any
  clear(e: any): void {
    this.formCtrl.setValue(undefined);
    this.formCtrl.markAsTouched();
    this.formCtrl.markAsDirty();
    if (this.deleteBtnClicked) {
      this.deleteBtnClicked.emit(e);
    }
  }
}
