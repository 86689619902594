import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Channels } from '@slc-libs/enums';
import { createHttpParams } from '@slc-libs/helpers';
import {
  getIdMock,
  getNumberMock,
  getRandomSentense,
  getUsernameMock,
} from '@slc-libs/mocks';

import { environment } from '@slice-env/environment';

import { DiscoverCreatorsFilters } from '@slice-interfaces/discover-creators/discover-creators-filters';
import { DiscoverCreatorsResponse } from '@slice-interfaces/discover-creators/discover-creators-response';
import { UserPublicProfile } from '@slice-interfaces/profile/creator-profile';
import { GetUsersParams } from '@slice-interfaces/users/get-users-params';
import {
  GetUserEmailResponse,
  GetUsersResponse,
} from '@slice-interfaces/users/get-users-response';

import { getDiscoveryCreatorsMocks } from '../_mocks/discover-creators.mock';
import { getUserEmailMocks, getUsersMocks } from '../_mocks/get-users.mock';

@Injectable({
  providedIn: 'root',
})
export class UsersHttpService {
  constructor(private http: HttpClient) {}

  getUserPublicProfile(
    id: string,
    useMock?: boolean,
  ): Observable<UserPublicProfile> {
    // mock
    const mocks = of({
      profileId: getIdMock(),
      userId: getIdMock(),
      name:
        Math.random() > 0.5
          ? getUsernameMock() + ' ' + getUsernameMock()
          : getUsernameMock(),
      avatar: Math.random() > 0.5 ? '/assets/svg/avatar.svg' : undefined,
      categories: [],
      biography: getRandomSentense(15),
      connectedAccounts: [
        {
          id: getIdMock(),
          channel: Channels.YOUTUBE,
          username:
            Math.random() > 0.5
              ? getUsernameMock() + ' ' + getUsernameMock()
              : getUsernameMock(),
          followersCount: getNumberMock(100),
        },
        {
          id: getIdMock(),
          channel: Channels.INSTAGRAM,
          username: 'sfgsdghjksdfgsdgfsdgfsdgfsdgfsdgfsdgfsdgfsdfgsdgsg',
          followersCount: getNumberMock(100),
        },
      ],
    }).pipe(delay(1000));
    const req = this.http.get<UserPublicProfile>(
      `/api/v1/users/${id}/public-profile`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }

  searchUser(
    params: GetUsersParams,
    useMock?: boolean,
  ): Observable<GetUsersResponse> {
    const req = this.http.get<GetUsersResponse>(`/api/v1/users`, {
      params: createHttpParams(params),
    });
    // mock
    const mocks = getUsersMocks(params);
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }

  searchEmail(
    params: GetUsersParams,
    useMock?: boolean,
  ): Observable<GetUserEmailResponse | null> {
    const req = this.http.get<GetUserEmailResponse | null>(
      `/api/v1/users/email`,
      {
        params: createHttpParams(params),
      },
    );
    // mock
    const mocks = getUserEmailMocks(params);
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }

  discoverCreators(
    params: DiscoverCreatorsFilters,
    useMock?: boolean,
  ): Observable<DiscoverCreatorsResponse> {
    const req = this.http.get<DiscoverCreatorsResponse>(`/api/v1/creators`, {
      params: createHttpParams(params),
    });
    // mock
    const mocks = getDiscoveryCreatorsMocks(params).pipe(delay(1000));
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }
}
