import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Observable } from 'rxjs';

import { BreakpointsService } from '@slc-libs/services';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

@Component({
  selector: 'slice-instagram-connect-info',
  templateUrl: './instagram-connect-info.component.html',
  styleUrls: ['./instagram-connect-info.component.scss'],
})
export class InstagramConnectInfoComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() isVisible: boolean;
  @Output() closed = new EventEmitter<boolean>();

  public isDesktop$: Observable<boolean>;

  constructor(private breakpointsS: BreakpointsService) {
    super();
  }

  ngOnInit(): void {
    this.isDesktop$ = this.breakpointsS.selectIsDesktop();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
