import { Injectable } from '@angular/core';

import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Layouts } from '@slc-libs/services';

const rerenderDebounceTime = 50;

@Injectable({
  providedIn: 'root',
})
export class BreakpointsService {
  private winW: number;
  private readonly breakpoints = {
    mobileMax: 599,
    tabletMax: 959,
  }; // must be equal vriables in css

  private layout$ = new BehaviorSubject<Layouts>({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    isNotDesktop: false,
  });
  private isMobile$ = new BehaviorSubject<boolean>(false);
  private isTablet$ = new BehaviorSubject<boolean>(false);
  private isDesktop$ = new BehaviorSubject<boolean>(false);
  public isNotDesktop$ = new BehaviorSubject<boolean>(false);

  private isInited = false;

  constructor() {
    this.winW = window.innerWidth;
    this.init();
  }

  initBreakpoints(d: { mobileMax: number; tabletMax: number }): void {
    this.breakpoints.mobileMax = d.mobileMax;
    this.breakpoints.tabletMax = d.tabletMax;
  }

  private init(): void {
    if (!this.isInited) {
      this.setLayoutType();
      fromEvent(window, 'resize').subscribe(() => {
        this.setLayoutType();
      });
    }
    this.isInited = true;
  }

  get winWidth(): number {
    return this.winW;
  }

  private setLayoutType(): void {
    const winWidth = window.innerWidth;
    this.winW = winWidth;
    // mobile
    if (winWidth <= this.breakpoints.mobileMax) {
      this.isMobile$.next(true);
      this.isTablet$.next(false);
      this.isDesktop$.next(false);
      this.isNotDesktop$.next(true);
    } else if (
      winWidth > this.breakpoints.mobileMax &&
      winWidth <= this.breakpoints.tabletMax
    ) {
      // tablet
      this.isMobile$.next(false);
      this.isTablet$.next(true);
      this.isDesktop$.next(false);
      this.isNotDesktop$.next(true);
    } else {
      // desktop
      this.isMobile$.next(false);
      this.isTablet$.next(false);
      this.isDesktop$.next(true);
      this.isNotDesktop$.next(false);
    }
    this.layout$.next({
      isMobile: this.isMobile$.value,
      isTablet: this.isTablet$.value,
      isDesktop: this.isDesktop$.value,
      isNotDesktop: this.isNotDesktop$.value,
    });
  }

  public selectIsMobile(): Observable<boolean> {
    return this.isMobile$
      .asObservable()
      .pipe(debounceTime(rerenderDebounceTime));
  }
  public getIsMobile(): boolean {
    return this.isMobile$.value;
  }

  public selectIsTablet(): Observable<boolean> {
    return this.isTablet$
      .asObservable()
      .pipe(debounceTime(rerenderDebounceTime));
  }

  public selectIsDesktop(): Observable<boolean> {
    return this.isDesktop$
      .asObservable()
      .pipe(debounceTime(rerenderDebounceTime));
  }

  public getIsDesktop(): boolean {
    return this.isDesktop$.value;
  }

  public selectIsNotDesktop(): Observable<boolean> {
    return this.isNotDesktop$
      .asObservable()
      .pipe(debounceTime(rerenderDebounceTime));
  }

  public selectLayout(): Observable<Layouts> {
    return this.layout$.asObservable().pipe(debounceTime(rerenderDebounceTime));
  }
}
