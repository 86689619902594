import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { NgOtpInputConfig } from 'ng-otp-input';

@Component({
  selector: 'slice-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() title? = 'OTP';
  @Input() subTitle?: string;
  @Input() timer = 1;
  @Input() itHasTimer = true;
  @Output() closed = new EventEmitter<any>();
  @Output() submitData = new EventEmitter<any>();
  @Output() resendData = new EventEmitter<any>();

  countdownDisplay: string;
  countdownFinished = false;

  form: FormGroup;
  verificationCodeControl: FormControl;
  otpConfig: NgOtpInputConfig = {
    length: 6,
    placeholder: '_',
    inputStyles: {
      width: '45px',
      'margin-top': '5px',
      'margin-left': '2px',
      'margin-right': '2px',
    },
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.isVisible = true;
    this.initForm();
    this.startCountdown(this.timer * 60);
  }

  initForm(): void {
    this.verificationCodeControl = new FormControl('', [Validators.required]);
    this.form = this.fb.group({
      verificationCode: this.verificationCodeControl,
    });
  }

  hide(): void {
    this.closed.emit(false);
  }

  resetCountdown(): void {
    this.countdownFinished = false;
    this.startCountdown(this.timer * 60);
  }

  startCountdown(durationInSeconds: number): void {
    let secondsRemaining = durationInSeconds;

    const updateDisplay = () => {
      const minutes = Math.floor(secondsRemaining / 60);
      const seconds = secondsRemaining % 60;
      this.countdownDisplay = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    updateDisplay(); // Initial display
    const countdownInterval = setInterval(() => {
      secondsRemaining--;
      if (secondsRemaining < 0) {
        clearInterval(countdownInterval);
        this.countdownDisplay = '0:00';
        this.countdownFinished = true;
      } else {
        updateDisplay();
      }
    }, 1000);
  }

  resend(): void {
    this.resetCountdown();
    this.resendData.emit();
  }
  submit(): void {
    this.submitData.emit(this.verificationCodeControl.value);
  }
}
