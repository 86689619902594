import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AuthStateService } from '@slice-services/auth-state.service';
import { SearchHttpService } from '@slice-services/search-http.service';
import { MessageService } from 'primeng/api';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { Channels } from '@slc-libs/enums';
import { getEmailMock, getIdMock } from '@slc-libs/mocks';
import { BreakpointsService } from '@slc-libs/services';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import {
  CreatorSearchDetail,
  CreatorSearchParams,
  XpressReportCreator,
} from '@slice-interfaces/xpress-report.model';

@Component({
  selector: 'slice-add-creator',
  templateUrl: './add-creator.component.html',
  styleUrls: ['./add-creator.component.scss'],
})
export class AddCreatorComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() channel: Channels;
  @Input() buttonTitle?: string = 'Add to Report';
  @Input() showConnectCreator? = true;
  @Input() creatorList: XpressReportCreator[] = [];
  @Input() creatorListAPI = '';
  // '/v1/agencies/aa639e7f-51aa-4e03-9e84-3be0a4496004/users';
  // '/v1/agency/{id}/users';
  // '/api/v1/agencies/{id}/creators';
  @Output() closed = new EventEmitter<XpressReportCreator[]>();
  @ViewChild('input', { static: true }) Input: ElementRef;

  public isVisible = true;
  public isLoading = false;
  public searchForm: any;
  public creatorSearchArray: CreatorSearchDetail[];
  public creatorFormArray: XpressReportCreator[];

  constructor(
    public tS: TranslocoService,
    public bS: BreakpointsService,
    private messageS: MessageService,
    private authStateS: AuthStateService,
    private searchHttpS: SearchHttpService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.inputEvent();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private initForm(): void {
    this.creatorFormArray = [];
    this.creatorSearchArray = [];
  }

  /* This method associate to filter query from user input */
  inputEvent() {
    fromEvent(this.Input.nativeElement, 'keyup') // event from keyboard
      .pipe(
        map(
          (evt: any) => evt.target.value,
        ) /* map different value from user input */,
        // filter(res => res.length > 2),                   /* text length must be > 2 chars */
        debounceTime(750) /* emit after 1s of silence */,
        distinctUntilChanged() /* emit only if data changes since the last emit */,
      )
      .subscribe((keyword: string) => this.searchCreator(keyword));
  }

  public searchCreator(keyword: string): void {
    this.isLoading = true;
    const params: CreatorSearchParams = {
      page: 0,
      size: 10,
      name: keyword,
      roles: ['creator'],
    };
    if (this.creatorListAPI) {
      this.searchHttpS.hitCustomURL(this.creatorListAPI, params).subscribe(
        res => {
          if (res?.content !== undefined && res?.content !== null) {
            this.creatorSearchArray = res.content;
          }
          this.isLoading = false;
        },
        error => {
          console.log('error', error);
          this.isLoading = false;
        },
      );
    } else {
      this.searchHttpS
        .findCreatorAgencyId(
          this.authStateS.getAuthenticatedUser()?.agencyOwned as string,
          // this.authStateS.getAuthenticatedUser()?.userId as string,
          params,
          false,
        )
        .subscribe(
          res => {
            if (res?.content !== undefined && res?.content !== null) {
              this.creatorSearchArray = res.content;
            }
            this.isLoading = false;
          },
          e => {
            console.log('error', e);
            this.isLoading = false;
          },
        );
    }
  }

  public addingCreatorFromSearch(creator: any) {
    if (creator) {
      this.creatorFormArray.push(<XpressReportCreator>{
        userId: getIdMock(),
        email: getEmailMock(),
        channel: this.channel as unknown,
        username: creator,
        followersCount: 69,
        engagementRate: 69,
        cpr: 42,
        cpv: 42,
        cpi: 42,
        cpe: 42,
        postList: [],
        postPrices: [],
        totalPrice: 0,
      });
    }
  }

  public addingCreatorFromSelect(creator: CreatorSearchDetail) {
    // check if creator already selected inside form
    if (
      this.creatorList?.length &&
      this.creatorList.findIndex(c => c.userId === creator.userId) !== -1
    ) {
      this.messageS.add({
        summary: this.tS.translate('campaign.message.cannot-add-creator'),
        severity: 'error',
        life: 8 * 1000,
        detail: this.tS.translate('campaign.message.existed-creator-form'),
      });
      return;
    }

    // check if creator already selected inside dialog
    const existedAccountIndex = this.creatorFormArray.findIndex(
      account => account.username === creator.name,
    );
    if (existedAccountIndex > -1) {
      this.messageS.add({
        summary: this.tS.translate('campaign.message.cannot-add-creator'),
        severity: 'error',
        life: 8 * 1000,
        detail: this.tS.translate('campaign.message.existed-creator'),
      });
      return;
    }

    // check if creator is having a connected account
    // if (!creator || creator.socialAccounts.length === 0) {
    //   this.messageS.add({
    //     summary: this.tS.translate('campaign.message.cannot-add-creator'),
    //     severity: 'error',
    //     life: 8 * 1000,
    //     detail: this.tS.translate('campaign.message.no-social-creator'),
    //   });
    //   return;
    // }

    // check if creator has account for this channel
    const accountIndex = creator.socialAccounts.findIndex(
      account => account.channel === this.channel,
    );
    // if (accountIndex === -1) {
    //   this.messageS.add({
    //     summary: this.tS.translate('campaign.message.cannot-add-creator'),
    //     severity: 'error',
    //     life: 8 * 1000,
    //     detail: this.tS.translate('campaign.message.no-channel-creator', {
    //       channel: this.channel,
    //     }),
    //   });
    //   return;
    // }

    // All validations passed, add creator to the form array
    const selectedAccount = creator.socialAccounts[accountIndex];
    this.creatorFormArray.push(<XpressReportCreator>{
      avatar: creator.avatar,
      userId: creator.userId,
      email: creator.email,
      username: creator.name,
      channel: selectedAccount?.channel ?? (this.channel as any),
      followersCount: selectedAccount?.followers ?? 0,
      engagementRate: selectedAccount?.stats?.er ?? 0,
      cpr: selectedAccount?.stats?.cpr ?? 0,
      cpv: selectedAccount?.stats?.cpv ?? 0,
      cpi: selectedAccount?.stats?.cpi ?? 0,
      cpe: selectedAccount?.stats?.cpe ?? 0,
      postPrices: creator.postPrices,
    });
  }

  public deleteCreatorId(userId?: string) {
    if (userId) {
      this.creatorFormArray = this.creatorFormArray.filter(
        creator => creator.userId !== userId,
      );
    } else {
      this.creatorFormArray.pop();
    }
  }

  onHide(): void {
    this.closed?.emit(undefined);
  }

  onSubmit(): void {
    if (this.creatorFormArray.length > 0) {
      this.closed?.emit(this.creatorFormArray);
    }
  }

  checkIfChannelExists(creator: any): boolean {
    if (this.showConnectCreator) {
      return (
        creator?.socialAccounts?.some(
          (account: { channel: Channels }) => account.channel === this.channel,
        ) ?? false
      );
    } else {
      return true;
    }
  }
}
