<button
  *ngFor="let item of list; let isLast = last; let i = index"
  class="slc-switcher-item"
  [ngClass]="[
    list.length > 2 ? 'slc-switcher-more-than-2' : '',
    isLast ? 'slc-switcher-item-last' : '',
    i === 0 ? 'slc-switcher-item-first' : '',
    i === currIndex ? 'slc-switcher-item-selected' : '',
    i === currIndex - 1 ? 'slc-switcher-next-selected' : ''
  ]"
  type="button"
  (click)="select(item)"
  title="{{ item?.title }}"
>
  <ng-container *ngIf="item.tmpl; else baseTmpl">
    <ng-container
      [ngTemplateOutlet]="item.tmpl"
      [ngTemplateOutletContext]="{ item: item }"
    ></ng-container>
  </ng-container>

  <ng-template #baseTmpl>
    <span class="slc-switcher-title">
      {{ item.title }}
    </span>
  </ng-template>
</button>

<div
  *ngIf="currIndex >= 0"
  class="slc-switcher-toggle {{ 'slc-switcher-toggle-length-' + list.length }}"
  [ngStyle]="{
    left: !isVertical ? (100 / list.length) * currIndex + '%' : 'auto',
    top: isVertical ? (100 / list.length) * currIndex + '%' : 'auto'
  }"
  title="{{ currItem?.title }}"
>
  <div class="slc-switcher-toggle-inner">
    <ng-container *ngIf="currItem?.tmpl; else baseTmpl">
      <ng-container
        [ngTemplateOutlet]="$any(currItem?.tmpl)"
        [ngTemplateOutletContext]="{ item: currItem }"
      ></ng-container>
    </ng-container>

    <ng-template #baseTmpl>
      <span class="slc-switcher-title">
        {{ currItem?.title }}
      </span>
    </ng-template>
  </div>
</div>
