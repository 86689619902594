<header class="slc-flex-fs-c">
  <div class="title">
    {{ titleValue }}
    <span *ngIf="titleColored">{{ titleColored }}</span>
  </div>
  <slc-tooltip
    *ngIf="tooltipKey"
    tooltipKey="{{ tooltipKey }}"
    okBtnTitle="{{ okBtnTitle ? okBtnTitle : 'ok' }}"
  >
    <slc-svg-icon-question-circle
      *ngIf="!isInfoIcon; else questionIconTmpl"
    ></slc-svg-icon-question-circle>
    <ng-template #questionIconTmpl>
      <slc-svg-icon-info-circle></slc-svg-icon-info-circle>
    </ng-template>
  </slc-tooltip>
  <button *ngIf="isEditBtn" (click)="editClicked.emit()">
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 21H21.5"
        stroke="grey"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.5 13.36V17H9.1586L19.5 6.65405L15.8476 3L5.5 13.36Z"
        stroke="grey"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</header>

<div class="values-wrap set_flex_end_c slc-text-align-right">
  <div
    class="value"
    [ngClass]="{ 'slc-text-ellipsis': !disableEllipsis }"
    title="{{
      isNumber
        ? val || val === 0
          ? (val | number : NUMBERS_DECIMAL_VIEW)
          : '-'
        : val || '-'
    }}"
  >
    <ng-container *ngIf="val && currency"> {{ currency }}</ng-container>
    {{
      isNumber
        ? val || val === 0
          ? (val | number : NUMBERS_DECIMAL_VIEW)
          : '-'
        : val || '-'
    }}
    <ng-container *ngIf="val && symbol"> {{ symbol }}</ng-container>
  </div>
  <div
    *ngIf="percentChanges"
    class="percent"
    [ngClass]="[percentChanges > 0 ? 'plus' : 'minus']"
  >
    <span *ngIf="percentChanges > 0">+</span>
    <span>{{ percentChanges }}%</span>
  </div>
</div>
