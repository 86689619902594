import { Injectable, TemplateRef } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BreadcrumbsWithoutLinksItem } from '@slice-interfaces/breadcrumbs-without-links';

@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  public pageTitleKey$ = new BehaviorSubject<string | undefined>(undefined);
  private customTitle$ = new BehaviorSubject<string | undefined>(undefined);
  public customTmpl$ = new BehaviorSubject<TemplateRef<any> | null>(null);
  public breadcrumbsWithoutLinks$ =
    new BehaviorSubject<Array<BreadcrumbsWithoutLinksItem> | null>(null);

  constructor(private tS: TranslocoService) {}

  setPageTitleKey(key: string): void {
    this.pageTitleKey$.next(key);
  }

  removePageTitle(): void {
    this.pageTitleKey$.next(undefined);
    this.customTitle$.next(undefined);
    this.customTmpl$.next(null);
    this.breadcrumbsWithoutLinks$.next(null);
  }

  selectPageTitle(): Observable<string | undefined> {
    return combineLatest([
      this.selectPageTitleKeyTranslation(),
      this.customTitle$.asObservable(),
    ]).pipe(map(([usual, custom]) => custom || usual || undefined));
  }

  private selectPageTitleKeyTranslation(): Observable<string | undefined> {
    return combineLatest([
      this.tS.selectTranslation(),
      this.pageTitleKey$.asObservable(),
    ]).pipe(
      filter(([vocabulary]) =>
        Boolean(vocabulary && Object.values(vocabulary)),
      ),
      map(([, key]) => (key ? this.tS.translate(key) : undefined)),
    );
  }

  setCustomTitle(v: string | undefined): void {
    this.customTitle$.next(v);
  }
  selectCustomTitle(): Observable<string | undefined> {
    return this.customTitle$.asObservable();
  }

  setCustomTmpl(tmpl: TemplateRef<any> | null): void {
    this.removePageTitle();
    this.customTmpl$.next(tmpl);
  }
  removeCustomTmpl(): void {
    this.customTmpl$.next(null);
  }
  selectCustomTmpl(): Observable<TemplateRef<any> | null> {
    return this.customTmpl$.asObservable();
  }

  setBreadcrumbsWithoutLinks(
    d: Array<BreadcrumbsWithoutLinksItem> | null,
  ): void {
    this.removePageTitle();
    this.breadcrumbsWithoutLinks$.next(d);
  }
  removeBreadcrumbsWithoutLinks(): void {
    this.breadcrumbsWithoutLinks$.next(null);
  }
  selectBreadcrumbsWithoutLinks(): Observable<Array<BreadcrumbsWithoutLinksItem> | null> {
    return this.breadcrumbsWithoutLinks$.asObservable();
  }
}
