export enum PostTypes {
  POST = 'post', // instagram
  STORIES = 'story', // instagram
  VIDEO = 'video', // instagram,
  TIKTOK_VIDEO = 'tiktok_video',
  REELS = 'reels', // instagram
  SHORTS = 'shorts', // yt
  HOST_READ_AD = 'host_read_ad', // yt
  PRODUCT_REVIEW = 'product_review_full_ep', // yt
  PRODUCT_PLACEMENT = 'product_placement', // yt
  SPONSORED_VIDEO = 'sponsored_video', // tk
  StoreVisit = 'store_visit', // tk
  LICENSE = 'license', // ig, yt, tk

  CAROUSEL_ALBUM = 'carousel_album', // optional instagram, yt
  STORY_SESSION = 'story_session', // optional instagram
  STORY_LINK = 'story_link', // optional instagram
  Exclusive1Month = 'exclusive_1month', // optional instagram, tk
  Exclusive3Month = 'exclusive_3month', // optional instagram, tk
  Exclusive5Month = 'exclusive_5month', // optional instagram
  Permanent = 'exclusive_permanent', // optional instagram
  BioLink1Week = 'link_1week', // optional instagram
  BioLink1Month = 'link_1month', // optional instagram

  SpokePerson = 'spoke_person', // appearance only
  Ambassador = 'ambassador', // appearance only
  Attendance = 'attendance', // appearance only
  PhotoShoot = 'photo_shoot', // appearance only

  VideoShoot = 'video_shoot', // custom SOW
  LiveStream = 'livestream', // custom SOW
  ContentOwning = 'content_owning', // custom SOW
}
