import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { AuthHttpService } from '@slice-services/auth-http.service';
import { AuthStateService } from '@slice-services/auth-state.service';
import { LocalStorageService } from '@slice-services/local-storage.service';
import { NavigationService } from '@slice-services/navigation.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { createNotAuthenticatedToken } from '../_helpers/create-not-auth-http-options';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private accessTokenSubject$ = new BehaviorSubject<string | undefined>(
    undefined,
  );
  constructor(private injector: Injector) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const authStateS: AuthStateService = this.injector.get(AuthStateService);
    const authHttpS: AuthHttpService = this.injector.get(AuthHttpService);
    const navigationS: NavigationService = this.injector.get(NavigationService);
    const lsS: LocalStorageService = this.injector.get(LocalStorageService);
    const at = authStateS.getAccessToken();
    if (request.url.includes('/blog/')) {
      // include additional header for squarespace auth here is needed
    } else {
      if (request.url.includes('/api/public/')) {
        request = request.clone({
          setHeaders: {
            Authorization: createNotAuthenticatedToken(),
          },
        });
      } else if (at) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${at}`,
          },
        });
      }
      if (at) {
        if (request.url === authHttpS.REFRESH_TOKEN_URL) {
          request = request.clone({
            setHeaders: {
              Authorization: createNotAuthenticatedToken(),
            },
          });
        } else {
          request = request.clone({
            setHeaders: {
              Authorization: request.url.includes('/api/public/')
                ? createNotAuthenticatedToken()
                : `Bearer ${at}`,
            },
          });
        }
      }
    }
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (request.url === authHttpS.REFRESH_TOKEN_URL) {
            setTimeout(() => {
              authStateS.logout();
              navigationS.goToSignIn();
            }, 0);
            return of(true);
          }
          return this.handle401Error(
            request,
            next,
            authStateS,
            authHttpS,
            navigationS,
            lsS,
          );
        }
        return throwError(error);
      }),
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
    authStateS: AuthStateService,
    authHttpS: AuthHttpService,
    navigationS: NavigationService,
    lsS: LocalStorageService,
  ): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.accessTokenSubject$.next(undefined);
      let rt;
      try {
        rt = lsS.getRefreshToken();
      } catch (err) {
        console.error(err);
      }

      if (!rt) {
        authStateS.logout();
        navigationS.goToSignIn();
        return throwError('Logout');
      }
      return authHttpS.refreshToken(rt).pipe(
        catchError(error => {
          authStateS.logout();
          navigationS.goToSignIn();
          return throwError(error);
        }),
        switchMap(data => {
          this.isRefreshing = false;
          lsS.setRefreshToken(data.refresh_token);
          authStateS.setAccessToken(data.access_token);
          this.accessTokenSubject$.next(data.access_token);
          return next.handle(
            request.clone({
              setHeaders: {
                Authorization: `Bearer ${data?.access_token}`,
              },
            }),
          );
        }),
      );
    }

    return this.accessTokenSubject$.pipe(
      filter(accessToken => accessToken !== undefined),
      take(1),
      switchMap(accessToken => {
        return next.handle(
          request.clone({
            setHeaders: {
              Authorization: `Bearer ${accessToken}`,
            },
          }),
        );
      }),
    );
  }
}
