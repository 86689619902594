import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { Channels } from '@slc-libs/enums';
import { BreakpointsService } from '@slc-libs/services';
import { BasicTableModel, TableColumnAlignment } from '@slc-libs/tables';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import {
  XpressReportCreator,
  XpressReportCreatorPostRequests,
  XpressReportPayload,
} from '@slice-interfaces/xpress-report.model';

@Component({
  selector: 'slice-custom-rate-creator',
  templateUrl: './custom-rate-creator.component.html',
  styleUrls: ['./custom-rate-creator.component.scss'],
})
export class CustomRateCreatorComponent
  extends AbstractSubscriberComponent
  implements OnInit
{
  @Input() channel: Channels;
  @Input() otherTotals: number;
  @Input() creatorForm: XpressReportCreator | null;
  @Output() closed = new EventEmitter<XpressReportCreator>();

  @ViewChild('rateCardTmpl', { static: true }) rateCardTmpl: TemplateRef<any>;
  @ViewChild('postTypeTmpl', { static: true }) postTypeTmpl: TemplateRef<any>;
  @ViewChild('sowTmpl', { static: true }) sowTmpl: TemplateRef<any>;

  public isVisible = true;
  public isEditing = false;
  public Channels = Channels;
  public customCreatorRate: XpressReportCreator;

  public table = new BasicTableModel();
  public resize$ = new Subject<any>();
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.resize$.next(event.target.innerWidth);
  }

  constructor(public tS: TranslocoService, public bS: BreakpointsService) {
    super();
  }

  ngOnInit(): void {
    if (this.channel && this.creatorForm) {
      this.isEditing = false;
      this.customCreatorRate = JSON.parse(JSON.stringify(this.creatorForm)); // deep copy
    } else {
      alert('wrong input value');
      setTimeout(() => {
        this.onHide();
      }, 0);
    }
    this.initTable();
  }

  private initTable() {
    this.table.columns = [
      {
        titleKey: 'table.column.post-type.title',
        cellTmpl: this.postTypeTmpl,
        alignment: TableColumnAlignment.left,
        width: '50%',
      },
      // {
      //   titleKey: 'table.column.sow.title',
      //   cellTmpl: this.sowTmpl,
      //   alignment: TableColumnAlignment.center,
      //   width: '30%',
      // },
      {
        titleKey: 'table.column.rate-card.title',
        cellTmpl: this.rateCardTmpl,
        alignment: TableColumnAlignment.right,
        width: '50%',
      },
    ];
    this.table.windowWidth = window.innerWidth;
    this.initWindowResize();
  }

  private initWindowResize(): void {
    this.resize$
      .asObservable()
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => {
        this.table.windowWidth = window.innerWidth;
      });
  }

  getCurrentNetTotal(): number {
    return this.customCreatorRate.totalPrice + (this.otherTotals ?? 0);
  }

  updateTotalPrices(): void {
    if (this.customCreatorRate) {
      this.customCreatorRate.totalPrice = 0;
      this.customCreatorRate.postList.map(
        (p: XpressReportCreatorPostRequests) => {
          p.priceChanged =
            p.quantity !== 0 &&
            p.price !==
              XpressReportPayload.findPostPrices(
                this.customCreatorRate,
                this.channel,
                p.postType,
              );
          this.customCreatorRate.totalPrice += p.price * p.quantity;
          return p;
        },
      );
    }
  }

  onHide(): void {
    if (this.isEditing) {
      this.onCancel(this.creatorForm as any);
    } else {
      this.closed?.emit(this.customCreatorRate);
    }
  }

  onCancel(creatorForm: XpressReportCreator): void {
    this.customCreatorRate = creatorForm;
    this.isEditing = false;
  }

  toggleEditing() {
    this.isEditing = !this.isEditing;
  }
}
