// ang
import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { AuthStateService } from '@slice-services/auth-state.service';
import { NavigationService } from '@slice-services/navigation.service';

import { E_ROUTES } from '@slice-enums/routes.enum';

// lib

@Injectable()
export class PublicGuard implements CanActivate {
  constructor(
    private navigationS: NavigationService,
    private router: Router,
    private authStateS: AuthStateService,
  ) {}

  public canActivate(): boolean | UrlTree {
    return this.check();
  }

  private check(): boolean | UrlTree {
    if (this.authStateS.isAuthenticated()) {
      return this.router.parseUrl(this.navigationS.getRoutePath(E_ROUTES.HOME));
    } else {
      return true;
    }
  }
}
