// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const removeFromFilterEmptyValues = (obj: any): any => {
  for (const prop in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(prop)) {
      if (
        typeof obj[prop] === 'undefined' ||
        obj[prop] === 'undefined_undefined,undefined_undefined' ||
        obj[prop] === null ||
        obj[prop] === '' ||
        (Array.isArray(obj[prop]) && obj[prop]?.length === 0)
      ) {
        delete obj[prop];
      }
    }
  }
  return obj;
};
