<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  (onHide)="hide()"
>
  <p-header><h4>Connect Social Media Account</h4></p-header>

  <div>
    <div class="img-wrap">
      <img src="/assets/svg/tiktok.svg" alt="" class="tiktok" />
    </div>
    <p *ngIf="!isTiktokConnected" class="no-acc my-2">
      No Account Connected Yet
    </p>

    <slice-tiktok-connect
      [isConnected]="isTiktokConnected"
      [accountId]="dtTiktokConnected?.id"
      [disabled]="isLoadingTiktok"
      [redirectTo]="redirectTo"
      (actionInProgress)="actionInProgressTiktok($event)"
      (connected)="connectedTiktok($event)"
      (disconnected)="disconnectedTiktok($event)"
    >
      <div
        class="slc-btn"
        [ngClass]="{
          'slc-btn-loading': isLoadingTiktok,
          'slc-btn-outline-green my-2': isTiktokConnected,
          'slc-btn-outline-blue': !isTiktokConnected
        }"
      >
        <span *ngIf="!isTiktokConnected">Connect Account</span>
        <span *ngIf="isTiktokConnected">
          <i class="pi pi-check-circle mx-2"></i>
          Connected</span
        >
      </div>
    </slice-tiktok-connect>
    <div *ngIf="isTiktokConnected" class="my-2">
      <div class="d-flex justify-content-between">
        <p class="font-weight-bold user-name">
          {{ dtTiktokConnected?.username }}
        </p>
        <button (click)="disconnectBtnTiktok()" class="btn-dc">
          Disconnect
        </button>
      </div>
    </div>
  </div>
  <hr />
  <div>
    <div class="img-wrap">
      <img src="/assets/svg/instagram.svg" class="instagram" alt="" />
    </div>
    <p>
      This will allow us to pull accurate performance analytics, audience
      demographics, and reporting data. Learn more
    </p>
    <p *ngIf="!isIgConnected" class="no-acc my-2">No Account Connected Yet</p>
    <slice-instagram-connect
      #ig
      [popupFacebookSocialMsg]="true"
      [isConnected]="isIgConnected"
      [accountId]="dtIgConnected?.id"
      [disabled]="isIgConnected || isLoadingIg"
      (actionInProgress)="actionInProgressIg($event)"
      (disconnected)="disconnectedIg($event)"
      (connected)="connectedIg($event)"
    >
      <div
        class="slc-btn"
        [ngClass]="{
          'slc-btn-loading': isLoadingIg,
          'slc-btn-outline-green my-2': isIgConnected,
          'slc-btn-outline-blue': !isIgConnected
        }"
      >
        <span *ngIf="!isIgConnected">Connect Account</span>
        <span *ngIf="isIgConnected">
          <i class="pi pi-check-circle mx-2"></i>
          Connected</span
        >
      </div>
    </slice-instagram-connect>
    <div class="my-2" *ngIf="dtIgConnected">
      <div class="d-flex justify-content-between my-2">
        <p class="font-weight-bold user-name">{{ dtIgConnected?.username }}</p>
        <button (click)="disconnectBtnIg()" class="btn-dc">Disconnect</button>
      </div>
    </div>
  </div>
  <hr />

  <div>
    <div class="img-wrap">
      <img src="/assets/svg/youtube.svg" class="tiktok" alt="" />
    </div>
    <p>This is to ensure accurate reporting. Learn more</p>
    <p *ngIf="!isGoogleConnected" class="no-acc my-2">
      No Account Connected Yet
    </p>

    <slice-google-connect
      #google
      [isConnected]="isGoogleConnected"
      [disabled]="isLoadingGoogle || dtGoogleConnected"
      [accountId]="dtGoogleConnected?.id"
      (actionInProgress)="actionInProgressGoogle($event)"
      (connected)="connectedGoogle($event)"
      (disconnected)="disconnectedGoogle($event)"
    >
      <div
        class="slc-btn"
        [ngClass]="{
          'slc-btn-loading': isLoadingGoogle,
          'slc-btn-outline-green my-2': isGoogleConnected,
          'slc-btn-outline-blue': !isGoogleConnected
        }"
      >
        <span *ngIf="!isGoogleConnected">Connect Account</span>
        <span *ngIf="isGoogleConnected">
          <i class="pi pi-check-circle mx-2"></i>
          Connected</span
        >
      </div>
    </slice-google-connect>
    <div class="my-2">
      <div
        *ngIf="dtGoogleConnected"
        class="d-flex justify-content-between my-2"
      >
        <p class="font-weight-bold user-name">
          {{ dtGoogleConnected?.username }}
        </p>
        <button (click)="disconnectBtnGoogle()" class="btn-dc">
          Disconnect
        </button>
      </div>
    </div>
  </div>
</p-dialog>
