import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AssetsTitleKeyMap } from 'apps/slice/src/app/_const/assets-titles';

import { AssetsType } from '@slice-enums/assets/asset-type';

interface Item {
  id: number;
  base64?: string | undefined;
  video?: File;
  file: File;
}
@Component({
  selector: 'slice-submit-content-popup-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class SubmitContentPopupPreviewComponent {
  @Input() set fileList(data: Array<File>) {
    this.selected(data);
  }
  @Input() assetType: AssetsType;

  public files: Array<Item> = [];
  public currItemOrder = 0;
  public assetsTitleKeyMap = AssetsTitleKeyMap;

  @Output() delete = new EventEmitter<File>();

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  playerInited(playerApi: any): void {
    console.log('submit', playerApi);
  }

  changePage(e: { page: number }): void {
    this.currItemOrder = e.page;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  selected(files?: Array<File>): void {
    this.files = [];
    files?.forEach(file => {
      const isExist = this.files.find(i => i.file === file);
      if (!isExist) {
        const id = Math.ceil(Math.random() * 10000);
        if (file.type.startsWith('image')) {
          const reader = new FileReader();
          const val = {
            id,
            base64: undefined,
            file: file,
          };
          this.files.push(val);
          reader.onload = (event: any) => {
            val.base64 = event.target.result;

            this.files = [...this.files];
          };
          reader.readAsDataURL(file);
        }
        if (file.type.startsWith('video')) {
          this.files.push({
            id,
            base64: undefined,
            video: URL.createObjectURL(file as any) as any,
            file: file,
          });
          this.files = [...this.files];
        }
      }
    });
  }

  deleteItem(): void {
    // this.files = this.files.filter(
    //   i => i.id !== this.files[this.currItemOrder].id,
    // );
    this.delete?.emit(this.files[this.currItemOrder].file);
  }

  // localFileVideoPlayer(): void {
  // 'use strict'
  // const URL = window.URL || window.webkitURL;
  // const displayMessage = function (message, isError) {
  //   const element = document.querySelector('#message');
  //   element.innerHTML = message;
  //   element.className = isError ? 'error' : 'info';
  // };
  // const playSelectedFile = (event): any => {
  //   const file = this.files[0];
  //   const type = file.type;
  //   const videoNode = document.querySelector('video');
  //   let canPlay = videoNode.canPlayType(type);
  //   if (canPlay === '') canPlay = 'no';
  //   const message = 'Can play type "' + type + '": ' + canPlay;
  //   const isError = canPlay === 'no';
  //   displayMessage(message, isError);

  //   if (isError) {
  //     return;
  //   }

  //   const fileURL = URL.createObjectURL(file);
  //   videoNode.src = fileURL;
  // };
  // const inputNode = document.querySelector('input');
  // inputNode.addEventListener('change', playSelectedFile, false);
  // }
}
