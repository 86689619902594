<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  (onHide)="hide()"
>
  <p-header>
    <div class="w-100">
      <h4 class="text-center font-weight-bold text-dark">{{ title }}</h4>
    </div>
  </p-header>
  <div>
    <h6 *ngIf="subTitle" class="text-center">
      {{ subTitle }}
    </h6>
    <div *ngIf="itHasTimer">
      <p class="counter mt-3 mb-2 text-center">{{ countdownDisplay }}</p>
      <div class="w-100 text-center">
        <a
          (click)="resend()"
          class="ver mt-1"
          [ngClass]="{
            invisible: !countdownFinished,
            visible: countdownFinished
          }"
          >Resend Code</a
        >
      </div>
    </div>
    <form (ngSubmit)="submit()" [formGroup]="form">
      <div class="mb-3">
        <div class="rounded shadow-sm py-4 px-md-1 px-0">
          <ng-otp-input
            #verificationCodes
            [config]="otpConfig"
            [formCtrl]="verificationCodeControl"
          ></ng-otp-input>
        </div>
      </div>
      <button type="submit" class="slc-btn slc-btn-primary text-light w-100">
        Submit
      </button>
    </form>
  </div>
</p-dialog>
