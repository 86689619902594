import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';

import { SvgIconCheckmarkModule } from '@slc-libs/svg-icons';

import { LanguageComponent } from './language/language.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    TranslocoModule,
    SvgIconCheckmarkModule,
  ],
  declarations: [LanguageComponent],
  exports: [TranslocoModule, NgSelectModule, LanguageComponent],
})
export class LanguageModule {}
