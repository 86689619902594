<div #scrollContainer class="steps-wrap">
  <ng-container *ngFor="let item of items; let l = last; let i = index">
    <button
      type="button"
      id="{{ domIdPrefix + item.value }}"
      [ngClass]="[
        item.isCurrent ? 'current' : '',
        l ? 'last' : '',
        item.isTouched ? 'touched' : ''
      ]"
      [disabled]="!item.isTouched && !item.isCurrent"
      (click)="setItem(item)"
    >
      <div class="order">{{ i + 1 }}</div>
      <div class="title">{{ item.titleKey | transloco }}</div>
      <span
        *ngIf="item.isPassed && !item.isDataValid"
        class="error-label"
      ></span>
    </button>
  </ng-container>
</div>
