import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';
import { NgOtpInputConfig } from 'ng-otp-input';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

import { ChangePinFacadeService } from '../services/change-pin-facade.service';

interface Config {
  imgPath: string;
  titleKey?: string;
  descriptionKey?: string;
  mainBtnKey?: string;
  additionalBtnKey?: string;
  showInfoIcon?: boolean;
  showSupportBtn?: boolean;
  showAnnualPaymentBtn?: boolean;
  logout?: boolean;
}

@Component({
  selector: 'slice-forgot-verification-code',
  templateUrl: './forgot-verification-code.component.html',
  styleUrls: ['./forgot-verification-code.component.scss'],
})
export class ForgotVerificationCodeComponent
  extends AbstractSubscriberComponent
  implements OnInit
{
  @HostBinding('style.--image') image = '';
  @ViewChild('verificationCodes', { static: false }) verificationCodes: any;
  private config: Config = {
    imgPath: "url('/assets/images/girl-email-sent.png')",
    titleKey: 'invoice-response-modal.invoice-response-success.title',
    descriptionKey:
      'invoice-response-modal.invoice-response-success.description',
    mainBtnKey: 'invoice-response-modal.invoice-response-success.main-btn',
  };

  countdownDisplay: string;
  countdownFinished = false;

  public currConfig: Config;
  isVisible = false;
  isLoading = false;
  email: string;
  verificationCodeControl: FormControl;
  form: FormGroup;
  otpConfig: NgOtpInputConfig = {
    length: 6,
    placeholder: '_',
    inputStyles: {
      width: '60px',
      'margin-top': '5px',
    },
  };

  constructor(
    private fb: FormBuilder,
    private facadeS: ChangePinFacadeService,
    private tS: TranslocoService,
    private mS: MessageService,
  ) {
    super();
    this.currConfig = this.config;
    this.image = this.config.imgPath;
    this.facadeS
      .selectIsModalVerification()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => (this.isVisible = true));
    this.facadeS
      .selectIsLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => (this.isLoading = r));
    this.facadeS
      .selectEmail()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => (this.email = r));
  }

  ngOnInit(): void {
    this.startCountdown(2 * 60);
    this.initForm();
  }

  resetCountdown(): void {
    this.countdownFinished = false;
    this.startCountdown(2 * 60); // Reset to 5 minutes
  }

  startCountdown(durationInSeconds: number): void {
    let secondsRemaining = durationInSeconds;

    const updateDisplay = () => {
      const minutes = Math.floor(secondsRemaining / 60);
      const seconds = secondsRemaining % 60;
      this.countdownDisplay = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    updateDisplay(); // Initial display
    const countdownInterval = setInterval(() => {
      secondsRemaining--;
      if (secondsRemaining < 0) {
        clearInterval(countdownInterval);
        this.countdownDisplay = '0:00';
        this.countdownFinished = true;
      } else {
        updateDisplay();
      }
    }, 1000);
  }

  initForm(): void {
    this.verificationCodeControl = new FormControl('', [Validators.required]);
    this.form = this.fb.group({
      verificationCode: this.verificationCodeControl,
    });
  }
  triggerHide(): void {
    this.facadeS.setIsModalVerification(false);
    this.reset();
  }

  reset(): void {
    this.form.reset();
    this.setVal('');
  }

  setVal(val: any) {
    this.verificationCodeControl.setValue(val);
  }
  submit(): void {
    if (this.form.valid) {
      const payload = {
        verificationCode: this.verificationCodeControl.value,
      };

      this.facadeS.setIsLoading(true);
      this.facadeS
        .verificationEmail(payload)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          r => {
            this.facadeS.setIsLoading(false);
            if (r) {
              this.mS.add({
                severity: 'success',
                life: 5 * 1000,
                detail: 'Success to Verified',
              });
              this.facadeS.setIsModalResetPin(true);
              this.facadeS.setVerificationCode(
                this.verificationCodeControl.value,
              );
              this.triggerHide();
            } else {
              this.mS.clear();
              this.mS.add({
                severity: 'error',
                life: 5 * 1000,
                detail: 'Failed to Verified the code',
              });
            }
          },
          err => {
            this.facadeS.setIsLoading(false);
            this.mS.clear();
            this.mS.add({
              severity: 'error',
              life: 5 * 1000,
              detail: 'Failed to Verified the code',
            });
          },
        );
    } else {
      this.mS.add({
        severity: 'error',
        life: 5 * 1000,
        detail: 'Please Input the right code',
      });
    }
  }

  resend(): void {
    this.facadeS.setIsLoading(true);
    this.resetCountdown();
    const py = { email: this.email };
    this.facadeS
      .forgotPass(py)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.facadeS.setIsLoading(false);
          this.mS.add({
            severity: 'success',
            life: 5 * 1000,
            detail: this.tS.translate(
              'profiles.my.response.forgot-pin.success',
            ),
          });
        },
        err => {
          this.facadeS.setIsLoading(false);
          this.mS.clear();
          this.mS.add({
            severity: 'error',
            life: 5 * 1000,
            detail: 'Failed to sent the email',
          });
        },
      );
  }
}
