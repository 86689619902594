export const MOCK_EMAILS = [
  'qq@mocked.com',
  'qweqwe33e2@mocked.com',
  'faste543tgs@mocked.com',
  'ymi68u5hrrhye@mocked.com',
  'wefr3424ff324dwaxwc3d@mocked.com',
  '324f2fasfre4f3r434f34ffqwfqqf@mocked.com',
];

export const getEmailMock = (): string => {
  return MOCK_EMAILS[Math.floor(Math.random() * MOCK_EMAILS.length)];
};
