<svg
  xmlns="http://www.w3.org/2000/svg"
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
>
  <path
    d="M0.75 0.75V3.75C8.1975 3.75 14.25 9.8025 14.25 17.25H17.25C17.25 8.1375 9.8625 0.75 0.75 0.75ZM0.75 6V9C5.3025 9 9 12.6975 9 17.25H12C12 11.04 6.96 6 0.75 6ZM3 12.75C2.40326 12.75 1.83097 12.9871 1.40901 13.409C0.987053 13.831 0.75 14.4033 0.75 15C0.75 15.5967 0.987053 16.169 1.40901 16.591C1.83097 17.0129 2.40326 17.25 3 17.25C3.59674 17.25 4.16903 17.0129 4.59099 16.591C5.01295 16.169 5.25 15.5967 5.25 15C5.25 14.4033 5.01295 13.831 4.59099 13.409C4.16903 12.9871 3.59674 12.75 3 12.75Z"
    fill="#5D6468"
  />
</svg>
