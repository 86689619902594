<p-avatar
  *ngIf="imagePath; else initialsTmpl"
  image="{{ imagePath }}"
  shape="circle"
  size="large"
></p-avatar>

<ng-template #initialsTmpl>
  <p-avatar size="large" label="{{ initials }}" shape="circle"></p-avatar>
</ng-template>
