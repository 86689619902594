<svg
  *ngIf="!isDisable"
  width="17"
  height="16"
  viewBox="0 0 17 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3.1864 14H15.3154"
    stroke="#606974"
    stroke-width="1.31358"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M4.53406 8.90663V11.3333H6.99934L13.9677 4.43603L11.5066 2L4.53406 8.90663Z"
    stroke="#606974"
    stroke-width="1.31358"
    stroke-linejoin="round"
  />
</svg>
<svg
  *ngIf="isDisable"
  width="17"
  height="16"
  viewBox="0 0 17 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect x="0.828125" width="16.172" height="16" rx="3" fill="#F1F1F1" />
  <path
    d="M3.18652 14H15.3155"
    stroke="#DFDFDF"
    stroke-width="1.31358"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M4.53418 8.90663V11.3333H6.99946L13.9678 4.43603L11.5067 2L4.53418 8.90663Z"
    stroke="#DFDFDF"
    stroke-width="1.31358"
    stroke-linejoin="round"
  />
</svg>
