import { Channels, PostTypes } from '@slc-libs/enums';

export const list = Object.values(PostTypes);

const postTypesByChannel = [
  {
    channel: Channels.TIKTOK,
    items: [PostTypes.TIKTOK_VIDEO],
  },
  {
    channel: Channels.INSTAGRAM,
    items: [
      PostTypes.POST,
      PostTypes.REELS,
      PostTypes.STORIES,
      PostTypes.VIDEO,
    ],
  },
  {
    channel: Channels.YOUTUBE,
    items: [
      PostTypes.HOST_READ_AD,
      PostTypes.PRODUCT_PLACEMENT,
      PostTypes.PRODUCT_REVIEW,
    ],
  },
];
export const getPostTypeMock = (): PostTypes => {
  return list[Math.floor(Math.random() * list.length)];
};

export const getPostTypeMockByChannel = (
  channel: Channels,
  exeptions?: Array<PostTypes>,
): PostTypes => {
  const list = postTypesByChannel
    .find(i => i.channel === channel)
    ?.items.filter(i =>
      exeptions?.includes(i) ? false : i,
    ) as Array<PostTypes>;
  return list[Math.floor(Math.random() * list.length)];
};
