<div>
  <a (click)="openForgotPin()">Forget Code</a>
  <ng-container *ngIf="modalForgotPin">
    <slice-forgot-pin-modal></slice-forgot-pin-modal>
  </ng-container>
  <ng-container *ngIf="modalVerif">
    <slice-forgot-verification-code></slice-forgot-verification-code>
  </ng-container>
  <ng-container *ngIf="modalInputNewPin">
    <slice-input-new-pin></slice-input-new-pin>
  </ng-container>
</div>
