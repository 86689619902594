import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';

import { AuthHttpService } from '@slice-services/auth-http.service';
import { AuthStateService } from '@slice-services/auth-state.service';
import { NavigationService } from '@slice-services/navigation.service';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { AuthenticatedUser } from '@slice-interfaces/auth/auth-user';
import { E_ROUTES } from '@slice-enums/routes.enum';
import { UserTypes } from '@slice-enums/user-types.enum';

@Injectable()
export class EmailConfirmationGuard implements CanActivate {
  constructor(
    private navigationService: NavigationService,
    private authStateService: AuthStateService,
    private authHttpService: AuthHttpService,
    private mS: MessageService,
    private router: Router,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
  ): boolean | Observable<boolean | UrlTree> | UrlTree {
    const isEmailVerified =
      this.authStateService.getAuthenticatedUser()?.emailConfirmed;
    if (isEmailVerified) {
      return this.router.parseUrl(
        this.navigationService.getRoutePath(E_ROUTES.BASE),
      );
      // return true; // only show the page when user is not logged in
    } else if (route.queryParams.userId && route.queryParams.code) {
      return this.authHttpService
        .sendConfirmEmail(route.queryParams.userId, route.queryParams.code)
        .pipe(
          shareReplay(1), // Prevent multiple call
          catchError((err: HttpErrorResponse) => {
            if (err.status === 412) {
              this.mS.add({
                summary: 'Info',
                severity: 'warn',
                life: 5 * 1000,
                detail: 'Confirmation code has been expired',
              });
            } else if (err.status === 400) {
              this.mS.add({
                summary: 'Info',
                severity: 'warn',
                life: 5 * 1000,
                detail: 'Invalid email confirmation link',
              });
            } else {
              console.log('ERROR confirmation code: ', err);
            }
            return of({ error: true });
          }),
          map((res: any) => {
            if (res && res.error) {
              return this.router.parseUrl(
                this.navigationService.getRoutePath(E_ROUTES.SIGN_IN),
              );
            }

            const user =
              this.authStateService.getAuthenticatedUser() as AuthenticatedUser;
            if (user) {
              this.mS.add({
                summary: 'Thank You',
                severity: 'success',
                life: 5 * 1000,
                detail: 'You have confirmed your email successfully',
              });
              user.emailConfirmed = true;
              this.authStateService.setAuthenticatedUser({ ...user });
              this.authStateService.setUserStatusOnboard(
                user?.profileCompleted,
              ); // onboard agency & creator
              return user.userType === UserTypes.CREATOR
                ? (this.router.parseUrl(
                    this.navigationService.getRoutePath(E_ROUTES.HOME),
                  ) as UrlTree)
                : (this.router.parseUrl(
                    this.navigationService.getRoutePath(E_ROUTES.BASE),
                  ) as UrlTree);
            } else {
              // only show the page when user is not logged in
              return true;
            }
          }),
        );
    } else {
      return this.router.parseUrl(
        this.navigationService.getRoutePath(E_ROUTES.NOT_FOUND),
      );
    }
  }
}
