import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { loadStripe, Stripe, StripeError } from '@stripe/stripe-js';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { PLANS_IDS } from '@slc-libs/constants';
import { E_ROUTES, PlanTypes } from '@slc-libs/enums';

import { environment } from '@slice-env/environment';

import {
  AgencyCheckoutSessionCreatePayload,
  AgencyUpgradeSessionCreatePayload,
} from '@slice-interfaces/stripe/agency-checkout-session-payload';
import {
  AgencySubscriptionPayload,
  AgencySubscriptionResponse,
} from '@slice-interfaces/stripe/agency-subscription-payload';
import {
  StripeCardChangeSession,
  StripeCurrentSubscriptionResponse,
  StripeDefaultCardResponse,
} from '@slice-interfaces/stripe/stripe-card-payment';
import { StripeCheckoutSession } from '@slice-interfaces/stripe/stripe-checkout-session';
import {
  AgencyStripeRedirectQueryParams,
  CardChangeRedirectQueryParams,
} from '@slice-interfaces/stripe/stripe-redirect-url-query-params';
import { BillingPeriod } from '@slice-enums/billing-period';

import { AuthStateService } from './auth-state.service';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  public stripeApi$ = new BehaviorSubject<Stripe | null>(null);

  constructor(
    private http: HttpClient,
    private navS: NavigationService,
    private mS: MessageService,
    private authStateS: AuthStateService,
  ) {}

  createAgencyCheckoutSession(
    agencyId: string,
    payload: AgencyCheckoutSessionCreatePayload,
  ): Observable<StripeCheckoutSession> {
    return this.http.post<StripeCheckoutSession>(
      `/api/v1/stripe/agency/${agencyId}/sessions`,
      payload,
    );
  }

  createAgencySubscription(
    agencyId: string,
    payload: AgencySubscriptionPayload,
  ): Observable<AgencySubscriptionResponse> {
    return this.http.post<AgencySubscriptionResponse>(
      `/api/v1/agencies/${agencyId}/subscription`,
      payload,
    );
  }

  createAgencyUpgrade(
    agencyId: string,
    payload: AgencyUpgradeSessionCreatePayload,
  ): Observable<StripeCheckoutSession> {
    return this.http.put<StripeCheckoutSession>(
      `/api/v1/stripe/${agencyId}/subscription`,
      payload,
    );
  }

  getAgencySubscription(
    agencyId: string,
  ): Observable<StripeCurrentSubscriptionResponse> {
    return this.http.get<StripeCurrentSubscriptionResponse>(
      `/api/v1/stripe/${agencyId}/subscription`,
    );
  }

  getDefaultCard(agencyId: string): Observable<StripeDefaultCardResponse> {
    return this.http.get<StripeDefaultCardResponse>(
      `/api/v1/stripe/${agencyId}/payment-method`,
    );
  }

  createChangeCardSession(
    agencyId: string,
    successUrl?: string,
    cancelUrl?: string,
  ): Observable<StripeCardChangeSession> {
    return this.http.post<StripeCardChangeSession>(
      `/api/v1/stripe/${agencyId}/change-card`,
      {
        successUrl: successUrl || this.getCardChangeRedirectUrl('success'),
        cancelUrl: cancelUrl || this.getCardChangeRedirectUrl('fail'),
        // successUrl: successUrl ?? 'http://localhost:4200',
        // cancelUrl: cancelUrl ?? 'http://localhost:4200',
        // billingPeriod: 'monthly',
        // subscriptionId: subscriptionId,
      },
    );
  }

  initStripe() {
    from(loadStripe(environment.stripeKey))
      .pipe(
        catchError(err => {
          console.error('STRIPE ERROR - ', err);
          this.mS.add({
            summary: 'Stripe',
            severity: 'error',
            life: 5 * 1000,
            detail: 'Connection Error...',
          });
          return throwError(err);
        }),
        tap(api => this.stripeApi$.next(api)),
      )
      .subscribe();
  }

  selectStripe(): Observable<Stripe | null> {
    return this.stripeApi$.asObservable();
  }

  isStripeInited(): boolean {
    return Boolean(this.stripeApi$.value);
  }

  redirectToCheckout(sessionId: string): Observable<{
    error: StripeError;
  }> {
    return this.selectStripe().pipe(
      switchMap(stripeApi => {
        const api = stripeApi as Stripe;
        return from(
          api.redirectToCheckout({
            sessionId,
          }),
        );
      }),
    );
  }

  goToAgencyPayment(d: {
    billingPeriod: BillingPeriod;
    planId?: string;
    successUrl?: string;
    cancelUrl?: string;
  }): Observable<{ error: StripeError }> {
    return this.createAgencyCheckoutSession(
      this.authStateS.getAuthenticatedUser()?.agencyOwned as string,
      {
        successUrl: d.successUrl || this.getAgencyPaymentRedirectUrl('success'),
        cancelUrl: d.cancelUrl || this.getAgencyPaymentRedirectUrl('fail'),
        billingPeriod: d.billingPeriod,
        planId: d.planId,
      },
    ).pipe(
      switchMap(session =>
        this.selectStripe().pipe(
          map(stripeApi => ({ sessionId: session.sessionId, stripeApi })),
        ),
      ),
      switchMap(data => {
        return this.redirectToCheckout(data.sessionId).pipe(
          tap(res => {
            this.mS.add({
              summary: 'Stripe Error',
              severity: 'error',
              life: 5 * 1000,
              detail: res?.error?.message,
            });
          }),
        );
      }),
    );
  }

  goToAgencyUpgrade(
    plan: PlanTypes,
    planPriceId: string,
    billingPeriod: BillingPeriod,
    successUrl?: string,
    cancelUrl?: string,
  ): Observable<any> {
    return this.createAgencyUpgrade(
      this.authStateS.getAuthenticatedUser()?.agencyOwned as string,
      {
        // successUrl: successUrl || this.getAgencyPaymentRedirectUrl('success'),
        // cancelUrl: cancelUrl || this.getAgencyPaymentRedirectUrl('fail'),
        billingPeriod: billingPeriod,
        providerPriceId: planPriceId,
      },
    );
    /*.pipe(
      switchMap(session =>
        this.selectStripe().pipe(
          map(stripeApi => ({ sessionId: session.sessionId, stripeApi })),
        ),
      ),
      switchMap(data => {
        return this.redirectToCheckout(data.sessionId).pipe(
          tap(res => {
            this.mS.add({
              summary: 'Stripe Error',
              severity: 'error',
              life: 5 * 1000,
              detail: res?.error?.message,
            });
          }),
        );
      }),
    );*/
  }

  /* old existing flow */
  upgradeAgencyPlan(
    plan: PlanTypes,
    billingPeriod: BillingPeriod,
  ): Observable<{ error: StripeError }> {
    return this.createAgencySubscription(
      this.authStateS.getAuthenticatedUser()?.agencyOwned as string,
      {
        planId: PLANS_IDS[environment.envType][plan],
        billingPeriod: billingPeriod,
      },
    ).pipe(
      switchMap(data =>
        this.goToAgencyPayment({
          billingPeriod: billingPeriod,
          planId: PLANS_IDS[environment.envType][plan],
          successUrl: location.href,
          cancelUrl: location.href,
        }).pipe(
          switchMap(res => (res.error ? throwError(() => res) : of(res))),
        ),
      ),
    );
  }

  // below is for redirect urls
  getCardChangeRedirectUrl(type: 'success' | 'fail'): string {
    const origin = location.origin;
    const routePath = this.navS.getRoutePath(E_ROUTES.PLAN_BILLING);
    const queryParams: CardChangeRedirectQueryParams = {
      changeSuccessful: type === 'success' ? 'true' : 'false',
    };
    return `${origin}${routePath}?${new URLSearchParams(queryParams)}`;
  }

  getAgencyPaymentRedirectUrl(type: 'success' | 'fail'): string {
    const origin = location.origin;
    const routePath = this.navS.getRoutePath(E_ROUTES.HOME);
    const queryParams: AgencyStripeRedirectQueryParams = {
      paymentSuccessful: type === 'success' ? 'true' : 'false',
    };
    return `${origin}${routePath}?${new URLSearchParams(queryParams)}`;
  }
}
