export enum ReportsOrganicCharts {
  targetVsCreatedMedia = 'targetVsCreatedMedia',
  viewsByCountry = 'viewsByCountry',
  viewsByGenderAge = 'viewsByGenderAge',
  viewsByTrafficSources = 'viewsByTrafficSources',
  percentageOfSearchByTerm = 'percentageOfSearchByTerm',
  engagementByType = 'engagementByType',
}

export enum ReportsPublicPageRouteParams {
  campaignId = 'campaignId',
  accessCode = 'accessCode',
  reportType = 'reportType',
}
