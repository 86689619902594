<ng-container *ngIf="stream$ | async as stream">
  <ng-container *ngIf="isMenuVisible$ | async as isMenuVisible">
    <slice-menu
      *ngIf="
        isMenuVisible.value &&
        (!stream.isMobile || (stream.isMobile && stream.isMenuOpen))
      "
      [ngClass]="{
        'slice-is-mobile': stream.isMobile,
        'slice-is-desktop': stream.isDesktop,
        'slice-is-menu-open': stream.isMenuOpen,
        'slice-is-menu-hidden': stream.isAuthenticated || !isMenuVisible.value
      }"
    ></slice-menu>

    <div
      *ngIf="
        !stream.isMobile &&
        isMenuVisible.value &&
        stream.isMenuOpen &&
        !stream.isDesktop
      "
      class="bg-overlay"
      (click)="closeMenu()"
    ></div>

    <slice-header
      [hidden]="!isHeaderVisible"
      [ngClass]="{
        'with-animation': pageWithAnimation,
        'slice-is-authenticated': stream.isAuthenticated,
        'slice-is-menu-open': stream.isMenuOpen && stream.isDesktop,
        'slice-is-menu-hidden':
          stream.isMobile || !isMenuVisible.value || stream.isMobile
      }"
    ></slice-header>

    <main
      [ngClass]="{
        'with-animation': pageWithAnimation,
        'slice-is-menu-open': stream.isMenuOpen && stream.isDesktop,
        'slice-is-authenticated': stream.isAuthenticated,
        'slice-is-menu-hidden':
          !stream.isAuthenticated || !isMenuVisible.value || stream.isMobile
      }"
    >
      <ng-container *ngIf="!stream.isDesktop || isAgencyBasicPlan">
        <div *ngIf="pageTitle" class="page-title">
          {{ pageTitle }}
        </div>
      </ng-container>
      <div class="main-wrapper">
        <router-outlet></router-outlet>

        <div
          *ngIf="isCampaignRedirectLoading$ | async"
          class="campaign-redirection-loader"
        >
          <slice-loader [showSvg]="true" class="campaign-loader"></slice-loader>
        </div>
      </div>
    </main>
  </ng-container>
</ng-container>
<p-toast></p-toast>

<!--GLOBAL DIALOG THAT CAN BE CALLED ANYWHERE USING UI-STATE-->
<ng-container *ngIf="isModalRateCard$ | async">
  <slice-onboard-rate-card></slice-onboard-rate-card>
</ng-container>
<ng-container *ngIf="showDialogResult$ | async">
  <slice-dialog-result-modal></slice-dialog-result-modal>
</ng-container>

<ng-container *ngIf="showDialogPrice$ | async">
  <slice-see-plan-modal></slice-see-plan-modal>
</ng-container>

<slice-creator-expired-channels></slice-creator-expired-channels>
