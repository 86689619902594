import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconArrowInCircleComponent } from './arrow-in-circle.component';

@NgModule({
  declarations: [SvgIconArrowInCircleComponent],
  imports: [CommonModule],
  exports: [SvgIconArrowInCircleComponent],
})
export class SvgIconArrowInCircleModule {}
