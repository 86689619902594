import { Component, OnDestroy, OnInit } from '@angular/core';

import { AppMenuStateService } from '@slice-services/app-menu-state.service';
import { AuthStateService } from '@slice-services/auth-state.service';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { MenuItem } from '@slice-interfaces/navigation/menu-item';

@Component({
  selector: 'slice-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  public items$: Observable<Array<MenuItem>>;
  constructor(
    private appMenuStateS: AppMenuStateService,
    private authStateS: AuthStateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.items$ = this.appMenuStateS.selectItems();
    this.initMenuItems();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private initMenuItems(): void {
    this.authStateS
      .selectAuthenticatedUser()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(u => {
        if (u) {
          this.appMenuStateS.initMenu(u.userType);
          this.appMenuStateS.initBottomMenu(u.userType);
          // this.appMenuStateS.actionUpdateByCampaigns();
        }
      });
  }
}
