<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="false"
  [closeOnEscape]="false"
  [dismissableMask]="true"
  (onHide)="triggerHide()"
>
  <p-header></p-header>
  <div class="content-wrap" *ngIf="currConfig && isVisible">
    <p class="mt-2 mb-2">
      We have sent you a verification code to your email.
      <br />If you don’t see an email, search “Slice” in your inbox or Spam.
    </p>
    <p class="counter mb-2">{{ countdownDisplay }}</p>
    <form (ngSubmit)="submit()" [formGroup]="form">
      <div class="d-flex justify-content-center">
        <div class="form-group">
          <p for="oldPin" class="text-center ver">Verification Code</p>
          <ng-otp-input
            #verificationCodes
            [config]="otpConfig"
            [formCtrl]="verificationCodeControl"
          ></ng-otp-input>
          <button type="submit" class="slc-btn slc-btn-primary mt-4">
            Set New Pin
          </button>
          <div class="w-100 text-center">
            <a *ngIf="countdownFinished" (click)="resend()" class="ver mt-1"
              >Resend Code</a
            >
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
