const baseUrl = window.location.origin;
export const mockImages = [
  baseUrl + '/assets/mocks/photos/1.jpeg',
  baseUrl + '/assets/mocks/photos/2.jpeg',
  baseUrl + '/assets/mocks/photos/3.jpeg',
  baseUrl + '/assets/mocks/photos/4.jpeg',
  baseUrl + '/assets/mocks/photos/5.jpeg',
  baseUrl + '/assets/mocks/photos/6.jpeg',
  // 'https://s3.ap-southeast-1.amazonaws.com/slice-dev/null/531cfe99-e3d8-4b09-9019-2e1505388d6a/d6dca4efc7e74a7ea721ebfe67d7f2f91669380064227.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIARWM7HWCSVUUTD2EQ%2F20221130%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Date=20221130T000000Z&X-Amz-Expires=259200&X-Amz-SignedHeaders=host&X-Amz-Signature=fc6d9b48d78e156e9bc4542f63a4db2f1846f0ebcefa0c99e08e9d699a7d84e6',
];
export const mockAvatarImages = [
  baseUrl + '/assets/mocks/avatars/1.jpeg',
  baseUrl + '/assets/mocks/avatars/2.jpeg',
  baseUrl + '/assets/mocks/avatars/3.jpeg',
];

// export const mockVideos = [
//   'https://drive.google.com/uc?export=download&id=14U_Qvc-hGQur5mjgcZ8cznziICzwVM-W',
//   'https://drive.google.com/uc?export=download&id=1zul1cl5e83HxmMqJ7h3LIQY80lfDTAgz',
//   'https://drive.google.com/uc?export=download&id=1iUa9leEMkbnvyHe5_cgz4GRJkUak7s0D',
//   'https://drive.google.com/uc?export=download&id=1C7D3zyI7_CXyPv8BWyE5y353LtV98lAY',
//   'https://drive.google.com/uc?export=download&id=1rnpjsTgd5AoaNky40WABMkWsdIhbivU8',
// ];
export const mockVideos = [
  // 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
  // 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
  // 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
  // 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4',
  //   'https://drive.google.com/uc?export=download&id=14U_Qvc-hGQur5mjgcZ8cznziICzwVM-W',
  // 'https://drive.google.com/uc?export=download&id=1zul1cl5e83HxmMqJ7h3LIQY80lfDTAgz',
  'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4',
  'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mov-file.mov',
  // 'http://techslides.com/demos/sample-videos/small.webm',
  // 'http://techslides.com/demos/sample-videos/small.ogv',
  // 'http://techslides.com/demos/sample-videos/small.mp4',
  // 'http://techslides.com/demos/sample-videos/small.3gp',
];
//tools.missionmike.dev/video-placeholder-generator/
export const mockImagesVideos = [...mockImages, ...mockVideos];

export const getImageMock = (): string => {
  return mockImages[Math.floor(Math.random() * mockImages.length)];
};

export const getRandomMockAvatar = (): string => {
  return mockImages[Math.floor(Math.random() * mockImages.length)];
};

export const getVideoMock = (): string => {
  return mockVideos[Math.floor(Math.random() * mockVideos.length)];
};
