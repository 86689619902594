import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconBrandComponent } from './brand.component';

@NgModule({
  declarations: [SvgIconBrandComponent],
  imports: [CommonModule],
  exports: [SvgIconBrandComponent],
})
export class SvgIconBrandModule {}
