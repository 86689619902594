import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconOffersComponent } from './offers.component';

@NgModule({
  declarations: [SvgIconOffersComponent],
  imports: [CommonModule],
  exports: [SvgIconOffersComponent],
})
export class SvgIconOffersModule {}
