import { Component, OnInit } from '@angular/core';

import { takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { WalletBalanceDetail } from '@slice-interfaces/wallet/wallet-balance';

import { CardBalanceFacadeService } from '../services/card-balance-facade.service';

@Component({
  selector: 'slice-balance-detail-button',
  templateUrl: './balance-detail-button.component.html',
  styleUrls: ['./balance-detail-button.component.scss'],
})
export class BalanceDetailButtonComponent extends AbstractSubscriberComponent {
  data: WalletBalanceDetail | null;
  dataList: any;
  isLoading = false;

  constructor(private facadeS: CardBalanceFacadeService) {
    super();
  }

  buttonFunction(): void {
    this.facadeS.setModalDetail(true);
    if (this.data === null || !this.data) {
      this.facadeS.fetchBalanceDetails().subscribe();
      this.fetchData();
    }
    this.fetchData();
  }
  closeModal(): void {
    this.facadeS.setModalDetail(false);
  }
  fetchData(): void {
    this.isLoading = true;
    this.facadeS
      .selectBalanceDetail()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        this.isLoading = false;
        this.data = r;
        this.dataList = r?.campaigns;
      });
  }
}
