import { BriefProposalStatus } from '@slc-libs/enums';

const list = Object.values(BriefProposalStatus);

export const getBriefProposalStatusMock = (
  availableList?: Array<BriefProposalStatus>,
): BriefProposalStatus => {
  const l = availableList || list;
  return l[Math.floor(Math.random() * l.length)];
};
