import { Component } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-icon-users',
  styles: [
    ':host { display: inline-flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './users.component.html',
})
export class SvgIconUsersComponent extends AbstractSvgIconComponent {}
