import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconDownloadFileComponent } from './svg-icon-download-file.component';

@NgModule({
  declarations: [SvgIconDownloadFileComponent],
  imports: [CommonModule],
  exports: [SvgIconDownloadFileComponent],
})
export class SvgIconDownloadFileModule {}
