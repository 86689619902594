import { Component } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-icon-clock',
  styles: [
    ':host { display: flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './clock.component.html',
})
export class SvgIconClockComponent extends AbstractSvgIconComponent {}
