import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconReportComponent } from './report.component';

@NgModule({
  declarations: [SvgIconReportComponent],
  imports: [CommonModule],
  exports: [SvgIconReportComponent],
})
export class SvgIconReportModule {}
