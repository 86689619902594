import { Component, Input } from '@angular/core';

@Component({
  selector: 'slice-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() hideIcon?: boolean;
  @Input() hideText = true;
  @Input() showSvg = false;
}
