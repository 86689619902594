<svg
  xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="19"
  viewBox="0 0 22 19"
  fill="none"
>
  <path
    d="M1.34876 7.92215L14.4534 2.52281C15.747 1.96038 20.134 0.160594 20.134 0.160594C20.134 0.160594 22.1587 -0.62681 21.99 1.28546C21.9338 2.07286 21.4838 4.82878 21.0339 7.80967L19.6278 16.6398C19.6278 16.6398 19.5153 17.9334 18.5592 18.1584C17.603 18.3834 16.0282 17.371 15.747 17.146C15.522 16.9773 11.5288 14.4464 10.0665 13.209C9.67276 12.8716 9.22281 12.1966 10.1227 11.4092C12.1475 9.55321 14.5659 7.24724 16.0282 5.78491C16.7032 5.10999 17.3781 3.53519 14.5659 5.44745L6.63562 10.7906C6.63562 10.7906 5.73573 11.353 4.04844 10.8468C2.36114 10.3406 0.39263 9.66569 0.39263 9.66569C0.39263 9.66569 -0.957206 8.82204 1.34876 7.92215Z"
    fill="#5D6468"
  />
</svg>
