import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { WalletBalanceDetail } from '@slice-interfaces/wallet/wallet-balance';

import { CardBalanceFacadeService } from '../services/card-balance-facade.service';

@Component({
  selector: 'slice-shared-balance-detail',
  templateUrl: './balance-detail.component.html',
  styleUrls: ['./balance-detail.component.scss'],
})
export class BalanceDetailSharedComponent
  extends AbstractSubscriberComponent
  implements OnInit
{
  @ViewChild('input1', { static: true }) Input1: ElementRef;
  // @Input() isVisible?: boolean;
  @Output() closed = new EventEmitter<any>();
  // isVisible: boolean;
  @Input() data: WalletBalanceDetail | null;
  @Input() dataList: any;
  @Input() isLoading = false;

  isVisible?: boolean = false;
  searchData: string;

  constructor(private facadeS: CardBalanceFacadeService) {
    super();
  }

  ngOnInit(): void {
    this.facadeS
      .selectModalDetail()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        this.isVisible = r;
      });
  }

  inputEvent() {
    fromEvent(this.Input1.nativeElement, 'keyup')
      .pipe(
        map((evt: any) => evt.target.value),
        debounceTime(750),
        distinctUntilChanged(),
      )
      .subscribe((keyword: string) => this.search(keyword));
  }

  search(v: string): void {
    const filter = this.data?.campaigns.filter((el: { name: string }) =>
      el.name.toLowerCase().includes(v.toLowerCase()),
    );
    this.dataList = filter;
  }

  triggerHide(): void {
    this.closed?.emit();
  }
}
