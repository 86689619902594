<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="onHide()"
>
  <p-header>
    <slc-svg-icon-logo-one-color></slc-svg-icon-logo-one-color>
  </p-header>

  <main>
    <div class="slc-flex-c-c">
      <slc-svg-icon-info-circle></slc-svg-icon-info-circle>
    </div>

    <div class="slc-fw-700 slc-fs-20 slc-lh-32 slc-mt-24">
      {{ 'connect-info-modal.title' | transloco }}
    </div>

    <div class="slc-fw-400 slc-fs-14 slc-lh-22 slc-mt-32">
      {{ 'connect-info-modal.description.1' | transloco }}
    </div>

    <div class="slc-fw-400 slc-fs-14 slc-lh-22 slc-mt-16">
      {{ 'connect-info-modal.description.2' | transloco }}
      <a href="mailto:help@slice.id" title="help@slice.id"> help@slice.id </a>
    </div>

    <div class="slc-flex-c-c slc-mt-32">
      <slice-social-connect-btns
        [channel]="channel"
        [isExpiredFlow]="isExpired"
        (connectDone)="connectDone(true)"
        (disconnectDone)="connectDone(false)"
      ></slice-social-connect-btns>
    </div>
  </main>

  <p-footer>
    <div class="slc-flex-fe-c">
      <button
        class="slc-btn slc-btn-outline-grey"
        (click)="onHide()"
        type="button"
      >
        <span>{{ 'buttons.cancel' | transloco }}</span>
      </button>
    </div>
  </p-footer>
</p-dialog>
