export enum AssetsType {
  AVATAR = 'image',
  IG_VIDEO = 'ig_video',
  IG_IMAGE = 'ig_image',
  // YT_VIDEO = 'yt_video',
  MEDIA = 'media',
}

export enum ContactFormFileType {
  IMAGE = 'image',
  VIDEO = 'video',
}
export enum NewAssetsType {
  PDF = 'pdf',
  IMG_PDF = 'img_pdf',
  // YT_VIDEO = 'yt_video',
}
export enum TypeFileUpload {
  CONTRACT = 'contract',
  DEFAULT = 'default',
  KYC = 'kyc',
  // YT_VIDEO = 'yt_video',
}
export enum AcademyAssetType {
  AUDIO = 'audio',
  IMAGE = 'image',
  VIDEO = 'video',
  PDF = 'pdf',
}
