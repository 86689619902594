import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'slice-search-campaign',
  templateUrl: './search-campaign.component.html',
  styleUrls: ['./search-campaign.component.scss'],
})
export class SearchCampaignComponent {
  public value: string | undefined;
  @Output() selectedValue = new EventEmitter<string | undefined>();

  search(e: { query: string }): void {
    this.selectedValue?.emit(e.query);
  }

  onSelect(e: { query: string }): void {
    this.selectedValue?.emit(e.query);
  }

  onClear(): void {
    this.selectedValue?.emit(undefined);
  }
}
