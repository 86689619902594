import { Routes } from '@angular/router';

import { HomeComponent } from '@slice-core/components/home/home.component';
import { RoleComponent } from '@slice-core/components/role/role.component';

import { E_ROUTES } from './_enums/routes.enum';
import { AuthGuard } from './_guards/auth.guard';
import { BaseGuard } from './_guards/base.guard';
import { PublicGuard } from './_guards/public.guard';
import { AdminGuard } from './_guards/role-admin.guard';

export const adminRoutes: Routes = [
  {
    path: '',
    canActivate: [BaseGuard],
    component: HomeComponent,
  },
  {
    path: E_ROUTES.ADMIN,
    canActivate: [AuthGuard, AdminGuard],
    component: RoleComponent,
    children: [
      {
        path: '',
        redirectTo: E_ROUTES.HOME,
        pathMatch: 'full',
      },
      {
        path: E_ROUTES.HOME,
        canActivate: [],
        loadChildren: (): any =>
          import('./views/_role-admin/home/admin-home.module').then(
            m => m.AdminHomeModule,
          ),
      },
      {
        path: E_ROUTES.EXCO,
        canActivate: [],
        loadChildren: (): any =>
          import('./views/_role-admin/exco/admin-exco.module').then(
            m => m.AdminExcoModule,
          ),
      },
      {
        path: E_ROUTES.CLIENTS,
        canActivate: [],
        loadChildren: (): any =>
          import('./views/_role-admin/clients/admin-clients.module').then(
            m => m.AdminClientsModule,
          ),
      },
      {
        path: E_ROUTES.CAMPAIGNS,
        canActivate: [],
        loadChildren: (): any =>
          import('./views/_role-admin/campaigns/campaigns.module').then(
            m => m.AdminCampaignsModule,
          ),
      },
      {
        path: E_ROUTES.CAMPAIGN_CREATE,
        canActivate: [],
        loadChildren: (): any =>
          import('./shared/modules/campaign-create/campaign.module').then(
            m => m.CampaignModule,
          ),
      },
    ],
  },

  // AUTH PAGES
  {
    path: E_ROUTES.SIGN_IN,
    canActivate: [PublicGuard],
    loadChildren: (): any =>
      import('./views/auth/sign-in/sign-in.module').then(m => m.SignInModule),
  },

  // 404
  {
    path: E_ROUTES.NOT_FOUND,
    loadChildren: () =>
      import('./views/public/404/page-not-found.module').then(
        m => m.PageNotFoundModule,
      ),
  },
  { path: '**', redirectTo: E_ROUTES.NOT_FOUND },
];
