const words = [
  'biodegradable',
  'succeeded',
  'trebbianos',
  'seeding',
  'neuroscientist',
  'lithospheres',
  'mongeese',
  'lynches',
  'flowerettes',
  'motet',
  'disowns',
  'boxball',
  'rapeseeds',
  'hewn',
  'deducible',
  'unhusk',
  'hegumenoses',
  'belady',
  'downiness',
  'replunge',
  'homeschooled',
  'humified',
  'rousements',
  'prettifiers',
  'rates',
  'implying',
  'miracles',
  'folksong',
  'unhistorical',
  'globing',
  'foveolae',
  'dropshots',
  'practically',
  'rowers',
  'unmakers',
  'ergotamines',
  'resisting',
  'crotch',
  'demagnetizing',
  'servicewomen',
];
export const getRandomWords = (amount?: number): Array<string> => {
  const res = [];
  for (
    let i = 0;
    i < (amount ? amount : Math.floor(5 + Math.random() * 30));
    i++
  ) {
    res.push(words[Math.floor(Math.random() * words.length)]);
  }
  return res;
};

export const getRandomSentense = (wordAmount?: number): string => {
  return getRandomWords(wordAmount).join(' ');
};

export const getHashtagsMock = (wordAmount: number): string => {
  const list = [];
  for (let i = 0; i < wordAmount; i++) {
    if (Math.random() > 0.5) {
      list.push('#' + words[i]);
    } else {
      const word = words[Math.floor(Math.random() * words.length)];
      list.push('#' + words[i] + word.charAt(0).toUpperCase() + word.slice(1));
    }
  }
  return list.join(' ');
};
