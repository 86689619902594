export enum UserTypes {
  CREATOR = 'creator',
  BRAND = 'brand',
  MANAGER = 'manager',
  ADMIN = 'admin',
  AGENCY = 'agency',
}

export enum UserRoleTypes {
  ADMIN = 'admin',
  MEMBER = 'member',
  FINANCE = 'finance',
}
