<svg
  xmlns="http://www.w3.org/2000/svg"
  width="14"
  height="15"
  viewBox="0 0 14 15"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M6.8258 0.333374C10.4058 0.333374 13.3178 3.24537 13.3178 6.82537C13.3178 8.5144 12.6696 10.0549 11.6089 11.211L13.6961 13.2938C13.8914 13.4892 13.8921 13.8052 13.6967 14.0005C13.5994 14.0992 13.4707 14.1478 13.3427 14.1478C13.2154 14.1478 13.0874 14.0992 12.9894 14.0018L10.877 11.8954C9.76584 12.7853 8.35691 13.318 6.8258 13.318C3.2458 13.318 0.33313 10.4054 0.33313 6.82537C0.33313 3.24537 3.2458 0.333374 6.8258 0.333374ZM6.8258 1.33337C3.79713 1.33337 1.33313 3.79671 1.33313 6.82537C1.33313 9.85404 3.79713 12.318 6.8258 12.318C9.8538 12.318 12.3178 9.85404 12.3178 6.82537C12.3178 3.79671 9.8538 1.33337 6.8258 1.33337Z"
    fill="#606974"
  />
</svg>
