<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="18"
  viewBox="0 0 20 18"
  fill="none"
>
  <path
    d="M2 0C0.895 0 0 0.895 0 2V16C0 17.105 0.895 18 2 18H14H18C19.0931 18 20 17.0931 20 16V6C20 4.90694 19.0931 4 18 4H14V2C14 0.895 13.105 0 12 0H2ZM4.5 4H5.5C5.776 4 6 4.224 6 4.5V5.5C6 5.776 5.776 6 5.5 6H4.5C4.224 6 4 5.776 4 5.5V4.5C4 4.224 4.224 4 4.5 4ZM8.5 4H9.5C9.776 4 10 4.224 10 4.5V5.5C10 5.776 9.776 6 9.5 6H8.5C8.224 6 8 5.776 8 5.5V4.5C8 4.224 8.224 4 8.5 4ZM14 6H18V16H14V14H15.5C15.776 14 16 13.776 16 13.5V12.5C16 12.224 15.776 12 15.5 12H14V10H15.5C15.776 10 16 9.776 16 9.5V8.5C16 8.224 15.776 8 15.5 8H14V6ZM4.5 8H5.5C5.776 8 6 8.224 6 8.5V9.5C6 9.776 5.776 10 5.5 10H4.5C4.224 10 4 9.776 4 9.5V8.5C4 8.224 4.224 8 4.5 8ZM8.5 8H9.5C9.776 8 10 8.224 10 8.5V9.5C10 9.776 9.776 10 9.5 10H8.5C8.224 10 8 9.776 8 9.5V8.5C8 8.224 8.224 8 8.5 8ZM4.5 12H5.5C5.776 12 6 12.224 6 12.5V13.5C6 13.776 5.776 14 5.5 14H4.5C4.224 14 4 13.776 4 13.5V12.5C4 12.224 4.224 12 4.5 12ZM8.5 12H9.5C9.776 12 10 12.224 10 12.5V13.5C10 13.776 9.776 14 9.5 14H8.5C8.224 14 8 13.776 8 13.5V12.5C8 12.224 8.224 12 8.5 12Z"
    fill="white"
  />
</svg>
