<div class="avatar-bg">
  <slc-avatar
    [userFullName]="data?.name"
    [imagePath]="data?.avatar"
    class="slc-mr-16"
  ></slc-avatar>
</div>

<div class="username slc-mt-32">{{ data?.name || '-' }}</div>

<div class="slc-input-title slc-mt-16">
  {{ 'inputs.biography.title' | transloco }}
</div>

<p class="slc-mt-16">
  {{ data?.biography || '-' }}
</p>

<hr />

<div class="slc-input-title slc-mt-16">
  {{ 'profiles.my.areas.socials.title' | transloco }}
</div>

<table class="slc-mt-16">
  <tr *ngFor="let account of data?.connectedAccounts">
    <td>
      <img
        src="{{ channelData[account.channel]?.icon }}"
        alt="{{ account.channel }}"
      />
    </td>
    <td>{{ account.username || '-' }}</td>
    <td><span></span></td>
    <td>{{ account.followersCount || '-' }}</td>
    <td>{{ channelData[account.channel]?.titleKey | transloco }}</td>
  </tr>
</table>

<slice-loader *ngIf="isLoading" class="slice-loader-fluid"></slice-loader>
