import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import {
  CampaignListTopup,
  payloadMoveBalance,
  WalletBalanceDetail,
} from '@slice-interfaces/wallet/wallet-balance';

@Injectable({
  providedIn: 'root',
})
export class CardBalanceStateService {
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public isModalPin$ = new BehaviorSubject<boolean>(false);
  public isModalDetail$ = new BehaviorSubject<boolean>(false);
  public isShowModalMoveBalance$ = new BehaviorSubject<boolean>(false);
  public balanceDetail$ = new BehaviorSubject<WalletBalanceDetail | null>(null);
  public campaignList$ = new BehaviorSubject<Array<CampaignListTopup>>([]);
  public payload$ = new BehaviorSubject<payloadMoveBalance | null>(null);
}
