import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { DataFormatService } from '@slice-services/data-format.service';
import { Calendar } from 'primeng/calendar';

import { Select } from '@slice-interfaces/select/select';
const hourMs = 60 * 60 * 1000;
@Component({
  selector: 'slice-submit-content-popup-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
})
export class SubmitContentPopupDateTimeComponent implements OnInit {
  @Input() dateTime: string;

  public initValue: Date;
  public DATE_FORMAT: string;
  public DATE_TIME_FORMAT: string;
  public isOpen = false;
  public times: Array<Select> = [
    { label: '8:00', value: 8 * hourMs },
    { label: '11:00', value: 11 * hourMs },
    { label: '14:00', value: 14 * hourMs },
    { label: '18:00', value: 18 * hourMs },
  ];
  public currTime: number = this.times[1].value;
  public currDate: string;

  @Output() changed = new EventEmitter<string>();

  @ViewChild('datePicker', { static: true }) datePicker: Calendar;

  constructor(private dataFormatS: DataFormatService) {}
  ngOnInit(): void {
    this.DATE_FORMAT = this.dataFormatS.DATE_FORMAT;
    this.DATE_TIME_FORMAT = this.dataFormatS.DATE_TIME_FORMAT;
    if (!this.dateTime) {
      this.dateTime = new Date(
        this.withoutTime(new Date()).getTime() +
          24 * 60 * 60 * 1000 +
          this.times[1].value,
      ).toISOString();
      this.changed.emit(this.dateTime);
    }
    this.initValue = new Date(this.dateTime);
  }

  private withoutTime(dateTime: Date): Date {
    const date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
  }

  showDropdown(): void {
    this.datePicker.toggle();
  }

  dateSelected(ev: string): void {
    this.currDate = ev;
  }

  timeSelected(ev: Select): void {
    this.currTime = ev.value;
  }

  apply(): void {
    this.datePicker.toggle();
    this.dateTime = new Date(
      new Date(this.currDate).getTime() + this.currTime,
    ).toISOString();
    this.changed.emit(this.dateTime);
  }
}
