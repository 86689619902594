<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [modal]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  position="{{ bS.winWidth > 768 ? 'right' : '' }}"
  (onHide)="onHide()"
>
  <p-header>
    <button
      class="btn btn-outline-light btn-back"
      type="button"
      (click)="onHide()"
    >
      <i class="pi pi-arrow-left"></i>
      <span>&nbsp;&nbsp;{{ 'common.back' | transloco }}</span>
    </button>
  </p-header>

  <div class="row">
    <div class="col-2 d-inline-flex justify-content-center align-items-center">
      <slc-avatar
        class="slc-avatar-56"
        [userFullName]="customCreatorRate.username"
        [imagePath]="customCreatorRate?.avatar"
      ></slc-avatar>
    </div>
    <div class="col-8 d-flex align-items-center">
      <h5 class="mr-3">{{ customCreatorRate.username }}</h5>
    </div>
  </div>

  <div class="shadow p-3 rounder mt-4">
    <div class="text-center">
      <h5>Creator Total Calculation <span class="light">(IDR)</span></h5>
      <!--<p class="default-text p-12 mt-1">
        Rate card number is already set by Content Creator. You are able to change rate card number based on your agreement with your client.
        However, it wil affect grand total cost calculation and cost efficiency metrics in your reporting.
      </p>-->
    </div>
    <div class="d-flex flex-column mt-2">
      <div
        class="d-flex flex-row justify-content-between align-items-center mt-2 rounder net p-2"
      >
        <div class="col-8">
          <p class="fw-700 font-black mt-1">Creator Net Amount</p>
          <p class="default-text mt-1">
            Net total of Scope of Work (SOW) for every channel
          </p>
        </div>
        <div class="col-4" style="text-align: right">
          <p class="fw-700 font-black">
            {{ getCurrentNetTotal() | number : '1.0-2' }}
            <!--{{ customCreatorRate?.totalPrice | number : '1.0-2' }}-->
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-3 mt-4">
    <div class="text-center">
      <h5>Edit Creator Rate Card</h5>
      <p class="desc-text p-12 mt-2">
        Rate card number is already set by Content Creator. You are able to
        change rate card number based on your agreement with your client.
        However, it wil affect grand total cost calculation and cost efficiency
        metrics in your reporting.
      </p>
    </div>
  </div>

  <div class="shadow p-3 rounder mt-2" *ngIf="customCreatorRate?.postList">
    <div class="d-flex flex-column">
      <div class="d-flex flex-row justify-content-between">
        <p class="fw-700 font-black pt-2">{{ channel | transloco }}</p>
        <!--<a *ngIf="!isEditing" (click)="toggleEditing()">
          <slc-svg-icon-edit class="pt-2"></slc-svg-icon-edit>
        </a>-->
        <button
          class="slc-btn slc-btn-h-36 slc-btn-xm"
          [ngClass]="{
            'slc-btn-outline-blue': !isEditing,
            'slc-btn-green': isEditing
          }"
          (click)="toggleEditing()"
        >
          <span>
            {{ (isEditing ? 'buttons.save' : 'buttons.edit') | transloco }}
          </span>
        </button>
      </div>

      <p-table
        #pTable
        [value]="customCreatorRate.postList"
        styleClass="p-datatable-striped mt-3"
        [paginator]="false"
        [showCurrentPageReport]="false"
      >
        <ng-template pTemplate="header">
          <tr>
            <ng-container *ngFor="let column of table.columns">
              <th
                width="{{ column.width || 'auto' }}"
                slc-table-head-cell
                [column]="column"
                [pTable]="pTable"
              ></th>
            </ng-container>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr
            slc-table-body-row
            [pTable]="pTable"
            [windowWidth]="table.windowWidth"
            [columns]="table.columns"
            [item]="item"
          ></tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr sliceTableNoItemsFound></tr>
        </ng-template>
      </p-table>

      <!--<div class="d-flex flex-row justify-content-between mt-3">
        <p class="fw-700 font-black">SubTotal Net</p>
        <p class="fw-700 font-black">
          {{ customCreatorRate?.totalPrice | number : '1.0-2' }}
        </p>
      </div>-->
    </div>
  </div>
</p-dialog>

<ng-template #postTypeTmpl let-item="item">
  <span>{{ 'enums.post-types.' + item.postType | transloco }}</span>
</ng-template>
<ng-template #sowTmpl let-item="item">
  <span *ngIf="isEditing">
    <p-inputNumber
      locale="en-US"
      styleClass="small-input"
      inputId="minmax{{ item.postType }}"
      [(ngModel)]="item.quantity"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="updateTotalPrices()"
      mode="decimal"
      [min]="0"
      [max]="100"
    >
    </p-inputNumber>
  </span>
  <span *ngIf="!isEditing">
    {{ item.quantity }}
  </span>
</ng-template>
<ng-template #rateCardTmpl let-item="item">
  <span *ngIf="isEditing">
    <p-inputNumber
      locale="en-US"
      styleClass="small-input"
      inputId="integeronly{{ item.postType }}"
      [(ngModel)]="item.price"
      [ngModelOptions]="{ standalone: true }"
      (ngModelChange)="updateTotalPrices()"
    ></p-inputNumber>
  </span>
  <span *ngIf="!isEditing">
    {{ item.price | number : '1.0-2' }}
  </span>
</ng-template>
