import { CampaignModelStatus } from '@slice-enums/campaign-status';

export const MOCK_BRAND_CAMPAIGN_STATUSES = Object.values(
  CampaignModelStatus,
).filter(i => i !== CampaignModelStatus.COMPLETED);

export const getRandomBrandCampaignStatusMock = (): CampaignModelStatus => {
  return MOCK_BRAND_CAMPAIGN_STATUSES[
    Math.floor(Math.random() * MOCK_BRAND_CAMPAIGN_STATUSES.length)
  ];
};
