export const MOCK_AGENCIES = [
  'Blitz Agency',
  'Final Word Agency',
  'Kion Agency',
  'Esther Howard Talent Management ',
  'Savannah Talent Management',
  'Diane Agency',
];

export const getAgencyNameMock = (): string => {
  return MOCK_AGENCIES[Math.floor(Math.random() * MOCK_AGENCIES.length)];
};
