import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[sliceTranslation]',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliceTranslationComponent implements OnInit {
  @Input() sliceTranslationKey?: string;
  @Input() sliceTranslationDefName?: string;

  public value$: Observable<string | undefined>;

  constructor(private tS: TranslocoService) {}

  ngOnInit(): void {
    this.value$ = this.tS.selectTranslation().pipe(
      filter(voc => Boolean(voc && Object.values(voc))),
      map(() => {
        const translation = this.sliceTranslationKey
          ? this.tS.translate(this.sliceTranslationKey)
          : undefined;
        const isTranslated =
          this.sliceTranslationKey && this.sliceTranslationKey !== translation;
        return isTranslated ? translation : this.sliceTranslationDefName;
      }),
    );
  }
}
