import { Component, Input } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-download-file-logo',
  styles: [
    ':host { display: flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './svg-icon-download-file.component.html',
})
export class SvgIconDownloadFileComponent extends AbstractSvgIconComponent {
  @Input() isProKit = false;
  @Input() isLight = false;
}
