import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Channels } from '@slc-libs/enums';

import { ChannelAccountData } from '@slice-interfaces/channel-account-selector/channel-account-selector';

@Component({
  selector: 'slice-select-account-modal',
  templateUrl: './select-account-modal.component.html',
  styleUrls: ['./select-account-modal.component.scss'],
})
export class SelectAccountModalComponent {
  @Input() channel: Channels | null | undefined;
  @Input() accounts: Array<ChannelAccountData>;

  public isVisible = true;
  public currAccount: ChannelAccountData;
  public loading = false;
  public FB_CHANNEL = Channels.INSTAGRAM;

  @Output() closed = new EventEmitter<{ account: ChannelAccountData | null }>();

  onHide(): void {
    this.isVisible = false;
    this.closed.emit({ account: null });
  }

  setAccount(acc: ChannelAccountData): void {
    this.currAccount = acc;
  }

  select(): void {
    this.loading = true;
    this.closed.emit({ account: this.currAccount });
  }
}
