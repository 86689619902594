import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

import { Channels } from '@slc-libs/enums';

@Component({
  selector: 'slice-social-label',
  templateUrl: './social-label.component.html',
  styleUrls: ['./social-label.component.scss'],
})
export class SocialLabelComponent implements AfterViewInit {
  @Input() social: Channels;
  @Input() userAmount?: string | number;
  @Output() rendered = new EventEmitter<void>();
  @HostBinding('class') get classes(): string {
    return this.social ? this.social.toLowerCase() : '';
  }
  public readonly Channels = Channels;

  ngAfterViewInit(): void {
    this.rendered?.emit();
  }
}
