import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { getPublicAgencyClientsRosterResponseMocks } from '@slice-services/clients-roster/public-agency-client-roster-response.mock';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { createHttpParams } from '@slc-libs/helpers';

import { environment } from '@slice-env/environment';

import {
  CreatorSearchParams,
  CreatorSearchResponse,
} from '@slice-interfaces/xpress-report.model';

@Injectable({
  providedIn: 'root',
})
export class SearchHttpService {
  constructor(private http: HttpClient) {}

  findCreatorAgencyId(
    agencyId: string,
    params: CreatorSearchParams,
    useMock?: boolean,
  ): Observable<CreatorSearchResponse> {
    // mock
    const mocks = of(
      getPublicAgencyClientsRosterResponseMocks(params as any),
    ).pipe(delay(1000)) as Observable<any>;
    const req = this.http.get<CreatorSearchResponse>(
      `/api/v1/agencies/${agencyId}/users`,
      {
        params: createHttpParams(params),
      },
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mocks : req;
      } else {
        return req;
      }
    } else {
      return useMock ? mocks : req;
    }
  }

  hitCustomURL(customURL: string, params: any): Observable<any> {
    return this.http.get<any>(`${customURL}`, {
      params: createHttpParams(params),
    });
  }
}
