import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { UsersHttpService } from '@slice-services/users-http.service';
import { takeUntil } from 'rxjs/operators';

import { Channels } from '@slc-libs/enums';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { UserPublicProfile } from '@slice-interfaces/profile/creator-profile';

@Component({
  selector: 'slice-profile-public',
  templateUrl: './profile-public.component.html',
  styleUrls: ['./profile-public.component.scss'],
})
export class ProfilePublicComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() userId!: string;
  @Input() useMock?: boolean | undefined;

  public isLoading = false;
  public data: UserPublicProfile;
  public channelData: Record<Channels, { icon: string; titleKey: string }> = {
    [Channels.INSTAGRAM]: {
      icon: '/assets/svg/instagram-icon.svg',
      titleKey: 'common.followers',
    },
    [Channels.YOUTUBE]: {
      icon: '/assets/svg/youtube-icon.svg',
      titleKey: 'common.subscribers',
    },
    [Channels.TIKTOK]: {
      icon: '/assets/svg/tiktok-icon.svg',
      titleKey: 'common.followers',
    },
    [Channels.APPEARANCE]: {
      icon: '/assets/svg/person.svg',
      titleKey: 'common.followers',
    },
  };
  constructor(private usersHttpS: UsersHttpService) {
    super();
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private getData(): void {
    this.isLoading = true;
    this.usersHttpS
      .getUserPublicProfile(this.userId, this.useMock)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        r => {
          this.isLoading = false;
          this.data = r;
        },
        () => {
          this.isLoading = false;
        },
      );
  }
}
