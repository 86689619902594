import { ActivatedRoute } from '@angular/router';

const rootRoute = (route: ActivatedRoute): ActivatedRoute => {
  while (route.firstChild) {
    route = route.firstChild;
  }
  return route;
};
// get without route params and route query params
export const getCleanUrl = (
  fullUrl: string,
  actRoute: ActivatedRoute,
): string => {
  const urlWithoutQueryParams = fullUrl.split('?')[0].slice(1).split('/');
  const routeParamsAmount = rootRoute(actRoute).snapshot.paramMap.keys.length;
  const cleanUrl = urlWithoutQueryParams.slice(
    0,
    urlWithoutQueryParams.length - routeParamsAmount,
  );
  return '/' + cleanUrl.join('/');
};
