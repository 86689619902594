<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="onHide()"
>
  <p-header>
    <slc-svg-icon-logo-one-color></slc-svg-icon-logo-one-color>
  </p-header>
  <div class="title">
    {{ 'onboarding.connect-account.title.1' | transloco }}
    {{ channel === FB_CHANNEL ? 'Facebook' : (channel | transloco) }}
    {{
      channel === FB_CHANNEL
        ? 'page'
        : ('onboarding.connect-account.title.2' | transloco)
    }}
  </div>

  <div class="description" *ngIf="channel !== FB_CHANNEL">
    {{ 'onboarding.connect-account.description' | transloco }}
  </div>
  <div class="description" *ngIf="channel === FB_CHANNEL">
    {{ 'onboarding.connect-account.description-fb' | transloco }}
  </div>

  <div class="items-wrap">
    <button
      *ngFor="let account of accounts"
      class="account-item"
      type="button"
      [ngClass]="{ selected: account.id === currAccount?.id }"
      (click)="setAccount(account)"
    >
      <div class="slc-flex-fs-c">
        <slc-avatar
          [userFullName]="account?.title"
          [imagePath]="account?.image"
          class="slc-mr-8 slc-avatar-32"
        ></slc-avatar>
        <div class="username">{{ account.title }}</div>
      </div>
    </button>
  </div>

  <p class="policy-terms slc-mt-24">
    {{ 'onboarding.connect-account.privacy.1' | transloco }}
    <a href="https://slice.id/policy" target="_blank">{{
      'onboarding.connect-account.privacy.2' | transloco
    }}</a>
    {{ 'onboarding.connect-account.privacy.3' | transloco }}
    <a href="https://slice.id/terms" target="_blank">{{
      'onboarding.connect-account.privacy.4' | transloco
    }}</a>
  </p>

  <p-footer>
    <button
      class="slc-btn slc-btn-outline-grey"
      (click)="onHide()"
      type="button"
    >
      <span>{{ 'buttons.cancel' | transloco }}</span>
    </button>
    <button
      class="slc-btn slc-btn-primary"
      (click)="select()"
      type="button"
      [ngClass]="{ 'slc-btn-loading': loading }"
    >
      <span>{{ 'buttons.select' | transloco }}</span>
    </button>
  </p-footer>
</p-dialog>
