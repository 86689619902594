import { Component, Input } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-icon-iskyc',
  styles: [
    ':host { display: flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './isKYC.component.html',
})
export class SvgIconIsKYCComponent extends AbstractSvgIconComponent {}
