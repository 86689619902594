<h2 *ngIf="title">{{ title }}</h2>

<div class="row slc-flex-sb-c slc-gap-16 slc-mt-16">
  <div class="col-md-5" *ngFor="let contact of reachOutContact">
    <div class="contact-card" (click)="openLink(contact)">
      <div class="slc-flex-fs-c">
        <span class="contact-icon">
          <i class="pi" [ngClass]="contact.icon"></i>
        </span>
        <span>
          <p class="contact-title">
            {{ contact.title }}
          </p>
          <p class="contact-subtitle">
            {{ contact.subtitle }}
          </p>
        </span>
      </div>
    </div>
  </div>
</div>
