import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconInfoCircleComponent } from './info-circle.component';

@NgModule({
  declarations: [SvgIconInfoCircleComponent],
  imports: [CommonModule],
  exports: [SvgIconInfoCircleComponent],
})
export class SvgIconInfoCircleModule {}
