import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { SvgIconCloseModule } from '@slc-libs/svg-icons';

import { TagComponent } from './tag.component';
@NgModule({
  imports: [CommonModule, TranslocoModule, SvgIconCloseModule],
  declarations: [TagComponent],
  exports: [TagComponent],
})
export class TagModule {}
