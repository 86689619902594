import { Component, HostBinding, Input } from '@angular/core';

import { ChannelPropsalStatus } from '@slc-libs/enums';

@Component({
  selector: 'slice-channel-proposal-status',
  templateUrl: './channel-proposal-status.component.html',
  styleUrls: ['./channel-proposal-status.component.scss'],
})
export class ChannelProposalStatusComponent {
  @Input() status: ChannelPropsalStatus;

  public readonly statuses = ChannelPropsalStatus;
  public translateKey: string;
  @HostBinding('class') get classes(): string {
    if (this.status === ChannelPropsalStatus.IN_PROGRESS) {
      this.translateKey = 'creator-statuses.in-progress.title';
      return 'color-blue';
    } else if (this.status === ChannelPropsalStatus.CHANGES_REQUESTED) {
      this.translateKey = 'creator-statuses.edit-requested.title';
      return 'color-yellow';
    } else if (
      this.status === ChannelPropsalStatus.PENDING_CREATOR_PUBLICATION ||
      this.status === ChannelPropsalStatus.BRAND_APPROVED
    ) {
      this.translateKey = 'creator-statuses.pending-publication.title';
      return 'color-red';
    } else if (this.status === ChannelPropsalStatus.PUBLISHED) {
      this.translateKey = 'creator-statuses.published.title';
      return 'color-green';
    } else if (this.status === ChannelPropsalStatus.CONTENT_SUBMITTED) {
      this.translateKey = 'creator-statuses.content-in-review.title';
      return 'color-purple';
    } else {
      return '';
    }
  }
}
