<button (click)="openMoveBalance()" class="text" [disabled]="disabled">
  Move Balance
</button>
<slice-move-balance-modal
  [moveFrom]="moveFrom"
  [dataCampaign]="dataCampaign"
></slice-move-balance-modal>
<slice-submit-with-pin
  [isVisible]="isVisible"
  [isLoading]="isLoadingSubmit"
  (closed)="closeModalPin()"
  (submitFunction)="submitMoveBalance($event)"
></slice-submit-with-pin>
