import { GetPageParams, PaginationResponse } from '@slc-libs/interfaces';

const totalElements = 82;

export const getPagebaleResponseMock = (
  params: GetPageParams,
  totalAmount?: number,
): PaginationResponse => {
  const total = totalAmount || totalElements;
  const totalPages = Math.floor(total / params.size);
  return {
    totalPages,
    totalElements: total,
    size: params.size,
    number: params.page,
    sort: {
      sorted: false,
      unsorted: false,
      empty: false,
    },
    first: params.page === 0,
    last: params.page === totalPages,
    numberOfElements: params.size,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pageable: params as any,
    empty: false,
    content: [],
  };
};
