import { Component, EventEmitter, Input, Output } from '@angular/core';

import { WizardFooterActions } from '@slice-enums/wizard';

@Component({
  selector: 'slice-wizard-step-footer',
  templateUrl: './wizard-step-footer.component.html',
  styleUrls: ['./wizard-step-footer.component.scss'],
})
export class WizardStepFooterComponent {
  @Input() disabledBtns: Array<WizardFooterActions>;
  @Input() hiddenBtns: Array<WizardFooterActions>;
  @Input() loading: boolean;
  @Input() nextBtnCssClass: string;

  public readonly ACTIONS = WizardFooterActions;

  @Output() action = new EventEmitter<WizardFooterActions>();

  next(): void {
    this.action.emit(WizardFooterActions.next);
  }

  prev(): void {
    this.action.emit(WizardFooterActions.prev);
  }

  submit(): void {
    this.action.emit(WizardFooterActions.submit);
  }
}
