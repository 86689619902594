import { Component, Input } from '@angular/core';

import { BreadcrumbsWithLinksItem } from '@slice-interfaces/breadcrumbs-with-links';

@Component({
  selector: 'slice-breadcrumbs-with-links',
  templateUrl: './breadcrumbs-with-links.component.html',
  styleUrls: ['./breadcrumbs-with-links.component.scss'],
})
export class BreadcrumbsWithLinksComponent {
  @Input() items: Array<BreadcrumbsWithLinksItem>;
  @Input() highlightLast = true;
}
