<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="closed.emit()"
>
  <p-header>
    <span class="grey-title text-left"> Invite Creator > Import File </span>
  </p-header>

  <main>
    <div class="section blue">
      <p>
        You are going to import Content Creator data for roster invitation.
        Please prepare 3 fields in your file.
      </p>
      <ul style="list-style: disc" class="ml-3 my-2">
        <li class="font-weight-bold">Name</li>
        <li class="font-weight-bold">Phone Number</li>
        <li class="font-weight-bold">
          Email <span class="font-weight-normal">(optional)</span>
        </li>
        <li>
          <span class="font-weight-bold"> Current earnings </span> (optional,
          but it’s able to provide a precise tax calculation for creators)
        </li>
      </ul>
      <div class="text-center">
        <button class="slc-btn" (click)="downloadFile()">
          <slc-svg-download-file-logo></slc-svg-download-file-logo>
          <span class="ml-2 btn-download">
            Click here to download template
          </span>
        </button>
      </div>
    </div>
    <div class="section my-2 shadow">
      <div
        class="file-upload-container"
        (drop)="onFileDropped($event)"
        (dragover)="onDragOver($event)"
      >
        <i class="pi pi-file-export my-3"></i>
        <p class="drag-text" *ngIf="!selectedFile">
          Please drag and drop the template or
          <span class="font-weight-bold text-blue">choose a file</span> to
          upload. Only .csv file type are supported.
        </p>
        <input
          type="file"
          #fileInput
          (change)="onFileSelected(fileInput)"
          accept=".csv"
        />
        <div *ngIf="selectedFile" class="file-info">
          <p>{{ selectedFile.name }}</p>
        </div>
        <button
          class="slc-btn slc-btn-primary slc-mt-16"
          (click)="fileInput.click()"
        >
          {{ selectedFile ? 'Re-Upload' : 'Upload' }}
        </button>
      </div>
    </div>
    <button
      [ngClass]="{
        'slc-btn-loading': isLoading
      }"
      [disabled]="!selectedFile"
      class="slc-btn slc-btn-outline-blue w-100 my-4 slc-text-color-primary"
      (click)="readFile()"
    >
      Import File
    </button>
  </main>
</p-dialog>
<ng-container *ngIf="isVisibleResponse">
  <slice-modal-csv-creator-response
    [isVisible]="isVisibleResponse"
    [data]="responseData"
    (closed)="closeResponseModal()"
  ></slice-modal-csv-creator-response>
</ng-container>
