import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconPlayComponent } from './svg-icon-play.component';

@NgModule({
  declarations: [SvgIconPlayComponent],
  imports: [CommonModule],
  exports: [SvgIconPlayComponent],
})
export class SvgIconPlayModule {}
