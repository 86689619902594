import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { AuthStateService } from '@slice-services/auth-state.service';
import { takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { WalletBalanceDetail } from '@slice-interfaces/wallet/wallet-balance';
import { BALANCE_TYPE } from '@slice-enums/bulk-payment/campaign-enums';

import { CardBalanceStateService } from './services/card-balance.service';
import { CardBalanceFacadeService } from './services/card-balance-facade.service';

@Component({
  selector: 'slice-card-balance',
  templateUrl: './card-balance.component.html',
  styleUrls: ['./card-balance.component.scss'],
  providers: [CardBalanceFacadeService, CardBalanceStateService],
})
export class SharedCardBalanceComponent
  extends AbstractSubscriberComponent
  implements OnChanges
{
  @Input() type: BALANCE_TYPE = BALANCE_TYPE.MainBalance;
  // @Input() type?: string = 'mainBalance'; // mainBalance, supportBalance, termTemplate, allbalance, allBalanceDetail
  @Input() color?: string = '#4dbb7b'; //#hexcolor
  @Input() title?: string = 'Current Balance';
  @Input() subTitle?: string = '';
  @Input() currency?: string = 'IDR';
  @Input() isShowsButtonBalance?: boolean = false;
  @Input() btnType?: string = 'campaign';
  @Input() btnLabel?: string = 'Balance Detail';
  @Input() number?: any = 0;
  @Input() content?: any;
  @Input() dataCampaign?: any;
  //allbalanceDetail
  @Input() datas?: any = [];
  @Output() functionButton = new EventEmitter<any>();

  disableMoveBalance: boolean;
  disableMoveBalanceDetail: boolean;
  public balanceTypes = BALANCE_TYPE;

  constructor(
    private facadeS: CardBalanceFacadeService,
    private authStateS: AuthStateService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
    this.authStateS.selectAuthenticatedUser().subscribe(dt => {
      if (dt?.userType !== 'creator') {
        this.facadeS.fetchBalanceDetails().subscribe();
        this.fetchData();
      }
    });
  }

  data: WalletBalanceDetail | null;
  dataList: any;
  isLoading = false;
  isShowModal = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.cdr.detectChanges();
  }

  defaultButtonDisabled(): boolean {
    const dts = this.datas[0].number > 0 ? false : true;
    return dts;
  }

  buttonFunction(): void {
    this.isShowModal = true;
    this.fetchData();
    // this.functionButton?.emit();
  }
  closeModal(): void {
    this.isShowModal = false;
    // this.functionButton?.emit();
  }

  fetchBalance(): void {
    this.facadeS
      .fetchBalanceDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  fetchData(): void {
    this.isLoading = true;
    this.facadeS
      .selectBalanceDetail()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        this.isLoading = false;
        this,
          (this.disableMoveBalance =
            (r?.generalBalance as number) > 0 ? false : true);
        this.data = r;
        this.dataList = r?.campaigns;
      });
  }

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }
}
