import { Component, Input } from '@angular/core';

import { E_ROUTES } from '@slice-enums/routes.enum';

@Component({
  selector: 'slice-menu-item-icon',
  templateUrl: './menu-item-icon.component.html',
  styleUrls: ['./menu-item-icon.component.scss'],
})
export class MenuItemIconComponent {
  public readonly ROUTES = E_ROUTES;
  @Input() routeType: E_ROUTES | undefined;
}
