import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ProfileHttpService } from '@slice-services/profiles-http.service';
import { filter, takeUntil } from 'rxjs/operators';

import { Channels } from '@slc-libs/enums';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

@Component({
  selector: 'slice-social-connect-btns',
  templateUrl: './social-connect-btns.component.html',
  styleUrls: ['./social-connect-btns.component.scss'],
})
export class SocialConnectBtnsComponent
  extends AbstractSubscriberComponent
  implements OnInit
{
  @Input() channel: Channels;
  @Input() isExpiredFlow: boolean;

  public isInited = false;
  public username: string | undefined;
  public accountId: string | undefined;
  public isConnected: boolean | undefined;
  public isActionInProgress: boolean;
  public Channels = Channels;

  @Output() disconnectDone = new EventEmitter<boolean>();
  @Output() connectDone = new EventEmitter<boolean>();

  constructor(private profileHttpS: ProfileHttpService) {
    super();
  }

  ngOnInit(): void {
    this.initDataFromProfile();
  }

  private initDataFromProfile(): void {
    this.profileHttpS
      .fetchCreatorProfile()
      .pipe(
        takeUntil(this.destroy$),

        filter(r => Boolean(r)),
        takeUntil(this.destroy$),
      )
      .subscribe(res => {
        this.isInited = true;
        const data = res?.connectedAccounts.find(
          i => i.channel === this.channel,
        );
        this.isConnected = Boolean(data);
        this.accountId = data?.id;
        this.username = data?.username || '';
      });
  }

  actionInProgress(isProgress: boolean): void {
    this.isActionInProgress = isProgress;
  }

  disconnected(isDisConnected: boolean): void {
    this.isActionInProgress = false;
    this.disconnectDone?.emit(isDisConnected);
  }

  connected(isConnected: boolean): void {
    this.isActionInProgress = false;
    this.connectDone?.emit(isConnected);
  }
}
