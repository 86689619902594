import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconBuildingComponent } from './building.component';

@NgModule({
  declarations: [SvgIconBuildingComponent],
  imports: [CommonModule],
  exports: [SvgIconBuildingComponent],
})
export class SvgIconBuildingModule {}
