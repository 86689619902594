<p-dialog
  [(visible)]="showModal"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onBeforeHide)="hidePopup()"
  (onHide)="hidePopup()"
>
  <p-header>
    <span>{{ 'crop-image.title' | transloco }}</span>
  </p-header>

  <ng-container *ngTemplateOutlet="cropperTmpl"></ng-container>

  <p-footer>
    <button
      class="slc-btn slc-btn-outline-grey"
      (click)="hidePopup()"
      type="button"
    >
      <span>{{ 'buttons.cancel' | transloco }}</span>
    </button>
    <button
      class="slc-btn slc-btn-primary"
      [disabled]="applyLoading"
      [ngClass]="{ 'slc-btn-loading': applyLoading }"
      (click)="apply()"
      type="button"
    >
      <span>{{ 'buttons.apply' | transloco }}</span>
    </button>
  </p-footer>
</p-dialog>

<ng-container *ngIf="!isModal">
  <ng-container *ngTemplateOutlet="cropperTmpl"></ng-container>
</ng-container>

<ng-template #cropperTmpl>
  <image-cropper
    [imageFile]="imageFile"
    [roundCropper]="cropperType === cropperTypes.AVATAR"
    [maintainAspectRatio]="true"
    format="png"
    [output]="'base64'"
    [aspectRatio]="aspectRatio"
    (imageCropped)="imageCropped($event)"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>

  <slice-loader *ngIf="loading" class="slice-loader-fluid"></slice-loader>

  <div *ngIf="showPreview && !loading" class="slc-mt-40">
    <div class="slc-flex-c-c"><span>Preview:</span></div>
    <img
      class="slice-img-cropper-preview slc-mt-16"
      [src]="croppedImageBase64"
      class="slice-img-cropper-preview-{{ cropperType }}"
    />
  </div>
</ng-template>
