import { ContentTypeImgVideo } from '@slice-enums/content-types-img-video';

const imgTypes = [
  'jpeg',
  'jpg',
  'gif',
  'png',
  'jfif',
  'pjpeg',
  'pjp',
  'svg',
  'webp',
  'apng',
  'avif',
];
const videoTypes = [
  'mp4',
  '3gp',
  'quicktime',
  'mov',
  'mpeg',
  'mpg',
  'ogg',
  'wmv',
  'wav',
  'rm',
  '3g2',
  '3gp',
  'webm',
];

export const getContentTypeByUrl = (
  path: string,
): ContentTypeImgVideo | undefined => {
  let currType: ContentTypeImgVideo | undefined;
  const url = new URL(path);
  const splited = url.pathname.split('.');
  const extension = splited[splited.length - 1];
  if (imgTypes.includes(extension)) {
    currType = ContentTypeImgVideo.image;
  } else if (videoTypes.includes(extension)) {
    currType = ContentTypeImgVideo.video;
  } else {
    currType = undefined;
    console.warn('HELPER. getContentTypeByUrl: Not defined type of source');
  }
  return currType;
};
