import { Injectable } from '@angular/core';

import { ProfileHttpService } from '@slice-services/profiles-http.service';
import { WalletBalanceHttpService } from '@slice-services/wallet-balance-http.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ProfilePinChangePayload } from '@slice-interfaces/profile/creator-profile';
import {
  CampaignListTopup,
  payloadMoveBalance,
  WalletBalanceDetail,
} from '@slice-interfaces/wallet/wallet-balance';

import { CardBalanceStateService } from './card-balance.service';

@Injectable({
  providedIn: 'root',
})
export class CardBalanceFacadeService {
  constructor(
    private stateS: CardBalanceStateService,
    private profileHttpS: ProfileHttpService,
    private walletBalanceHttpS: WalletBalanceHttpService,
  ) {}
  setIsLoading(d: boolean): void {
    this.stateS.isLoading$.next(d);
  }
  selectIsLoading(): Observable<boolean> {
    return this.stateS.isLoading$.asObservable();
  }
  setIsModalMoveBalance(d: boolean): void {
    this.stateS.isShowModalMoveBalance$.next(d);
  }
  selectIsModalMoveBalance(): Observable<boolean> {
    return this.stateS.isShowModalMoveBalance$.asObservable();
  }
  setBalanceDetail(d: WalletBalanceDetail | null): void {
    this.stateS.balanceDetail$.next(d);
  }
  selectBalanceDetail(): Observable<WalletBalanceDetail | null> {
    return this.stateS.balanceDetail$.asObservable();
  }
  setModalDetail(d: boolean): void {
    this.stateS.isModalDetail$.next(d);
  }
  selectModalDetail(): Observable<boolean> {
    return this.stateS.isModalDetail$.asObservable();
  }

  setCampaignTopUp(d: Array<CampaignListTopup> | []): void {
    this.stateS.campaignList$.next(d);
  }
  selectCampaignTopUp(): Observable<Array<CampaignListTopup>> {
    return this.stateS.campaignList$.asObservable();
  }
  getCampaignTopUp(): Array<CampaignListTopup> | [] {
    return this.stateS.campaignList$.value;
  }

  setPayload(d: payloadMoveBalance | null): void {
    this.stateS.payload$.next(d);
  }
  selectPayload(): Observable<payloadMoveBalance | null> {
    return this.stateS.payload$.asObservable();
  }

  getPayload(): payloadMoveBalance | null {
    return this.stateS.payload$.value;
  }
  setModalPin(d: boolean): void {
    this.stateS.isModalPin$.next(d);
  }
  selectModalPin(): Observable<boolean> {
    return this.stateS.isModalPin$.asObservable();
  }
  // setIsModalVerification(d: boolean): void {
  //   this.stateS.isShowModalVerification$.next(d);
  // }
  // selectIsModalVerification(): Observable<boolean> {
  //   return this.stateS.isShowModalVerification$.asObservable();
  // }
  // setIsModalForgotPin(d: boolean): void {
  //   this.stateS.forgotPinModal$.next(d);
  // }
  // selectIsModalForgotPin(): Observable<boolean> {
  //   return this.stateS.forgotPinModal$.asObservable();
  // }
  // setIsModalResetPin(d: boolean): void {
  //   this.stateS.isShowResetPin$.next(d);
  // }
  // selectIsModalResetPin(): Observable<boolean> {
  //   return this.stateS.isShowResetPin$.asObservable();
  // }
  // setVerificationCode(d: string): void {
  //   this.stateS.verificationCode$.next(d);
  // }
  // selectVerificationCode(): Observable<string> {
  //   return this.stateS.verificationCode$.asObservable();
  // }
  // setEmail(d: string): void {
  //   this.stateS.email$.next(d);
  // }
  // selectEmail(): Observable<string> {
  //   return this.stateS.email$.asObservable();
  // }

  // service https
  fetchBalanceDetails(): Observable<WalletBalanceDetail | null> {
    return this.walletBalanceHttpS
      .getBalanceDetail()
      .pipe(tap((wb: WalletBalanceDetail | null) => this.setBalanceDetail(wb)));
  }
  fetchCampaignList(): Observable<Array<CampaignListTopup>> {
    return this.walletBalanceHttpS
      .getListCampaignTopUp()
      .pipe(tap((wb: Array<CampaignListTopup>) => this.setCampaignTopUp(wb)));
  }
  moveBalanceService(): Observable<Array<CampaignListTopup>> {
    return this.walletBalanceHttpS.moveBalance(
      this.getPayload() as payloadMoveBalance,
    );
  }
  // forgotPass(payload: any): Observable<any> {
  //   return this.profileHttpS.postEmailForgotPass(payload);
  // }
  // verificationEmail(payload: any): Observable<any> {
  //   return this.profileHttpS.postVerificationEmail(payload);
  // }
  // resetPinService(payload: any): Observable<any> {
  //   return this.profileHttpS.resetPin(payload);
  // }
}
