export enum ChannelPropsalStatus {
  PRE_SELECTED = 'PRE_SELECTED',
  SCORED = 'SCORED',
  SLICE_INVITED = 'SLICE_INVITED',
  CREATOR_DECLINED = 'CREATOR_DECLINED',
  CREATOR_ACCEPTED = 'CREATOR_ACCEPTED',
  BRAND_DECLINED = 'BRAND_DECLINED',
  BRAND_ACCEPTED = 'BRAND_ACCEPTED',
  SLICE_RECOMMENDED = 'SLICE_RECOMMENDED',
  IN_PROGRESS = 'IN_PROGRESS',
  CONTENT_SUBMITTED = 'CONTENT_SUBMITTED',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  BRAND_APPROVED = 'BRAND_APPROVED',
  PUBLISHED = 'PUBLISHED',
  PENDING_CREATOR_PUBLICATION = 'PENDING_CREATOR_PUBLICATION',
}
