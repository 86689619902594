const week = 7 * 24 * 60 * 60 * 1000;

export const getPastDateMock = (): string => {
  return new Date(
    new Date().getTime() - Math.ceil(Math.random() * week),
  ).toString();
};

export const getFutureDateMock = (): string => {
  return new Date(
    new Date().getTime() + Math.ceil(Math.random() * week),
  ).toString();
};
