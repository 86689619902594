import { Component, HostBinding, Input } from '@angular/core';

import { BriefProposalStatus } from '@slc-libs/enums';

import { briefProposalMap, Config } from './config';

@Component({
  selector: 'slice-brief-proposal-status-label',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class BriefProposalStatusLabelComponent {
  @Input() set statusVal(val: BriefProposalStatus) {
    if (val) {
      this.status = val;
      this.config = briefProposalMap[val];
    }
  }
  public status: any;

  public briefPostProposalMap: Record<BriefProposalStatus, Config>;

  public config: Config;

  @HostBinding('class') get classes(): string {
    return this.config?.cssColorClass || '';
  }
}
