<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M14.34 -0.000976562C17.73 -0.000976562 20 2.37902 20 5.91902V14.09C20 17.62 17.73 19.999 14.34 19.999H5.67C2.28 19.999 0 17.62 0 14.09V5.91902C0 2.37902 2.28 -0.000976562 5.67 -0.000976562H14.34ZM9.99 8.48002C9.51 8.48002 9.12 8.87902 9.12 9.36002V13.78C9.12 14.26 9.51 14.65 9.99 14.65C10.48 14.65 10.87 14.26 10.87 13.78V9.36002C10.87 8.87902 10.48 8.48002 9.99 8.48002ZM10.01 5.31002C9.52 5.31002 9.13 5.70002 9.13 6.19002C9.13 6.66902 9.52 7.06002 9.99 7.06002C10.49 7.06002 10.88 6.66902 10.88 6.19002C10.88 5.70002 10.49 5.31002 10.01 5.31002Z"
    fill="#5D6468"
  />
</svg>
