import { Component, OnDestroy, OnInit } from '@angular/core';

import { AuthStateService } from '@slice-services/auth-state.service';
import { takeUntil } from 'rxjs/operators';

import { Channels, UserTypes } from '@slc-libs/enums';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

@Component({
  selector: 'slice-creator-expired-channels',
  templateUrl: './creator-expired-channels.component.html',
  styleUrls: ['./creator-expired-channels.component.scss'],
})
export class CreatorExpiredChannelsComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  public Channels = Channels;
  public showIg = false;
  public showYt = false;
  constructor(private authStateS: AuthStateService) {
    super();
  }

  ngOnInit(): void {
    this.initAgencyChannelExpired();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  initAgencyChannelExpired(): void {
    this.authStateS
      .selectAuthenticatedUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        const showModal =
          user &&
          user.emailConfirmed &&
          user?.userType === UserTypes.CREATOR &&
          (user.youtubeExpired || user?.instagramExpired);
        this.showYt = showModal && user?.youtubeExpired ? true : false;
        this.showIg = showModal && user?.instagramExpired ? true : false;
      });
  }

  close(isAccountConnected: boolean, channel: Channels): void {
    if (channel === Channels.INSTAGRAM) {
      this.showIg = false;
    } else if (channel === Channels.YOUTUBE) {
      this.showYt = false;
    }
  }
}
