<div class="slc-p-autocomplete-wrap">
  <slc-svg-icon-search></slc-svg-icon-search>
  <p-autoComplete
    [style]="{ width: '100%' }"
    [inputStyle]="{ width: '100%' }"
    [(ngModel)]="value"
    [delay]="500"
    [dropdown]="false"
    [multiple]="multiple"
    [suggestions]="suggestions"
    [minLength]="2"
    field="name"
    placeholder="Search Creators"
    (completeMethod)="search($event)"
    (onSelect)="onSelect($event)"
    (onClear)="onClear()"
  >
  </p-autoComplete>
</div>
