import { TemplateRef } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { TABLES_CELL_TYPE } from '../constants';

export enum TableColumnAlignment {
  center = 'center',
  left = 'left',
  right = 'right',
}

export interface TableColumn {
  columnName?: string;
  titleKey?: string;
  field?: string;
  dataType?: TABLES_CELL_TYPE;
  isExpandBtnColumn?: boolean;
  isEmptyExpandColumn?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellTmpl?: TemplateRef<any> | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerTmpl?: TemplateRef<any>;
  isSortable?: boolean;
  areaSort?: 'ascending' | 'descending';
  isSelection?: boolean;
  width?: string; // % || px
  tooltipKey?: string;
  tooltipMarkupHTML$?: BehaviorSubject<string | undefined | null>;
  tooltipCssClass?: string | undefined;
  frozen?: boolean;
  cssClass?: string;
  alignment?: TableColumnAlignment;
}
