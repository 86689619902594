import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconInstagramComponent } from './instagram.component';

@NgModule({
  declarations: [SvgIconInstagramComponent],
  imports: [CommonModule],
  exports: [SvgIconInstagramComponent],
})
export class SvgIconInstagramModule {}
