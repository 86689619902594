import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

@Component({
  selector: 'slice-submit-with-pin',
  templateUrl: './submit-with-pin.component.html',
  styleUrls: ['./submit-with-pin.component.scss'],
})
export class SubmitWithPinComponent
  extends AbstractSubscriberComponent
  implements OnInit
{
  @Input() isVisible?: boolean;
  @Input() isLoading?: boolean;
  @Output() closed = new EventEmitter<any>();
  @Output() submitFunction = new EventEmitter<any>();

  public mainForm = new UntypedFormGroup({});
  public pinFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
  ]);
  // constructor() {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.mainForm.addControl('pin', this.pinFormControl);
  }

  updatePin(newPin: string) {
    this.pinCtrl.setValue(newPin);
  }

  triggerHide(): void {
    this.closed?.emit();
  }

  submitPin() {
    this.submitFunction.emit(this.pinCtrl.value);
  }

  get pinCtrl(): AbstractControl {
    return this.mainForm.get('pin') as AbstractControl;
  }
}
