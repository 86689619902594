import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventApi } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { UiStateService } from '@slice-services/ui-state.service';
import { CALENDAR_DEF_VIEW_TYPE } from 'apps/slice/src/app/_const/calendar-def-view-type';
import { takeUntil } from 'rxjs/operators';

import { BreakpointsService } from '@slc-libs/services';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { FullCalendarEvent } from '@slice-interfaces/calendar/event';
import { CalendarView } from '@slice-interfaces/calendar/views';
import { Periods } from '@slice-enums/periods';

export interface DomRenderInfo {
  event: EventApi;
  el: HTMLElement;
}

export interface CalendarOnEventRenderOutput {
  event: EventApi;
  el: HTMLElement;
  content: HTMLElement;
}

@Component({
  selector: 'slice-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() date: string;
  @Input() isLoading: boolean;
  @Input() events: Array<FullCalendarEvent>;
  @Input() period: Periods;

  private viewMap = {
    [Periods.DAY]: CalendarView.DAY,
    [Periods.WEEK]: CalendarView.WEEK,
    [Periods.MONTH]: CalendarView.MONTH,
  };
  public redraw: boolean;
  private viewType: CalendarView;
  public showEvents: boolean;
  public options: any;
  public isInitialLoadingDone: boolean;
  public isMobile: boolean;
  private eventsMap: Record<string, HTMLElement> = {};
  isDesktop = false;
  @ViewChild('pCalendar', { static: false }) pCalendar: FullCalendarComponent;

  @Output() calendarInited = new EventEmitter<FullCalendarComponent>();
  @Output() eventRender = new EventEmitter<CalendarOnEventRenderOutput>();

  constructor(
    private uiStateS: UiStateService,
    private bpS: BreakpointsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initChangeViewByLayout();
    this.initCalendarOptions();
    this.initViewByAppMenuSub();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date) {
      if (changes.date.currentValue) {
        this.pCalendar
          ?.getApi()
          .gotoDate(
            changes.date.currentValue
              ? new Date(changes.date.currentValue)
              : new Date(),
          );
      }
    }

    if (changes.period && !changes.period.firstChange) {
      if (changes.period.currentValue) {
        const viewType = this.getViewTypeByPeriod(changes.period.currentValue);
        if (viewType !== this.viewType) {
          this.viewType = viewType;
          this.pCalendar?.getApi().changeView(this.viewType);
          this.pCalendar.getApi().render();
        }
      }
    }

    if (changes.events) {
      if (changes.events.currentValue) {
        if (this.options) {
          this.options.events = changes.events.currentValue || [];
        }
      } else {
        if (this.options) {
          this.options.events = [];
        }
      }
    }
  }

  private initChangeViewByLayout(): void {
    this.bpS
      .selectIsDesktop()
      .pipe(takeUntil(this.destroy$))
      .subscribe(isDesktop => {
        this.isDesktop = isDesktop;
      });
    this.bpS
      .selectIsMobile()
      .pipe(takeUntil(this.destroy$))
      .subscribe(isM => {
        this.isMobile = isM;

        if (isM) {
          this.pCalendar?.getApi().changeView(CalendarView.LIST_WEEK);
        } else {
          this.pCalendar
            ?.getApi()
            .changeView(this.getViewTypeByPeriod(this.period));
        }
        setTimeout(() => {
          this.pCalendar?.getApi().render();
        }, 300);
      });
  }

  private getViewTypeByPeriod(period: Periods): CalendarView {
    return (
      (this.viewMap[period] as unknown as CalendarView) ||
      (CALENDAR_DEF_VIEW_TYPE as CalendarView)
    );
  }

  private initViewByAppMenuSub(): void {
    this.uiStateS
      .selectIsAppMenuOpen()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => {
          this.pCalendar.getApi().render();
        }, 200);
      });
  }

  private initCalendarOptions(): void {
    this.options = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
      initialDate: this.date ? new Date(this.date) : new Date(),
      initialView: this.isMobile
        ? CalendarView.LIST_WEEK
        : this.getViewTypeByPeriod(this.period),
      headerToolbar: {
        start: '',
        center: '',
        end: '',
      },
      firstDay: 1,
      eventDidMount: this.onEventRender.bind(this),
      loading: this.calendarLoaded.bind(this),
      events: this.events,
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
    };
  }

  ngAfterViewInit(): void {
    this.calendarInited?.emit(this.pCalendar);
  }

  public calendarLoaded(isLoading: boolean): void {
    this.showEvents = !isLoading;
  }

  public onEventRender(fullCalendarEvDom: DomRenderInfo): void {
    const content = document.createElement('div');
    if (this.eventsMap[fullCalendarEvDom.event.extendedProps.eventId]) {
      this.eventsMap[fullCalendarEvDom.event.extendedProps.eventId].remove();
    }
    this.eventRender.emit({
      event: fullCalendarEvDom.event,
      el: fullCalendarEvDom.el,
      content: content,
    });
  }
}
