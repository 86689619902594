import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconFunnelConversionComponent } from './funnel-conversion.component';

@NgModule({
  declarations: [SvgIconFunnelConversionComponent],
  imports: [CommonModule],
  exports: [SvgIconFunnelConversionComponent],
})
export class SvgIconFunnelConversionModule {}
