import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconPhotoComponent } from './photo.component';

@NgModule({
  declarations: [SvgIconPhotoComponent],
  imports: [CommonModule],
  exports: [SvgIconPhotoComponent],
})
export class SvgIconPhotoModule {}
