import { PostTypes } from '@slc-libs/enums';
import { Channels } from '@slc-libs/enums';

const divider = '___';

export const createPriceFormCtrlName = (
  channel: Channels,
  postType: PostTypes,
): string => {
  return channel + divider + postType;
};

export const parsePriceFormCtrlName = (
  formControlName: string,
): {
  channel: Channels;
  postType: PostTypes;
} => {
  const res = formControlName.split(divider);
  if (res.length !== 2) {
    throw new Error(
      'Channels Prices. Issue for parse FormControlName  - ' + formControlName,
    );
  }
  return {
    channel: res[0] as Channels,
    postType: res[1] as PostTypes,
  };
};
