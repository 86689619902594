import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';

import { AuthStateService } from '@slice-services/auth-state.service';
import { NavigationService } from '@slice-services/navigation.service';
import { SessionStorageService } from '@slice-services/session-storage.service';

import { E_ROUTES } from '@slice-enums/routes.enum';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authStateService: AuthStateService,
    private navigationService: NavigationService,
    private ssS: SessionStorageService,
    private router: Router,
  ) {}
  public canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    return this.check(route);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private check(route: ActivatedRouteSnapshot): boolean | UrlTree {
    if (this.authStateService.isAuthenticated()) {
      return true;
    } else {
      const fullUrl = window.location.href.replace(window.location.origin, '');
      this.ssS.setBackUrlAfterSignIn(fullUrl);
      return this.router.parseUrl(
        this.navigationService.getRoutePath(E_ROUTES.SIGN_IN),
      );
    }
  }
}
