<svg
  xmlns="http://www.w3.org/2000/svg"
  width="22"
  height="18"
  viewBox="0 0 22 18"
  fill="none"
>
  <path
    d="M21.4 2.54963C20.6344 2.88963 19.812 3.11843 18.9488 3.22163C19.8304 2.69363 20.5064 1.85763 20.8248 0.860828C20.0008 1.34963 19.0872 1.70483 18.1144 1.89683C17.336 1.06723 16.2272 0.548828 15 0.548828C12.6432 0.548828 10.7328 2.46003 10.7328 4.81603C10.7328 5.15043 10.7712 5.47683 10.8432 5.78803C7.2968 5.61043 4.1528 3.91123 2.0472 1.32883C1.6808 1.95923 1.4704 2.69203 1.4704 3.47523C1.4704 4.95523 2.2232 6.26163 3.368 7.02643C2.6688 7.00403 2.0104 6.81203 1.4352 6.49283C1.4352 6.51123 1.4352 6.52803 1.4352 6.54643C1.4352 8.61443 2.9056 10.3392 4.8584 10.7304C4.5008 10.828 4.1232 10.88 3.7336 10.88C3.4592 10.88 3.1912 10.8528 2.9312 10.804C3.4744 12.4992 5.0504 13.7336 6.9176 13.768C5.4576 14.9128 3.6176 15.5952 1.6176 15.5952C1.2736 15.5952 0.933597 15.5752 0.599197 15.5352C2.488 16.7456 4.7304 17.452 7.1408 17.452C14.9904 17.452 19.2816 10.9496 19.2816 5.31043C19.2816 5.12563 19.2776 4.94163 19.2696 4.75843C20.104 4.15603 20.828 3.40483 21.4 2.54963Z"
    fill="#5D6468"
  />
</svg>
