import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { CanComponentDeactivate } from '@slice-shared/abstract-classes/deactivate';

@Injectable()
export class DeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(
    component: CanComponentDeactivate,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const result = component.spongebobYellow();

    if (typeof result === 'boolean') {
      return this.handleDeactivateRouteEvent(result);
    } else {
      return result.pipe(
        switchMap(confirmed => this.handleDeactivateRouteEvent(confirmed)),
      );
    }
  }

  private handleDeactivateRouteEvent(allowed: boolean): Observable<boolean> {
    if (!allowed) {
      const userConfirmed = confirm('Changes you made may not be saved.');
      return of(userConfirmed);
    }
    return of(true);
  }
}
