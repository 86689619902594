import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconGoogleComponent } from './google.component';

@NgModule({
  declarations: [SvgIconGoogleComponent],
  imports: [CommonModule],
  exports: [SvgIconGoogleComponent],
})
export class SvgIconGoogleModule {}
