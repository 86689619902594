<svg
  xmlns="http://www.w3.org/2000/svg"
  width="12"
  height="8"
  viewBox="0 0 12 8"
  fill="none"
>
  <path
    d="M6.00015 3.52573L2.17915 7.34673C1.76515 7.76073 1.09315 7.76073 0.679152 7.34673C0.265152 6.93273 0.265152 6.26073 0.679152 5.84673L5.29315 1.23273C5.68415 0.841726 6.31715 0.841726 6.70715 1.23273L11.3212 5.84673C11.7352 6.26073 11.7352 6.93273 11.3212 7.34673C10.9072 7.76073 10.2352 7.76073 9.82115 7.34673L6.00015 3.52573Z"
    fill="#5D6468"
  />
</svg>
