import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconInstagramLightComponent } from './instagram-light.component';

@NgModule({
  declarations: [SvgIconInstagramLightComponent],
  imports: [CommonModule],
  exports: [SvgIconInstagramLightComponent],
})
export class SvgIconInstagramLightModule {}
