import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconList1Component } from './list-1.component';

@NgModule({
  declarations: [SvgIconList1Component],
  imports: [CommonModule],
  exports: [SvgIconList1Component],
})
export class SvgIconList1Module {}
