import { Component, Input } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-icon-file-detail',
  styles: [
    ':host { display: inline-flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './file-detail.component.html',
})
export class SvgIconArrowShortComponent extends AbstractSvgIconComponent {
  @Input() isDisable = false;
}
