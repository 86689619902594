import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AuthStateService } from '@slice-services/auth-state.service';
import { FacebookLoginService } from '@slice-services/facebook-login-service';
import { UiStateService } from '@slice-services/ui-state.service';
import { MessageService } from 'primeng/api';
import { Observable, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { Channels } from '@slc-libs/enums';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { DIALOG_RESULT_MODAL_TYPE } from '@slice-shared/components/dialog-result-modal/dialog-result-modal.component';
import { ChannelAccountData } from '@slice-interfaces/channel-account-selector/channel-account-selector';
import { FacebookAccount } from '@slice-interfaces/facebook/fb-account';

@Component({
  selector: 'slice-instagram-connect',
  templateUrl: './instagram-connect.component.html',
  styleUrls: ['./instagram-connect.component.scss'],
})
export class InstagramConnectComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() set isConnected(isConnected: boolean | undefined) {
    if (typeof isConnected === 'boolean') {
      this.isInited = true;
      this.isInstagramConnected = isConnected;
    }
  }
  @Input() accountId: string | undefined;
  @Input() popupFacebookSocialMsg = false; // popup only for my-profile page
  @Input() showConnectSuccessMsg = true;
  @Input() showDisconnectSuccessMsg = true;
  @Input() disabled: boolean | undefined;
  @Input() isExpiredFlow: boolean | undefined;

  public isInstagramConnected: boolean;
  public isInited: boolean;
  public CHANNELS = Channels;
  public username: string | undefined;
  public showAccountSelectionModal = false;
  public connectionInProgress: boolean;
  public showDisconnectErrorMsg = false;
  public showFacebookSocialMsg = false;
  public accounts: Array<any>;
  private userId: string;

  @Output() actionInProgress = new EventEmitter<boolean>();
  @Output() disconnected = new EventEmitter<boolean>();
  @Output() connected = new EventEmitter<boolean>();

  constructor(
    private messageS: MessageService,
    private authStateS: AuthStateService,
    private facebookLoginS: FacebookLoginService,
    private uiStateS: UiStateService,
    private tS: TranslocoService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.authStateS
      .selectAuthenticatedUser()
      .pipe(
        filter(r => Boolean(r)),
        takeUntil(this.destroy$),
      )
      .subscribe(u => (this.userId = u?.userId as string));
    this.initSdk();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private initSdk(): void {
    if (!this.facebookLoginS.isInited && !this.facebookLoginS.inProcess) {
      this.initFbSdk();
    }
  }

  private initFbSdk(): void {
    this.facebookLoginS.init().then(
      () => {
        //
      },
      err => {
        console.log('ERROR FB. init failed ' + err);
        this.connectionInProgress = false;
        this.actionInProgress.emit(false);
      },
    );
  }

  disconnect(): void {
    this.actionInProgress.emit(true);
    this.connectionInProgress = true;
    this.facebookLoginS
      .disconnect(this.accountId as string, this.userId)
      .subscribe(
        () => {
          this.actionInProgress.emit(false);
          this.disconnected?.emit(true);
          this.connectionInProgress = false;
          if (this.showDisconnectSuccessMsg) {
            this.messageS.clear();
            this.messageS.add({
              severity: 'success',
              life: 7 * 1000,
              detail:
                this.tS.translate(Channels.INSTAGRAM) +
                ' ' +
                this.tS.translate('common.disconnected'),
            });
          }
        },
        () => {
          this.showDisconnectErrorMsg = true;
          this.connectionInProgress = false;
          this.disconnected?.emit(false);
          this.actionInProgress.emit(false);
        },
      );
  }

  connect(): void {
    if (this.popupFacebookSocialMsg) {
      this.showFacebookSocialMsg = true;
    } else {
      this.socialConnect();
    }
  }

  popupSocialMsgClosed(result: boolean): void {
    this.showFacebookSocialMsg = false;
    if (result) {
      this.socialConnect();
    } else {
      // console.log('user canceled the flow');
    }
  }

  socialConnect() {
    this.connectionInProgress = true;
    this.actionInProgress.emit(true);
    if (this.isExpiredFlow) {
      this.expiredFlow();
    } else {
      this.firstConnectFlow();
    }
  }

  private expiredFlow(): void {
    this.facebookLoginS
      .connectIgAccount()
      .then(authResponse => {
        this.facebookLoginS.authCodeFb(authResponse.accessToken).subscribe(
          () => {
            this.actionInProgress.emit(false);
            this.connected.emit(true);
            this.connectionInProgress = false;
            this.messageS.clear();
            this.connectedMsg();
          },
          err => {
            this.connected.emit(false);
            this.actionInProgress.emit(false);
            this.connectionInProgress = false;
            let errMsg;
            if (err.status === 412) {
              errMsg = this.tS.translate(
                'connect-socials.ig-token-412-err-msg',
              );
            } else {
              errMsg =
                this.tS.translate(Channels.INSTAGRAM) +
                '. ' +
                'Connection Error ';
            }
            this.messageS.add({
              severity: 'error',
              life: 7 * 1000,
              detail: errMsg,
            });
          },
        );
      })
      .catch(err => {
        this.actionInProgress.emit(false);
        this.connectionInProgress = false;
        console.error('FB. error ' + err);
      });
  }

  private firstConnectFlow(): void {
    this.facebookLoginS.connectIgAccount().then(
      authResponse => {
        this.handleFacebookResponse(authResponse.accessToken);
      },
      err => {
        this.actionInProgress.emit(false);
        this.connectionInProgress = false;
        console.error('FB. error ' + err);
      },
    );
  }

  private handleFacebookResponse(accessToken: string): void {
    let errorMsg = DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG;
    this.facebookLoginS
      .authCodeFb(accessToken)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.actionInProgress.emit(false);
          this.connectionInProgress = false;
          if (err.status === 412) {
            errorMsg = DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG_412;
          }
          return throwError(err);
        }),
        switchMap(() => this.facebookLoginS.getFbAccounts()),
        switchMap((accounts: Array<FacebookAccount>): any => {
          if (accounts?.length === 0) {
            errorMsg = DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG_PAGES;
            return throwError(new Error('Instagram. Not accounts'));
          } else if (accounts?.length === 1) {
            return this.connectInstagram(accounts[0].id);
          } else {
            this.accounts = accounts.map(i => ({
              id: i.id,
              title: i.name,
              image: i.image,
            }));
            this.showAccountSelectionModal = true;
            return of(null);
          }
        }),
      )
      .subscribe(
        isConnected => {
          if (isConnected) {
            this.actionInProgress.emit(false);
            this.connected.emit(true);
            this.connectionInProgress = false;
            this.messageS.clear();
            this.connectedMsg();
          }
        },
        () => {
          this.actionInProgress.emit(false);
          this.connectionInProgress = false;
          this.uiStateS.showSliceModal(errorMsg);
        },
      );
  }

  private connectedMsg(): void {
    this.messageS.add({
      severity: 'success',
      life: 7 * 1000,
      detail:
        this.tS.translate(Channels.INSTAGRAM) +
        ' ' +
        this.tS.translate('common.connected'),
    });
  }

  private connectInstagram(id: string): Observable<boolean> {
    this.actionInProgress.emit(true);
    this.connectionInProgress = true;
    return this.facebookLoginS
      .postPageId(this.authStateS.getAuthenticatedUser()?.userId as string, id)
      .pipe(
        catchError(err => {
          this.actionInProgress.emit(false);
          this.connectionInProgress = false;
          return throwError(err);
        }),
        tap(() => {
          this.actionInProgress.emit(false);
          this.connectionInProgress = false;
        }),
        map(() => true),
      );
  }

  accountSelected(acc: { account: ChannelAccountData | null }): void {
    if (acc.account) {
      this.showAccountSelectionModal = false;
      this.connectionInProgress = true;
      this.actionInProgress.emit(true);
      this.connectInstagram(acc.account?.id as string).subscribe(
        isConnected => {
          if (isConnected) {
            this.actionInProgress.emit(false);
            this.connected.emit(true);
            this.connectionInProgress = false;
            if (this.showConnectSuccessMsg) {
              this.messageS.clear();
              this.messageS.add({
                severity: 'success',
                life: 7 * 1000,
                detail:
                  this.tS.translate(Channels.INSTAGRAM) +
                  ' ' +
                  this.tS.translate('common.connected'),
              });
            }
          }
        },
        () => {
          this.connectionInProgress = false;
          this.actionInProgress.emit(false);
          this.uiStateS.showSliceModal(
            DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_IG,
          );
        },
      );
    } else {
      this.showAccountSelectionModal = false;
      this.actionInProgress.emit(false);
      this.connectionInProgress = false;
    }
  }
}
