import { ENVIRONMENTS, PlanTypes } from '@slc-libs/enums';
export type PlansIdsMap = Record<PlanTypes, string>;
export type PlansIdsMapByEnv = Record<ENVIRONMENTS, PlansIdsMap>;

export const PLANS_IDS: PlansIdsMapByEnv = {
  [ENVIRONMENTS.stage]: {
    [PlanTypes.Free]: 'f1501b75-8154-45fc-bd6b-5d738ec90410',
    [PlanTypes.Lite]: '240d6482-718b-11ee-b962-0242ac120002',
    [PlanTypes.Standard]: 'd8556257-2fe3-45e8-ad9c-355c67715c38',
    [PlanTypes.Plus]: '88d88b67-6a93-4703-b756-0c729108e8bc',
    [PlanTypes.Premium]: '',
  },
  [ENVIRONMENTS.prod]: {
    [PlanTypes.Free]: '72d850d7-5893-41a3-be15-b97341b498c1',
    [PlanTypes.Lite]: 'b0b745ae-1a09-40d5-952d-c3890bafe821',
    [PlanTypes.Standard]: 'd0b37697-b091-4c44-8100-c124c201034d',
    [PlanTypes.Plus]: 'e2da709d-4d9f-4931-a6a4-c37f7d4d6379',
    [PlanTypes.Premium]: '',
  },
  [ENVIRONMENTS.dev]: {
    [PlanTypes.Free]: 'f1501b75-8154-45fc-bd6b-5d738ec90410',
    [PlanTypes.Lite]: '240d6482-718b-11ee-b962-0242ac120002',
    [PlanTypes.Standard]: 'd8556257-2fe3-45e8-ad9c-355c67715c38',
    [PlanTypes.Plus]: '88d88b67-6a93-4703-b756-0c729108e8bc',
    [PlanTypes.Premium]: '',
  },
  [ENVIRONMENTS.demo]: {
    [PlanTypes.Free]: 'f1501b75-8154-45fc-bd6b-5d738ec90410',
    [PlanTypes.Lite]: '240d6482-718b-11ee-b962-0242ac120002',
    [PlanTypes.Standard]: 'd8556257-2fe3-45e8-ad9c-355c67715c38',
    [PlanTypes.Plus]: '88d88b67-6a93-4703-b756-0c729108e8bc',
    [PlanTypes.Premium]: '',
  },
};

export const getPlanIdByEnvAndType = (
  env: ENVIRONMENTS,
  type: PlanTypes,
): string => {
  return PLANS_IDS[env][type];
};
