import { Injectable } from '@angular/core';

import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserRoleTypes, UserTypes } from '@slc-libs/enums';

import { RoleAccessEnum } from './role-access.enum';
import { RoleMenuEnum } from './role-menu.enum';
import { RolePermissionModel, RoleUserModel } from './role-user';

@Injectable()
export class RolePermissionService {
  private userRoleData$ = new BehaviorSubject<RoleUserModel | null>(null);

  public setRoleJWT(token: string): void {
    try {
      const userInfo = jwt_decode(token);
      this.setRoleData(userInfo);
    } catch (Error) {
      alert('Invalid JWT Token');
    }
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  public setRoleData(v: any): void {
    let permission: RolePermissionModel[];
    if (v.permissions && v.permissions[0]) {
      permission = [];
      v.permissions.forEach((d: RoleMenuEnum) => {
        permission.push({
          menu: d,
          access: [RoleAccessEnum.all], // default all
          subAccess: [],
        });
      });
    } else {
      permission = [
        {
          menu: RoleMenuEnum.creators, // default creators role
          access: [RoleAccessEnum.all],
          subAccess: [],
        },
      ];
    }

    // adjust code below to match the API response with AuthenticatedUser Model
    v.permissions = permission;
    v.user_role = v.role || UserRoleTypes.ADMIN;
    v.user_type = v.user_type || UserTypes.MANAGER;
    this.userRoleData$.next(v);
  }
  public resetRoleData(): void {
    this.userRoleData$.next(null);
  }

  public checkUserRole(role: string): boolean {
    if (!this.userRoleData$.value) {
      console.log('no user role data');
      return false;
    }
    return this.userRoleData$.value?.user_role === role;
  }
  public checkUserType(type: UserTypes): boolean {
    if (!this.userRoleData$.value) {
      console.log('no user type data');
      return false;
    }
    return this.userRoleData$.value?.user_type === type;
  }
  public checkUserPermission(
    menu: RoleMenuEnum,
    access: RoleAccessEnum = RoleAccessEnum.all,
  ): boolean {
    if (!this.userRoleData$.value) {
      console.log('no user permission data');
      return false;
    }
    const permission = this.userRoleData$.value.permissions.find(
      p => p.menu === menu,
    );
    if (!permission) {
      return false;
    }
    if (permission.access.includes(RoleAccessEnum.all)) {
      return true;
    }
    return permission.access.includes(access);
  }

  /*public checkUserRole$(role: string): Observable<boolean> {
    return this.userRoleData$
      .asObservable()
      .pipe(map(d => d?.user_role === role));
  }
  public checkUserType$(type: UserTypes): Observable<boolean> {
    return this.userRoleData$
      .asObservable()
      .pipe(map(d => d?.user_type === type));
  }
  public checkUserPermission$(
    menu: RoleMenuEnum,
    access: RoleAccessEnum = RoleAccessEnum.all,
  ): Observable<boolean> {
    return this.userRoleData$.asObservable().pipe(
      map(d => {
        if (!d) {
          return false;
        }
        const permission = d.permissions.find(p => p.menu === menu);
        if (!permission) {
          return false;
        }
        if (permission.access.includes(RoleAccessEnum.all)) {
          return true;
        }
        return permission.access.includes(access);
      }),
    );
  }*/
}
