import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class ChunkErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedRegex = /ChunkLoadError: Loading chunk .* failed/;

    if (chunkFailedRegex.test(error.message)) {
      console.log('ERROR: Server chunk is updated, reloading the page ...');
      window.location.reload();
    }
  }
}
