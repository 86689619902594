<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M21.0133 21.4733V9.84943L12.17 2.87332L3.32671 9.84943V21.4733H21.0133ZM23.54 22.7367C23.54 23.0717 23.4069 23.3931 23.17 23.63C22.9331 23.8669 22.6117 24 22.2767 24H2.06338C1.72832 24 1.40699 23.8669 1.17007 23.63C0.933149 23.3931 0.800049 23.0717 0.800049 22.7367V9.23672C0.799973 9.04589 0.843127 8.85753 0.926272 8.68577C1.00942 8.51401 1.13039 8.36331 1.28011 8.245L11.3868 0.272119C11.6098 0.0958729 11.8858 0 12.17 0C12.4543 0 12.7303 0.0958729 12.9533 0.272119L23.0599 8.24374C23.2098 8.36218 23.3309 8.51308 23.4141 8.68507C23.4972 8.85707 23.5403 9.04568 23.54 9.23672V22.7367ZM5.85337 12.63H8.38003C8.38003 13.6352 8.77934 14.5992 9.4901 15.31C10.2009 16.0207 11.1649 16.42 12.17 16.42C13.1752 16.42 14.1392 16.0207 14.85 15.31C15.5607 14.5992 15.96 13.6352 15.96 12.63H18.4867C18.4867 14.3053 17.8212 15.912 16.6366 17.0966C15.452 18.2812 13.8453 18.9467 12.17 18.9467C10.4947 18.9467 8.88808 18.2812 7.70348 17.0966C6.51887 15.912 5.85337 14.3053 5.85337 12.63Z"
    fill="#434DDB"
  />
</svg>
