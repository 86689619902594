<button class="ml-2" type="button" (click)="openDialog()">
  <slc-svg-icon-file-detail></slc-svg-icon-file-detail>
</button>

<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
>
  <p-header>
    <slice-breadcrumbs-without-links
      [items]="breadcrumbs"
      [highlightLast]="false"
    ></slice-breadcrumbs-without-links>
  </p-header>

  <div class="no-connected" *ngIf="!channelAccountConnected">
    <p>
      This creator already submitted the post, but you can't see it because
      their {{ channel }} account already
      <span class="font-weight-bold">Disconnected</span> from their Slice
      account
    </p>
  </div>
  <div class="slc-pos-rel" *ngIf="channelAccountConnected">
    <main *ngIf="!loading; else loadingTmpl">
      <div *ngIf="data; else emptyTmpl" class="row">
        <div class="item">
          <div class="img-wrap">
            <div
              class="image-container"
              [ngStyle]="{
                'background-image': 'url(' + data.mediaUrl + ')'
              }"
            ></div>
            <div class="h-100 d-flex align-items-end">
              <a
                href="{{ data.postUrl }}"
                target="_blank"
                class="btn btn-outline-primary mx-3 my-2"
                >Go to content</a
              >
            </div>
          </div>
          <p>
            {{ 'enums.post-types.' + postType | transloco }} -
            {{ data.caption }}
          </p>
        </div>
      </div>
      <ng-template #emptyTmpl>
        <div *ngIf="channel === Channels.INSTAGRAM" class="no-story">
          <img src="assets/images/empty_preview.png" alt="empty" />
          <h5>No Preview Available</h5>
          <p>
            Don't worry! We have all the performance data saved and it's being
            displayed in the report. Instagram does not allow us to preview
            stories after 24 hours. Sorry!
          </p>
        </div>
      </ng-template>
    </main>

    <ng-template #loadingTmpl>
      <slice-loader class="slc-fluid-box"></slice-loader>
    </ng-template>
  </div>
</p-dialog>
