import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'slice-pay-creators-home-modal',
  templateUrl: './pay-creators-home-modal.component.html',
  styleUrls: ['./pay-creators-home-modal.component.scss'],
})
export class PayCreatorsHomeModalComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() title? = 'New Payments';
  @Output() closed = new EventEmitter<any>();
  @Output() campaignPaymentCallback = new EventEmitter<any>();
  @Output() individualPaymentCallback = new EventEmitter<any>();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.isVisible = true;
  }

  hide(): void {
    this.closed.emit(false);
  }

  campaignPaymentClicked(): void {
    this.campaignPaymentCallback.emit();
  }
  individualPaymentClicked(): void {
    this.individualPaymentCallback.emit();
  }
}
