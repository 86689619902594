import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CreatorAnalyticsHttpService } from '@slice-services/creator-analytics-http.service';
import { PostTypesService } from '@slice-services/post-types.service';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Channels, PostTypes } from '@slc-libs/enums';

import { CreatorAnalyticsParams } from '@slice-interfaces/creator-analytics/creator-analytics-params';
import {
  CreatorIGAnalyticsResponse,
  CreatorTKAnalyticsResponse,
  CreatorYtAnalyticsResponse,
} from '@slice-interfaces/creator-analytics/creator-analytics-response';
import {
  InfoWidgetModel,
  ReportsInfoWidgets,
} from '@slice-enums/reports-info-list';

import {
  createIgPerformanceWidget,
  createTkPerformanceWidget,
  createYtPerformanceWidget,
} from './widget-past-performance';

@Component({
  selector: 'slice-creator-past-performance',
  templateUrl: './creator-performance.component.html',
  styleUrls: ['./creator-performance.component.scss'],
})
export class CreatorPerformanceComponent implements OnInit {
  @Input() userId: string;
  @Input() userChannels: Array<Channels>;
  @Input() isLoading: boolean;
  @Output() isLoadingChange = new EventEmitter<boolean>();

  groupedPostType: SelectItemGroup[];
  selectedGroupItem?: SelectItem;
  selectedChannel?: Channels;
  selectedPost?: PostTypes;
  public widgets$: Observable<Array<Array<InfoWidgetModel>>>;
  private widgets: Array<InfoWidgetModel>;
  public gridMap: any = {
    1: 'col-12',
    2: 'col-6',
    3: 'col-4',
    4: 'col-3',
  };
  dropdownCampaign: SelectItem[];
  selectedCampaign?: SelectItem;

  constructor(
    private postTypesS: PostTypesService,
    private httpS: CreatorAnalyticsHttpService,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  }

  ngOnInit(): void {
    this.initPostTypes(this.userChannels);
    this.initCampaigns(this.userId);
    this.initMapData();
  }

  initPostTypes(channelList: Array<Channels>) {
    if (this.userChannels && this.userChannels.length) {
      this.postTypesS
        .getGroupedPostTypesByChannels$(channelList)
        .pipe(
          map((r: SelectItemGroup[]) => {
            r.forEach((s: SelectItemGroup) => {
              if (s.value === Channels.INSTAGRAM) {
                s.items = s.items.filter(
                  i =>
                    i.value === PostTypes.REELS ||
                    // i.value === PostTypes.STORIES ||
                    i.value === PostTypes.POST ||
                    i.value === PostTypes.CAROUSEL_ALBUM,
                );
              } else if (s.value === Channels.YOUTUBE) {
                s.items = s.items.filter(
                  i =>
                    i.value === PostTypes.HOST_READ_AD ||
                    i.value === PostTypes.PRODUCT_PLACEMENT ||
                    i.value === PostTypes.PRODUCT_REVIEW,
                );
              } else if (s.value === Channels.TIKTOK) {
                s.items = s.items.filter(
                  i => i.value === PostTypes.TIKTOK_VIDEO,
                );
              }
            });
            return r;
          }),
        )
        .subscribe(list => {
          this.groupedPostType = list;
          this.selectedPost = undefined;
        });
    } else {
      console.log('no userChannels data for this user');
      this.groupedPostType = [];
      this.selectedPost = undefined;
    }
  }

  initCampaigns(userId: string) {
    if (userId) {
      // this.httpS.getCampaignsByUserId(userId).subscribe(list => {
      this.dropdownCampaign = [
        { label: 'General Overview', value: '' },
        { label: 'Australia', value: 'AU' },
        { label: 'Brazil', value: 'BR' },
        { label: 'China', value: 'CN' },
        { label: 'Egypt', value: 'EG' },
        { label: 'France', value: 'FR' },
        { label: 'Germany', value: 'DE' },
        { label: 'India', value: 'IN' },
        { label: 'Japan', value: 'JP' },
        { label: 'Spain', value: 'ES' },
        { label: 'United States', value: 'US' },
      ];
      this.selectedCampaign = { label: 'General Overview', value: '' };
      // });
    } else {
      console.log('no userId data for this user');
      this.dropdownCampaign = [];
      this.selectedCampaign = undefined;
    }
  }

  initMapData(): void {
    this.widgets = [
      {
        type: ReportsInfoWidgets.reach,
        titleKey: 'reporting.info-widget.reach.title',
      },
      {
        type: ReportsInfoWidgets.impressions,
        titleKey: 'reporting.info-widget.impressions.title',
      },
      {
        type: ReportsInfoWidgets.subscribers,
        titleKey: 'reporting.info-widget.subscribers.title',
      },
      {
        type: ReportsInfoWidgets.totalClicks,
        titleKey: 'reporting.info-widget.total-clicks.title',
      },
      {
        type: ReportsInfoWidgets.costPerReach,
        titleKey: 'reporting.info-widget.cpr.title',
        titleBlue: ' (IDR)',
      },
      {
        type: ReportsInfoWidgets.costPerView,
        titleKey: 'reporting.info-widget.cpv.title',
        titleBlue: ' (IDR)',
      },
      {
        type: ReportsInfoWidgets.costPerImpression,
        titleKey: 'reporting.info-widget.cpi.title',
        titleBlue: ' (IDR)',
      },
      {
        type: ReportsInfoWidgets.costPerEngagement,
        titleKey: 'reporting.info-widget.cpe.title',
        titleBlue: ' (IDR)',
      },
      {
        type: ReportsInfoWidgets.potentialReach,
        titleKey: 'reporting.info-widget.potential-reach.title',
      },
      {
        type: ReportsInfoWidgets.reachRate,
        titleKey: 'reporting.info-widget.reach-rate.title',
        isPercentVal: true,
      },
      {
        type: ReportsInfoWidgets.averageViewDuration,
        titleKey: 'reporting.info-widget.average-view-duration.title',
      },
      {
        type: ReportsInfoWidgets.engagementRate,
        titleKey: 'reporting.info-widget.er.title',
        isPercentVal: true,
      },
      {
        type: ReportsInfoWidgets.engagementRateByReach,
        titleKey: 'reporting.info-widget.engagement-rate.by',
        titleBlue: 'reporting.info-widget.engagement-rate.reach',
        isPercentVal: true,
      },
      {
        type: ReportsInfoWidgets.engagementRateByFollower,
        titleKey: 'reporting.info-widget.engagement-rate.by',
        titleBlue: 'reporting.info-widget.engagement-rate.followers',
        isPercentVal: true,
      },
      {
        type: ReportsInfoWidgets.followers,
        titleKey: 'reporting.info-widget.followers.title',
      },
      {
        type: ReportsInfoWidgets.likes,
        titleKey: 'reporting.info-widget.likes.title',
      },
      {
        type: ReportsInfoWidgets.comments,
        titleKey: 'reporting.info-widget.comments.title',
      },
      {
        type: ReportsInfoWidgets.shares,
        titleKey: 'reporting.info-widget.shares.title',
      },
      {
        type: ReportsInfoWidgets.saves,
        titleKey: 'reporting.info-widget.saves.title',
      },
      {
        type: ReportsInfoWidgets.clickRate,
        titleKey: 'reporting.info-widget.click-rate.title',
        isPercentVal: true,
      },
      {
        type: ReportsInfoWidgets.totalEngagement,
        titleKey: 'reporting.info-widget.total-engagements.title',
      },
      {
        type: ReportsInfoWidgets.totalWatchTime,
        titleKey: 'reporting.info-widget.total-watch-time.title',
      },
      {
        type: ReportsInfoWidgets.views,
        titleKey: 'reporting.info-widget.views.title',
      },
    ];
  }

  private getWidgetListBySocial(
    social: Channels | undefined,
    postType: PostTypes | undefined,
  ): Array<Array<ReportsInfoWidgets>> {
    if (social === Channels.YOUTUBE) {
      return createYtPerformanceWidget(postType);
    } else if (social === Channels.INSTAGRAM) {
      return createIgPerformanceWidget(postType);
    } else {
      return createTkPerformanceWidget(postType);
    }
  }

  initWidgets(
    social: Channels | undefined,
    postType: PostTypes | undefined,
    info: any,
    // CreatorIGAnalyticsResponse | CreatorYtAnalyticsResponse | CreatorTKAnalyticsResponse,
  ): Array<Array<InfoWidgetModel>> {
    const wdgtList = this.getWidgetListBySocial(social, postType);
    return wdgtList.map(i => {
      return i.map(j => {
        const wdgt = this.createWidgetByType(j, info);
        return wdgt;
      });
    });
  }

  createWidgetByType(
    type: ReportsInfoWidgets,
    info: any,
    // CreatorIGAnalyticsResponse | CreatorYtAnalyticsResponse | CreatorTKAnalyticsResponse,
  ): InfoWidgetModel {
    const wdgt = JSON.parse(
      JSON.stringify(this.widgets.find(i => i.type === type)),
    ) as InfoWidgetModel;
    if (type === ReportsInfoWidgets.reach) {
      wdgt.value = info?.reach;
    } else if (type === ReportsInfoWidgets.impressions) {
      wdgt.value = info?.impressions;
    } else if (type === ReportsInfoWidgets.subscribers) {
      wdgt.value = info?.followers;
    } else if (type === ReportsInfoWidgets.totalClicks) {
      wdgt.value = info?.totalSpend;
    } else if (type === ReportsInfoWidgets.costPerReach) {
      wdgt.value = info?.cpr;
    } else if (type === ReportsInfoWidgets.costPerView) {
      wdgt.value = info?.cpv;
    } else if (type === ReportsInfoWidgets.costPerImpression) {
      wdgt.value = info?.cpi;
    } else if (type === ReportsInfoWidgets.costPerEngagement) {
      wdgt.value = info?.cpe;
    } else if (type === ReportsInfoWidgets.potentialReach) {
      wdgt.value = info?.potentialReach;
    } else if (type === ReportsInfoWidgets.reachRate) {
      wdgt.value = info?.reachRate;
    } else if (type === ReportsInfoWidgets.averageViewDuration) {
      wdgt.value = info?.avgViewDuration;
    } else if (type === ReportsInfoWidgets.engagementRate) {
      wdgt.value = info?.engagementRate;
    } else if (type === ReportsInfoWidgets.engagementRateByReach) {
      wdgt.value = info?.engagementRateByReach;
    } else if (type === ReportsInfoWidgets.engagementRateByFollower) {
      wdgt.value = info?.engagementRateByFollower;
    } else if (type === ReportsInfoWidgets.followers) {
      wdgt.value = info?.followers;
    } else if (type === ReportsInfoWidgets.likes) {
      wdgt.value = info?.likes;
    } else if (type === ReportsInfoWidgets.comments) {
      wdgt.value = info?.comments;
    } else if (type === ReportsInfoWidgets.shares) {
      wdgt.value = info?.shares;
    } else if (type === ReportsInfoWidgets.saves) {
      wdgt.value = info?.saves;
    } else if (type === ReportsInfoWidgets.clickRate) {
      wdgt.value = info?.ctr;
    } else if (type === ReportsInfoWidgets.totalEngagement) {
      wdgt.value = info?.totalEngagements;
    } else if (type === ReportsInfoWidgets.totalWatchTime) {
      wdgt.value = info?.watchTime;
    } else if (type === ReportsInfoWidgets.views) {
      wdgt.value = info?.view;
    }

    return wdgt;
  }

  updateDropdown() {
    if (this.selectedGroupItem) {
      this.selectedChannel = this.selectedGroupItem.title as Channels;
      this.selectedPost = this.selectedGroupItem.value;
    }
    // console.log(this.selectedCampaign, this.selectedChannel, this.selectedPost);
    if (this.selectedChannel && this.selectedPost) this.fetchData();
  }

  fetchData() {
    const params: CreatorAnalyticsParams = {
      userId: this.userId,
      channel: this.selectedChannel as Channels,
      postType: this.selectedPost as PostTypes,
      campaignId: this.selectedCampaign?.value as string,
    };
    this.isLoadingChange.emit(true);
    this.widgets$ = this.httpS.getCreatorAnalytics(params).pipe(
      map(info => {
        this.isLoadingChange.emit(false);
        return this.initWidgets(this.selectedChannel, this.selectedPost, info);
      }),
      catchError(err => {
        this.isLoadingChange.emit(false);
        return of([]);
      }),
    );
  }
}
