<div [ngSwitch]="type">
  <div *ngSwitchCase="balanceTypes.SupportBalance">
    <div class="secondary shadow-sm">
      <!-- TODO ADD DYNAMIC COL -->
      <div class="row">
        <div class="col-md-4 col-12" *ngFor="let data of datas">
          <div class="d-flex flex-row">
            <div
              class="green-line vr"
              [style.background-color]="data.color"
              [style.height]="'48px'"
            >
              |
            </div>
            <div class="mx-2">
              <div style="height: 28px" class="d-flex align-items-center">
                <div class="align-self-center">
                  {{ data.title }}
                </div>
              </div>
              <div style="height: 24px" class="d-flex align-items-center">
                <span class="balance-value text-left">
                  {{ data.number | number : '1.0-2' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="balanceTypes.AllBalance">
    <div class="secondary shadow-sm mb-2">
      <div class="d-flex flex-row justify-content-between">
        <div class="sub-title">My Balance (IDR)</div>
        <div class="sub-title font-weight-bold">
          {{ data?.totalBalance || 0 | number : '1.0-2' }}
        </div>
      </div>
    </div>
    <div class="content-balance shadow-sm px-2 flex-row-reverse">
      <div class="d-flex">
        <div class="d-flex">
          <div class="green-line vr" [style.background-color]="color">|</div>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex">
            <span class="balance-title">{{ title }}</span>
            <div class="ml-auto">
              <slice-balance-detail-button></slice-balance-detail-button>
              <!-- <div class="btns" (click)="buttonFunction()">Detail</div> -->
            </div>
          </div>
          <span class="sub-title w-75">{{ subTitle }}</span>
          <span class="balance-value2 green">
            {{ data?.campaignBalance || 0 | number : '1.0-2' }}
          </span>
        </div>
      </div>
      <div class="d-flex">
        <div class="d-flex">
          <div class="green-line vr" [style.background-color]="'#434DDB'">
            |
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex">
            <span class="balance-title">General Balance</span>
            <div class="ml-auto">
              <slice-move-balance
                [disabled]="disableMoveBalance"
              ></slice-move-balance>
            </div>
          </div>
          <span class="sub-title w-75"
            >Amount eligible for Bank Payments, and Contracts creation.</span
          >
          <span class="balance-value2">
            {{ data?.generalBalance || 0 | number : '1.0-2' }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="balanceTypes.GeneralBalanceOnly">
    <div class="content-balance shadow-sm">
      <div class="main-content d-flex">
        <div class="d-flex">
          <div class="green-line vr" [style.background-color]="'#434DDB'">
            |
          </div>
        </div>
        <div class="d-flex flex-column flex-grow-1">
          <div class="d-flex">
            <span class="balance-title">General Balance</span>
          </div>
          <span class="sub-title"
            >Amount eligible for Bank Payments, and Contracts creation.</span
          >
          <span class="balance-value2">
            {{ data?.generalBalance || 0 | number : '1.0-2' }}
          </span>
        </div>
        <div class="mr-24">
          <slice-move-balance
            [disabled]="disableMoveBalance"
          ></slice-move-balance>
        </div>
      </div>
    </div>
  </div>

  <div *ngSwitchCase="balanceTypes.CampaignBalanceOnly">
    <div class="content-balance shadow-sm">
      <div class="main-content d-flex">
        <div class="d-flex">
          <div class="green-line vr" [style.background-color]="'#4DBB7B'">
            |
          </div>
        </div>
        <div class="d-flex flex-column flex-grow-1">
          <div class="d-flex">
            <span class="balance-title">Campaign Balance</span>
          </div>
          <span class="sub-title"
            >Amount associated to a specific campaign payment.</span
          >
          <span class="balance-value2">
            {{ data?.campaignBalance || 0 | number : '1.0-2' }}
          </span>
        </div>
        <div class="mr-24">
          <slice-balance-detail-button></slice-balance-detail-button>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="balanceTypes.AllBalanceDetail">
    <div class="secondary shadow-sm d-flex flex-row">
      <div class="mx-2" *ngFor="let data of datas">
        <div class="d-flex flex-row">
          <div
            class="green-line vr"
            [style.background-color]="data.color"
            [style.height]="'48px'"
          >
            |
          </div>
          <div>
            <div style="height: 28px" class="d-flex align-items-center">
              <div class="align-self-center">
                {{ data.title }}
              </div>
            </div>
            <div style="height: 24px" class="d-flex align-items-center">
              <div class="sub-title mt-2">
                {{ data.subTitle }}
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column m-2">
          <!-- <span class="balance-curency">
          {{ data.currency }}
        </span> -->
          <span class="balance-value text-left">
            {{ data.number | number : '1.0-2' }}
          </span>
          <div *ngIf="data.isShowsButtonBalance">
            <slice-move-balance
              [moveFrom]="'campaign'"
              [dataCampaign]="dataCampaign"
              (closed)="this.functionButton?.emit()"
              [disabled]="defaultButtonDisabled()"
            ></slice-move-balance>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="balanceTypes.TermTemplate">
    <div class="secondary shadow-sm">
      <div class="d-flex flex-row p-2">
        <div class="align-self-center" [style.color]="color">{{ title }}</div>
      </div>
      <div style="max-height: 54px; overflow: auto" class="px-2">
        <div [innerHTML]="content"></div>
      </div>
    </div>
  </div>
  <div *ngSwitchDefault>
    <div class="content-balance shadow-sm">
      <div class="d-flex">
        <div class="green-line vr" [style.background-color]="color">|</div>
      </div>
      <div class="d-flex flex-column">
        <span class="balance-title">{{ title }}</span>
        <span class="sub-title">{{ subTitle }}</span>
        <span class="balance-value">
          <ng-container *ngIf="isNumber(number); else notANumber">
            {{ number | number : '1.0-2' }}
          </ng-container>
          <ng-template #notANumber>
            {{ number }}
          </ng-template>
        </span>
      </div>

      <div class="ml-auto mr-3" *ngIf="isShowsButtonBalance">
        <div class="btns" (click)="buttonFunction()">{{ btnLabel }}</div>
      </div>
    </div>
  </div>
</div>

<!-- <slice-shared-balance-detail
  [isLoading]="isLoading"
  [data]="data"
  [dataList]="dataList"
  (closed)="closeModal()"
>
</slice-shared-balance-detail> -->
