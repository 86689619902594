<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M10.0004 0.399902C4.69879 0.399902 0.400391 4.6983 0.400391 9.9999C0.400391 15.3015 4.69879 19.5999 10.0004 19.5999C15.302 19.5999 19.6004 15.3015 19.6004 9.9999C19.6004 4.6983 15.302 0.399902 10.0004 0.399902ZM10.0004 15.9999C9.33799 15.9999 8.80039 15.4623 8.80039 14.7999C8.80039 14.1375 9.33799 13.5999 10.0004 13.5999C10.6628 13.5999 11.2004 14.1375 11.2004 14.7999C11.2004 15.4623 10.6628 15.9999 10.0004 15.9999ZM11.6548 10.3999C11.1252 10.8887 10.8004 11.2095 10.8004 11.5999H9.20039C9.20039 10.4887 9.95959 9.7879 10.5692 9.2239C11.2084 8.6335 11.6004 8.2391 11.6004 7.5999C11.6004 6.7175 10.8828 5.9999 10.0004 5.9999C9.11799 5.9999 8.40039 6.7175 8.40039 7.5999H6.80039C6.80039 5.8351 8.23559 4.3999 10.0004 4.3999C11.7652 4.3999 13.2004 5.8351 13.2004 7.5999C13.2004 8.9719 12.3436 9.7631 11.6548 10.3999Z"
    fill="#5D6468"
  />
</svg>
