<ng-select
  [items]="list"
  [searchable]="false"
  [clearable]="false"
  bindValue="code"
  [(ngModel)]="currLang"
  (change)="changeLang($event)"
>
  <ng-template ng-label-tmp let-item="item">
    <ng-container
      [ngTemplateOutlet]="iconTmpl"
      [ngTemplateOutletContext]="{ code: item.code, title: item.title }"
    >
    </ng-container>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <div class="slc-flex-fs-c">
      <ng-container
        [ngTemplateOutlet]="iconTmpl"
        [ngTemplateOutletContext]="{ code: item.code }"
      >
      </ng-container>
      <span title="{{ item.title }}">{{ item.title }}</span>
    </div>
    <slc-svg-icon-checkmark
      *ngIf="item.code === currLang"
    ></slc-svg-icon-checkmark>
  </ng-template>
</ng-select>

<ng-template #iconTmpl let-code="code" let-title="title">
  <ng-container [ngSwitch]="code">
    <img
      src="/assets/nx-common/engl-us.svg"
      *ngSwitchCase="IsoLanguages.ENGLISH_US"
    />
    <img
      src="/assets/nx-common/bahana-indonesia.svg"
      *ngSwitchCase="IsoLanguages.BAHANA_INDONESIA"
    />
    <ng-container *ngSwitchDefault></ng-container>
  </ng-container>
  <div *ngIf="withTitle">{{ title }}</div>
</ng-template>
