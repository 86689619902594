import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

import { IsoLanguages } from '@slc-libs/types';

interface Item {
  code: IsoLanguages;
  title: string;
}

@Component({
  selector: 'slc-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {
  @Input() currLang: IsoLanguages | null | undefined;
  @Input() withTitle: boolean;
  @Input() languages: Array<IsoLanguages> = [
    IsoLanguages.ENGLISH_US,
    IsoLanguages.BAHANA_INDONESIA,
  ];

  public readonly IsoLanguages = IsoLanguages;
  public list: Array<Item>;

  constructor(private translocoService: TranslocoService) {}
  @Output() changed = new EventEmitter<IsoLanguages>();

  private map: Array<Item> = [
    {
      code: IsoLanguages.BAHANA_INDONESIA,
      title: 'Bahasa Indonesia',
    },
    {
      code: IsoLanguages.ENGLISH_US,
      title: 'English (US)',
    },
  ];

  ngOnInit(): void {
    this.list = this.languages
      .map(lang => this.map.find(i => i.code === lang))
      .filter(i => i) as Array<Item>;
  }

  changeLang(lang: { code: IsoLanguages; title: string }): void {
    this.translocoService.setActiveLang(lang.code);
  }
}
