import { Channels } from '@slc-libs/enums';

export const CALENDAR_CHANNELS: Record<
  Channels,
  { img: string; translateKey: string; color: string }
> = {
  [Channels.INSTAGRAM]: {
    img: '/assets/svg/instagram-icon.svg',
    translateKey: 'instagram',
    color: '#D7DCDE',
  },
  [Channels.YOUTUBE]: {
    img: '/assets/svg/youtube-icon.svg',
    translateKey: 'youtube',
    color: '#FFB4B2',
  },
  [Channels.TIKTOK]: {
    img: '/assets/svg/tiktok-icon.svg',
    translateKey: 'tiktok',
    color: '#FFCDE2',
  },
  [Channels.APPEARANCE]: {
    img: '/assets/svg/person.svg',
    translateKey: 'appearance',
    color: '#FFFFFF',
  },
  // [Channels.ANCHOR]: {
  //   img: '/assets/svg/podcast-icon.svg',
  //   translateKey: 'Anchor',
  //   color: '',
  // },
  // [Channels.PODCAST]: {
  //   img: '/assets/svg/podcast-icon.svg',
  //   translateKey: 'Podcast',
  //   color: '#DACEED',
  // },
  // [Channels.LICENSING]: {
  //   img: '',
  //   translateKey: '',
  //   color: '',
  // },
};

export const CALENDAR_DAY_TRANSLATE_KEY = [
  'day-name.sunday.short',
  'day-name.monday.short',
  'day-name.tuesday.short',
  'day-name.wednesday.short',
  'day-name.thursday.short',
  'day-name.friday.short',
  'day-name.saturday.short',
];

export const CALENDAR_EVENT_CSS_BASE_CLASS = 'slice-calendar-event'; // ! must be equal to css. full version - slice-calendar-event-socials
