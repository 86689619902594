import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconRssComponent } from './rss.component';

@NgModule({
  declarations: [SvgIconRssComponent],
  imports: [CommonModule],
  exports: [SvgIconRssComponent],
})
export class SvgIconRssModule {}
