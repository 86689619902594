import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconFunnelAwarenessComponent } from './funnel-awareness.component';

@NgModule({
  declarations: [SvgIconFunnelAwarenessComponent],
  imports: [CommonModule],
  exports: [SvgIconFunnelAwarenessComponent],
})
export class SvgIconFunnelAwarenessModule {}
