import { Component, Input } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-icon-check-circle',
  styles: [
    ':host { display: inline-flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './check-circle.component.html',
})
export class SvgIconCheckCircleComponent extends AbstractSvgIconComponent {
  @Input() disabled = false;
  @Input() height = 24;
  @Input() width = 24;
}
