import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

import { ChangePinFacadeService } from '../services/change-pin-facade.service';

@Component({
  selector: 'slice-forgot-pin-modal',
  templateUrl: './forgot-pin-modal.component.html',
  styleUrls: ['./forgot-pin-modal.component.scss'],
})
export class ForgotPinModalComponent
  extends AbstractSubscriberComponent
  implements OnInit
{
  isVisible = false;
  isLoading = false;
  email: FormControl;
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private facadeS: ChangePinFacadeService,
    private tS: TranslocoService,
    private mS: MessageService,
  ) {
    super();
    this.facadeS
      .selectIsModalForgotPin()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => (this.isVisible = r));
    this.facadeS
      .selectIsLoading()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => (this.isLoading = r));
  }

  ngOnInit(): void {
    this.initForm();
  }
  initForm(): void {
    this.email = new FormControl('', [Validators.required]);
    this.form = this.fb.group({
      email: this.email,
    });
  }
  triggerHide(): void {
    this.facadeS.setIsModalForgotPin(false);
    this.reset();
  }
  reset(): void {
    this.form.reset();
    this.setVal('');
  }
  setVal(val: any) {
    this.email.setValue(val);
  }
  submit(): void {
    if (this.form.valid) {
      this.facadeS.setIsLoading(true);
      this.facadeS
        .forgotPass(this.form.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.facadeS.setIsLoading(false);
            this.mS.add({
              severity: 'success',
              life: 5 * 1000,
              detail: this.tS.translate(
                'profiles.my.response.forgot-pin.success',
              ),
            });
            this.facadeS.setEmail(this.email.value);
            this.facadeS.setIsModalVerification(true);
            this.triggerHide();
          },
          err => {
            this.facadeS.setIsLoading(false);
            this.mS.clear();
            this.mS.add({
              severity: 'error',
              life: 5 * 1000,
              detail: 'Failed to send code, make sure your email is correct',
            });
          },
        );
    } else {
      this.mS.add({
        severity: 'error',
        life: 5 * 1000,
        detail: 'Please Input the right email',
      });
    }
  }
}
