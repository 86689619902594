<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M7.86537 0.886355C9.03579 -0.292645 10.9465 -0.292645 12.1269 0.877355L12.8572 1.60635C13.1373 1.88635 13.5174 2.04735 13.9176 2.04735H14.9479C16.6085 2.04735 17.959 3.39636 17.959 5.05635V6.08735C17.959 6.48635 18.1191 6.86635 18.3992 7.14635L19.1194 7.86635C19.6897 8.42636 19.9998 9.18635 19.9998 9.98635C20.0098 10.7864 19.6997 11.5474 19.1395 12.1164L18.3992 12.8564C18.1191 13.1364 17.959 13.5164 17.959 13.9174V14.9464C17.959 16.6064 16.6085 17.9574 14.9479 17.9574H13.9176C13.5174 17.9574 13.1373 18.1164 12.8572 18.3964L12.1369 19.1164C11.5467 19.7074 10.7764 19.9964 10.0061 19.9964C9.23586 19.9964 8.46559 19.7074 7.87537 19.1274L7.14511 18.3964C6.86501 18.1164 6.48487 17.9574 6.08472 17.9574H5.05435C3.39375 17.9574 2.04326 16.6064 2.04326 14.9464V13.9174C2.04326 13.5164 1.8832 13.1364 1.6031 12.8464L0.882836 12.1364C-0.287588 10.9674 -0.297592 9.05635 0.872832 7.87735L1.6031 7.14635C1.8832 6.86635 2.04326 6.48635 2.04326 6.07635V5.05635C2.04326 3.39636 3.39375 2.04735 5.05435 2.04735H6.08472C6.48487 2.04735 6.86501 1.88635 7.14511 1.60635L7.86537 0.886355ZM12.5771 11.6964C12.0869 11.6964 11.6968 12.0874 11.6968 12.5664C11.6968 13.0564 12.0869 13.4464 12.5771 13.4464C13.0573 13.4464 13.4474 13.0564 13.4474 12.5664C13.4474 12.0874 13.0573 11.6964 12.5771 11.6964ZM13.1873 6.80635C12.8472 6.46735 12.297 6.46735 11.9569 6.80635L6.81499 11.9464C6.47486 12.2864 6.47486 12.8464 6.81499 13.1864C6.97505 13.3564 7.19513 13.4464 7.42521 13.4464C7.6653 13.4464 7.88538 13.3564 8.04543 13.1864L13.1873 8.04735C13.5274 7.70735 13.5274 7.14636 13.1873 6.80635ZM7.43521 6.55635C6.95504 6.55635 6.55489 6.94635 6.55489 7.42635C6.55489 7.91736 6.95504 8.30635 7.43521 8.30635C7.91539 8.30635 8.30553 7.91736 8.30553 7.42635C8.30553 6.94635 7.91539 6.55635 7.43521 6.55635Z"
    fill="#5D6468"
  />
</svg>
