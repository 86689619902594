export enum CategoryType {
  GENERIC = 'generic',
  USER_SPECIFIC = 'user_specific',
}

export enum Categories {
  CONTENT = 'CONTENT',
  BRAND = 'BRAND',
  HASHTAG = 'HASHTAG',
}
