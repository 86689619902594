<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M23.97 12.0527C23.97 5.39619 18.6042 0 11.985 0C5.36587 0 0 5.39619 0 12.0527C0 18.0686 4.38274 23.0549 10.1124 23.959V15.5367H7.0693V12.0527H10.1124V9.39737C10.1124 6.37664 11.9016 4.7081 14.6393 4.7081C15.9505 4.7081 17.3221 4.9435 17.3221 4.9435V7.90961H15.8108C14.322 7.90961 13.8577 8.83868 13.8577 9.79185V12.0527H17.1817L16.6503 15.5367H13.8577V23.959C19.5873 23.0549 23.97 18.0686 23.97 12.0527Z"
    fill="white"
  />
</svg>
