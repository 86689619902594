import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

import { ChangePinFacadeService } from '../services/change-pin-facade.service';
import { ChangePinStateService } from '../services/change-pin-state.service';

@Component({
  selector: 'slice-forgot-button',
  templateUrl: './forgot-button.component.html',
  styleUrls: ['./forgot-button.component.scss'],
  providers: [ChangePinStateService, ChangePinFacadeService],
})
export class ForgotButtonComponent
  extends AbstractSubscriberComponent
  implements OnInit
{
  @Output() triggerOpen = new EventEmitter<any>();
  modalVerif: boolean;
  modalForgotPin: boolean;
  modalInputNewPin: boolean;
  constructor(private facadeS: ChangePinFacadeService) {
    super();
  }
  ngOnInit(): void {
    this.facadeS
      .selectIsModalForgotPin()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => (this.modalForgotPin = r));
    this.facadeS
      .selectIsModalVerification()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => (this.modalVerif = r));
    this.facadeS
      .selectIsModalResetPin()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => (this.modalInputNewPin = r));
  }

  openForgotPin(): void {
    // this.triggerOpen?.emit(false);
    this.facadeS.setIsModalForgotPin(true);
  }
}
