import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconRevenueComponent } from './revenue.component';

@NgModule({
  declarations: [SvgIconRevenueComponent],
  imports: [CommonModule],
  exports: [SvgIconRevenueComponent],
})
export class SvgIconRevenueModule {}
