import { getCampaignNameMock } from '@slc-libs/mocks';
import { getPagebaleResponseMock } from '@slc-libs/mocks';
import { getUsernameMock } from '@slc-libs/mocks';

import { CampaignManager } from '@slice-interfaces/campaign-manager/campaign-manager';
import { CampaignManagerFilters } from '@slice-interfaces/campaign-manager/filters';
import { CampaignManagerResponse } from '@slice-interfaces/campaign-manager/get-list';
import { CampaignModelStatus } from '@slice-enums/campaign-status';

import { getImageMock } from './_helpers/images';

const _getCampaignManager = (
  params: CampaignManagerFilters,
  res: CampaignManagerResponse,
): Array<CampaignManager> => {
  const list = [];
  const statuses = (
    params.status ? [params.status] : Object.values(CampaignModelStatus)
  ).filter((i: CampaignModelStatus) => i !== CampaignModelStatus.COMPLETED);
  let order: any = [];

  for (
    let i = 0;
    i < (res.last ? res.totalElements - res.number * res.size : params.size);
    i++
  ) {
    if (order.length === 0) {
      order = [...statuses];
    }
    const st = order[0];
    order.shift();
    list.push({
      creatorName: getUsernameMock(),
      creatorAvatar: getImageMock(),
      campaignName: getCampaignNameMock(),
      dueDate: new Date().toString(),
      status: st,
    });
  }
  return list;
};

export const getCampaignManagerMocks = (
  params: CampaignManagerFilters,
): CampaignManagerResponse => {
  const res = getPagebaleResponseMock(params);
  res.content = _getCampaignManager(params, res as any);
  return res as CampaignManagerResponse;
};
