import { Channels, PostTypes } from '@slc-libs/enums';
import { getNumberMock, getUsernameMock } from '@slc-libs/mocks';
import { getPagebaleResponseMock } from '@slc-libs/mocks';

import { PublicAgencyClientsRosterFilters } from '@slice-interfaces/public-agency-client-roster/public-agency-client-roster-filters';
import { PublicAgencyClientsRosterItem } from '@slice-interfaces/public-agency-client-roster/public-agency-client-roster-item';
import { PublicAgencyClientsRosterResponse } from '@slice-interfaces/public-agency-client-roster/public-agency-client-roster-response';

import { getRandomMockAvatar } from '../../_mocks/_helpers/images';

const _getList = (
  params: PublicAgencyClientsRosterFilters,
  res: PublicAgencyClientsRosterResponse,
): Array<PublicAgencyClientsRosterItem> => {
  const list: Array<PublicAgencyClientsRosterItem> = [];

  for (
    let i = 0;
    i < (res.last ? res.totalElements - res.number * res.size : params.size);
    i++
  ) {
    list.push({
      name: getUsernameMock(),
      avatar: getRandomMockAvatar(),
      recentBrands: (() => {
        const res = [];
        const amount = getNumberMock(30);
        for (let i = 0; i < amount; i++) {
          res.push(getUsernameMock());
        }
        return res;
      })(),
      postPrices: [
        {
          channel: Channels.INSTAGRAM,
          postType: PostTypes.POST,
          price: getNumberMock(100000),
        },
        {
          channel: Channels.INSTAGRAM,
          postType: PostTypes.STORIES,
          price: getNumberMock(100000),
        },
        {
          channel: Channels.INSTAGRAM,
          postType: PostTypes.VIDEO,
          price: getNumberMock(100000),
        },
        {
          channel: Channels.INSTAGRAM,
          postType: PostTypes.REELS,
          price: getNumberMock(100000),
        },

        {
          channel: Channels.YOUTUBE,
          postType: PostTypes.HOST_READ_AD,
          price: getNumberMock(100000),
        },
        {
          channel: Channels.YOUTUBE,
          postType: PostTypes.PRODUCT_REVIEW,
          price: getNumberMock(100000),
        },
        {
          channel: Channels.YOUTUBE,
          postType: PostTypes.PRODUCT_PLACEMENT,
          price: getNumberMock(100000),
        },
        {
          channel: Channels.YOUTUBE,
          postType: PostTypes.CAROUSEL_ALBUM,
          price: getNumberMock(100000),
        },
      ],
      channelStats: [
        {
          channel: Channels.INSTAGRAM,
          followers: getNumberMock(1000000000),
          engagementRate: getNumberMock(100),
          cpr: getNumberMock(100),
          cpe: getNumberMock(100),
        },
        {
          channel: Channels.YOUTUBE,
          followers: getNumberMock(1000000000),
          engagementRate: getNumberMock(100),
          cpr: getNumberMock(100),
          cpe: getNumberMock(100),
        },
      ],
    });
  }
  return list;
};

export const getPublicAgencyClientsRosterResponseMocks = (
  params: PublicAgencyClientsRosterFilters,
): PublicAgencyClientsRosterResponse => {
  const res = getPagebaleResponseMock(params, 0);
  res.content = _getList(params, res as any);
  return res as PublicAgencyClientsRosterResponse;
};
