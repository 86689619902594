import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { ChannelMediaHttpService } from '@slice-services/channel-media-http.service';
import { takeUntil } from 'rxjs/operators';

import { Channels, PostTypes } from '@slc-libs/enums';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { BreadcrumbsWithoutLinksItem } from '@slice-interfaces/breadcrumbs-without-links';
import { ChannelMediaModel } from '@slice-interfaces/media/channel-medias.intf';

import { AssetsTitleKeyMap } from '../../../_const/assets-titles';

@Component({
  selector: 'slice-detail-content-popup',
  templateUrl: './detail-content-popup.component.html',
  styleUrls: ['./detail-content-popup.component.scss'],
})
export class DetailContentPopupComponent extends AbstractSubscriberComponent {
  @Input() isPublic = false;
  @Input() channel!: Channels | undefined;
  @Input() postType!: PostTypes | undefined;
  @Input() platformContentId!: string | undefined;
  @Input() channelAccountConnected? = true;
  public assetsTitleKeyMap = AssetsTitleKeyMap;

  public breadcrumbs: Array<BreadcrumbsWithoutLinksItem>;
  public data: ChannelMediaModel;
  public Channels = Channels;
  public isVisible = false;
  public loading = false;

  constructor(
    private channelMediaHttpS: ChannelMediaHttpService,
    private tS: TranslocoService,
  ) {
    super();
  }

  fetchDetail() {
    this.loading = true;
    this.channelMediaHttpS
      .getContentMedia(
        this.channel as Channels,
        this.platformContentId,
        this.isPublic,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        res => {
          this.data = res;
          this.loading = false;
        },
        () => {
          this.loading = false;
        },
      );
  }

  private initBreadCrumbs(postTypeKey: any) {
    this.breadcrumbs = [
      {
        title:
          this.tS.translate(this.channel as string) +
          ' ' +
          this.tS.translate('enums.post-types.' + postTypeKey),
      },
    ];
  }

  openDialog(): void {
    this.isVisible = true;
    if (
      !this.channel ||
      !this.postType ||
      !this.platformContentId ||
      !this.channelAccountConnected
    ) {
      console.log('Missing channel or postType or platformContentId');
      return;
    }
    this.initBreadCrumbs(this.postType);
    this.fetchDetail();
  }

  closeDialog(): void {
    this.isVisible = false;
  }
}
