import { Component } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-icon-circle-wavy-check',
  styles: [
    ':host { display: inline-flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './circle-wavy-check.component.html',
})
export class SvgIconCircleWavyCheckComponent extends AbstractSvgIconComponent {}
