<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M2.66965 4.11497C1.79613 4.50806 1.5 4.94799 1.5 5.25C1.5 5.55201 1.79613 5.99194 2.66965 6.38503C3.49624 6.75699 4.67325 7 6 7C7.32675 7 8.50376 6.75699 9.33035 6.38503C10.2039 5.99194 10.5 5.55201 10.5 5.25C10.5 4.94799 10.2039 4.50806 9.33035 4.11497C8.50376 3.74301 7.32675 3.5 6 3.5C4.67325 3.5 3.49624 3.74301 2.66965 4.11497ZM2.25928 3.20305C3.24234 2.76067 4.56533 2.5 6 2.5C7.43467 2.5 8.75766 2.76067 9.74072 3.20305C10.6768 3.6243 11.5 4.30937 11.5 5.25C11.5 6.19063 10.6768 6.8757 9.74072 7.29695C8.75766 7.73933 7.43467 8 6 8C4.56533 8 3.24234 7.73933 2.25928 7.29695C1.32316 6.8757 0.5 6.19063 0.5 5.25C0.5 4.30937 1.32316 3.6243 2.25928 3.20305Z"
    fill="#5D6468"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M1 4.75C1.27614 4.75 1.5 4.97386 1.5 5.25V7.75C1.5 8.05272 1.79611 8.49261 2.66921 8.88543C3.49561 9.25724 4.67261 9.5 6 9.5C7.32739 9.5 8.50439 9.25724 9.33079 8.88543C10.2039 8.49261 10.5 8.05272 10.5 7.75V5.25C10.5 4.97386 10.7239 4.75 11 4.75C11.2761 4.75 11.5 4.97386 11.5 5.25V7.75C11.5 8.69103 10.6774 9.37614 9.74109 9.79738C8.75811 10.2396 7.43511 10.5 6 10.5C4.56489 10.5 3.24189 10.2396 2.25891 9.79738C1.32264 9.37614 0.5 8.69103 0.5 7.75V5.25C0.5 4.97386 0.723858 4.75 1 4.75Z"
    fill="#5D6468"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4 6.8125C4.27614 6.8125 4.5 7.03636 4.5 7.3125V9.8125C4.5 10.0886 4.27614 10.3125 4 10.3125C3.72386 10.3125 3.5 10.0886 3.5 9.8125V7.3125C3.5 7.03636 3.72386 6.8125 4 6.8125Z"
    fill="#5D6468"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M10.5022 5.99736C10.5278 5.72241 10.7714 5.52028 11.0464 5.54589C12.2338 5.6565 13.3042 5.94942 14.0945 6.37957C14.8571 6.79464 15.5 7.42295 15.5 8.24998C15.5 9.19102 14.6774 9.87613 13.7411 10.2974C12.7581 10.7396 11.4351 11 10 11C8.72476 11 7.53916 10.7924 6.60607 10.4433C6.34743 10.3465 6.2162 10.0584 6.31296 9.79979C6.40972 9.54116 6.69782 9.40993 6.95646 9.50668C7.76086 9.80761 8.82527 9.99998 10 9.99998C11.3274 9.99998 12.5044 9.75722 13.3308 9.38542C14.2039 8.99259 14.5 8.5527 14.5 8.24998C14.5 7.98952 14.2835 7.62095 13.6165 7.2579C12.9771 6.90993 12.0475 6.64347 10.9536 6.54158C10.6787 6.51597 10.4766 6.27231 10.5022 5.99736Z"
    fill="#5D6468"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M15 7.75C15.2761 7.75 15.5 7.97386 15.5 8.25V10.75C15.5 11.691 14.6774 12.3761 13.7411 12.7974C12.7581 13.2396 11.4351 13.5 10 13.5C8.56489 13.5 7.24189 13.2396 6.25891 12.7974C5.32264 12.3761 4.5 11.691 4.5 10.75V9.95625C4.5 9.68011 4.72386 9.45625 5 9.45625C5.27614 9.45625 5.5 9.68011 5.5 9.95625V10.75C5.5 11.0527 5.79611 11.4926 6.66921 11.8854C7.49561 12.2572 8.67261 12.5 10 12.5C11.3274 12.5 12.5044 12.2572 13.3308 11.8854C14.2039 11.4926 14.5 11.0527 14.5 10.75V8.25C14.5 7.97386 14.7239 7.75 15 7.75Z"
    fill="#5D6468"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 9.8125C12.2761 9.8125 12.5 10.0364 12.5 10.3125V12.8125C12.5 13.0886 12.2761 13.3125 12 13.3125C11.7239 13.3125 11.5 13.0886 11.5 12.8125V10.3125C11.5 10.0364 11.7239 9.8125 12 9.8125Z"
    fill="#5D6468"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M8 6.8125C8.27614 6.8125 8.5 7.03636 8.5 7.3125V12.8125C8.5 13.0886 8.27614 13.3125 8 13.3125C7.72386 13.3125 7.5 13.0886 7.5 12.8125V7.3125C7.5 7.03636 7.72386 6.8125 8 6.8125Z"
    fill="#5D6468"
  />
</svg>
