<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11.9865 0.131836C5.43931 0.131836 0.131836 5.43931 0.131836 11.9865C0.131836 18.5337 5.43931 23.8412 11.9865 23.8412C18.5337 23.8412 23.8412 18.5337 23.8412 11.9865C23.8412 5.43931 18.5337 0.131836 11.9865 0.131836ZM18.4853 18.4853C18.1744 18.7961 17.7608 18.9676 17.3209 18.9676C16.8812 18.9676 16.4676 18.7964 16.1567 18.4853L10.413 12.7415C8.94356 13.2958 7.29075 12.9425 6.17562 11.8277C5.10158 10.7536 4.73251 9.18776 5.21223 7.74123C5.22909 7.69012 5.2715 7.65166 5.32366 7.63901C5.37582 7.62663 5.43114 7.64217 5.46908 7.68037L7.28522 9.49651C7.58685 9.79788 7.98912 9.96201 8.41431 9.95437C8.84055 9.94804 9.23676 9.77312 9.5297 9.462C10.1027 8.85346 10.065 7.85424 9.44514 7.23464L7.67985 5.46934C7.64191 5.43141 7.62611 5.37635 7.63849 5.32393C7.65087 5.2715 7.68959 5.22935 7.7407 5.21249C9.1875 4.73251 10.7531 5.10158 11.8271 6.17588C12.9423 7.29101 13.2955 8.94382 12.741 10.4133L18.4853 16.1567C18.7961 16.4676 18.9676 16.8812 18.9676 17.3209C18.9676 17.7608 18.7964 18.1744 18.4853 18.4853Z"
    fill="#0D193F"
  />
</svg>
