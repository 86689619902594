import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconWalletComponent } from './wallet.component';

@NgModule({
  declarations: [SvgIconWalletComponent],
  imports: [CommonModule],
  exports: [SvgIconWalletComponent],
})
export class SvgIconWalletModule {}
