<svg
  width="18"
  height="20"
  viewBox="0 0 18 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.17623 0.0995642L15.8137 2.32652C16.5566 2.57461 17.059 3.25711 17.063 4.02198L17.1053 10.6626C17.1184 12.6758 16.3845 14.6282 15.0405 16.1579C14.4223 16.8601 13.63 17.4631 12.6183 18.0025L9.05039 19.9097C8.93864 19.9686 8.81581 19.999 8.69199 20C8.56816 20.0009 8.44433 19.9715 8.33359 19.9137L4.73249 18.0505C3.70964 17.52 2.91029 16.9258 2.28611 16.2335C0.919968 14.7194 0.160888 12.7758 0.1478 10.7597L0.105493 4.12397C0.10149 3.35811 0.594792 2.67071 1.33374 2.41281L7.94599 0.106428C8.33862 -0.0328174 8.77655 -0.0357592 9.17623 0.0995642ZM12.3503 7.21866C12.0533 6.9333 11.5751 6.93526 11.2821 7.22454L7.91358 10.5449L6.53435 9.21909C6.23736 8.93373 5.76016 8.93667 5.4662 9.22595C5.17324 9.51523 5.17626 9.98004 5.47324 10.2654L7.38907 12.1089C7.53806 12.2521 7.73136 12.3227 7.92465 12.3208C8.11794 12.3198 8.31023 12.2472 8.45722 12.1021L12.3563 8.2581C12.6493 7.96882 12.6463 7.50401 12.3503 7.21866Z"
    fill="#F4F3FE"
  />
</svg>
