import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslocoModule } from '@ngneat/transloco';

import { TabsComponent } from './tabs.component';

@NgModule({
  imports: [CommonModule, RouterModule, TranslocoModule],
  declarations: [TabsComponent],
  exports: [TabsComponent],
})
export class TabsModule {}
