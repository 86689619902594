export enum ReportsInfoWidgets {
  reach = 'reach',
  impressions = 'impressions',
  subscribers = 'subscribers',
  totalClicks = 'totalClicks',
  costPerReach = 'costPerReach',
  costPerView = 'costPerView',
  costPerImpression = 'costPerImpression',
  costPerEngagement = 'costPerEngagement',
  reachRate = 'reachRate',
  potentialReach = 'potentialReach',
  averageWatchTime = 'avgViewDuration',
  averageViewDuration = 'averageViewDuration',
  engagementRate = 'engagementRate',
  engagementRateByReach = 'engagementRateByReach',
  engagementRateByFollower = 'engagementRateByFollower',
  followers = 'followers',
  likes = 'likes',
  comments = 'comments',
  shares = 'shares',
  saves = 'saves',
  clickRate = 'clickRate',
  totalEngagement = 'totalEngagement',
  totalWatchTime = 'totalWatchTime',
  views = 'views',
}

export interface InfoWidgetModel {
  type: ReportsInfoWidgets;
  titleKey: string;
  titleBlue?: string;
  tooltipKey?: string;
  value?: string | number | undefined;
  currency?: string;
  isSecondVal?: boolean;
  isPercentVal?: boolean;
  backgroundColor?: 'col-magenta' | 'col-green' | 'col-white';
  // creator past performance MAGENTA, cost efficiency GREEN, default WHITE
}
