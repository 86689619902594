<svg
  xmlns="http://www.w3.org/2000/svg"
  width="14"
  height="14"
  viewBox="0 0 14 14"
  fill="none"
>
  <path
    d="M7.00016 0.333984C3.31816 0.333984 0.333496 3.31865 0.333496 7.00065C0.333496 10.6827 3.31816 13.6673 7.00016 13.6673C10.6822 13.6673 13.6668 10.6827 13.6668 7.00065C13.6668 3.31865 10.6822 0.333984 7.00016 0.333984ZM7.00016 10.334C6.63216 10.334 6.3335 10.0353 6.3335 9.66732V7.00065C6.3335 6.63265 6.63216 6.33398 7.00016 6.33398C7.36816 6.33398 7.66683 6.63265 7.66683 7.00065V9.66732C7.66683 10.0353 7.36816 10.334 7.00016 10.334ZM7.3335 5.00065H6.66683C6.48283 5.00065 6.3335 4.85132 6.3335 4.66732V4.00065C6.3335 3.81665 6.48283 3.66732 6.66683 3.66732H7.3335C7.5175 3.66732 7.66683 3.81665 7.66683 4.00065V4.66732C7.66683 4.85132 7.5175 5.00065 7.3335 5.00065Z"
    fill="#5D6468"
  />
</svg>
