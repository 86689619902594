import { Component } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-icon-eye',
  styles: [
    ':host { display: flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './svg-icon-eye.component.html',
})
export class SvgIconEyeComponent extends AbstractSvgIconComponent {}
