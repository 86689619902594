import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconHamburgerComponent } from './hamburger.component';

@NgModule({
  declarations: [SvgIconHamburgerComponent],
  imports: [CommonModule],
  exports: [SvgIconHamburgerComponent],
})
export class SvgIconHamburgerModule {}
