import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconGoogleDriveComponent } from './google-drive.component';

@NgModule({
  declarations: [SvgIconGoogleDriveComponent],
  imports: [CommonModule],
  exports: [SvgIconGoogleDriveComponent],
})
export class SvgIconGoogleDriveModule {}
