<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M3.84 4.55957C3.30939 4.55957 2.88 4.98895 2.88 5.51957C2.88 6.05019 3.30939 6.47957 3.84 6.47957H20.16C20.6906 6.47957 21.12 6.05019 21.12 5.51957C21.12 4.98895 20.6906 4.55957 20.16 4.55957H3.84ZM3.84 11.0396C3.30939 11.0396 2.88 11.469 2.88 11.9996C2.88 12.5302 3.30939 12.9596 3.84 12.9596H20.16C20.6906 12.9596 21.12 12.5302 21.12 11.9996C21.12 11.469 20.6906 11.0396 20.16 11.0396H3.84ZM3.84 17.5196C3.30939 17.5196 2.88 17.949 2.88 18.4796C2.88 19.0102 3.30939 19.4396 3.84 19.4396H20.16C20.6906 19.4396 21.12 19.0102 21.12 18.4796C21.12 17.949 20.6906 17.5196 20.16 17.5196H3.84Z"
    fill="#5D6468"
  />
</svg>
