<svg
  xmlns="http://www.w3.org/2000/svg"
  width="26"
  height="24"
  viewBox="0 0 26 24"
  fill="none"
>
  <path
    d="M23.4703 13.0803V20.9433H24.6338V23.2704H1.36347V20.9433H2.52699V13.0803C1.81069 12.6022 1.22351 11.9546 0.81757 11.1951C0.411626 10.4356 0.199477 9.58758 0.199952 8.72639C0.199952 7.76416 0.46058 6.83683 0.936459 6.0468L4.09192 0.581759C4.19404 0.404886 4.34091 0.258009 4.51779 0.155889C4.69466 0.0537695 4.89529 5.18838e-06 5.09953 0H20.8989C21.1032 5.18838e-06 21.3038 0.0537695 21.4807 0.155889C21.6576 0.258009 21.8044 0.404886 21.9066 0.581759L25.0504 6.02935C25.7445 7.18225 25.9676 8.5583 25.6733 9.87145C25.3791 11.1846 24.5901 12.3338 23.4703 13.0803ZM21.1433 13.9296C20.3433 14.0194 19.5334 13.9233 18.7766 13.6488C18.0198 13.3744 17.3366 12.929 16.7801 12.3473C16.2917 12.8581 15.7048 13.2647 15.0548 13.5425C14.4049 13.8202 13.7054 13.9634 12.9987 13.9634C12.292 13.9637 11.5927 13.8209 10.9427 13.5436C10.2928 13.2662 9.70585 12.86 9.21722 12.3496C8.66062 12.9311 7.97734 13.3763 7.22057 13.6505C6.46379 13.9248 5.65393 14.0207 4.85403 13.9308V20.9433H21.1433V13.9308V13.9296ZM5.77204 2.32704L2.9412 7.22894C2.66612 7.9086 2.65786 8.66708 2.91808 9.35257C3.1783 10.0381 3.68781 10.6 4.34462 10.9259C5.00142 11.2518 5.75708 11.3176 6.46036 11.1102C7.16363 10.9028 7.76263 10.4375 8.13747 9.8073C8.52725 8.83343 9.90602 8.83343 10.297 9.8073C10.5126 10.3477 10.8852 10.8109 11.3668 11.1373C11.8485 11.4637 12.4169 11.6381 12.9987 11.6381C13.5804 11.6381 14.1488 11.4637 14.6305 11.1373C15.1121 10.8109 15.4847 10.3477 15.7003 9.8073C16.0901 8.83343 17.4689 8.83343 17.8598 9.8073C18.0108 10.179 18.2371 10.5154 18.5244 10.7954C18.8116 11.0755 19.1538 11.2931 19.5292 11.4345C19.9046 11.5759 20.3052 11.6382 20.7059 11.6174C21.1065 11.5965 21.4985 11.493 21.8572 11.3134C22.2159 11.1337 22.5336 10.8818 22.7903 10.5735C23.047 10.2652 23.2371 9.90709 23.3487 9.52174C23.4603 9.13639 23.491 8.73212 23.4388 8.33434C23.3867 7.93656 23.2528 7.55387 23.0456 7.21032L20.2241 2.32704H5.7732H5.77204Z"
    fill="#434DDB"
  />
</svg>
