import { Channels } from '@slc-libs/enums';
import {
  getIdMock,
  getPostTypeMockByChannel,
  getRandomWords,
} from '@slc-libs/mocks';

import { ChannelMediaModel } from '@slice-interfaces/media/channel-medias.intf';

import { getImageMock } from './_helpers/images';

export const getMediaByChannelMock = (channel: Channels): ChannelMediaModel => {
  return {
    id: getIdMock(),
    postType: getPostTypeMockByChannel(channel),
    caption: getRandomWords(Math.random() > 0.5 ? 5 : 30).join(' '),
    mediaUrl: getImageMock(),
  };
};

export const getMediaListByChannelMock = (
  channel: Channels,
  amount: number,
): Array<ChannelMediaModel> => {
  const list = [];
  for (let i = 0; i < amount; i++) {
    list.push(getMediaByChannelMock(channel));
  }
  return list;
};
