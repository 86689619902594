import { Component, Input } from '@angular/core';

import { EXTERNAL_LINKS } from '@slc-libs/constants';

@Component({
  selector: 'slc-landing-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class LandingFooterComponent {
  public readonly EXTERNAL_LINKS = EXTERNAL_LINKS;
  @Input() infoLinks: Array<{
    title: string;
    urlSegments?: Array<string>;
    link?: string;
  }> | null;
}
