import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconAgencyComponent } from './agency.component';

@NgModule({
  declarations: [SvgIconAgencyComponent],
  imports: [CommonModule],
  exports: [SvgIconAgencyComponent],
})
export class SvgIconAgencyModule {}
