<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M15.7172 8.72521C15.6079 8.73585 15.4983 8.74139 15.3886 8.74191C14.1851 8.74204 13.0626 8.13544 12.4034 7.12856V12.6224C12.4034 14.8649 10.5854 16.6829 8.34287 16.6829C6.10034 16.6829 4.28239 14.8649 4.28239 12.6224C4.28239 10.3798 6.10034 8.56188 8.34287 8.56188C8.42765 8.56188 8.5105 8.56949 8.59383 8.57476V10.5757C8.5105 10.5657 8.42858 10.5505 8.34287 10.5505C7.19833 10.5505 6.27048 11.4783 6.27048 12.6229C6.27048 13.7674 7.19833 14.6952 8.34287 14.6952C9.48762 14.6952 10.4986 13.7933 10.4986 12.6486L10.5186 3.31807H12.4329C12.6134 5.03472 13.9976 6.37558 15.7191 6.5014V8.72521H15.7172ZM17.6191 0H2.38096C1.0681 0 0 1.06807 0 2.38093V17.619C0 18.9319 1.0681 20 2.38096 20H17.6191C18.9319 20 20 18.9319 20 17.619V2.38093C20 1.06807 18.9319 0 17.6191 0Z"
    fill="#5D6468"
  />
</svg>
