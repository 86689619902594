import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconYoutubeComponent } from './svg-icon-youtube.component';

@NgModule({
  declarations: [SvgIconYoutubeComponent],
  imports: [CommonModule],
  exports: [SvgIconYoutubeComponent],
})
export class SvgIconYoutubeModule {}
