<button
  type="button"
  (click)="isGoogleConnected && !isExpiredFlow ? disconnect() : connect()"
  [disabled]="!isInited || isLoading || disabled"
>
  <ng-content></ng-content>
</button>

<slice-select-account-modal
  *ngIf="showAccountSelectionModal"
  [channel]="CHANNELS.YOUTUBE"
  [accounts]="accounts"
  (closed)="accountSelected($event)"
>
</slice-select-account-modal>

<!--<slice-result-modal
  *ngIf="showConnectErrorMsg"
  iconPath="/assets/svg/something-wrong.svg"
  text="{{ 'connect-socials.connect-err-msg.msg-1' | transloco }}{{
    CHANNELS.YOUTUBE
  }}{{ 'connect-socials.connect-err-msg.msg-2' | transloco }}"
  (closed)="showConnectErrorMsg = false"
></slice-result-modal>-->

<slice-result-modal
  *ngIf="showDisconnectErrorMsg"
  iconPath="/assets/svg/something-wrong.svg"
  text="{{ 'connect-socials.disconnect-err-msg.msg-1' | transloco }}{{
    CHANNELS.YOUTUBE
  }}{{ 'connect-socials.disconnect-err-msg.msg-2' | transloco }}"
  (closed)="showDisconnectErrorMsg = false"
></slice-result-modal>
