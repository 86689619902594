<svg
  xmlns="http://www.w3.org/2000/svg"
  [attr.width]="width"
  [attr.height]="height"
  viewBox="0 0 16 16"
  fill="none"
>
  <path
    d="M0.246895 14.5619L6.80874 8L0.246895 1.43815C-0.0822985 1.10896 -0.0822985 0.575301 0.246895 0.246895C0.575301 -0.0822985 1.10896 -0.0822985 1.43815 0.246895L8 6.80875L14.5618 0.246895C14.891 -0.0822985 15.4247 -0.0822985 15.7531 0.246895C16.0823 0.575301 16.0823 1.10896 15.7531 1.43815L9.19125 8L15.7531 14.5619C16.0823 14.891 16.0823 15.4247 15.7531 15.7531C15.4247 16.0823 14.891 16.0823 14.5618 15.7531L8 9.19125L1.43815 15.7531C1.10896 16.0823 0.575301 16.0823 0.246895 15.7531C-0.0822985 15.4247 -0.0822985 14.891 0.246895 14.5619Z"
    fill="#5D6468"
  />
</svg>
