<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M10 0.400391C4.72 0.400391 0.400002 4.72039 0.400002 10.0004C0.400002 15.2804 4.72 19.6004 10 19.6004C15.28 19.6004 19.6 15.2804 19.6 10.0004C19.6 4.72039 15.28 0.400391 10 0.400391ZM13.7848 14.8004C13.6088 14.8004 13.5208 14.7124 13.3448 14.6244C12.0248 13.8316 10.3528 13.3924 8.5928 13.3924C7.6248 13.3924 6.5688 13.5684 5.6888 13.7444C5.5128 13.7444 5.3368 13.8324 5.2488 13.8324C4.8968 13.8324 4.6328 13.5684 4.6328 13.2164C4.6328 12.8644 4.8088 12.6004 5.1608 12.6004C6.3048 12.3364 7.4496 12.1604 8.6816 12.1604C10.7056 12.1604 12.5536 12.6884 14.1376 13.5684C14.3136 13.7444 14.4896 13.8324 14.4896 14.1844C14.312 14.6244 14.048 14.8004 13.7848 14.8004ZM14.752 12.3372C14.576 12.3372 14.4 12.2492 14.224 12.1612C12.728 11.1932 10.6152 10.6652 8.328 10.6652C7.184 10.6652 6.1272 10.8412 5.336 11.0172C5.16 11.1052 5.072 11.1052 4.896 11.1052C4.456 11.1052 4.1912 10.7532 4.1912 10.4004C4.1912 9.96039 4.3672 9.78439 4.8072 9.60759C5.8632 9.34359 6.92 9.07959 8.416 9.07959C10.88 9.07959 13.168 9.69559 15.1048 10.8396C15.3688 11.0156 15.5448 11.2796 15.5448 11.5444C15.456 11.9844 15.1928 12.3372 14.752 12.3372ZM15.896 9.52039C15.72 9.52039 15.632 9.43239 15.368 9.34439C13.608 8.37639 11.056 7.76039 8.592 7.76039C7.36 7.76039 6.04 7.93639 4.8952 8.20039C4.7192 8.20039 4.6312 8.28839 4.3672 8.28839C3.8392 8.37719 3.4872 7.93639 3.4872 7.40919C3.4872 6.88199 3.7512 6.61639 4.1032 6.52919C5.512 6.17639 7.008 6.00039 8.592 6.00039C11.32 6.00039 14.136 6.61639 16.3368 7.76039C16.6008 7.93639 16.8648 8.20039 16.8648 8.64039C16.7768 9.16839 16.424 9.52039 15.896 9.52039Z"
    fill="#5D6468"
  />
</svg>
