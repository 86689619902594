import { Observable, of } from 'rxjs';

import { PaginationResponse } from '@slc-libs/interfaces';
import { getIdMock, getPagebaleResponseMock } from '@slc-libs/mocks';
import { getUsernameMock } from '@slc-libs/mocks';

import { GetUsersParams } from '@slice-interfaces/users/get-users-params';
import {
  GetUserEmailResponse,
  GetUsersResponse,
} from '@slice-interfaces/users/get-users-response';
import { User } from '@slice-interfaces/users/user.intf';

import { getRandomMockAvatar } from './_helpers/images';

const _getList = (
  params: GetUsersParams,
  res: PaginationResponse,
): Array<User> => {
  const list: Array<User> = [];
  for (
    let i = 0;
    i < (res.last ? res.totalElements - res.number * res.size : params.size);
    i++
  ) {
    list.push({
      id: getIdMock(),
      avatar: getRandomMockAvatar(),
      name: getUsernameMock(),
    });
  }
  return list;
};

export const getUsersMocks = (
  params: GetUsersParams,
): Observable<GetUsersResponse> => {
  const res = getPagebaleResponseMock(params);
  res.content = _getList(params, res);
  return of(res as GetUsersResponse);
};

export const getUserEmailMocks = (
  params: GetUsersParams,
): Observable<GetUserEmailResponse | null> => {
  const res = getPagebaleResponseMock(params);
  res.content = _getList(params, res);
  return of(null);
};
