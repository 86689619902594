<ng-container *ngIf="fullTextVisible; else restrictedTmpl">
  {{ sliceShowMoreText }}
</ng-container>

<ng-template #restrictedTmpl>
  <ng-container *ngIf="isShowedMore; else restrictedTextTmpl">{{
    sliceShowMoreText
  }}</ng-container>
  <ng-template #restrictedTextTmpl> {{ shortText }}... </ng-template>
  <br />
  <button type="button" (click)="isShowedMore = !isShowedMore">
    {{ (isShowedMore ? 'toggle-text.less' : 'toggle-text.more') | transloco }}
  </button>
</ng-template>
