export enum OnboardingSteps {
  ACCOUNT = 'ACCOUNT',
  INSTAGRAM = 'INSTAGRAM',
  YOUTUBE = 'YOUTUBE',
  TIKTOK = 'TIKTOK',
  ANCHOR = 'ANCHOR',
  RATE = 'RATE',
  PREVIEW = 'PREVIEW',
}
export enum OnboardingFormFields {
  name = 'name',
  email = 'email',
  phone = 'phone',
  address = 'address',
  gender = 'gender',
  birthDate = 'birthDate',
  country = 'country',
  city = 'city',
  biography = 'biography',
  prices = 'prices',
  brands = 'brands',
  categories = 'categories',
  hashtags = 'hashtags',
  kycType = 'kycType',
  companyKycData = 'companyKycData',

  // front-end
  isAtLeastOnePriceFilled = 'isAtLeastOnePriceFilled',
}

export enum ProfileWhatsNextEnum {
  SET_RATE_CARD = 'Setup Rate Card',
  LEARN_ACADEMY = 'Learn From Academy',
  SUBMIT_TAX_DOC = 'Submit Tax Documents',
  CONNECT_SOCIAL_MEDIA = 'Connect 1 Social Media Account',
}
