<div class="slc-flex-sb-c">
  <div class="preview-title">
    {{ 'submit-content.preview-title' | transloco }}&nbsp;({{
      assetsTitleKeyMap[assetType] | transloco
    }})
    <span class="slc-text-color-error slc-fs-14">*</span>
  </div>

  <div class="slc-flex-fe-c">
    <!-- <div class="carousel-progress slc-flex-c-c slc-mr-16">
      {{ files?.length ? currItemOrder + 1 : 0 }}
      {{ 'pagination.of' | transloco }}
      {{ files?.length }}
    </div> -->

    <button *ngIf="files?.length" type="button" (click)="deleteItem()">
      <slc-svg-icon-close></slc-svg-icon-close>
    </button>
  </div>
</div>

<p-carousel
  [value]="files"
  (onPage)="changePage($event)"
  [showIndicators]="false"
>
  <ng-template let-img pTemplate="item">
    <img *ngIf="img.base64" [src]="img.base64" alt="" />

    <slice-video-player
      *ngIf="img.video"
      class="slc-flex-c-c"
      [ngStyle]="{
        width: '100%',
        'max-height': '100%'
      }"
      [options]="{
        fluid: false,
        autoplay: false,
        controls: true,
        muted: false,
        sources: [{ src: img.video, type: 'video/mp4' }]
      }"
      (playerInited)="playerInited($event)"
    ></slice-video-player>
  </ng-template>
</p-carousel>
