import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Channels, PostTypes } from '@slc-libs/enums';
import { BreakpointsService } from '@slc-libs/services';

import { PostTypePrice } from '@slice-interfaces/profile/price';
import { RateSocialItem, RatesSocial } from '@slice-interfaces/rate-social';

import { createPriceFormCtrlName } from '../../../_helpers/price-form-control-name';
import { AbstractSubscriberComponent } from '../../abstract-classes/subscriber';

export interface ChannelPricesOutput {
  channel: Channels;
  postType: PostTypes;
  value: number | undefined;
}
export interface TiktokFormModel {
  username: string;
  followersCount: number;
}
@Component({
  selector: 'slice-rates-social',
  templateUrl: './rates-social.component.html',
  styleUrls: ['./rates-social.component.scss'],
})
export class RatesSocialComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() prices: Array<PostTypePrice> | null | undefined;
  @Input() ratesFormGroup!: UntypedFormGroup;
  @Input() tiktokForm: TiktokFormModel;
  @Output() tiktokFormChange: EventEmitter<TiktokFormModel> =
    new EventEmitter<any>();

  public additionalRates: MenuItem[];
  public channels = Channels;
  public socials: Array<RatesSocial>;
  public isDesktop$: Observable<boolean>;
  public visibleSocials: Array<Channels> = [
    Channels.TIKTOK,
    Channels.INSTAGRAM,
    Channels.YOUTUBE,
    Channels.APPEARANCE,
  ];
  private moreInstagramRates: MenuItem[] = [
    {
      // License
      id: 'onboarding.step-rate.common-license.title',
      command: () => {
        this.addOptionalRate(Channels.INSTAGRAM, {
          additionalItem: true,
          type: PostTypes.LICENSE,
          titleKey: 'onboarding.step-rate.common-license.title',
          descriptionKey: 'onboarding.step-rate.common-license.description',
        });
      },
    },
    {
      // IG Story Session
      id: 'onboarding.step-rate.instagram-rates.story_session.title',
      command: () => {
        this.addOptionalRate(Channels.INSTAGRAM, {
          additionalItem: true,
          type: PostTypes.STORY_SESSION,
          titleKey: 'onboarding.step-rate.instagram-rates.story_session.title',
          descriptionKey:
            'onboarding.step-rate.instagram-rates.story_session.description',
        });
      },
    },
    {
      // IG Story Additional Link
      id: 'onboarding.step-rate.instagram-rates.story_link.title',
      command: () => {
        this.addOptionalRate(Channels.INSTAGRAM, {
          additionalItem: true,
          type: PostTypes.STORY_LINK,
          titleKey: 'onboarding.step-rate.instagram-rates.story_link.title',
          descriptionKey:
            'onboarding.step-rate.instagram-rates.story_link.description',
        });
      },
    },
    {
      // Exclusivity for 3 Months
      id: 'onboarding.step-rate.instagram-rates.exclusive_3month.title',
      command: () => {
        this.addOptionalRate(Channels.INSTAGRAM, {
          additionalItem: true,
          type: PostTypes.Exclusive3Month,
          titleKey:
            'onboarding.step-rate.instagram-rates.exclusive_3month.title',
          descriptionKey:
            'onboarding.step-rate.instagram-rates.exclusive_3month.description',
        });
      },
    },
    {
      // Exclusivity for 5 Months
      id: 'onboarding.step-rate.instagram-rates.exclusive_5month.title',
      command: () => {
        this.addOptionalRate(Channels.INSTAGRAM, {
          additionalItem: true,
          type: PostTypes.Exclusive5Month,
          titleKey:
            'onboarding.step-rate.instagram-rates.exclusive_5month.title',
          descriptionKey:
            'onboarding.step-rate.instagram-rates.exclusive_5month.description',
        });
      },
    },
    {
      // Permanent
      id: 'onboarding.step-rate.instagram-rates.exclusive_permanent.title',
      command: () => {
        this.addOptionalRate(Channels.INSTAGRAM, {
          additionalItem: true,
          type: PostTypes.Permanent,
          titleKey:
            'onboarding.step-rate.instagram-rates.exclusive_permanent.title',
          descriptionKey:
            'onboarding.step-rate.instagram-rates.exclusive_permanent.description',
        });
      },
    },
    {
      // Link on Bio for 1 Week
      id: 'onboarding.step-rate.instagram-rates.link_1week.title',
      command: () => {
        this.addOptionalRate(Channels.INSTAGRAM, {
          additionalItem: true,
          type: PostTypes.BioLink1Week,
          titleKey: 'onboarding.step-rate.instagram-rates.link_1week.title',
          descriptionKey:
            'onboarding.step-rate.instagram-rates.link_1week.description',
        });
      },
    },
    {
      // Link on Bio for 1 Month
      id: 'onboarding.step-rate.instagram-rates.link_1month.title',
      command: () => {
        this.addOptionalRate(Channels.INSTAGRAM, {
          additionalItem: true,
          type: PostTypes.BioLink1Month,
          titleKey: 'onboarding.step-rate.instagram-rates.link_1month.title',
          descriptionKey:
            'onboarding.step-rate.instagram-rates.link_1month.description',
        });
      },
    },
    {
      // Content Owning as Ad
      id: 'onboarding.step-rate.instagram-rates.content_owning.title',
      command: () => {
        this.addOptionalRate(Channels.INSTAGRAM, {
          additionalItem: true,
          type: PostTypes.ContentOwning,
          titleKey: 'onboarding.step-rate.instagram-rates.content_owning.title',
          descriptionKey:
            'onboarding.step-rate.instagram-rates.content_owning.description',
        });
      },
    },
  ];

  @Output() priceChanged = new EventEmitter<ChannelPricesOutput>();

  constructor(
    private tS: TranslocoService,
    private breakpointsS: BreakpointsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.socials = this.createRateData();
    this.isDesktop$ = this.breakpointsS.selectIsDesktop();
    this.createAdditionalRates(Channels.INSTAGRAM);
    this.initForm();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.prices && !changes.prices.firstChange) {
      this.assignAdditionalRates(this.prices, Channels.INSTAGRAM);
      this.patchForm(changes.prices.currentValue || []);
    }
  }

  private initForm(): void {
    this.createFormControls(this.ratesFormGroup, this.socials);
  }

  toggleVisibility(social: Channels): void {
    if (this.visibleSocials.includes(social)) {
      this.visibleSocials = this.visibleSocials.filter(i => i !== social);
    } else {
      this.visibleSocials = [...this.visibleSocials, social];
    }
  }

  private createFormControls(
    formCtrl: UntypedFormGroup,
    channels: Array<RatesSocial>,
  ): void {
    channels.forEach(channel => {
      channel.rates.forEach(price => {
        const name = this.getPriceFormCtrlName(channel.socialType, price.type);
        formCtrl.addControl(
          name,
          new UntypedFormControl(
            this.getCurrValueByType(channel.socialType, price.type),
            [],
          ),
        );
      });
    });
  }

  getCurrValueByType(
    channel: Channels,
    postType: PostTypes,
  ): number | undefined {
    return this.prices?.find(
      i => i.channel === channel && i.postType === postType,
    )?.price;
  }

  private patchForm(prices: Array<PostTypePrice>): void {
    prices.forEach(price => {
      this.ratesFormGroup
        .get(this.getPriceFormCtrlName(price.channel, price.postType))
        ?.patchValue(price.price);
    });
  }

  getPriceFormCtrlName(channel: Channels, postType: PostTypes): string {
    return createPriceFormCtrlName(channel, postType);
  }

  private createRateData(): Array<RatesSocial> {
    return [
      {
        socialType: Channels.TIKTOK,
        imgPath: '/assets/svg/tiktok.svg',
        rates: this.createTiktokRateList(),
      },
      {
        socialType: Channels.INSTAGRAM,
        imgPath: '/assets/svg/instagram.svg',
        rates: this.createInstagramRateList(),
      },
      {
        socialType: Channels.YOUTUBE,
        imgPath: '/assets/svg/youtube.svg',
        rates: this.createYoutubeRateList(),
      },
      {
        socialType: Channels.APPEARANCE,
        imgPath: '/assets/svg/person.svg',
        rates: this.createAppearanceRateList(),
      },
    ];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onKeyUp(e: any, channel: Channels, postType: PostTypes): void {
    this.priceChanged.emit({
      channel,
      postType,
      value: this.ratesFormGroup.get(
        this.getPriceFormCtrlName(channel, postType),
      )?.value,
    });
  }

  private createInstagramRateList(): Array<RateSocialItem> {
    return [
      {
        type: PostTypes.POST,
        titleKey: 'onboarding.step-rate.instagram-rates.post.title',
        descriptionKey: 'onboarding.step-rate.instagram-rates.post.description',
      },
      {
        type: PostTypes.STORIES,
        titleKey: 'onboarding.step-rate.instagram-rates.stories.title',
        descriptionKey:
          'onboarding.step-rate.instagram-rates.stories.description',
      },
      {
        type: PostTypes.REELS,
        titleKey: 'onboarding.step-rate.instagram-rates.reels.title',
        descriptionKey:
          'onboarding.step-rate.instagram-rates.reels.description',
      },
      // {
      //   type: PostTypes.VIDEO,
      //   titleKey: 'onboarding.step-rate.instagram-rates.video.title',
      //   descriptionKey:
      //     'onboarding.step-rate.instagram-rates.video.description',
      // },
      {
        type: PostTypes.CAROUSEL_ALBUM,
        titleKey: 'onboarding.step-rate.instagram-rates.carousel.title',
        descriptionKey:
          'onboarding.step-rate.instagram-rates.carousel.description',
      },
    ];
  }

  private createYoutubeRateList(): Array<RateSocialItem> {
    return [
      {
        type: PostTypes.HOST_READ_AD,
        titleKey: 'onboarding.step-rate.youtube-rates.hostReadAd.title',
        descriptionKey:
          'onboarding.step-rate.youtube-rates.hostReadAd.description',
      },
      {
        type: PostTypes.PRODUCT_REVIEW,
        titleKey: 'onboarding.step-rate.youtube-rates.productReview.title',
        descriptionKey:
          'onboarding.step-rate.youtube-rates.productReview.description',
      },
      {
        type: PostTypes.PRODUCT_PLACEMENT,
        titleKey: 'onboarding.step-rate.youtube-rates.productPlacement.title',
        descriptionKey:
          'onboarding.step-rate.youtube-rates.productPlacement.description',
      },
      {
        type: PostTypes.LICENSE,
        titleKey: 'onboarding.step-rate.common-license.title',
        descriptionKey: 'onboarding.step-rate.common-license.description',
      },
    ];
  }

  private createTiktokRateList(): Array<RateSocialItem> {
    return [
      {
        type: PostTypes.TIKTOK_VIDEO,
        titleKey: 'onboarding.step-rate.tiktok-rates.video.title',
        descriptionKey: 'onboarding.step-rate.tiktok-rates.video.description',
      },
      {
        type: PostTypes.ContentOwning,
        titleKey: 'onboarding.step-rate.tiktok-rates.content_owning.title',
        descriptionKey:
          'onboarding.step-rate.tiktok-rates.content_owning.description',
      },
      {
        type: PostTypes.Exclusive1Month,
        titleKey: 'onboarding.step-rate.tiktok-rates.exclusive_1month.title',
        descriptionKey:
          'onboarding.step-rate.tiktok-rates.exclusive_1month.description',
      },
      // {
      //   type: PostTypes.Exclusive3Month,
      //   titleKey: 'onboarding.step-rate.tiktok-rates.exclusive_3month.title',
      //   descriptionKey:
      //     'onboarding.step-rate.tiktok-rates.exclusive_3month.description',
      // },
      // {
      //   type: PostTypes.Permanent,
      //   titleKey: 'onboarding.step-rate.tiktok-rates.permanent.title',
      //   descriptionKey:
      //     'onboarding.step-rate.tiktok-rates.permanent.description',
      // },
      // {
      //   type: PostTypes.StoreVisit,
      //   titleKey: 'onboarding.step-rate.tiktok-rates.store_visit.title',
      //   descriptionKey:
      //     'onboarding.step-rate.tiktok-rates.store_visit.description',
      // },
      // {
      //   type: PostTypes.LICENSE,
      //   titleKey: 'onboarding.step-rate.common-license.title',
      //   descriptionKey: 'onboarding.step-rate.common-license.description',
      // },
    ];
  }

  private createAppearanceRateList(): Array<RateSocialItem> {
    return [
      {
        type: PostTypes.SpokePerson,
        titleKey: 'onboarding.step-rate.appearance-rates.spoke_person.title',
        descriptionKey:
          'onboarding.step-rate.appearance-rates.spoke_person.description',
      },
      {
        type: PostTypes.Ambassador,
        titleKey: 'onboarding.step-rate.appearance-rates.ambassador.title',
        descriptionKey:
          'onboarding.step-rate.appearance-rates.ambassador.description',
      },
      {
        type: PostTypes.Attendance,
        titleKey: 'onboarding.step-rate.appearance-rates.attendance.title',
        descriptionKey:
          'onboarding.step-rate.appearance-rates.attendance.description',
      },
      {
        type: PostTypes.PhotoShoot,
        titleKey: 'onboarding.step-rate.appearance-rates.photo_shoot.title',
        descriptionKey:
          'onboarding.step-rate.appearance-rates.photo_shoot.description',
      },
      {
        type: PostTypes.VideoShoot,
        titleKey: 'onboarding.step-rate.appearance-rates.video_shoot.title',
        descriptionKey:
          'onboarding.step-rate.appearance-rates.video_shoot.description',
      },
      {
        type: PostTypes.StoreVisit,
        titleKey: 'onboarding.step-rate.appearance-rates.store_visit.title',
        descriptionKey:
          'onboarding.step-rate.appearance-rates.store_visit.description',
      },
      {
        type: PostTypes.LiveStream,
        titleKey: 'onboarding.step-rate.appearance-rates.livestream.title',
        descriptionKey:
          'onboarding.step-rate.appearance-rates.livestream.description',
      },
      // {
      //   type: PostTypes.LICENSE,
      //   titleKey: 'onboarding.step-rate.common-license.title',
      //   descriptionKey: 'onboarding.step-rate.common-license.description',
      // },
    ];
  }

  private createAdditionalRates(social: Channels): void {
    switch (social) {
      case Channels.TIKTOK:
        this.additionalRates = [];
        break;
      case Channels.INSTAGRAM:
        this.additionalRates = this.moreInstagramRates;
        break;
      case Channels.YOUTUBE:
        this.additionalRates = [];
        break;
    }

    // observables for updating p-menu label
    this.tS.langChanges$
      .pipe(
        switchMap(lang => this.tS.selectTranslate('menu.home.title', {}, lang)),
      )
      .subscribe(e => {
        console.log('Result', e);
        this.additionalRates.map(
          item => (item.label = this.tS.translate(`${item.id}`)),
        );
      });
  }

  private assignAdditionalRates(
    form: Array<PostTypePrice> | null | undefined,
    channels: Channels,
  ): void {
    if (form) {
      form.forEach(f => {
        if (f.channel === channels) {
          const rate = this.additionalRates.find(r => {
            if (r.id) {
              const postType = r.id.split('.')[3];
              return postType === f.postType;
            } else {
              return false;
            }
          });
          if (rate && rate.command) {
            // console.log('rate added', rate);
            rate.command();
          }
        }
      });
    }
  }

  private addOptionalRate(social: Channels, rateItem: RateSocialItem): void {
    // adding new rate to the list & form
    this.socials = this.socials.map(s => {
      if (s.socialType === social) {
        s.rates.push(rateItem);
      }
      return s;
    });
    this.ratesFormGroup.addControl(
      this.getPriceFormCtrlName(social, rateItem.type),
      new UntypedFormControl(null),
    );

    // removing rate from the additional list
    this.additionalRates = this.additionalRates.filter(
      r => r.id !== rateItem.titleKey,
    );
  }

  public deleteOptionalRate(social: Channels, rateItem: RateSocialItem): void {
    if (rateItem.additionalItem) {
      // removing rate from main list & form
      this.socials = this.socials.map(s => {
        if (s.socialType === social) {
          s.rates = s.rates.filter(r => r.type !== rateItem.type);
        }
        return s;
      });
      this.ratesFormGroup.removeControl(
        this.getPriceFormCtrlName(social, rateItem.type),
      );

      // adding rate to the additional list
      const deletedRate = this.moreInstagramRates.find(
        r => r.id === rateItem.titleKey,
      );
      this.additionalRates.push({
        id: deletedRate?.id,
        label: this.tS.translate(`${deletedRate?.id}`),
        command: () => {
          this.addOptionalRate(social, rateItem);
        },
      });
    }
  }
}
