<p-calendar
  #datePicker
  placeholder="{{ 'inputs.date.placeholder' | transloco }}"
  dateFormat="{{ DATE_FORMAT }}"
  [readonlyInput]="true"
  (onShow)="isOpen = true"
  [ngModel]="initValue"
  appendTo="body"
  (onClose)="isOpen = false"
  [hideOnDateTimeSelect]="false"
  (onSelect)="dateSelected($event)"
>
  <ng-template pTemplate="footer">
    <div class="slc-flex-sb-c">
      <div class="slc-flex-fs-c">
        <div class="slc-mr-8">
          {{ 'submit-content.date-time.time' | transloco }}
        </div>
        <ng-select
          class="slice-ng-select"
          [items]="times"
          [(ngModel)]="currTime"
          bindLabel="label"
          bindValue="value"
          dropdownPosition="top"
          [multiple]="false"
          [searchable]="false"
          [closeOnSelect]="true"
          [clearable]="false"
        >
        </ng-select>
      </div>
      <button
        class="slc-btn slc-btn-primary slc-btn-h-36"
        (click)="apply()"
        [disabled]="!currDate || !currTime"
      >
        <span>{{ 'buttons.apply' | transloco }}</span>
      </button>
    </div>
  </ng-template>
</p-calendar>

<div class="title">
  {{ 'submit-content.date-time.post-schedule' | transloco }}
</div>

<div class="value">
  {{ dateTime ? (dateTime | date : DATE_TIME_FORMAT) : '-' }}
</div>

<button (click)="showDropdown()" [disabled]="isOpen" class="btn-edit">
  <span>{{ 'buttons.edit' | transloco }}</span>
</button>
