import { Injectable } from '@angular/core';

import { Translation } from '@ngneat/transloco';

import { IsoLanguages } from '@slc-libs/types';

import { CampaignCreateState } from '@slice-interfaces/campaign-create/create-campaign';

import {
  OnboadingFullData,
  UserGeneralStatus,
} from '../_interfaces/onboarding';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private readonly keys = {
    refreshToken: 'slc-refresh-token',
    createCampaign: 'slc-create-campaign',
    userStatus: 'slc-user-status',
    onboarding: 'slc-onboarding',
    appVersion: 'slc-app-version',
    localization: 'slc-localization',
  };

  getAppVersion(): string | null {
    return localStorage.getItem(this.keys.appVersion);
  }
  setAppVersion(v: string): void {
    localStorage.setItem(this.keys.appVersion, v);
  }
  removeAppVersion(): void {
    return localStorage.removeItem(this.keys.appVersion);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(this.keys.refreshToken);
  }
  setRefreshToken(token: string): void {
    localStorage.setItem(this.keys.refreshToken, token);
  }
  removeRefreshToken(): void {
    localStorage.removeItem(this.keys.refreshToken);
  }

  getLocalization(lang: IsoLanguages): Translation | null {
    const json = localStorage.getItem(this.keys.localization);
    const loc = json ? JSON.parse(json) : null;
    return loc && loc[lang] ? loc[lang] : null;
  }
  setLocalization(lang: IsoLanguages, localization: Translation): void {
    // eslint-disable-next-line prefer-const
    const locale = localStorage.getItem(this.keys.localization);
    const loc: any = locale ? JSON.parse(locale) : {};
    loc[lang] = localization;
    localStorage.setItem(this.keys.localization, JSON.stringify(loc));
  }
  removeLocalization(): void {
    localStorage.removeItem(this.keys.localization);
  }

  setCampaignCreateData(d: CampaignCreateState): void {
    localStorage.setItem(this.keys.createCampaign, JSON.stringify(d));
  }
  getCampaignCreateData(): CampaignCreateState | null {
    const d = localStorage.getItem(this.keys.createCampaign);
    return d ? JSON.parse(d) : null;
  }
  removeCampaignCreateData(): void {
    localStorage.removeItem(this.keys.createCampaign);
  }

  setUserStatus(d: UserGeneralStatus): void {
    localStorage.setItem(this.keys.userStatus, JSON.stringify(d));
  }
  getUserStatus(): UserGeneralStatus | null {
    const d = localStorage.getItem(this.keys.userStatus);
    return d ? JSON.parse(d) : null;
  }
  removeUserStatus(): void {
    localStorage.removeItem(this.keys.userStatus);
  }

  setOnboardingData(d: OnboadingFullData): void {
    localStorage.setItem(this.keys.onboarding, JSON.stringify(d));
  }
  getOnboardingData(): OnboadingFullData | null {
    const d = localStorage.getItem(this.keys.onboarding);
    return d ? JSON.parse(d) : null;
  }
  removeOnboardingData(): void {
    localStorage.removeItem(this.keys.onboarding);
  }

  clear(): void {
    Object.values(this.keys).forEach(i => {
      localStorage.removeItem(i);
    });
  }

  clearAfterChangeVersion(): void {
    Object.values(this.keys).forEach(i => {
      if (i !== this.keys.refreshToken && i !== this.keys.userStatus) {
        localStorage.removeItem(i);
      }
    });
  }
}
