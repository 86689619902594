import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconChevronComponent } from './chevron.component';

@NgModule({
  declarations: [SvgIconChevronComponent],
  imports: [CommonModule],
  exports: [SvgIconChevronComponent],
})
export class SvgIconChevronModule {}
