import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';

import { SvgIconQuestionCircleModule } from '@slc-libs/svg-icons';

import { TooltipComponent } from './tooltip.component';
@NgModule({
  imports: [
    TranslocoModule,
    CommonModule,
    TooltipModule,
    DialogModule,
    SvgIconQuestionCircleModule,
  ],
  declarations: [TooltipComponent],
  exports: [TooltipComponent],
})
export class AppTooltipModule {}
