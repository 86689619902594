export const EXTERNAL_LINKS = {
  blog: 'https://blog.slice.id',
  helpSupport: 'https://slicegroup.zendesk.com/hc/en-us',
  careers: 'https://www.linkedin.com/company/slicegroup/jobs',
  faq: 'https://drive.google.com/drive/folders/1x0dsGVWqKLcEQ4OOU0U-xph65rh111vh',
  instagram: 'https://www.instagram.com/joinslice',
  tiktok: 'https://www.tiktok.com/@joinslice',
  linkedin: 'https://www.linkedin.com/company/slicegroup',
  spotify: 'https://open.spotify.com/show/0gGhoIOi75jqdjDta8vu2g',
  telegram: 'https://t.me/+pJouWaAetnA4Njc1',
  twitter: 'https://twitter.com/join_slice',
  youtube: 'https://www.youtube.com/@Join_Slice',
};
