import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Ages, Channels, Genders, PostTypes } from '@slc-libs/enums';
import { createHttpParams } from '@slc-libs/helpers';
import { getNumberMock, getPastDateMock } from '@slc-libs/mocks';

import { environment } from '@slice-env/environment';

import { CreatorAnalyticsParams } from '@slice-interfaces/creator-analytics/creator-analytics-params';
import {
  CreatorIGAnalyticsResponse,
  CreatorTKAnalyticsResponse,
  CreatorYtAnalyticsBreakdownResponse,
  CreatorYtAnalyticsCountriesResponse,
  CreatorYtAnalyticsGenderAgeDemographicResponse,
  CreatorYtAnalyticsResponse,
  CreatorYtAnalyticsTrafficSourceResponse,
} from '@slice-interfaces/creator-analytics/creator-analytics-response';
import { Countries } from '@slice-enums/countries';

@Injectable({
  providedIn: 'root',
})
export class CreatorAnalyticsHttpService {
  constructor(private http: HttpClient) {}

  getCreatorAnalytics(
    p: CreatorAnalyticsParams,
    useMock?: boolean,
  ): Observable<
    | CreatorIGAnalyticsResponse
    | CreatorYtAnalyticsResponse
    | CreatorTKAnalyticsResponse
  > {
    const mock = of(this.createMockAnalysis(p.channel)).pipe(delay(300));
    const req = this.http.get<any>(
      `/api/v1/users/${p.userId}/creator-analytics/${p.channel}`,
      {
        params: createHttpParams({
          postType: p?.postType,
          campaignId: p.campaignId,
        }),
      },
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mock : req;
      } else {
        // prod + stage
        return req;
      }
    } else {
      return useMock ? mock : req;
    }
  }

  createMockAnalysis(
    channel: Channels,
  ):
    | CreatorIGAnalyticsResponse
    | CreatorYtAnalyticsResponse
    | CreatorTKAnalyticsResponse {
    const mockIGData: CreatorIGAnalyticsResponse = {
      userId: 'id-mock',
      postType: PostTypes.POST,
      reach: getNumberMock(100),
      impressions: getNumberMock(100),
      followers: getNumberMock(100),
      likes: getNumberMock(100),
      comments: getNumberMock(100),
      save: getNumberMock(100),
      shares: getNumberMock(100),
      engagementRate: getNumberMock(100),
      engagementRateByReach: getNumberMock(100),
      engagementRateByFollower: getNumberMock(100),
      cpr: getNumberMock(100),
      cpv: getNumberMock(100),
      cpi: getNumberMock(100),
      cpe: getNumberMock(100),
      followerGrowth: {
        [getPastDateMock()]: getNumberMock(100),
        [getPastDateMock()]: getNumberMock(100),
        [getPastDateMock()]: getNumberMock(100),
        [getPastDateMock()]: getNumberMock(100),
        [getPastDateMock()]: getNumberMock(100),
        [getPastDateMock()]: getNumberMock(100),
        [getPastDateMock()]: getNumberMock(100),
        [getPastDateMock()]: getNumberMock(100),
        [getPastDateMock()]: getNumberMock(100),
      },
    };
    const mockYTData: CreatorYtAnalyticsResponse = {
      subscribers: getNumberMock(1000),
      views: getNumberMock(1000),
      avgComment: getNumberMock(1000),
      engagementRate: getNumberMock(1000),
      engagements: getNumberMock(1000),
      cpr: getNumberMock(1000),
      cpv: getNumberMock(1000),
      cpi: getNumberMock(1000),
      cpe: getNumberMock(1000),
      avgDuration: '1:40',
    };
    const mockTKData: CreatorTKAnalyticsResponse = {
      userId: 'id-mock',
      postType: PostTypes.POST,
      view: getNumberMock(100),
      likes: getNumberMock(100),
      comments: getNumberMock(100),
      shares: getNumberMock(100),
      cpe: getNumberMock(100),
      cpv: getNumberMock(100),
      engagementRate: getNumberMock(100),
      engagements: getNumberMock(100),
      followers: getNumberMock(1000),
    };
    // return as channel
    switch (channel) {
      case Channels.INSTAGRAM:
        return mockIGData;
      case Channels.YOUTUBE:
        return mockYTData;
      case Channels.TIKTOK:
        return mockTKData;
      default:
        return {} as any;
    }
  }

  getAnalyticsYtGenderAgeDemographic(
    userId: string,
    useMock?: boolean,
  ): Observable<CreatorYtAnalyticsGenderAgeDemographicResponse> {
    const mock = of({
      data: {
        [Ages.a18_24]: { [Genders.female]: 20, [Genders.male]: 40 },
        [Ages.a25_35]: { [Genders.female]: 40, [Genders.male]: 80 },
        [Ages.a36_45]: { [Genders.female]: 80, [Genders.male]: 160 },
        [Ages.a45]: { [Genders.female]: 160, [Genders.male]: 320 },
      } as any,
    }).pipe(delay(500));
    const req = this.http.get<CreatorYtAnalyticsGenderAgeDemographicResponse>(
      `/api/v1/users/${userId}/creator-analytics/youtube/view-demographics`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mock : req;
      } else {
        // prod + stage
        return req;
      }
    } else {
      return useMock ? mock : req;
    }
  }

  getAnalyticsYtTraffic(
    userId: string,
    useMock?: boolean,
  ): Observable<CreatorYtAnalyticsTrafficSourceResponse> {
    const mock = of({ data: { '1': 10, '2': 20, '3': 30 } }).pipe(delay(500));
    const req = this.http.get<CreatorYtAnalyticsTrafficSourceResponse>(
      `/api/v1/users/${userId}/creator-analytics/youtube/view-by-traffic-source`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mock : req;
      } else {
        // prod + stage
        return req;
      }
    } else {
      return useMock ? mock : req;
    }
  }

  getAnalyticsYtBreakdown(
    userId: string,
    useMock?: boolean,
  ): Observable<CreatorYtAnalyticsBreakdownResponse> {
    const mock = of({
      data: {
        [getPastDateMock()]: 10,
        [getPastDateMock()]: 20,
        [getPastDateMock()]: 70,
        [getPastDateMock()]: 20,
        [getPastDateMock()]: 80,
        [getPastDateMock()]: 20,
      } as any,
    }).pipe(delay(500));
    const req = this.http.get<CreatorYtAnalyticsBreakdownResponse>(
      `/api/v1/users/${userId}/creator-analytics/youtube/daily-views-breakdown`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mock : req;
      } else {
        // prod + stage
        return req;
      }
    } else {
      return useMock ? mock : req;
    }
  }

  getAnalyticsYtCountries(
    userId: string,
    useMock?: boolean,
  ): Observable<CreatorYtAnalyticsCountriesResponse> {
    const mock = of({
      data: {
        [Countries.china]: 10,
        [Countries.india]: 20,
        [Countries.indonesia]: 30,
        [Countries.malaysia]: 30,
        [Countries.singapore]: 30,
        [Countries.thailand]: 30,
      },
    }).pipe(delay(500));
    const req = this.http.get<CreatorYtAnalyticsCountriesResponse>(
      `/api/v1/users/${userId}/creator-analytics/youtube/country-distribution`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mock : req;
      } else {
        // prod + stage
        return req;
      }
    } else {
      return useMock ? mock : req;
    }
  }
}
