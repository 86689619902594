import { Component, Input } from '@angular/core';

import { ContactHelpLinkModel } from '@slice-interfaces/contact-help/contact-us.interface';

@Component({
  selector: 'slice-contact-link',
  templateUrl: './contact-link.component.html',
  styleUrls: ['./contact-link.component.scss'],
})
export class ContactLinkComponent {
  @Input() title?: string;

  public reachOutContact: ContactHelpLinkModel[] = [
    {
      title: 'Whatsapp',
      subtitle: '+62 852-1355-4954',
      type: 'whatsapp',
      icon: 'pi-whatsapp',
      link: 'https://api.whatsapp.com/send?phone=6285213554954&text=Hi%20Sherry%2C%20saya%20butuh%20bantuan%20tentang%20fitur%20di%20Slice',
    },
    {
      title: 'Email',
      subtitle: 'support@slice.id',
      type: 'email',
      icon: 'pi-envelope',
      link: 'mailto:support@slice.id',
    },
  ];

  openLink(data: ContactHelpLinkModel): void {
    window.open(data.link, '_blank');
  }
}
