<svg
  xmlns="http://www.w3.org/2000/svg"
  width="28"
  height="28"
  viewBox="0 0 28 28"
  fill="none"
>
  <path
    d="M14 0.666992C6.63596 0.666992 0.666626 6.63633 0.666626 14.0003C0.666626 21.3643 6.63596 27.3337 14 27.3337C21.364 27.3337 27.3333 21.3643 27.3333 14.0003C27.3333 6.63633 21.364 0.666992 14 0.666992ZM18 15.3337H15.3333V18.0003C15.3333 18.7363 14.736 19.3337 14 19.3337C13.264 19.3337 12.6666 18.7363 12.6666 18.0003V15.3337H9.99996C9.26396 15.3337 8.66663 14.7363 8.66663 14.0003C8.66663 13.2643 9.26396 12.667 9.99996 12.667H12.6666V10.0003C12.6666 9.26433 13.264 8.66699 14 8.66699C14.736 8.66699 15.3333 9.26433 15.3333 10.0003V12.667H18C18.736 12.667 19.3333 13.2643 19.3333 14.0003C19.3333 14.7363 18.736 15.3337 18 15.3337Z"
    fill="#5D6468"
  />
</svg>
