import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'slice-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss'],
})
export class ShowMoreComponent implements OnInit {
  @Input() sliceShowMoreText: string | undefined;
  @Input() sliceShowMoreLimit: number | undefined = 120;

  public shortText: string | undefined;
  public isShowedMore = false;
  public fullTextVisible = true;
  ngOnInit(): void {
    if (this.sliceShowMoreText) {
      this.fullTextVisible =
        this.sliceShowMoreText?.length < (this.sliceShowMoreLimit as number);
    }
    if (
      this.sliceShowMoreLimit &&
      this.sliceShowMoreText &&
      this.sliceShowMoreText.length >= this.sliceShowMoreLimit
    ) {
      this.shortText = this.sliceShowMoreText?.slice(
        0,
        this.sliceShowMoreLimit,
      );
    }
  }
}
