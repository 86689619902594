import { Component, OnInit } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'slice-landing-footer',
  templateUrl: './landing-footer.component.html',
  styleUrls: ['./landing-footer.component.scss'],
})
export class LandingFooterComponent implements OnInit {
  public infoLinks$: Observable<
    Array<{ title: string; urlSegments?: Array<string>; link?: string }>
  >;
  constructor(private translocoS: TranslocoService) {}

  ngOnInit(): void {
    this.infoLinks$ = this.translocoS.selectTranslation().pipe(
      map(vocabulary => {
        if (Object.values(vocabulary).length) {
          return [
            {
              title: this.translocoS.translate('landing.policy'),
              link: 'https://slice.id/policy',
            },
            {
              title: this.translocoS.translate('landing.terms'),
              link: 'https://slice.id/terms',
            },
          ];
        } else {
          return [];
        }
      }),
    );
  }
}
