import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconLogoComponent } from './svg-icon-logo.component';

@NgModule({
  declarations: [SvgIconLogoComponent],
  imports: [CommonModule],
  exports: [SvgIconLogoComponent],
})
export class SvgIconLogoModule {}
