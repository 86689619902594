<div class="avatar-wrap">
  <button
    *ngIf="isEditMode"
    class="btn-upload-avatar slc-flex-c-c"
    (click)="initiateAvatarUpload()"
  >
    <slc-svg-icon-photo></slc-svg-icon-photo>
    <input
      #inputAvatar
      type="file"
      accept="image/*"
      (change)="uploadAvatar($event)"
      hidden
    />
  </button>
  <slc-avatar
    class="slc-avatar-96"
    [userFullName]="name"
    [imagePath]="imgPath"
  ></slc-avatar>
</div>

<slice-img-cropper
  *ngIf="fileForCropper"
  [imageFile]="fileForCropper"
  [cropperType]="cropperType"
  [applyLoading]="loading"
  (closeModal)="closeCropper($event)"
>
</slice-img-cropper>
