import { Component, Input } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-icon-close-circle',
  styles: [
    ':host { display: inline-flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './close-circle.component.html',
})
export class SvgIconCloseCircleComponent extends AbstractSvgIconComponent {
  @Input() height = 24;
  @Input() width = 24;
}
