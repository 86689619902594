import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconEyeComponent } from './svg-icon-eye.component';

@NgModule({
  declarations: [SvgIconEyeComponent],
  imports: [CommonModule],
  exports: [SvgIconEyeComponent],
})
export class SvgIconEyeModule {}
