import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconPhoneComponent } from './phone.component';

@NgModule({
  declarations: [SvgIconPhoneComponent],
  imports: [CommonModule],
  exports: [SvgIconPhoneComponent],
})
export class SvgIconPhoneModule {}
