import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'slc-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
})
export class TagComponent implements OnInit, OnDestroy {
  @Input() titleKey: string | undefined | null; // key for transloco
  @Input() defaultName: string | undefined | null; // if translation dont exist
  @Input() isSelected: boolean | undefined;
  @Input() isGreen: boolean | undefined;
  @Input() isGray: boolean | undefined;
  @Input() isView?: boolean;
  @Input() isOutlined?: boolean;
  @Input() isDeletable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() beforeTmpl: TemplateRef<any>;

  private d$ = new Subject();
  public title$: Observable<{ value: string | undefined | null }>;

  constructor(private tS: TranslocoService) {}

  @Output() changed = new EventEmitter<boolean>();
  @Output() remove = new EventEmitter<void>();

  ngOnInit(): void {
    this.title$ = this.tS.selectTranslation().pipe(
      filter(vocabulary => Boolean(Object.values(vocabulary))),
      map(() => {
        return {
          value:
            this.titleKey && this.titleKey !== this.tS.translate(this.titleKey)
              ? this.tS.translate(this.titleKey)
              : this.defaultName || this.titleKey,
        };
      }),
    );
  }

  ngOnDestroy(): void {
    this.d$.next();
    this.d$.complete();
  }
  change(): void {
    const newVal = !this.isSelected;
    this.changed?.emit(newVal);
  }
}
