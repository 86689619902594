/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NavigationService } from '@slice-services/navigation.service';
import { throwError } from 'rxjs';

import { RedirectTiktok } from '@slice-interfaces/redirect';

import { TiktokLoginService } from '../../../../../_services/tiktok-login.service';
@Component({
  selector: 'slice-integration-tiktok',
  templateUrl: './integration-tiktok.component.html',
  styleUrls: ['./integration-tiktok.component.scss'],
})
export class IntegrationTiktokComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private tiktokLoginS: TiktokLoginService,
    private router: Router,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const token = params['code'];
      const state = params['state'];

      // this.tiktokLoginS.setIsSuccess(false);
      this.tiktokLoginS.sendTokenToAPI(token).subscribe(
        response => {
          // Success callback
          if (state.includes(RedirectTiktok.ONBOARDING)) {
            this.navigationService.goToOnboardingWithState({
              state: { statustiktok: 'success' },
            });
          }
          if (state.includes(RedirectTiktok.PROFILE)) {
            this.navigationService.goToProfileWithState({
              state: { statustiktok: 'success' },
            });
          } else {
            this.navigationService.goToNewOnboardingTiktokWithState({
              state: { statustiktok: 'success' },
            });
          }
        },
        error => {
          // Error callback
          if (state.includes(RedirectTiktok.ONBOARDING)) {
            this.navigationService.goToOnboardingWithState({
              state: { statustiktok: 'failed' },
            });
          }
          if (state.includes(RedirectTiktok.PROFILE)) {
            this.navigationService.goToProfileWithState({
              state: { statustiktok: 'failed' },
            });
          } else {
            this.navigationService.goToNewOnboardingTiktokWithState({
              state: { statustiktok: 'failed' },
            });
          }
          // Handle the error here
          return throwError(error);
        },
      );
    });
  }
}
