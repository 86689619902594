import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Channels } from '@slc-libs/enums';

@Component({
  selector: 'slice-connect-info-modal',
  templateUrl: './connect-info-modal.component.html',
  styleUrls: ['./connect-info-modal.component.scss'],
})
export class ConnectInfoModalComponent {
  @Input() channel: Channels = Channels.YOUTUBE;
  @Input() isExpired = false;
  public isVisible = true;

  @Output() closed = new EventEmitter<boolean>();

  onHide(): void {
    this.closed?.emit(undefined);
  }

  connectDone(isConnected: boolean): void {
    this.closed?.emit(isConnected);
  }
}
