import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconHomeComponent } from './home.component';

@NgModule({
  declarations: [SvgIconHomeComponent],
  imports: [CommonModule],
  exports: [SvgIconHomeComponent],
})
export class SvgIconHomeModule {}
