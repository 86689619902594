<div [hidden]="hideTitle" [ngClass]="hideIcon ? 'link-title' : 'link-title-p'">
  {{ 'generate-link.title' | transloco }}
  <span> {{ getSecurityPIN() }} </span>
</div>

<div class="slc-flex-fs-c slc-mt-8">
  <div
    class="icon-wrap slc-flex-c-c slc-mr-16"
    [hidden]="hideTitle || hideIcon"
    [ngStyle]="{ opacity: link ? 1 : 0.5 }"
  >
    <slc-svg-icon-link-multi></slc-svg-icon-link-multi>
  </div>
  <div class="link-wrap slc-flex-sb-c">
    <div class="link-value">{{ link }}</div>
    <button
      type="button"
      [disabled]="!link"
      class="slc-flex-fs-c"
      ngxClipboard
      [cbContent]="$any(link)"
      (click)="copied()"
    >
      <slc-svg-icon-link></slc-svg-icon-link>
      <span>{{ 'buttons.copy' | transloco }}</span>
    </button>
  </div>
</div>
