import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';

import { createHttpParams } from '@slc-libs/helpers';

import {
  BulkCampaignListResponse,
  CampaignCreatorResponse,
  CreatorEarningListResponse,
  CreatorSubmissionResponse,
  CustomPaymentParams,
  CustomPaymentResponse,
  FilterCampaignParams,
  FilterPaymentHistory,
  GrossDetailData,
  GrossDetailParam,
  PaymentHistoryListResponse,
  paymentInvoice,
} from '@slice-interfaces/bulk-payment/campaign';
import {
  CreatorTransactionHistoryParams,
  CreatorTransactionHistoryResponse,
} from '@slice-interfaces/wallet/payment-detail';

@Injectable({
  providedIn: 'root',
})
export class BulkPaymentCampaignHttpService {
  constructor(private http: HttpClient) {}

  getListCampaign(params: FilterCampaignParams): any {
    const req = this.http.get<BulkCampaignListResponse>(
      `api/v1/financial/campaign`,
      {
        params: createHttpParams(params),
      },
    );
    return req;
    // return req;
  }
  getListPaymentHistory(params: FilterPaymentHistory): any {
    const req = this.http.get<PaymentHistoryListResponse>(
      `api/v1/financial/campaign/payment-history`,
      {
        params: createHttpParams(params),
      },
    );

    return req;
  }
  getListPaymentHistoryAll(params: FilterPaymentHistory): any {
    const req = this.http.get<PaymentHistoryListResponse>(
      `api/v1/financial/campaign/payment-history-list`,
      {
        params: createHttpParams(params),
      },
    );

    return req;
  }

  postInvoice(payload: any, useMocks?: boolean): Observable<void> {
    const req = this.http.post<void>(`api/v1/financial/invoice`, payload);
    return req;
  }
  getCampaignDetail(id: string, useMocks?: boolean): Observable<any> {
    const req = this.http.get<void>(`api/v1/financial/campaign/${id}`);
    return req;
  }

  getCampaignCreator(id: string | null, params: any): any {
    const req = this.http.get<void>(`api/v1/financial/campaign/${id}/creator`, {
      params: createHttpParams(params),
    });
    return req;
  }

  getCreatorDetail(
    idCampaign: string,
    id: string,
    useMocks?: boolean,
  ): Observable<CampaignCreatorResponse> {
    const req = this.http.get<CampaignCreatorResponse>(
      `api/v1/financial/campaign/${idCampaign}/creator/${id}/`,
    );
    return req;
  }

  getContentSubmission(
    idCampaign: string,
    id: string,
    useMocks?: boolean,
  ): Observable<CreatorSubmissionResponse> {
    const req = this.http.get<CreatorSubmissionResponse>(
      `api/v1/financial/campaign/${idCampaign}/creator/${id}/submission`,
    );
    return req;
  }

  getCreatorEarnings(params: any): Observable<CreatorEarningListResponse> {
    const req = this.http.get<CreatorEarningListResponse>(
      `api/v1/financial/earnings`,
      {
        params: createHttpParams(params),
      },
    );
    return req;
  }

  updateCreatorEarnings(id: string, payload: any): Observable<any> {
    const req = this.http.post<any>(`api/v1/financial/earnings/${id}`, payload);
    return req;
  }

  getCreatorInvoice(
    idCampaign: string,
    id: string,
    useMocks?: boolean,
  ): Observable<Array<paymentInvoice>> {
    const req = this.http.get<Array<paymentInvoice>>(
      `api/v1/financial/campaign/${idCampaign}/creator/${id}/invoice`,
    );
    return req;
  }

  getEarningHistory(
    id: string,
    params?: any,
    useMocks?: boolean,
  ): Observable<any> {
    const req = this.http.get<any>(`api/v1/financial/earnings/${id}`, {
      params: createHttpParams(params),
    });
    return req;
  }

  uploadBuppot(
    id: string,
    paymentHistId: string,
    payload: any,
  ): Observable<any> {
    const req = this.http.put<any>(
      `api/v1/financial/earnings/${id}/payment-history/${paymentHistId}/tax-certificate`,
      payload,
    );
    return req;
  }

  getPaymentInvoiceUrl(id: string, paymentHistId: string): Observable<any> {
    const req = this.http.get<any>(
      `api/v1/financial/earnings/${id}/payment-history/${paymentHistId}/invoice-url`,
    );
    return req;
  }

  postSimulatedGross(
    idCampaign: string,
    id: string,
    payload: any,
    useMocks?: boolean,
  ): Observable<any> {
    const req = this.http.post<CampaignCreatorResponse>(
      `api/v1/financial/campaign/${idCampaign}/creator/${id}/update-amount`,
      payload,
    );
    return req;
  }

  postBulkPaymentCampaign(
    idCampaign: string,
    payload: any,
    useMocks?: boolean,
  ): Observable<any> {
    const req = this.http.post<CampaignCreatorResponse>(
      `api/v1/financial/campaign/${idCampaign}/bulk-payment`,
      payload,
    );
    return req;
  }

  postMarkComplete(payload: any, useMocks?: boolean): Observable<boolean> {
    const req = this.http.post<boolean>(
      `api/v1/financial/campaign/mark-complete`,
      payload,
    );
    return req;
  }

  fetchPaymentTransactionHistory(
    params: CreatorTransactionHistoryParams,
  ): Observable<CreatorTransactionHistoryResponse> {
    return this.http.get<CreatorTransactionHistoryResponse>(
      `api/v1/financial/campaign/creator`,
      {
        params: createHttpParams(params),
      },
    );
  }

  getEarningSlice(userId: string, year: string): Observable<any> {
    const req = this.http.get<any>(
      `api/v1/financial/earnings/${userId}/summary`,
      {
        params: createHttpParams({ year: year }),
      },
    );
    return req;
  }

  fetchGrossDetailCalculation(
    params: GrossDetailParam,
  ): Observable<GrossDetailData> {
    return this.http.get<GrossDetailData>(
      `api/v1/financial/flexible-payment/calculate-gross`,
      {
        params: createHttpParams(params),
      },
    );
  }
  fetchGrossDetailCalculationNonSlice(payload: any): Observable<any> {
    const req = this.http.post<boolean>(
      `api/v1/financial/flexible-payment/custom-recipient/calculate-gross`,
      payload,
    );
    return req;
  }
  checkBulkUploadInquiry(payload: any): Observable<any> {
    const req = this.http.post<boolean>(
      `api/v1/financial/flexible-payment/bulk-inquiry`,
      payload,
    );
    return req;
  }
}
