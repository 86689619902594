import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { SelectItemGroup } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Channels, PostTypes } from '@slc-libs/enums';

import { Select } from '@slice-interfaces/select/select';

@Injectable({
  providedIn: 'root',
})
export class PostTypesService {
  private postTypes: Array<Select> = [
    {
      label: 'enums.post-types.reels',
      value: PostTypes.REELS,
    },
    {
      label: 'enums.post-types.story',
      value: PostTypes.STORIES,
    },
    {
      label: 'enums.post-types.post',
      value: PostTypes.POST,
    },
    {
      label: 'enums.post-types.video',
      value: PostTypes.VIDEO,
    },
    {
      label: 'enums.post-types.shorts',
      value: PostTypes.SHORTS,
    },
    {
      label: 'enums.post-types.carousel',
      value: PostTypes.CAROUSEL_ALBUM,
    },
    {
      label: 'enums.post-types.host_read_ad',
      value: PostTypes.HOST_READ_AD,
    },
    {
      label: 'enums.post-types.product_placement',
      value: PostTypes.PRODUCT_PLACEMENT,
    },
    {
      label: 'enums.post-types.product_review_full_ep',
      value: PostTypes.PRODUCT_REVIEW,
    },
    {
      label: 'enums.post-types.video',
      value: PostTypes.TIKTOK_VIDEO,
    },
  ];

  private postTypesByChannel: Array<{
    channel: Channels;
    items: Array<PostTypes>;
  }> = [
    {
      channel: Channels.TIKTOK,
      items: [PostTypes.TIKTOK_VIDEO],
    },
    {
      channel: Channels.INSTAGRAM,
      items: [
        PostTypes.REELS,
        PostTypes.STORIES,
        PostTypes.POST,
        // PostTypes.VIDEO,
        PostTypes.CAROUSEL_ALBUM,
      ],
    },
    {
      channel: Channels.YOUTUBE,
      items: [
        // PostTypes.VIDEO,
        // PostTypes.SHORTS,
        PostTypes.HOST_READ_AD,
        PostTypes.PRODUCT_PLACEMENT,
        PostTypes.PRODUCT_REVIEW,
      ],
    },
  ];

  constructor(private tS: TranslocoService, private http: HttpClient) {}

  getAvailablePostTypesByChannel(channel: Channels): Array<PostTypes> {
    return (
      this.postTypesByChannel.find(i => i.channel === channel)?.items || []
    );
  }

  getPostTypesAppearance(): Observable<Array<Select>> {
    return this.http
      .get<Array<PostTypes>>(`/api/v1/contract/additional-sow`)
      .pipe(
        map(items =>
          items.map(i => ({
            label: this.tS.translate('enums.post-types.' + i),
            value: i,
          })),
        ),
      );
  }

  getPostTypesByChannel$(channel: Channels): Observable<Array<Select>> {
    const availablePostTypes = this.getAvailablePostTypesByChannel(channel);
    return this.tS.selectTranslation().pipe(
      map(vocabulary => {
        if (vocabulary && Object.values(vocabulary).length) {
          return this.postTypes
            .filter(i => availablePostTypes.includes(i.value))
            .map(i => ({
              label: this.tS.translate(i.label),
              value: i.value,
            }));
        } else {
          return [];
        }
      }),
    );
  }

  getGroupedPostTypesByChannels$(
    channels: Channels[],
  ): Observable<SelectItemGroup[]> {
    return this.tS.selectTranslation().pipe(
      map(vocabulary => {
        if (vocabulary && Object.values(vocabulary).length) {
          return channels
            .map(channel => {
              const availablePostTypes =
                this.getAvailablePostTypesByChannel(channel);
              const items = this.postTypes
                .filter(i => availablePostTypes.includes(i.value))
                .map(i => ({
                  title: channel,
                  label: this.tS.translate(i.label),
                  value: i.value,
                }));
              return {
                label: this.tS.translate(channel),
                value: channel,
                items,
              };
            })
            .sort((a, b) => {
              const order = [
                Channels.TIKTOK,
                Channels.INSTAGRAM,
                Channels.YOUTUBE,
              ];

              return order.indexOf(a.value) - order.indexOf(b.value);
            });
        } else {
          return [];
        }
      }),
    );
  }
}
