import { Component, HostBinding, Input } from '@angular/core';

import { ADMIN_CAMPAIGN_STATUS } from '@slice-enums/admin-campaign-status';

@Component({
  selector: 'slice-admin-campaign-status',
  templateUrl: './campaign-status.component.html',
  styleUrls: ['./campaign-status.component.scss'],
})
export class AdminCampaignStatusComponent {
  @Input() status: ADMIN_CAMPAIGN_STATUS;

  @HostBinding('class') get classes(): string {
    return this.status ? this.status : '';
  }
}
