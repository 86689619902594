import { SortMeta } from 'primeng/api/sortmeta';

import {
  TableColumn,
  TABLES_DEF_ROWS_PER_PAGE,
  TABLES_DEF_SORT,
  TABLES_ROWS_PER_PAGE_LIST,
} from '@slc-libs/tables';

export class BasicTableModel {
  first: number;
  page: number;
  sort: SortMeta[];
  size: number;
  columns: Array<TableColumn>;
  sizeOptions: number[];
  windowWidth: number;

  constructor() {
    return {
      first: 0,
      page: 0,
      sort: TABLES_DEF_SORT,
      size: TABLES_DEF_ROWS_PER_PAGE,
      columns: [{} as TableColumn],
      sizeOptions: TABLES_ROWS_PER_PAGE_LIST,
      windowWidth: 0,
    };
  }
}
