<button type="button" (click)="toggleMenu()">
  <slc-avatar
    class="slc-avatar-40"
    [userFullName]="username"
    [imagePath]="avatar"
  ></slc-avatar>
</button>

<div *ngIf="isOpen" class="menu-user-dropdown">
  <ng-container *ngFor="let item of items">
    <ng-container [ngSwitch]="item.type">
      <a
        *ngSwitchCase="ElemTypes.link"
        [routerLink]="item.routePath"
        (click)="itemClicked(item)"
        >{{ item.translateKey | transloco }}</a
      >
      <button *ngSwitchCase="ElemTypes.button" (click)="itemClicked(item)">
        {{ item.translateKey | transloco }}
      </button>
      <div *ngSwitchDefault>not defined element</div>
    </ng-container>
  </ng-container>

  <span class="version-label">{{ appVersion }}</span>
</div>
