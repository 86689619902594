import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'slc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit, OnDestroy {
  @Input() value: boolean | undefined;
  @Input() formCtrl: AbstractControl;
  @Input() disabled: boolean;

  // title
  @Input() label: string | undefined; // first priority
  @Input() translateKey: string | undefined; // second priority
  @Input() defaultName: string | undefined; // third priority

  private d$ = new Subject();
  public styles: {
    width: string;
    height: string;
  };

  @Output() changed = new EventEmitter<boolean>();

  @HostListener('click', ['$event.target'])
  onClick(): void {
    if (!this.disabled) {
      const newVal = !this.value;
      this.value = newVal;
      this.changed?.emit(newVal);
      this.formCtrl?.setValue(newVal);
    }
  }
  @HostBinding('class') get classes(): string {
    return this.disabled ? 'slc-checkbox-disabled' : '';
  }

  constructor(private elRef: ElementRef, private tS: TranslocoService) {}
  ngOnInit(): void {
    this.tS
      .selectTranslation()
      .pipe(
        filter(vocabulary => {
          return Boolean(Object.values(vocabulary).length);
        }),
        takeUntil(this.d$),
      )
      .subscribe(() => this.initTitle());

    if (this.elRef.nativeElement.className.includes('slc-checkbox-square')) {
      this.styles = {
        width: '10px',
        height: '8px',
      };
    }
    if (this.elRef.nativeElement.className.includes('slc-checkbox-16')) {
      this.styles = {
        width: '9px',
        height: '7px',
      };
    }
  }

  ngOnDestroy(): void {
    this.d$.next();
    this.d$.complete();
  }

  private initTitle(): void {
    if (!this.label) {
      if (
        this.translateKey &&
        this.translateKey !== this.tS.translate(this.translateKey)
      ) {
        this.label = this.tS.translate(this.translateKey);
      } else {
        this.label = this.defaultName;
      }
    }
    const el = this.elRef.nativeElement as HTMLElement;
    if (!el.getAttribute('title') && this.label) {
      el.setAttribute('title', this.label);
    }
  }
}
