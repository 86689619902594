export enum BriefProposalStatus {
  PRE_SELECTED = 'PRE_SELECTED',
  INVITED = 'INVITED',
  CREATOR_ACCEPTED = 'CREATOR_ACCEPTED',
  CREATOR_DECLINED = 'CREATOR_DECLINED',
  BRAND_DECLINED = 'BRAND_DECLINED',
  BRAND_ACCEPTED = 'BRAND_ACCEPTED',
  SLICE_RECOMMENDED = 'SLICE_RECOMMENDED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}
