<button
  type="button"
  (click)="isInstagramConnected && !isExpiredFlow ? disconnect() : connect()"
  [disabled]="!isInited || connectionInProgress || disabled"
>
  <ng-content></ng-content>
</button>

<slice-select-account-modal
  *ngIf="showAccountSelectionModal"
  [channel]="CHANNELS.INSTAGRAM"
  [accounts]="accounts"
  (closed)="accountSelected($event)"
>
</slice-select-account-modal>

<!--<slice-result-modal
  *ngIf="showConnectErrorMsg"
  iconPath="/assets/svg/something-wrong.svg"
  text="{{ errorMsg }}"
  (closed)="closeConnectErrMsg()"
></slice-result-modal>-->

<slice-result-modal
  *ngIf="showDisconnectErrorMsg"
  iconPath="/assets/svg/something-wrong.svg"
  text="{{ 'connect-socials.disconnect-err-msg.msg-1' | transloco }}{{
    CHANNELS.INSTAGRAM
  }}{{ 'connect-socials.disconnect-err-msg.msg-2' | transloco }}"
  (closed)="showDisconnectErrorMsg = false"
></slice-result-modal>

<slice-instagram-connect-info
  [isVisible]="showFacebookSocialMsg"
  (closed)="popupSocialMsgClosed($event)"
></slice-instagram-connect-info>
