import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { UiStateService } from '@slice-services/ui-state.service';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { AssetsType, NewAssetsType } from '@slice-enums/assets/asset-type';

import { ASSETS_IG_IMG_REQUIREMENTS } from '../../../_const/assets-ig-image-requirements';
import { ASSETS_IG_VIDEO_REQUIREMENTS } from '../../../_const/assets-ig-video-requirements';

@Component({
  selector: 'slice-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent
  extends AbstractSubscriberComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() files: Array<File>;
  @Input() multiple: boolean;
  @Input() assetType!: AssetsType | NewAssetsType;

  private dragWrap: HTMLElement;
  public isDragged = false;
  public maxSizeByte: number;
  public typesRestriction?:
    | 'image/*'
    | 'video/*'
    | 'image/*, video/*'
    | '.pdf'
    | '.pdf, image/*';

  @Output() filesSelected = new EventEmitter<Array<File>>();
  @Output() removeFile = new EventEmitter<number>();

  @ViewChild('pFileUpload') pFileUpload: FileUpload;

  constructor(
    private tS: TranslocoService,
    private elRef: ElementRef,
    public uiStateS: UiStateService,
    private mS: MessageService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.restrictSelectionByType();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    this.dragWrap = (
      this.elRef.nativeElement as HTMLElement
    ).getElementsByClassName('p-fileupload-content')[0] as HTMLElement;
    this.dragWrap.addEventListener('dragenter', () => {
      this.dragEnter();
    });
    this.dragWrap.addEventListener('dragleave', () => {
      this.dragLeave();
    });
    this.dragWrap.addEventListener('drop', () => {
      this.dragLeave();
    });
  }

  private restrictSelectionByType(): void {
    if (
      this.assetType === AssetsType.IG_IMAGE ||
      this.assetType === AssetsType.AVATAR
    ) {
      this.typesRestriction = 'image/*';
    } else if (this.assetType === AssetsType.IG_VIDEO) {
      this.typesRestriction = 'video/*';
    } else if (this.assetType === AssetsType.MEDIA) {
      this.typesRestriction = 'image/*, video/*';
    } else if (this.assetType === NewAssetsType.PDF) {
      this.typesRestriction = '.pdf';
    } else if (this.assetType === NewAssetsType.IMG_PDF) {
      this.typesRestriction = '.pdf, image/*';
    } else {
      this.typesRestriction = undefined;
    }
  }

  dragEnter(): void {
    this.isDragged = true;
  }

  dragLeave(): void {
    this.isDragged = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  selected(e: { currentFiles: Array<File> }): void {
    const file = e.currentFiles[0];
    if (this.assetType === AssetsType.IG_IMAGE) {
      this.checkIgImage(file);
    } else if (this.assetType === AssetsType.IG_VIDEO) {
      this.checkIgVideo(file);
    } else {
      this.filesSelected?.emit(e.currentFiles);
    }
  }

  private checkIgVideoDuration(file: File): Observable<File> {
    return new Observable(obs => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = (): void => {
        let isValidFile = true;
        const duration = video.duration;
        if (duration > ASSETS_IG_VIDEO_REQUIREMENTS.durationMaxSec) {
          isValidFile = false;
          this.mS.add({
            summary: this.tS.translate('file-upload.video.error-toastr.title'),
            severity: 'error',
            life: 5 * 1000,
            detail: this.tS.translate(
              'file-upload.video.error-toastr.ig-duration-long',
            ),
          });
        }
        if (duration < ASSETS_IG_VIDEO_REQUIREMENTS.durationMinSec) {
          isValidFile = false;
          this.mS.add({
            summary: this.tS.translate('file-upload.video.error-toastr.title'),
            severity: 'error',
            life: 5 * 1000,
            detail: this.tS.translate(
              'file-upload.video.error-toastr.ig-duration-short',
            ),
          });
        }
        isValidFile ? obs.next(file) : obs.error();
        video.remove();
      };
      video.src = URL.createObjectURL(file);
    });
  }

  private checkIgVideo(file: File): void {
    this.checkIgVideoSize(file)
      .pipe(
        // switchMap(file => this.checkIgVideoFormat(file)),
        // switchMap(file => this.checkIgVideoDuration(file)),
        takeUntil(this.destroy$),
      )
      .subscribe(file => {
        this.filesSelected?.emit([file]);
      });
  }

  private checkIgVideoFormat(file: File): Observable<File> {
    const fileType = file.type;
    if (!ASSETS_IG_VIDEO_REQUIREMENTS.formats.includes(fileType)) {
      this.mS.add({
        summary: this.tS.translate('file-upload.video.error-toastr.title'),
        severity: 'error',
        life: 5 * 1000,
        detail: this.tS.translate('file-upload.video.error-toastr.ig-format'),
      });
      return throwError(false);
    } else {
      return of(file);
    }
  }

  private checkIgVideoSize(file: File): Observable<File> {
    return new Observable(obs => {
      const sizeBytes = file.size;
      if (sizeBytes > ASSETS_IG_VIDEO_REQUIREMENTS.maxFileSizeBytes) {
        this.mS.add({
          summary: this.tS.translate('file-upload.video.error-toastr.title'),
          severity: 'error',
          life: 5 * 1000,
          detail: this.tS.translate(
            'file-upload.video.error-toastr.ig-big-size',
          ),
        });
        obs.error();
      } else {
        obs.next(file);
      }
    });
  }

  private checkIgImage(file: File): void {
    this.checkIgImageSize(file)
      .pipe(
        // switchMap(file => this.checkIgImageFormat(file)),
        // switchMap(file => this.checkIgImageResolution(file)),
        takeUntil(this.destroy$),
      )
      .subscribe(file => {
        this.filesSelected?.emit([file]);
      });
  }

  // private isIgImageValidByAspectRatio(width: number, height: number): boolean {
  //   const gcd = (a: number, b: number): number => {
  //     return b ? gcd(b, a % b) : a;
  //   };
  //   const divisor = gcd(width, height);
  //   const aspRatio = { w: width / divisor, h: height / divisor };
  //   return true;
  // return
  // ASSETS_IG_VIDEO_REQUIREMENTS.pictureSizeMaximumAspectRatio[0] < aspRatio.w &&
  // ASSETS_IG_VIDEO_REQUIREMENTS.pictureSizeMaximumAspectRatio[0] > aspRatio.w
  // ASSETS_IG_VIDEO_REQUIREMENTS.pictureSizeMinimumAspectRatio[1] > aspRatio.h
  // ;
  // }

  private checkIgImageResolution(file: File): Observable<File> {
    return new Observable(obs => {
      const img = new Image();
      img.onload = (): void => {
        let isValid = true;
        const imgWidth = img.width;
        if (
          imgWidth < ASSETS_IG_IMG_REQUIREMENTS.minWidth ||
          imgWidth > ASSETS_IG_IMG_REQUIREMENTS.maxWidth
        ) {
          this.mS.add({
            summary: this.tS.translate('file-upload.image.error-toastr.title'),
            severity: 'error',
            life: 5 * 1000,
            detail: this.tS.translate(
              'file-upload.image.error-toastr.ig-width',
            ),
          });
          isValid = false;
        }
        // if (this.isIgImageValidByAspectRatio(img.width, img.height)) {
        //   this.mS.add({
        //     summary: this.tS.translate('file-upload.image.error-toastr.title'),
        //     severity: 'error',
        //     life: 5 * 1000,
        //     detail: this.tS.translate('todo'),
        //   });
        //   isValid = false;
        // }
        if (isValid) {
          obs.next(file);
        } else {
          obs.error();
        }
      };
      img.src = URL.createObjectURL(file);
    });
  }

  private checkIgImageFormat(file: File): Observable<File> {
    return new Observable(obs => {
      const fileType = file.type;
      if (!ASSETS_IG_IMG_REQUIREMENTS.formats.includes(fileType)) {
        this.mS.add({
          summary: this.tS.translate('file-upload.image.error-toastr.title'),
          severity: 'error',
          life: 5 * 1000,
          detail: this.tS.translate('file-upload.image.error-toastr.ig-format'),
        });
        obs.error();
      } else {
        obs.next(file);
      }
    });
  }

  private checkIgImageSize(file: File): Observable<File> {
    return new Observable(obs => {
      if (file.size > ASSETS_IG_IMG_REQUIREMENTS.maxFileSizeBytes) {
        this.mS.add({
          summary: this.tS.translate('file-upload.image.error-toastr.title'),
          severity: 'error',
          life: 5 * 1000,
          detail: this.tS.translate(
            'file-upload.image.error-toastr.ig-big-size',
          ),
        });
        obs.error();
      } else {
        obs.next(file);
      }
    });
  }

  checkIgImageAspectRatio(
    width: number,
    height: number,
  ): { w: number; h: number } {
    const gcd = (a: number, b: number): number => {
      return b ? gcd(b, a % b) : a;
    };
    const divisor = gcd(width, height);
    return { w: width / divisor, h: height / divisor };
  }
}
