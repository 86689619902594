import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconWhatsappComponent } from './whatsapp.component';

@NgModule({
  declarations: [SvgIconWhatsappComponent],
  imports: [CommonModule],
  exports: [SvgIconWhatsappComponent],
})
export class SvgIconWhatsappModule {}
