import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

interface DomRectOptions {
  parentEl: DOMRect;
  popup: DOMRect;
  body: DOMRect;
}

@Directive({
  selector: '[slicePopupPosition]',
})
export class PopupPositionDirective implements AfterViewInit {
  @Input() parentEl: HTMLElement;

  private indentToParent = 0;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.setPositionStyles();
  }

  private setPositionStyles(): void {
    const popup = this.elementRef.nativeElement as HTMLElement;
    const opt: DomRectOptions = {
      parentEl: this.parentEl.getBoundingClientRect(),
      popup: popup.getBoundingClientRect(),
      body: document.body.getBoundingClientRect(),
    };
    // after increase size of parent element it will deleted
    if (opt.parentEl) {
      popup.style.top = this.getYPosition(opt);
      popup.style.left = this.getXPosition(opt);
    }
  }

  private isExistEmptyBottomPlace(opt: DomRectOptions): boolean {
    return opt.parentEl.bottom + opt.popup.height < opt.body.bottom;
  }

  private isExistEmptyTopPlace(opt: DomRectOptions): boolean {
    return (
      opt.parentEl.top - opt.popup.height - this.indentToParent > opt.body.top
    );
  }

  private isExistEmptyVerticalPlace(opt: DomRectOptions): boolean {
    return this.isExistEmptyBottomPlace(opt) || this.isExistEmptyTopPlace(opt);
  }

  // first priority is - set to bottom place (previous ux)
  private getVerticalPosition(opt: DomRectOptions): number {
    if (this.isExistEmptyBottomPlace(opt)) {
      return opt.parentEl.bottom + this.indentToParent;
    } else if (this.isExistEmptyTopPlace(opt)) {
      return opt.parentEl.top - opt.popup.height - this.indentToParent;
    } else {
      return 0;
    }
  }

  private getVerticalValForShowInMiddleOfParentEl(opt: DomRectOptions): number {
    return opt.parentEl.top + opt.parentEl.height / 2 - opt.popup.height / 2;
  }

  private isPopupVisibleInAreaByVerticalPosition(
    possibleVerticalValue: number,
    opt: DomRectOptions,
  ): boolean {
    return (
      possibleVerticalValue + opt.popup.height > opt.body.bottom ||
      possibleVerticalValue < opt.body.top
    );
  }

  private getYPosition(opt: DomRectOptions): string {
    let value = this.isExistEmptyVerticalPlace(opt)
      ? this.getVerticalPosition(opt)
      : this.getVerticalValForShowInMiddleOfParentEl(opt);
    if (this.isPopupVisibleInAreaByVerticalPosition(value, opt)) {
      value = this.getYPositionForPinToBottom(opt) - this.indentToParent;
    }
    return `${value}px`;
  }

  private getYPositionForPinToBottom(opt: DomRectOptions): number {
    return opt.body.bottom - opt.popup.height;
  }

  private isExistRightEmptyPlaceForCaseWithVerticalEmptyPlace(
    opt: DomRectOptions,
  ): boolean {
    return opt.parentEl.left + opt.popup.width > opt.body.right;
  }

  private isExistRightEmptyPlaceForCaseWithoutVerticalEmptyPlace(
    opt: DomRectOptions,
  ): boolean {
    return (
      opt.parentEl.right + this.indentToParent + opt.popup.width >
      opt.body.right
    );
  }

  private getXPosition(opt: DomRectOptions): string {
    let val;
    if (this.isExistEmptyVerticalPlace(opt)) {
      if (this.isExistRightEmptyPlaceForCaseWithVerticalEmptyPlace(opt)) {
        val = opt.body.right - opt.popup.width;
      } else {
        val = opt.parentEl.left;
      }
    } else {
      if (this.isExistRightEmptyPlaceForCaseWithoutVerticalEmptyPlace(opt)) {
        val = opt.parentEl.left - opt.popup.width - this.indentToParent;
      } else {
        val = opt.parentEl.right + this.indentToParent;
      }
    }
    return `${val}px`;
  }
}
