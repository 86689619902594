import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { getProxiedImageUrl } from '@slc-libs/helpers';

@Component({
  selector: 'slc-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnChanges {
  @Input() userFullName: string | undefined;
  @Input() imagePath?: string | undefined;

  public initials: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.imagePath) {
      if (changes.imagePath.currentValue) {
        this.imagePath = getProxiedImageUrl(
          changes.imagePath.currentValue,
          100,
          100,
        );
      }
    }
    if (changes.userFullName) {
      if (changes.userFullName.currentValue) {
        this.initials = changes.userFullName.currentValue
          .split(' ')
          .map((i: string[]) => (i[0] ? i[0].toUpperCase() : ''))
          .slice(0, 2)
          .join('');
      } else {
        this.initials = undefined;
      }
    }
  }
}
