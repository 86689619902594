<ng-container [ngSwitch]="action">
  <ng-container *ngSwitchCase="actions.REPORT_CREATED">
    <slc-svg-icon-clock color="#F08833"></slc-svg-icon-clock>
    <span>{{ 'action-labels.waiting-for-creator-response' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="actions.LOOKING_FOR_CREATORS">
    <slc-svg-icon-clock color="#F08833"></slc-svg-icon-clock>
    <span>{{ 'action-labels.waiting-for-creator-response' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="actions.SCORED">
    <slc-svg-icon-clock color="#F08833"></slc-svg-icon-clock>
    <span>{{ 'action-labels.waiting-for-creator-response' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="actions.IG_SCORED">
    <slc-svg-icon-clock color="#F08833"></slc-svg-icon-clock>
    <span>{{ 'action-labels.waiting-for-creator-response' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="actions.YT_SCORED">
    <slc-svg-icon-clock color="#F08833"></slc-svg-icon-clock>
    <span>{{ 'action-labels.waiting-for-creator-response' | transloco }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="actions.BRAND_APPROVAL_PENDING">
    <slc-svg-icon-circle-wavy-check></slc-svg-icon-circle-wavy-check>
    <span>{{ 'action-labels.waiting-for-brand-approval' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="actions.PAID">
    <slc-svg-icon-circle-wavy-check></slc-svg-icon-circle-wavy-check>
    <span>{{ 'action-labels.in-progress' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="actions.IN_PROGRESS"
    ><slc-svg-icon-circle-wavy-check></slc-svg-icon-circle-wavy-check>
    <span>{{ 'action-labels.in-progress' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="actions.PAYMENT_PENDING"
    ><slc-svg-icon-coins></slc-svg-icon-coins>
    <span>{{ 'action-labels.waiting-brand-payment' | transloco }}</span>
  </ng-container>

  <ng-container *ngSwitchCase="actions.REPORT_ACTIVE">
    <span>{{ 'action-labels.active' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="actions.COMPLETED">
    <span>{{ 'action-labels.completed' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="actions.ARCHIVED">
    <span>{{ 'action-labels.archive' | transloco }}</span>
  </ng-container>
</ng-container>
