import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';

import { UiStateService } from '@slice-services/ui-state.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { BreakpointsService } from '@slc-libs/services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[slicePopupInfo]',
  templateUrl: './popup-info.component.html',
  styleUrls: ['./popup-info.component.scss'],
})
export class PopupInfoComponent implements OnInit, OnDestroy {
  @Input() slicePopupInfo: TemplateRef<any>;
  private d$ = new Subject();
  private _isHoverPopupShowed$ = new BehaviorSubject<boolean>(false);
  public isHoverPopupShowed: boolean;
  public isUsualPopupShowed: boolean;
  public isTouchScreen: boolean;

  @HostListener('mouseenter')
  mouseenter(): void {
    this._isHoverPopupShowed$.next(true);
  }

  @HostListener('mouseleave')
  mouseleave(): void {
    this._isHoverPopupShowed$.next(false);
  }

  @HostListener('window:resize')
  onResize(): void {
    this._isHoverPopupShowed$.next(false);
    this.isHoverPopupShowed = false;
    this.isUsualPopupShowed = false;
  }

  constructor(
    public elRef: ElementRef,
    public uiStateS: UiStateService,
    public bS: BreakpointsService,
  ) {}

  ngOnInit(): void {
    this.isTouchScreen = this.uiStateS.isTouchScreenDevice;
    this._isHoverPopupShowed$
      .asObservable()
      .pipe(debounceTime(750), takeUntil(this.d$))
      .subscribe(isShowed => {
        this.isHoverPopupShowed = isShowed;
      });
  }

  ngOnDestroy(): void {
    this.d$.next();
    this.d$.complete();
  }

  showUsusalPopup(): void {
    this.closeHoverPopup();
    this.isUsualPopupShowed = true;
  }

  hideUsusalPopup(): void {
    this.isUsualPopupShowed = false;
  }

  closeHoverPopup(): void {
    this.hideUsusalPopup();
    this._isHoverPopupShowed$.next(false);
    this.isHoverPopupShowed = false;
  }
}
