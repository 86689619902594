import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { takeUntil } from 'rxjs/operators';

import { BreakpointsService } from '@slc-libs/services';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

@Component({
  selector: 'slice-card-dashboard',
  templateUrl: './card-dashboard.component.html',
  styleUrls: ['./card-dashboard.component.scss'],
})
export class CardDashboardComponent
  extends AbstractSubscriberComponent
  implements OnInit
{
  @Input() btnLabel?: string = 'Submit';
  @Output() btnAction = new EventEmitter<any>();
  @Input() btnLabelSecondary?: any = null;
  @Output() btnActionSecondary = new EventEmitter<any>();

  isMobile = false;
  constructor(private breakpointsS: BreakpointsService) {
    super();
  }

  ngOnInit(): void {
    this.breakpointsS
      .selectIsMobile()
      .pipe(takeUntil(this.destroy$))
      .subscribe(isMobile => {
        this.isMobile = isMobile;
      });
  }

  onClickButton() {
    this.btnAction?.emit();
  }

  onClickButtonSecondary() {
    this.btnActionSecondary?.emit();
  }
}
