export enum RoleMenuEnum {
  team_management = 'TEAM_MANAGEMENT',
  plan_billing = 'SUBSCRIPTION_MANAGEMENT',
  invite_creator = 'INVITE_CREATOR',
  reporting = 'REPORTING',
  payment = 'PAYMENT',
  invite_by_email = 'INVITE_BY_EMAIL',
  // invite_member = 'INVITE_MEMBER',
  // invite_finance = 'INVITE_FINANCE',
  creators = 'CREATORS',
}
