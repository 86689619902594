import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AuthStateService } from '@slice-services/auth-state.service';
import { TiktokLoginService } from '@slice-services/tiktok-login.service';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { Channels } from '@slc-libs/enums';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

@Component({
  selector: 'slice-tiktok-connect',
  templateUrl: './tiktok-connect.component.html',
  styleUrls: ['./tiktok-connect.component.scss'],
})
export class TiktokConnectComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() set isConnected(isConnected: boolean | undefined) {
    if (typeof isConnected === 'boolean') {
      // this.isInited = true;
      this.isTiktokConnected = isConnected;
    }
  }
  @Input() accountId: string | undefined;
  @Input() disabled: boolean | undefined;
  @Input() redirectTo: string;
  @Input() showDisconnectSuccessMsg = true;
  @Output() actionInProgress = new EventEmitter<boolean>();
  @Output() actionConnected = new EventEmitter<boolean>();
  @Output() connected = new EventEmitter<boolean>();
  @Output() disconnected = new EventEmitter<boolean>();

  // public isInited = false;
  public isTiktokConnected = false;
  public isLoading: boolean;
  private userId: string;
  public showDisconnectErrorMsg = false;
  private isSuccessSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  isSuccess$: Observable<boolean> = this.isSuccessSubject.asObservable();

  setSuccess(value: boolean) {
    this.isSuccessSubject.next(value);
  }

  constructor(
    private authStateS: AuthStateService,
    public tiktokLoginS: TiktokLoginService,
    private messageS: MessageService,
    private tS: TranslocoService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.authStateS
      .selectAuthenticatedUser()
      .pipe(
        filter(r => Boolean(r)),
        takeUntil(this.destroy$),
      )
      .subscribe(u => (this.userId = u?.userId as string));
    this.tiktokLoginS.setInit(true);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  disconnect(): void {
    this.actionInProgress.emit(true);
    this.isLoading = true;
    this.tiktokLoginS
      .disconnect(this.accountId as string, this.userId)
      .subscribe(
        () => {
          this.actionInProgress.emit(false);
          this.disconnected?.emit(true);
          this.isLoading = false;
          if (this.showDisconnectSuccessMsg) {
            this.messageS.clear();
            this.messageS.add({
              severity: 'success',
              life: 7 * 1000,
              detail:
                this.tS.translate(Channels.TIKTOK) +
                ' ' +
                this.tS.translate('common.disconnected'),
            });
          }
        },
        () => {
          this.showDisconnectErrorMsg = true;
          this.isLoading = false;
          this.disconnected?.emit(false);
          this.actionInProgress.emit(false);
        },
      );
  }

  connect(): void {
    this.tiktokLoginS.setInit(true);
    this.tiktokLoginS.integrateTiktokUrl(this.redirectTo);
  }
}
