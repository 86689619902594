export enum RoleAccessEnum {
  all = 'all',
  view = 'view',
  create = 'create',
  update = 'update',
  filter = 'filter',
  upload = 'upload',
  download = 'download',
  archive = 'archive',
  delete = 'delete',
}
