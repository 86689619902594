import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconPaymentComponent } from './payment.component';

@NgModule({
  declarations: [SvgIconPaymentComponent],
  imports: [CommonModule],
  exports: [SvgIconPaymentComponent],
})
export class SvgIconPaymentModule {}
