<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M5.99844 0.400391C2.91124 0.400391 0.4 2.91395 0.4 6.00195V14.002C0.4 17.0892 2.91356 19.6004 6.00156 19.6004H14.0016C17.0888 19.6004 19.6 17.0868 19.6 13.9988V5.99883C19.6 2.91163 17.0864 0.400391 13.9984 0.400391H5.99844ZM15.6 3.60039C16.0416 3.60039 16.4 3.95879 16.4 4.40039C16.4 4.84199 16.0416 5.20039 15.6 5.20039C15.1584 5.20039 14.8 4.84199 14.8 4.40039C14.8 3.95879 15.1584 3.60039 15.6 3.60039ZM10 5.20039C12.6472 5.20039 14.8 7.35319 14.8 10.0004C14.8 12.6476 12.6472 14.8004 10 14.8004C7.3528 14.8004 5.2 12.6476 5.2 10.0004C5.2 7.35319 7.3528 5.20039 10 5.20039ZM10 6.80039C9.15131 6.80039 8.33737 7.13753 7.73726 7.73765C7.13714 8.33776 6.8 9.1517 6.8 10.0004C6.8 10.8491 7.13714 11.663 7.73726 12.2631C8.33737 12.8632 9.15131 13.2004 10 13.2004C10.8487 13.2004 11.6626 12.8632 12.2627 12.2631C12.8629 11.663 13.2 10.8491 13.2 10.0004C13.2 9.1517 12.8629 8.33776 12.2627 7.73765C11.6626 7.13753 10.8487 6.80039 10 6.80039Z"
    fill="#5D6468"
  />
</svg>
