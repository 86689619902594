import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconCloseComponent } from './close.component';

@NgModule({
  declarations: [SvgIconCloseComponent],
  imports: [CommonModule],
  exports: [SvgIconCloseComponent],
})
export class SvgIconCloseModule {}
