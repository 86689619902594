<ng-container [ngSwitch]="social">
  <ng-container *ngSwitchCase="Channels.INSTAGRAM">
    <div class="img-wrap slc-flex-c-c">
      <img src="/assets/svg/instagram-icon.svg" alt="" />
    </div>
    <span class="slc-text-ellipsis">{{ 'Instagram' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="Channels.YOUTUBE">
    <div class="img-wrap slc-flex-c-c">
      <img src="/assets/svg/youtube-icon.svg" alt="" />
    </div>
    <span class="slc-text-ellipsis">{{ 'YouTube' | transloco }}</span>
  </ng-container>

  <span class="amount" *ngIf="userAmount">{{ userAmount }}</span>
</ng-container>
