import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { filter, tap } from 'rxjs/operators';

import { BreakpointsService } from '@slc-libs/services';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { WizardProgressItem } from '@slice-interfaces/wizard/step';

@Component({
  selector: 'slice-wizard-progress',
  templateUrl: './wizard-progress.component.html',
  styleUrls: ['./wizard-progress.component.scss'],
})
export class WizardProgressComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() items: Array<WizardProgressItem> | null;

  public readonly domIdPrefix = 'js-slice-wizard-progress-';
  private isNotDesktop: boolean;
  @Output() itemSelected = new EventEmitter<WizardProgressItem>();

  @ViewChild('scrollContainer', { static: true })
  scrollContainer: ElementRef<HTMLElement>;

  constructor(
    private breakpointsS: BreakpointsService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items?.currentValue) {
      if (this.isNotDesktop) {
        this.scrollStepsForMobile(changes.items?.currentValue);
      }
    }
  }

  ngOnInit(): void {
    this.initStepsChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private initStepsChanges(): void {
    this.breakpointsS.selectIsNotDesktop().pipe(
      filter(i => Boolean(i)),
      tap(isNotDesktop => {
        this.isNotDesktop = isNotDesktop;
        isNotDesktop ? this.scrollStepsForMobile(this.items) : null;
      }),
    );
  }

  private scrollStepsForMobile(steps: Array<WizardProgressItem> | null): void {
    if (!steps) {
      return;
    }
    const currStep = steps?.find(i => i.isCurrent)?.value;
    const domElem = this.document.getElementById(this.domIdPrefix + currStep);
    if (
      typeof domElem?.offsetLeft === 'number' &&
      typeof this.scrollContainer.nativeElement.offsetLeft === 'number'
    ) {
      const isFirstStep = steps[0].value === currStep;
      const isLastStep = steps[steps.length - 1].value === currStep;
      if (isFirstStep) {
        this.scrollContainer.nativeElement.scrollLeft = 0;
      } else if (isLastStep) {
        setTimeout(() => {
          this.scrollContainer.nativeElement.scrollLeft =
            this.scrollContainer.nativeElement.scrollWidth;
        }, 100);
      } else {
        const delta =
          domElem?.offsetLeft - this.scrollContainer.nativeElement.offsetLeft;
        this.scrollContainer.nativeElement.scrollLeft = delta;
      }
    }
  }

  ngAfterViewInit(): void {
    this.scrollStepsForMobile(this.items);
  }

  setItem(s: WizardProgressItem): void {
    s.isTouched = true;
    this.itemSelected.emit(s);
  }
}
