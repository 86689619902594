<form [formGroup]="ratesFormGroup">
  <div
    *ngFor="let social of socials"
    [ngClass]="[social.socialType.toLowerCase()]"
  >
    <header class="slc-flex-sb-c">
      <img src="{{ social.imgPath }}" alt="img" />

      <div
        class="w-100 d-inline-block slc-text-align-right mr-2"
        *ngIf="tiktokForm && social.socialType === channels.TIKTOK && false"
      >
        <span class="p-input-icon-left">
          <i class="pi pi-at"></i>
          <input
            pInputText
            type="text"
            class="slc-input slc-input-with-bg input-name-tiktok"
            placeholder="your account"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="tiktokForm.username"
            (ngModelChange)="tiktokFormChange.emit(tiktokForm)"
          />
        </span>
        <p-inputNumber
          [inputStyleClass]="'slc-input slc-input-with-bg input-number-tiktok'"
          placeholder="your followers amount"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="tiktokForm.followersCount"
          (ngModelChange)="tiktokFormChange.emit(tiktokForm)"
        >
        </p-inputNumber>
      </div>

      <button
        class="slc-flex-c-c"
        (click)="toggleVisibility(social.socialType)"
      >
        <slc-svg-icon-chevron
          [ngClass]="{
            'slc-rotate-0': visibleSocials.includes(social.socialType)
          }"
        ></slc-svg-icon-chevron>
      </button>
    </header>

    <main *ngIf="visibleSocials.includes(social.socialType)">
      <div class="rate-hint slc-flex-fs-c">
        <span>{{ 'onboarding.step-rate.info-hint.title' | transloco }}</span>

        <slc-tooltip
          tooltipKey="onboarding.step-rate.info-hint.hint"
          okBtnTitle="{{ 'buttons.ok' | transloco }}"
        >
          <slc-svg-icon-question-circle></slc-svg-icon-question-circle>
        </slc-tooltip>
      </div>

      <div>
        <div *ngFor="let rate of social.rates" class="row slc-flex-sb-c">
          <div class="col-sm-6">
            <div class="item-value slc-flex-fs-c">
              <div>{{ rate.titleKey | transloco }}</div>
              <div>
                <p-inputNumber
                  [inputStyleClass]="
                    social.socialType === channels.INSTAGRAM ||
                    social.socialType === channels.TIKTOK
                      ? 'slc-input slc-input-with-bg'
                      : 'slc-input'
                  "
                  (keyup)="onKeyUp($event, social.socialType, rate.type)"
                  (onBlur)="onKeyUp($event, social.socialType, rate.type)"
                  formControlName="{{
                    getPriceFormCtrlName(social.socialType, rate.type)
                  }}"
                >
                </p-inputNumber>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="item-value-descr">
              {{ rate.descriptionKey | transloco }}
              <i
                class="pi pi-times-circle cursor-pointer my-auto"
                *ngIf="rate.additionalItem"
                (click)="deleteOptionalRate(social.socialType, rate)"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-full slc-text-align-right"
        *ngIf="social.socialType === channels.INSTAGRAM"
      >
        <p-menu
          #menu
          [popup]="true"
          [model]="additionalRates"
          [baseZIndex]="3000"
          appendTo="body"
        ></p-menu>
        <button
          pButton
          type="button"
          label="Add More"
          class="p-button-outlined p-button-rounded mt-3"
          [disabled]="!additionalRates.length"
          (click)="menu.toggle($event)"
        ></button>
      </div>
    </main>
  </div>
</form>
