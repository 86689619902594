<span *ngIf="!hideIcon && !showSvg" class="slice-loader-icon"></span>
<ng-container *ngIf="showSvg">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="15px"
    height="15px"
    viewBox="0 0 15 15"
    version="1.1"
  >
    <g id="surface1">
      <path
        style="
          stroke: none;
          fill-rule: nonzero;
          fill: rgb(29.019608%, 26.666667%, 26.666667%);
          fill-opacity: 1;
        "
        d="M 7.5 1.144531 C 3.988281 1.144531 1.144531 3.988281 1.144531 7.5 L 0 7.5 C 0 3.355469 3.355469 0 7.5 0 C 11.644531 0 15 3.355469 15 7.5 L 13.855469 7.5 C 13.855469 3.988281 11.011719 1.144531 7.5 1.144531 Z M 7.5 1.144531 "
      />
    </g>
  </svg>
</ng-container>
<span *ngIf="!hideText" class="slice-loader-text"
  >{{ 'common.loading' | transloco }}...</span
>
