import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconLogoOneColorComponent } from './svg-icon-logo-one-color.component';

@NgModule({
  declarations: [SvgIconLogoOneColorComponent],
  imports: [CommonModule],
  exports: [SvgIconLogoOneColorComponent],
})
export class SvgIconLogoOneColorModule {}
