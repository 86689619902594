export const ASSETS_IG_VIDEO_REQUIREMENTS = {
  formats: ['video/quicktime', 'video/mov', 'video/mp4'],
  audioCodec: 'AAC', // 48khz sample rate maximum, 1 or 2 channels (mono or stereo).
  videoCodec: ['HEVC', 'H264'],
  frameRate: [23, 60],
  pictureSizeMaximumColumns: 1920,
  pictureSizeMinimumAspectRatio: [4, 5],
  pictureSizeMaximumAspectRatio: [16, 9],
  videoBitrate: 'VBR', // 5Mbps maximum
  audioBitrate: '128kbps',
  durationMaxSec: 60,
  durationMinSec: 3,
  maxFileSizeBytes: 104857600,
};
