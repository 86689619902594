import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import {
  CALENDAR_CHANNELS,
  CALENDAR_DAY_TRANSLATE_KEY,
} from 'apps/slice/src/app/_const/calendar';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'slice-calendar-mobile-date',
  templateUrl: './calendar-mobile-date.component.html',
  styleUrls: ['./calendar-mobile-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarMobileDateComponent implements OnInit {
  @Input() date: string | undefined;

  public readonly CALENDAR_CHANNELS = CALENDAR_CHANNELS;
  public isToday: boolean;
  public dayName$: Observable<string>;
  public dateNumber: number;

  constructor(private tS: TranslocoService) {}
  ngOnInit(): void {
    this.dateNumber = new Date(this.date as string).getDate();
    this.initIsToday();
    this.initDayName();
  }

  private initDayName(): void {
    this.dayName$ = this.tS.selectTranslation().pipe(
      filter(v => Boolean(this.date && v && Object.values(v).length)),
      map(() => {
        const d = new Date(this.date as string);
        const tr = this.tS.translate(CALENDAR_DAY_TRANSLATE_KEY[d.getDay()]);
        return tr;
      }),
    );
  }

  private initIsToday(): void {
    this.isToday = moment(new Date())
      .startOf('day')
      .isSame(moment(new Date(this.date as string)).startOf('day'));
  }
}
