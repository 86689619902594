import { Injectable } from '@angular/core';

import { ProfileHttpService } from '@slice-services/profiles-http.service';
import { Observable } from 'rxjs';

import { ProfilePinChangePayload } from '@slice-interfaces/profile/creator-profile';

import { ChangePinStateService } from './change-pin-state.service';

@Injectable({
  providedIn: 'root',
})
export class ChangePinFacadeService {
  constructor(
    private stateS: ChangePinStateService,
    private profileHttpS: ProfileHttpService,
  ) {}
  setIsLoading(d: boolean): void {
    this.stateS.isLoading$.next(d);
  }
  selectIsLoading(): Observable<boolean> {
    return this.stateS.isLoading$.asObservable();
  }
  setIsModalChangePin(d: boolean): void {
    this.stateS.isShowModalChangePin$.next(d);
  }
  selectIsModalChangePin(): Observable<boolean> {
    return this.stateS.isShowModalChangePin$.asObservable();
  }
  setIsModalVerification(d: boolean): void {
    this.stateS.isShowModalVerification$.next(d);
  }
  selectIsModalVerification(): Observable<boolean> {
    return this.stateS.isShowModalVerification$.asObservable();
  }
  setIsModalForgotPin(d: boolean): void {
    this.stateS.forgotPinModal$.next(d);
  }
  selectIsModalForgotPin(): Observable<boolean> {
    return this.stateS.forgotPinModal$.asObservable();
  }
  setIsModalResetPin(d: boolean): void {
    this.stateS.isShowResetPin$.next(d);
  }
  selectIsModalResetPin(): Observable<boolean> {
    return this.stateS.isShowResetPin$.asObservable();
  }
  setVerificationCode(d: string): void {
    this.stateS.verificationCode$.next(d);
  }
  selectVerificationCode(): Observable<string> {
    return this.stateS.verificationCode$.asObservable();
  }
  setEmail(d: string): void {
    this.stateS.email$.next(d);
  }
  selectEmail(): Observable<string> {
    return this.stateS.email$.asObservable();
  }
  // fetchPlatformFee(): Observable<Array<PlatformFee>> {
  //   return this.walletBalanceHttpS
  //     .fetchPlatformFee()
  //     .pipe(tap(wb => this.setFetchedPlatformFee(wb)));
  // }
  // fetchCampaignList(): Observable<Array<CampaignListTopup>> {
  //   return this.walletBalanceHttpS
  //     .getListCampaignTopUp()
  //     .pipe(tap((wb: Array<CampaignListTopup>) => this.setCampaignTopUp(wb)));
  // }
  // submitTopup(payload: any): Observable<any> {
  //   return this.walletBalanceHttpS.submitTopUp(payload);
  // }
  // cancelTopup(id: string, payload: any): Observable<any> {
  //   return this.walletBalanceHttpS.cancelTopUp(id, payload);
  // }
  // setFetchedPlatformFee(d: Array<PlatformFee>): void {
  //   d?.forEach(v => {
  //     if (v.feeType === 'TOPUP_FEE') {
  //       this.setWithdrawalFee(v);
  //     }
  //   });
  // }
  // setWithdrawalFee(d: PlatformFee): void {
  //   this.stateS.withdrawalFee$.next(d);
  // }
  // selectWithdrawalFee(): Observable<PlatformFee | null> {
  //   return this.stateS.withdrawalFee$.asObservable();
  // }
  // getWithdrawalFee(): PlatformFee | null {
  //   return this.stateS.withdrawalFee$.value;
  // }
  // setCampaignTopUp(d: Array<CampaignListTopup> | []): void {
  //   this.stateS.campaignList$.next(d);
  // }
  // selectCampaignTopUp(): Observable<Array<CampaignListTopup>> {
  //   return this.stateS.campaignList$.asObservable();
  // }
  // getCampaignTopUp(): Array<CampaignListTopup> | [] {
  //   return this.stateS.campaignList$.value;
  // }

  // service https
  editPin(payload: ProfilePinChangePayload): Observable<any> {
    return this.profileHttpS.postPin(payload);
  }
  forgotPass(payload: any): Observable<any> {
    return this.profileHttpS.postEmailForgotPass(payload);
  }
  verificationEmail(payload: any): Observable<any> {
    return this.profileHttpS.postVerificationEmail(payload);
  }
  resetPinService(payload: any): Observable<any> {
    return this.profileHttpS.resetPin(payload);
  }
}
