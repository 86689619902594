import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconLetterComponent } from './letter.component';

@NgModule({
  declarations: [SvgIconLetterComponent],
  imports: [CommonModule],
  exports: [SvgIconLetterComponent],
})
export class SvgIconLetterModule {}
