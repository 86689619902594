import { Directive, OnDestroy, OnInit } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { BreakpointsService } from '@slc-libs/services';
import { Layouts } from '@slc-libs/services';
import { IsoLanguages } from '@slc-libs/types';

@Directive()
export abstract class AbstractCommonComponent implements OnDestroy, OnInit {
  public destroy$ = new Subject<void>();
  public layout$: Observable<Layouts>;
  public lang$: Observable<IsoLanguages>;
  public translateTrigger$ = new BehaviorSubject<Record<string, string> | null>(
    null,
  );

  constructor(
    private breakpointsService: BreakpointsService,
    protected translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.lang$ = this.translocoService.langChanges$.pipe(
      map(r => {
        return r;
      }),
    ) as Observable<IsoLanguages>;
    this.layout$ = this.breakpointsService.selectLayout();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initLang(): void {
    this.lang$ = this.translocoService.langChanges$.pipe(
      map(r => {
        return r;
      }),
    ) as Observable<IsoLanguages>;
  }

  initTranslationTrigger(): void {
    this.translocoService
      .selectTranslation()
      .pipe(
        filter(v => Boolean(v && Object.values(v).length)),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.translateTrigger$.next({}));
  }
}
