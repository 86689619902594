import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MessageService } from 'primeng/api';

import { ImageCropperTypes } from '@slice-enums/image-cropper';

@Component({
  selector: 'slice-img-cropper',
  templateUrl: './img-cropper.component.html',
  styleUrls: ['./img-cropper.component.scss'],
})
export class ImgCropperComponent implements OnInit {
  @Input() showPreview = true;
  @Input() cropperType: ImageCropperTypes;
  @Input() isModal = true;
  @Input() imageFile: File | undefined;
  @Input() aspectRatio = 1;
  @Input() applyLoading = false;

  public showModal = false;
  public loading = true;
  public cropperTypes = ImageCropperTypes;
  public croppedImageBase64: string | undefined;

  @Output() closeModal = new EventEmitter<string | undefined>();

  constructor(private messageS: MessageService, private tS: TranslocoService) {}

  ngOnInit(): void {
    if (this.isModal) {
      this.showModal = true;
    }
    if (this.cropperType === ImageCropperTypes.BACKGROUND) {
      this.aspectRatio = 25 / 10;
    } else if (this.cropperType === ImageCropperTypes.BRAND) {
      this.aspectRatio = 1;
    }
  }

  hidePopup(): void {
    this.showModal = false;
    this.closeModal?.emit();
  }

  cropperReady(): void {
    this.loading = false;
  }

  imageCropped(e: ImageCroppedEvent): void {
    this.croppedImageBase64 = e.base64 as string;
  }

  apply(): void {
    this.closeModal.emit(this.croppedImageBase64);
  }

  loadImageFailed(): void {
    this.messageS.add({
      severity: 'error',
      summary: this.tS.translate('crop-image.wrong-image-err.summary'),
      life: 5 * 1000,
      detail: this.tS.translate('crop-image.wrong-image-err.details'),
    });
  }
}
