import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@slice-env/environment';

import { GoogleAccount } from '@slice-interfaces/google/google-account';

declare let google: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleLoginService {
  protected client: any;
  public isInited = false;
  public inProcess = false;

  constructor(private http: HttpClient) {}

  private getRedirectUri(): string {
    if (environment.production) {
      if (environment.isDemo) {
        return 'https://app-dev.slice.id';
      } else if (environment.isStage) {
        return 'https://app-stage.slice.id';
      } else {
        return 'https://app.slice.id';
      }
    } else {
      return window.location.origin;
    }
  }

  init(codeFlowSuccess: (response: any) => any): Promise<void> {
    this.inProcess = true;
    return new Promise((resolve, reject) => {
      try {
        if (
          typeof document !== 'undefined' &&
          !document.getElementById('GOOGLE')
        ) {
          const googleJs = document.createElement('script');
          googleJs.async = true;
          googleJs.id = 'GOOGLE';
          googleJs.src = 'https://accounts.google.com/gsi/client';
          googleJs.onload = () => {
            this.client = google.accounts.oauth2.initCodeClient({
              client_id:
                environment.production &&
                !environment.isDemo &&
                !environment.isStage
                  ? '555156631943-69f4i4q06h37e76li38pa23sq0umina4.apps.googleusercontent.com'
                  : '607823831152-2qb32ld8ipfjtak40t5abch27t4os3e5.apps.googleusercontent.com',
              access_type: 'offline',
              redirect_uri: this.getRedirectUri(),
              scope:
                'https://www.googleapis.com/auth/youtube https://www.googleapis.com/auth/yt-analytics.readonly',
              ux_mode: 'popup',
              callback: codeFlowSuccess,
            });
            this.isInited = true;
            this.inProcess = false;
            resolve();
          };
          document.head.appendChild(googleJs);
        } else {
          this.inProcess = false;
          resolve();
        }
      } catch (err) {
        console.log('ERROR', err);
        this.inProcess = false;
        reject(err);
      }
    });
  }

  disconnect(channelId: string, userId: string): Observable<void> {
    return this.http.delete<void>(
      `/api/v1/users/${userId}/youtube-channel/${channelId}`,
    );
  }

  launchGoogleAuthCodeFlow(): void {
    this.client.requestCode();
  }

  authCodeGoogle(code: string): Observable<any> {
    return this.http.post<void>('/api/v1/integration/youtube/auth-code', {
      authCode: code,
      redirectUri: window.location.origin,
    });
  }

  getGoogleAccounts(): Observable<Array<GoogleAccount>> {
    return this.http.get<Array<GoogleAccount>>(
      '/api/v1/integration/youtube/channels',
    );
    // mock
    // .pipe(
    //   map(r => {
    //     if (!environment.production) {
    //       const mock1 = JSON.parse(JSON.stringify(r[0]));
    //       mock1.id = '1';
    //       const mock2 = JSON.parse(JSON.stringify(r[0]));
    //       mock2.id = '2';
    //       return [...r, mock1, mock2];
    //     } else {
    //       return r;
    //     }
    //   }),
    // )
  }

  postId(userId: string, accountId: string): Observable<void> {
    return this.http.post<void>(`/api/v1/users/${userId}/youtube-channel`, {
      id: accountId,
    });
  }
}
