import { E_ROUTES, ROLE_ROUTES, UserTypes } from '@slc-libs/enums';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getBasicRouteByUserType = (userType?: UserTypes): Array<any> => {
  const base = [E_ROUTES.BASE];
  const type = userType;
  if (type) {
    if (type === UserTypes.BRAND) {
      base.push(E_ROUTES.BRAND);
    } else if (type === UserTypes.CREATOR) {
      base.push(E_ROUTES.CREATOR);
    } else if (type === UserTypes.MANAGER) {
      base.push(E_ROUTES.MANAGER);
    } else if (type === UserTypes.ADMIN) {
      base.push(E_ROUTES.ADMIN);
    } else if (type === UserTypes.AGENCY) {
      base.push(E_ROUTES.AGENCY);
    } else {
      throw new Error('Routing for role not defined: ' + type);
    }
  }
  return base;
};

export const getBaseRoute = (
  route: E_ROUTES,
  userType?: UserTypes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Array<any> => {
  if (userType && ROLE_ROUTES.includes(route)) {
    return getBasicRouteByUserType(userType);
  } else {
    return [E_ROUTES.BASE];
  }
};

export const getFullSegmentList = (
  route: E_ROUTES,
  userType?: UserTypes,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Array<any> => {
  let res = [];
  if (route === E_ROUTES.BASE) {
    res = [E_ROUTES.BASE];
  } else {
    res = getBaseRoute(route, userType);
    res.push(route);
  }
  return res;
};

export const getFullSegmentListSubsRoute = (
  route: E_ROUTES,
  userType?: UserTypes,
): string[] => {
  const segments: string[] = [];

  if (route === E_ROUTES.BASE) {
    segments.push(E_ROUTES.BASE);
  } else {
    const baseSegments = getBaseRoute(route, userType);

    // Handle URL-encoded segments
    const decodedRoute = decodeURIComponent(route as string);
    const allSegments = decodedRoute.split('/');

    // Remove empty segments
    const cleanedSegments = allSegments.filter(segment => segment !== '');

    // Add base segments (if any)
    baseSegments.forEach(baseSegment => segments.push(baseSegment));

    // Add the remaining segments
    cleanedSegments.forEach(segment => segments.push(segment));
  }

  return segments;
};

export const getRoutePath = (route: E_ROUTES, useRole?: UserTypes): string => {
  const segments = getFullSegmentList(route, useRole);
  if (segments.length === 1) {
    return segments.join('');
  } else {
    segments.shift();
    return '/' + segments.join('/');
  }
};
