import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconImageComponent } from './image.component';

@NgModule({
  declarations: [SvgIconImageComponent],
  imports: [CommonModule],
  exports: [SvgIconImageComponent],
})
export class SvgIconImageModule {}
