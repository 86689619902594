<button
  (click)="select()"
  type="button"
  class="slc-flex-fs-c"
  [disabled]="disabled"
  title="{{ label }}"
  [ngClass]="outerValue && currValue === outerValue ? 'selected' : ''"
>
  <ng-content></ng-content>

  <ng-container *ngIf="!hideDefaultTmpl">
    <div class="slice-radio-icon slc-flex-c-c">
      <slc-svg-icon-checkmark></slc-svg-icon-checkmark>
    </div>
    <span class="slice-radio-label slc-text-ellipsis">{{ label }}</span>
  </ng-container>
</button>
