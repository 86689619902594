import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';
import { AssetsHttpService } from '@slice-services/assets-http.service';
import { PostProposalsHttpService } from '@slice-services/post-proposals-http.service';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { Channels, PostTypes } from '@slc-libs/enums';

import { environment } from '@slice-env/environment';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { BreadcrumbsWithoutLinksItem } from '@slice-interfaces/breadcrumbs-without-links';
import {
  BriefProposalDetailsModel,
  ChannelProposalModel,
} from '@slice-interfaces/brief-proposal/brief-proposals';
import { AssetsType } from '@slice-enums/assets/asset-type';

import { AssetsTitleKeyMap } from '../../../_const/assets-titles';
import { validateEmptyTextarea } from '../../../_helpers/validate-empty-textarea';

// eslint-disable-next-line prettier/prettier, no-useless-escape
// const htp = RegExp(^#?[^s!@#$%^&*()=+./,[{]};:'"?><]+$);
// const htp = RegExp(/^#?[^s!@#$%^&*()=+./,[{]};:'"?><]+$/gi);

@Component({
  selector: 'slice-submit-content-popup',
  templateUrl: './submit-content-popup.component.html',
  styleUrls: ['./submit-content-popup.component.scss'],
})
export class SubmitContentPopupComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() channelProposal?: ChannelProposalModel;
  @Input() briefProposal?: BriefProposalDetailsModel;

  public fileList: Array<File> = [];
  public form = new UntypedFormGroup({});
  public isVisible = true;
  public loading = false;
  public validHashtags: Array<string> = [];
  public postTypeKey: string;
  public dateTime: string;
  public assetType: AssetsType;
  public AssetsTypes = AssetsType;
  public breadcrumbs: Array<BreadcrumbsWithoutLinksItem>;
  public assetsTitleKeyMap = AssetsTitleKeyMap;
  public assetRequirementsHTML$ = new BehaviorSubject<
    string | null | undefined
  >(undefined);
  public tooltipCssClass = 'slice-submit-content-popup-asset-requirements';

  @Output() contentSubmitted = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private postProposalsHttpS: PostProposalsHttpService,
    private assetsHttpS: AssetsHttpService,
    private mS: MessageService,
    private tS: TranslocoService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
    this.initHeaderTitle(this.channelProposal?.channel);
    this.initAssetType();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private initAssetType(): void {
    console.log(this.channelProposal?.channel, this.channelProposal?.postType);
    this.assetType = this.getAssetType(
      this.channelProposal?.channel as Channels,
      this.channelProposal?.postType as PostTypes,
    );
  }

  private initHeaderTitle(channel?: Channels): void {
    if (channel === Channels.INSTAGRAM) {
      if (this.channelProposal?.postType === PostTypes.POST) {
        this.postTypeKey = 'common.post';
      } else if (this.channelProposal?.postType === PostTypes.VIDEO) {
        this.postTypeKey = 'common.video';
      } else if (this.channelProposal?.postType === PostTypes.REELS) {
        this.postTypeKey = 'common.reels';
      }
    } else if (channel === Channels.YOUTUBE) {
      this.postTypeKey = 'common.video';
    } else {
      this.postTypeKey = '';
    }
    this.initBreadCrumbs(this.postTypeKey);
  }

  private initBreadCrumbs(postTypeKey: string): void {
    this.breadcrumbs = [
      { title: this.briefProposal?.brandName },
      { title: this.briefProposal?.campaignName },
      {
        title:
          this.tS.translate(this.channelProposal?.channel as string) +
          ' ' +
          this.tS.translate(postTypeKey),
      },
    ];
  }

  dateTimeSelected(dateTime: string): void {
    this.dateTime = dateTime;
  }

  submit(): void {
    if (
      this.fileList.length &&
      this.form.valid &&
      this.dateTime &&
      this.validHashtags.length
    ) {
      this.loading = true;
      if (this.fileList[0].size > 20000000) {
        this.mS.add({
          summary: this.tS.translate('file-upload.info-msg-for-big-size-title'),
          severity: 'info',
          life: 8 * 1000,
          detail: this.tS.translate(
            'file-upload.info-msg-for-big-size-description',
          ),
        });
      }
      this.assetsHttpS
        .uploadAssets(
          {
            type: this.assetType,
            file: this.fileList[0],
          },
          environment.mocks?.creator.offers.offer.postProposalActions
            .submitContent.uploadAssets,
        )
        .pipe(
          switchMap(res => {
            return this.postProposalsHttpS.submitContent(
              this.channelProposal?.id as string,
              {
                assetIds: [res.fileId],
                publicationTime: this.dateTime as string,
                caption: this.captionCtrl.value as string,
                hashtags: this.validHashtags.join(' '),
              },
              environment.mocks?.creator.offers.offer.postProposalActions
                .submitContent.submit,
            );
          }),
        )
        .subscribe(
          () => {
            this.loading = false;
            this.mS.clear();
            this.mS.add({
              summary: this.tS.translate(
                'submit-content.submitted-success-msg.title',
              ),
              severity: 'success',
              life: 5 * 1000,
              detail: this.tS.translate(
                'submit-content.submitted-success-msg.description',
              ),
            });
            this.contentSubmitted?.emit();
          },
          () => {
            this.loading = false;
          },
        );
    }
  }

  private getAssetType(channel: Channels, postType: PostTypes): AssetsType {
    if (channel === Channels.INSTAGRAM) {
      // if (postType === PostTypes.STORIES) {
      //   return AssetsType.IG_VIDEO;
      // }
      if (postType === PostTypes.REELS) {
        return AssetsType.IG_VIDEO;
      }
      if (postType === PostTypes.VIDEO) {
        return AssetsType.IG_VIDEO;
      }
      if (postType === PostTypes.POST) {
        return AssetsType.IG_IMAGE;
      }
      throw new Error(
        'SubmitContentPopupComponent: Not defined asset ' + Channels.INSTAGRAM,
      );
    } else if (channel === Channels.YOUTUBE) {
      return AssetsType.IG_VIDEO;
    } else {
      throw new Error('SubmitContentPopupComponent: Not defined assets');
    }
  }

  initForm(): void {
    this.form = this.fb.group({
      caption: [undefined, [Validators.required, Validators.maxLength(4096)]],
      hashtags: [
        undefined,
        // Validators.pattern(htp)
        [(Validators.required, validateEmptyTextarea)],
      ],
    });
    this.hashtagsCtrl.valueChanges
      .pipe(debounceTime(200), takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.hashtagsCtrl.valid) {
          this.validHashtags = this.hashtagsCtrl.value
            ? this.hashtagsCtrl.value
                .replace(/[^a-zA-Z0-9 ]/g, '')
                .split(' ')
                .map((i: string) => (i.startsWith('#') ? i : '#' + i))
                .filter((i: string) => i.length > 1)
            : [];
        } else {
          this.validHashtags = [];
        }
        if (this.validHashtags.length === 0) {
          this.hashtagsCtrl.setErrors({
            pattern: true,
          });
        } else {
          delete this.hashtagsCtrl.errors?.pattern;
        }
      });
  }
  get captionCtrl(): AbstractControl {
    return this.form.get('caption') as AbstractControl;
  }
  get hashtagsCtrl(): AbstractControl {
    return this.form.get('hashtags') as AbstractControl;
  }

  dropbox(): void {
    // https://www.dropbox.com/developers/chooser
    console.log('Submit');
  }

  googleDrive(): void {
    // https://developers.google.com/drive/picker/guides/overview
    console.log('Submit');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  selected(files: Array<File>): void {
    // files.forEach(i => {
    //   if (!this.fileList.includes(i)) {
    //     this.fileList.push(i);
    //   }
    // });
    this.fileList = files; // [...this.fileList];
  }

  deleteItem(f: File): void {
    this.fileList = this.fileList.filter(i => i !== f);
  }

  tooltipContentReady(html: string): void {
    this.assetRequirementsHTML$.next(html);
  }
}
