import { Component, EventEmitter, Input, Output } from '@angular/core';

import { NUMBERS_DECIMAL_VIEW } from '@slc-libs/constants';

@Component({
  selector: 'slc-widget-simple',
  templateUrl: './widget-simple.component.html',
  styleUrls: ['./widget-simple.component.scss'],
})
export class WidgetSimpleComponent {
  @Input() titleValue!: string;
  @Input() titleColored?: string;
  @Input() tooltipKey?: string;
  @Input() isInfoIcon?: boolean;
  @Input() isEditBtn?: boolean;
  @Input() percentChanges?: number;
  @Input() currency?: string;
  @Input() symbol?: string;
  @Input() okBtnTitle?: string;
  @Input() disableEllipsis?: boolean;
  @Input() set value(value: number | string | undefined | null) {
    this.isNumber = typeof value === 'number';
    this.val = value;
  }
  @Output() editClicked: EventEmitter<void> = new EventEmitter<void>();
  public readonly NUMBERS_DECIMAL_VIEW = NUMBERS_DECIMAL_VIEW;
  public isNumber: boolean;
  public val: number | string | undefined | null;
}
