import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslocoModule } from '@ngneat/transloco';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';

import { PhoneComponent } from './phone.component';

@NgModule({
  imports: [
    CommonModule,
    NgxIntlTelInputModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  declarations: [PhoneComponent],
  exports: [PhoneComponent],
})
export class PhoneModule {}
