import { HttpHeaders } from '@angular/common/http';

export const createNotAuthenticatedToken = (): string => {
  return 'Basic ' + btoa('web:KEQ6oLkJu6YO2khL');
};

export const createNotAuthorizationHttpHeaders = (): HttpHeaders => {
  return new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: createNotAuthenticatedToken(),
    'Access-Control-Allow-Origin': '*',
  });
};
