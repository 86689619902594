export enum SessionStorageKeys {
  backUrlAfterSignIn = 'slc-back-url-after-sign-in',

  // BRAND
  brandHomeCampaigns = 'slc-brand-home-campaigns-table-params',
  brandReportsIndividualTable = 'slc-brand-reports-individual-table-params',

  // CREATOR
  creatorHomeTable = 'slc-creator-home-campaigns-table-params',
  creatorOffersTable = 'slc-creator-offers-table-params',
  creatorRecommendationsTable = 'slc-creator-recommendations-table-params',
  creatorPaymentOptionsEarningsByCampaigns = 'slc-creator-payment-options-earnings-by-campaigns-params',

  // MANAGER
  managerHomeCampaignsTable = 'slc-manager-home-campaigns-table-params',

  // ADMIN
  adminHomeTableParams = 'slc-admin-home-table-params',
  adminClientsTableParams = 'slc-admin-clients-table-params',
  adminCampaignsTableParams = 'slc-admin-campaigns-table-params',
  adminCampaignDetailsTableParams = 'slc-admin-campaign-details-table-params',

  // mocks
}
