import { Injectable } from '@angular/core';

import { SessionStorageTableParams } from '@slc-libs/tables';

import { SessionStorageKeys } from '@slice-enums/session-storage-keys';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
  getTableParams(key: SessionStorageKeys): SessionStorageTableParams | null {
    const d = sessionStorage.getItem(key);
    return d ? JSON.parse(d) : null;
  }
  setTableParams(
    key: SessionStorageKeys,
    params: SessionStorageTableParams,
  ): void {
    sessionStorage.setItem(key, JSON.stringify(params));
  }
  removeTableParams(key: SessionStorageKeys): void {
    sessionStorage.removeItem(key);
  }

  setBackUrlAfterSignIn(url: string): void {
    sessionStorage.setItem(SessionStorageKeys.backUrlAfterSignIn, url);
  }
  getBackUrlAfterSignIn(): string | null {
    return sessionStorage.getItem(SessionStorageKeys.backUrlAfterSignIn);
  }
  removeBackUrlAfterSignIn(): void {
    sessionStorage.removeItem(SessionStorageKeys.backUrlAfterSignIn);
  }

  clearAfterChangeVersion(): void {
    Object.values(SessionStorageKeys).forEach(i => {
      sessionStorage.removeItem(i);
    });
  }
}
