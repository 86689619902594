import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AgencyInvitationsHttpService } from '@slice-services/agency-invitation/agency-invitations-http.service';
import { AuthStateService } from '@slice-services/auth-state.service';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import {
  InviteCreatorBulkParams,
  ResponseInviteCreator,
} from '@slice-interfaces/agency-invitation/agency-invite-payload';

@Component({
  selector: 'slice-modal-csv-creator',
  templateUrl: './modal-csv-creator.component.html',
  styleUrls: ['./modal-csv-creator.component.scss'],
})
export class ModalCsvInviteCreatorComponent extends AbstractSubscriberComponent {
  @Output() closed = new EventEmitter<boolean>();
  public isVisible = true;
  public isVisibleResponse = false;
  public isLoading = false;
  public responseData: ResponseInviteCreator;
  delimiter = ',';

  selectedFile: File | null = null;
  jsonData: any;

  constructor(
    private tS: TranslocoService,
    private agencyInvitationsHttp: AgencyInvitationsHttpService,
    private messageS: MessageService,
    private authStateS: AuthStateService,
  ) {
    super();
  }

  onDragOver(event: any): void {
    event.preventDefault();
  }

  onFileDropped(event: any): void {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;

    if (droppedFiles.length > 0) {
      const droppedFile = droppedFiles[0];
      const allowedFileTypes = ['text/csv', 'application/vnd.ms-excel']; // Add more if needed

      if (allowedFileTypes.includes(droppedFile.type)) {
        this.selectedFile = droppedFile;
      } else {
        this.messageS.add({
          severity: 'error',
          life: 5 * 1000,
          detail: 'Please drop a valid .csv file.',
        });
      }
    }
  }

  onFileSelected(input: any): void {
    this.selectedFile = input.files[0] as File;
  }

  readFile(): void {
    if (this.selectedFile) {
      const fileReader = new FileReader();

      fileReader.onload = e => {
        const content = fileReader.result as string;
        this.detectDelimiterAndConvert(content);
      };

      fileReader.readAsText(this.selectedFile);
    }
  }

  detectDelimiterAndConvert(csvContent: string): void {
    const possibleDelimiters = [',', '\t', ';', '|', ':']; // Add more delimiters if needed
    let maxDelimiterCount = 0;
    let bestDelimiter = ',';

    for (const delimiter of possibleDelimiters) {
      const lines = csvContent.split('\n');
      const delimiterCount = lines[0].split(delimiter).length;

      if (delimiterCount > maxDelimiterCount) {
        maxDelimiterCount = delimiterCount;
        bestDelimiter = delimiter;
      }
    }

    this.delimiter = bestDelimiter;
    this.convertCsvToJson(csvContent);
  }

  convertCsvToJson(csvContent: string): void {
    const lines = csvContent.split('\n');
    const headers = lines[0].split(this.delimiter);
    const result: InviteCreatorBulkParams[] = [];

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(this.delimiter);
      const entry: InviteCreatorBulkParams = {
        name: currentLine[0],
        waPhoneNumber: currentLine[1],
        email: currentLine[2],
      };

      if (headers.length > 3 && currentLine.length > 3) {
        entry['earnings'] = currentLine[3].replace(/\r/g, '');
      }

      result.push(entry);
    }
    this.jsonData = result
      .filter(
        el =>
          el.waPhoneNumber !== undefined &&
          el.waPhoneNumber !== null &&
          el.name !== undefined &&
          el.name !== null,
      )
      .map(el => ({
        ...el,
        waPhoneNumber: this.convertPhoneNumber(el.waPhoneNumber),
      }));

    this.submitData(this.jsonData);
  }

  convertPhoneNumber(phone: any): any {
    if (phone) {
      if (phone.startsWith('+62')) {
        return phone.substring(1); // Remove the "+"
      } else if (phone.startsWith('0')) {
        return '62' + phone.substring(1); // Replace leading 0 with 62
      } else {
        return phone; // Return the phone number as is if it doesn't match any pattern
      }
    }
  }

  closeResponseModal(): void {
    this.isVisibleResponse = false;
    this.jsonData = {};
    this.closed.emit();
  }

  downloadFile(): void {
    const filePath = '/assets/template-slice-invite-creator.csv';

    const link = document.createElement('a');
    link.href = filePath;
    link.download = 'template-slice-invite-creator.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  submitData(payload: Array<InviteCreatorBulkParams>) {
    if (payload.length > 0) {
      this.isLoading = true;
      this.agencyInvitationsHttp
        .inviteUsersBulk(
          this.authStateS.getAuthenticatedUser()?.agencyOwned as string,
          payload,
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (r: ResponseInviteCreator) => {
            this.isLoading = false;
            this.responseData = r;
            this.isVisibleResponse = true;
          },
          (err: Error) => {
            this.isLoading = false;
            if (err) {
              this.messageS.add({
                severity: 'error',
                life: 5 * 1000,
                detail: this.tS.translate('common.error-500'),
              });
            }
          },
        );
    } else {
      this.messageS.add({
        severity: 'error',
        life: 5 * 1000,
        detail: 'No creator data in the .csv file',
      });
    }
  }
}
