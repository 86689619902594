<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  (onHide)="closed.emit(false)"
>
  <div class="slice-wzrd-step">
    <button type="button" class="float-right pt-3" (click)="closed.emit(false)">
      <slc-svg-icon-close></slc-svg-icon-close>
    </button>

    <div class="slice-wzrd-step-title">
      {{ 'onboarding.step-common.title' | transloco }}
    </div>

    <div class="slc-flex-sb-c">
      <div class="img-wrap">
        <img src="/assets/svg/instagram.svg" alt="" />
      </div>

      <ng-container *ngIf="isDesktop$ | async">
        <ng-template *ngTemplateOutlet="connectBtnTmpl"></ng-template>
      </ng-container>
    </div>

    <p class="slc-mt-32">
      <span>{{ 'onboarding.step-instagram.msg-1' | transloco }}</span>
      <button>
        {{ 'onboarding.step-common.learnMore' | transloco }}
      </button>
    </p>
    <p>{{ 'onboarding.step-instagram.msg-2' | transloco }}</p>

    <p>
      <span>{{ 'onboarding.step-instagram.msg-3-1' | transloco }}</span>
      <span>{{ 'onboarding.step-instagram.msg-3-2' | transloco }}</span>
      <span>{{ 'onboarding.step-instagram.msg-3-3' | transloco }}</span>
    </p>

    <p>
      <span>{{ 'onboarding.step-instagram.msg-4-1' | transloco }}</span>
      <button>
        {{ 'onboarding.step-instagram.msg-4-2' | transloco }}
      </button>
      <span>{{ 'onboarding.step-instagram.msg-4-3' | transloco }}</span>
    </p>

    <ng-container *ngIf="(isDesktop$ | async) === false">
      <ng-template *ngTemplateOutlet="connectBtnTmpl"></ng-template>
    </ng-container>
  </div>
</p-dialog>

<ng-template #connectBtnTmpl>
  <div class="fb-btn slc-flex-fs-c slc-pos-rel" (click)="closed.emit(true)">
    <slc-svg-icon-facebook></slc-svg-icon-facebook>
    <span>
      {{ 'onboarding.step-instagram.connect-btn' | transloco }}
      Facebook
    </span>
  </div>
</ng-template>
