<ng-container *ngFor="let column of columns">
  <td
    *ngIf="
      !(
        isExpanRow &&
        windowWidth <= TABLES_MOBILE_BREAKPOINT &&
        column.isEmptyExpandColumn
      )
    "
    [ngStyle]="{
      width: column.width || 'auto',
      'min-width': column.width || 'auto'
    }"
    pFrozenColumn
    [ngClass]="[
      isExpanRow ? 'slice-p-table-expanded-body-column' : '',
      column.alignment ? alignments[column.alignment] : ''
    ]"
    [frozen]="column.frozen || false"
    alignFrozen="right"
  >
    <ng-container *ngIf="windowWidth <= TABLES_MOBILE_BREAKPOINT">
      <div
        *ngIf="isExpanRow"
        slc-table-head-cell
        [column]="column"
        [pTable]="pTable"
      ></div>
      <div
        *ngIf="!isExpanRow"
        slc-table-head-cell
        [pTable]="pTable"
        [column]="column"
      ></div>
    </ng-container>

    <ng-container *ngIf="column.cellTmpl">
      <ng-container
        *ngTemplateOutlet="
          column.cellTmpl;
          context: { item: item, index: index, column: column }
        "
      >
      </ng-container>
    </ng-container>

    <ng-container *ngIf="column.isExpandBtnColumn && expandColumnTmpl">
      <ng-container
        *ngTemplateOutlet="
          expandColumnTmpl;
          context: { item: item, index: index, column: column }
        "
      ></ng-container>
    </ng-container>

    <ng-container *ngIf="!column.cellTmpl && !column.isExpandBtnColumn">
      <ng-container *ngIf="!column.dataType; else specificValueTmpl">
        {{ item && column.field ? item[column.field] : '' }}
      </ng-container>
      <ng-template #specificValueTmpl>
        <ng-container [ngSwitch]="column.dataType">
          <ng-container *ngSwitchCase="TABLES_CELL_TYPE.date">
            {{
              (item && column.field ? item[column.field] : '')
                | date : DATE_FORMAT
            }}
          </ng-container>
          <ng-container *ngSwitchCase="TABLES_CELL_TYPE.number">
            {{
              (item && column.field ? item[column.field] : '')
                | number : NUMBERS_DECIMAL_VIEW
            }}
          </ng-container>
          <ng-container *ngSwitchCase="TABLES_CELL_TYPE.dateRange">
            date range - todo
          </ng-container>
          <ng-container *ngSwitchCase="TABLES_CELL_TYPE.percent">
            {{
              (item && column.field ? item[column.field] : '')
                | number : NUMBERS_DECIMAL_VIEW
            }}
            %
          </ng-container>
          <ng-container *ngSwitchDefault>not defined cell type </ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
  </td>
</ng-container>
