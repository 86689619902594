import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

import { FacebookAuthResponse } from '@slice-interfaces/facebook/facebook-auth-response';
import { FacebookAccount } from '@slice-interfaces/facebook/fb-account';

declare let FB: any;

@Injectable({
  providedIn: 'root',
})
export class FacebookLoginService {
  locale = 'en_US';

  public isInited = false;
  public inProcess = false;
  constructor(private translocoS: TranslocoService, private http: HttpClient) {}

  init(): Promise<void> {
    this.inProcess = true;
    return new Promise((resolve, reject) => {
      try {
        if (
          typeof document !== 'undefined' &&
          !document.getElementById('FACEBOOK')
        ) {
          const facebookJs = document.createElement('script');

          facebookJs.async = true;
          facebookJs.id = 'FACEBOOK';
          //TODO: Denys, please add locale from the user selected language/locale
          facebookJs.src = `https://connect.facebook.net/${this.translocoS.getActiveLang()}/sdk.js`;
          facebookJs.onload = () => {
            FB.init({
              appId: '605392684626325',
              autoLogAppEvents: true,
              cookie: true,
              status: false,
              xfbml: true,
              version: 'v19.0',
            });
            resolve();
            this.inProcess = false;
            this.isInited = true;
          };
          document.head.appendChild(facebookJs);
        } else {
          this.inProcess = false;
          resolve();
        }
      } catch (err) {
        this.inProcess = false;
        reject(err);
      }
    });
  }

  authCodeFb(token: string): Observable<any> {
    return this.http.post<void>('/api/v1/integration/instagram/token', {
      token,
    });
  }

  getFbAccounts(): Observable<Array<FacebookAccount>> {
    return this.http.get<Array<FacebookAccount>>(
      '/api/v1/integration/instagram/pages',
    );
    // mock
    // .pipe(
    //   map(r => {
    //     if (!environment.production) {
    //       const mock1 = JSON.parse(JSON.stringify(r[0]));
    //       mock1.id = '1';
    //       const mock2 = JSON.parse(JSON.stringify(r[0]));
    //       mock2.id = '2';
    //       return [...r, mock1, mock2];
    //     } else {
    //       return r;
    //     }
    //   }),
    // )
  }

  postPageId(userId: string, pageId: string): Observable<void> {
    return this.http.post<void>(`/api/v1/users/${userId}/instagram-account`, {
      pageId,
    });
  }

  disconnect(channelId: string, userId: string): Observable<void> {
    return this.http.delete<void>(
      `/api/v1/users/${userId}/instagram-account/${channelId}`,
    );
  }

  connectIgAccount(): Promise<FacebookAuthResponse> {
    return new Promise((resolve, reject) => {
      FB.login(
        (response: any) => {
          if (response.authResponse) {
            resolve(response.authResponse);
          } else {
            reject('User cancelled login or did not fully authorize.');
          }
        },
        {
          scope:
            'pages_show_list,pages_read_engagement,instagram_basic,instagram_manage_insights,business_management',
        },
      );
    });
  }
}
