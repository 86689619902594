import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import {
  AssetsUploadPayload,
  AssetsUploadResponse,
} from '@slice-interfaces/assets/upload-assets-payload';

@Injectable({
  providedIn: 'root',
})
export class AssetsHttpService {
  constructor(private http: HttpClient) {}

  uploadAssets(
    data: AssetsUploadPayload,
    useMocks?: boolean,
  ): Observable<AssetsUploadResponse> {
    if (useMocks) {
      return of({ fileId: 'mock-id' }).pipe(delay(1500));
    } else {
      const formData = new FormData();
      const file = data.base64
        ? this.base64toFile(data.base64, 'image.png')
        : data.file;
      formData.append('file', file as File);
      formData.append('type', data.type);
      return this.http.post<{
        fileId: string;
      }>('/api/v1/assets', formData);
    }
  }

  private base64toFile(dataurl: any, filename: any): File {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
