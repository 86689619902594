import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AuthStateService } from '@slice-services/auth-state.service';
import { GoogleLoginService } from '@slice-services/google-login-service';
import { UiStateService } from '@slice-services/ui-state.service';
import { MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { Channels } from '@slc-libs/enums';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { DIALOG_RESULT_MODAL_TYPE } from '@slice-shared/components/dialog-result-modal/dialog-result-modal.component';
import { ChannelAccountData } from '@slice-interfaces/channel-account-selector/channel-account-selector';
import { GoogleAccount } from '@slice-interfaces/google/google-account';

@Component({
  selector: 'slice-google-connect',
  templateUrl: './google-connect.component.html',
  styleUrls: ['./google-connect.component.scss'],
})
export class GoogleConnectComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() set isConnected(isConnected: boolean | undefined) {
    if (typeof isConnected === 'boolean') {
      this.isInited = true;
      this.isGoogleConnected = isConnected;
    }
  }
  @Input() accountId: string | undefined;
  @Input() showConnectSuccessMsg = true;
  @Input() showDisconnectSuccessMsg = true;
  @Input() disabled: boolean | undefined;
  @Input() isExpiredFlow: boolean | undefined;

  public isInited = false;
  public isGoogleConnected = false;
  public CHANNELS = Channels;
  public username: string | undefined;
  public showAccountSelectionModal = false;
  public isLoading: boolean;
  public showDisconnectErrorMsg = false;
  public accounts: Array<any>;
  private userId: string;

  @Output() actionInProgress = new EventEmitter<boolean>();
  @Output() disconnected = new EventEmitter<boolean>();
  @Output() connected = new EventEmitter<boolean>();

  constructor(
    private messageS: MessageService,
    private authStateS: AuthStateService,
    private googleLoginS: GoogleLoginService,
    private uiStateS: UiStateService,
    private tS: TranslocoService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.authStateS
      .selectAuthenticatedUser()
      .pipe(
        filter(r => Boolean(r)),
        takeUntil(this.destroy$),
      )
      .subscribe(u => (this.userId = u?.userId as string));
    this.initSdk();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private initSdk(): void {
    if (!this.googleLoginS.isInited && !this.googleLoginS.inProcess) {
      this.initGoogleSdk();
    }
  }

  disconnect(): void {
    this.actionInProgress.emit(true);
    this.isLoading = true;
    this.googleLoginS
      .disconnect(this.accountId as string, this.userId)
      .subscribe(
        () => this.disconnectSuccess(),
        () => this.disconnectFailed(),
      );
  }

  connect(): void {
    this.googleLoginS.launchGoogleAuthCodeFlow();
  }

  private handleGoogleResponse(response: { code: string }): void {
    this.isLoading = true;
    this.actionInProgress.emit(true);
    if (this.isExpiredFlow) {
      this.expiredFlow(response.code);
    } else {
      this.firstConnectFlow(response.code);
    }
  }

  private expiredFlow(code: string): void {
    this.googleLoginS
      .authCodeGoogle(code)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        isConnected => {
          if (isConnected) {
            this.connectionFinishedSuccess();
          }
        },
        () => {
          this.connectionFailed();
        },
      );
  }

  private firstConnectFlow(code: string): void {
    this.googleLoginS
      .authCodeGoogle(code)
      .pipe(
        switchMap(() => this.googleLoginS.getGoogleAccounts()),
        switchMap((accounts: Array<GoogleAccount>): any => {
          if (accounts?.length === 1) {
            return this.connectYoutube(accounts[0].id);
          } else {
            this.accounts = accounts.map(i => ({
              id: i.id,
              title: i.title,
              image: i.image,
            }));
            this.showAccountSelectionModal = true;
            return of(null);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        isConnected => {
          if (isConnected) {
            this.connectionFinishedSuccess();
          }
        },
        () => {
          this.connectionFailed();
        },
      );
  }

  private initGoogleSdk(): void {
    this.googleLoginS
      .init(res => {
        this.handleGoogleResponse(res);
      })
      .then(
        () => {
          //
        },
        err => {
          this.isLoading = false;
          this.actionInProgress.emit(false);
          console.error('GOOGLE. init failed ' + err);
        },
      );
  }

  private connectYoutube(id: string): Observable<boolean> {
    this.isLoading = true;
    this.actionInProgress.emit(true);
    return this.googleLoginS
      .postId(this.authStateS.getAuthenticatedUser()?.userId as string, id)
      .pipe(map(() => true));
  }

  accountSelected(acc: { account: ChannelAccountData | null }): void {
    if (acc.account) {
      this.isLoading = true;
      this.connectYoutube(acc.account?.id as string).subscribe(
        isConnected => {
          if (isConnected) {
            this.connectionFinishedSuccess();
          } else {
            this.connectionFailed();
          }
        },
        () => {
          this.connectionFailed();
        },
      );
    } else {
      this.isLoading = false;
      this.actionInProgress.emit(false);
      this.showAccountSelectionModal = false;
      this.uiStateS.showSliceModal(DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_YT);
    }
  }

  private connectionFailed(): void {
    this.actionInProgress.emit(false);
    this.connected.emit(false);
    this.isLoading = false;
    this.showAccountSelectionModal = false;
    this.uiStateS.showSliceModal(DIALOG_RESULT_MODAL_TYPE.ERROR_CONNECT_YT);
  }

  private connectionFinishedSuccess(): void {
    this.connected.emit(true);
    this.isLoading = false;
    this.actionInProgress.emit(false);
    this.showAccountSelectionModal = false;
    if (this.showConnectSuccessMsg) {
      this.messageS.add({
        severity: 'success',
        life: 7 * 1000,
        detail:
          this.tS.translate(Channels.YOUTUBE) +
          ' ' +
          this.tS.translate('common.connected'),
      });
    }
  }

  private disconnectSuccess(): void {
    this.disconnected.emit(true);
    this.isLoading = false;
    this.actionInProgress.emit(false);
    if (this.showDisconnectSuccessMsg) {
      this.messageS.add({
        severity: 'success',
        life: 7 * 1000,
        detail:
          this.tS.translate(Channels.YOUTUBE) +
          ' ' +
          this.tS.translate('common.disconnected'),
      });
    }
  }

  private disconnectFailed(): void {
    this.actionInProgress.emit(false);
    this.disconnected.emit(false);
    this.showDisconnectErrorMsg = true;
    this.isLoading = false;
  }
}
