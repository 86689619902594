import { Component } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-icon-exco',
  styles: [
    ':host { display: inline-flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './exco.component.html',
})
export class SvgIconExcoComponent extends AbstractSvgIconComponent {}
