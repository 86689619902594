import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconCalendarComponent } from './calendar.component';

@NgModule({
  declarations: [SvgIconCalendarComponent],
  imports: [CommonModule],
  exports: [SvgIconCalendarComponent],
})
export class SvgIconCalendarModule {}
