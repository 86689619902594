<ng-container *ngIf="layouts$ | async as layout">
  <ng-container *ngIf="stream$ | async as stream">
    <div class="content-wrap slc-flex-sb-c">
      <ng-container
        *ngIf="
          layout.isNotDesktop ||
            (pageTitle === undefined &&
              (customTmpl$ | async) === null &&
              (breadcrumbsWithoutLinks$ | async) === null);
          else pageTitleTmpl
        "
      >
        <div *ngIf="!isAgencyBasicPlan; else freeTier">
          <a [routerLink]="['/']">
            <slc-svg-icon-logo-one-color
              *ngIf="!stream.isProKit"
            ></slc-svg-icon-logo-one-color>
            <slc-svg-icon-logo
              *ngIf="stream.isProKit"
              [isProKit]="stream.isProKit"
              [isLight]="false"
            ></slc-svg-icon-logo>
          </a>
        </div>
      </ng-container>

      <ng-template #pageTitleTmpl>
        <ng-container *ngIf="isAgencyBasicPlan">
          <ng-container *ngTemplateOutlet="freeTier"></ng-container>
        </ng-container>
        <ng-container *ngIf="pageTitle && !isAgencyBasicPlan">
          <div class="header-title">{{ pageTitle }}</div>
        </ng-container>
        <ng-container *ngIf="customTmpl$ | async as tmpl">
          <ng-container *ngTemplateOutlet="tmpl"></ng-container>
        </ng-container>
        <ng-container *ngIf="breadcrumbsWithoutLinks$ | async as items">
          <slice-breadcrumbs-without-links
            [items]="items"
          ></slice-breadcrumbs-without-links>
        </ng-container>
      </ng-template>

      <slice-navigation *ngIf="isHeaderNavVisible$ | async"></slice-navigation>

      <ng-template #notAuthenticatedTmpl>
        <div class="slc-flex-fe-c">
          <nav *ngIf="layout.isDesktop">
            <a [routerLink]="creatorPath">{{
              'landing.common.nav-creators' | transloco
            }}</a>
            <a [routerLink]="advertiserPath">{{
              'landing.common.nav-brands' | transloco
            }}</a>
          </nav>
          <button
            title="{{ 'buttons.login' | transloco }}"
            class="slc-btn slc-btn-h-36 slc-btn-outline-white"
            (click)="goToLogin()"
            type="button"
          >
            <span>{{ 'buttons.login' | transloco }}</span>
          </button>

          <ng-container *ngIf="layout.isDesktop">
            <button
              title="{{ 'buttons.sign-up' | transloco }}"
              class="slc-btn slc-btn-h-36 slc-btn-success"
              (click)="goToSignUp()"
              type="button"
            >
              <span>{{ 'buttons.sign-up' | transloco }}</span>
            </button>

            <ng-container *ngIf="!layout.isMobile">
              <ng-template *ngTemplateOutlet="langTmpl"></ng-template>
            </ng-container>
          </ng-container>

          <button *ngIf="!layout.isDesktop">
            <ng-container *ngTemplateOutlet="hamburgerTmpl"></ng-container>
          </button>
        </div>
      </ng-template>

      <div class="slc-flex-fe-c slc-flex-grow-1">
        <button
          *ngIf="allowedToInvite()"
          type="button"
          class="slc-btn slc-btn-outline-blue plus-btn"
          (click)="goToTeamManage()"
        >
          <slc-svg-icon-plus-circle class="slc-mr-8"></slc-svg-icon-plus-circle>
          <span>Invite Member</span>
        </button>

        <ng-container *ngIf="!layout.isMobile">
          <ng-template *ngTemplateOutlet="langTmpl"></ng-template>
        </ng-container>
        <ng-container *ngIf="isAuthMenuVisible">
          <slice-menu-user *ngIf="stream.authUser"></slice-menu-user>
        </ng-container>
      </div>

      <ng-container *ngTemplateOutlet="hamburgerTmpl"></ng-container>
    </div>
  </ng-container>

  <ng-template #freeTier>
    <i class="pi pi-info-circle"></i>
    <span>
      &nbsp;&nbsp;You are in free tier.
      <a class="upgrade-text" (click)="goToUpgradePlan()">
        Upgrade to See More
      </a>
    </span>
  </ng-template>

  <ng-template #langTmpl>
    <slc-language
      *ngIf="!isAdminBuild"
      [currLang]="lang$ | async"
      (changed)="changedLang($event)"
    ></slc-language>
  </ng-template>

  <ng-template #hamburgerTmpl>
    <button
      *ngIf="layout.isMobile && isAuthMenuVisible"
      class="menu-btn slc-flex-c-c"
      type="button"
      (click)="toggleMenu()"
    >
      <slc-svg-icon-hamburger></slc-svg-icon-hamburger>
    </button>
  </ng-template>
</ng-container>
