import {
  Directive,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslocoService } from '@ngneat/transloco';
import { clone, isEqual } from 'lodash';
import { LazyLoadEvent, SortMeta } from 'primeng/api';
import { Table } from 'primeng/table';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { GetPageParams, PaginationResponse } from '@slc-libs/interfaces';
import { TablePaginationWords } from '@slc-libs/tables';
import { TableColumn } from '@slc-libs/tables';
import {
  TABLES_LIST_AMOUNTS,
  TABLES_MOBILE_BREAKPOINT,
  TABLES_ROWS_PER_PAGE_LIST,
} from '@slc-libs/tables';
import { SessionStorageTableParams } from '@slc-libs/tables';

const DATE_FORMAT = 'd MMM yyyy';

const TABLE_USER_ACTION_DELAY = 1000;

@Directive()
export abstract class AbstractTableComponent implements OnInit, OnDestroy {
  protected queryParamsKey: string;
  public destroy$ = new Subject<void>();
  public columns: Array<TableColumn>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public items: Array<any> = [];
  public DATE_FORMAT = DATE_FORMAT;
  public TABLES_ROWS_PER_PAGE_LIST = TABLES_ROWS_PER_PAGE_LIST;
  public readonly TABLES_MOBILE_BREAKPOINT = TABLES_MOBILE_BREAKPOINT;
  public first = 0;
  public size = TABLES_LIST_AMOUNTS.i10;
  public sorting: Array<SortMeta> = [];
  public totalRecords = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getItems$ = new Subject<Observable<any>>();
  public page = 0;
  private pageProgressData$ = new Subject<{
    fromVal: number;
    toVal: number;
    total: number;
  }>();
  public pageProgressTitle$: Observable<string>;
  public isLoading = true;
  public onLazyLoad$ = new Subject<LazyLoadEvent>();
  public lastSorting: Array<SortMeta> = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sessionStorageKey?: any; // for cases without store params in url
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public resize$ = new Subject<any>();
  public windowWidth: number;
  public paginationProgressWords$ = new BehaviorSubject<TablePaginationWords>({
    showing: undefined,
    to: undefined,
    of: undefined,
    entires: undefined,
  });

  public expandColumns: Array<TableColumn>;
  @ViewChild('pTable', { static: false }) pTable: Table;
  @HostListener('window:resize', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  onResize(event: any): void {
    this.resize$.next(event.target.innerWidth);
  }

  constructor(
    protected tS: TranslocoService,
    protected actRoute: ActivatedRoute,
    protected router: Router,
  ) {}

  ngOnInit(): void {
    if (!this.columns) {
      throw new Error(
        'Columns for table must be inited before ngOnInit for abstract control',
      );
    }
    this.windowWidth = window.innerWidth;
    this.initWindowResize();
    this.initParamsFromSessionStorage();
    this.initGetItems();
    this.initPageProgressTitleSub();
    this.initTranslation();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initTranslation(): void {
    this.tS
      .selectTranslation()
      .pipe(
        filter(vocabulary =>
          Boolean(vocabulary && Object.values(vocabulary).length),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.paginationProgressWords$.next({
          showing: this.tS.translate('pagination.showing'),
          to: this.tS.translate('pagination.to'),
          of: this.tS.translate('pagination.of'),
          entires: this.tS.translate('pagination.entires'),
        });
      });
  }

  private initGetItems(): void {
    this.getItems$
      .asObservable()
      .pipe(
        catchError(err => {
          this.items = [];
          this.isLoading = false;
          return throwError(err);
        }),
        tap(() => {
          this.isLoading = true;
          this.items = [];
        }),
        switchMap(request => request),
        takeUntil(this.destroy$),
      )
      .subscribe(r => {
        this.handlePaginationResponse(r);
      });
  }

  private initWindowResize(): void {
    this.resize$
      .asObservable()
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe(() => {
        this.windowWidth = window.innerWidth;
      });
  }

  private initPageProgressTitleSub(): void {
    this.pageProgressTitle$ = combineLatest([
      this.paginationProgressWords$.asObservable(),
      this.pageProgressData$.asObservable(),
    ]).pipe(
      map(([words, data]) => {
        return [
          words.showing,
          data.fromVal,
          words.to,
          data.toVal,
          words.of,
          data.total,
          words.entires,
        ]
          .filter(i => i)
          .join(' ');
      }),
    );
  }

  private initParamsFromSessionStorage(): void {
    if (this.sessionStorageKey) {
      const ssParams = this.getTableParams();
      if (ssParams) {
        this.first = ssParams.first;
        this.page = ssParams.page;
        this.size = ssParams.size;
        this.sorting = ssParams.sort;
      }
    }
  }

  private getTableParams(): SessionStorageTableParams | null {
    const d = sessionStorage.getItem(this.sessionStorageKey);
    return d ? JSON.parse(d) : null;
  }
  private setTableParams(params: SessionStorageTableParams): void {
    sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(params));
  }

  isExistSessionStorageData(): boolean {
    return Boolean(this.sessionStorageKey && this.getTableParams());
  }

  tableChangesAfterUserAction(
    delayMs?: number,
  ): Observable<LazyLoadEvent | null> {
    return this.onLazyLoad$.asObservable().pipe(
      debounceTime(delayMs || TABLE_USER_ACTION_DELAY),
      map((e: LazyLoadEvent) => {
        let isChanged = false;
        const newPage =
          (((e.first as number) / (e.rows as number)) as number) || 0;
        if (newPage !== this.page) {
          this.page = newPage;
          isChanged = true;
        }
        const newSize = e.rows || 0;
        if (newSize !== this.size) {
          this.size = newSize;
          isChanged = true;
        }
        const newSort = JSON.parse(JSON.stringify(e.multiSortMeta || []));
        if (!isEqual(newSort.sort(), this.lastSorting.sort())) {
          this.page = 0;
          this.sorting = clone(newSort);
          this.lastSorting = clone(newSort);
          isChanged = true;
        }
        return isChanged ? e : null;
      }),
      filter(isChanged => {
        return Boolean(isChanged);
      }),
      takeUntil(this.destroy$),
    );
  }

  handlePaginationResponse(r: PaginationResponse | null): void {
    if (r) {
      this.isLoading = false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.items = r.content as any;
      this.first = r.number * r.size;
      this.page = r.number;
      this.size = r.size;
      this.totalRecords = r.totalElements;
      this.setProgressData(r);
      if (this.sessionStorageKey) {
        this.updateSessionStorage();
      }
    } else {
      this.isLoading = false;
      this.items = [];
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItems(request$: Observable<any>): Observable<PaginationResponse> {
    this.isLoading = true;
    this.items = [];
    return request$.pipe(
      catchError(err => {
        this.items = [];
        this.isLoading = false;
        return throwError(err);
      }),
      tap(r => {
        this.handlePaginationResponse(r);
      }),
      takeUntil(this.destroy$),
    );
  }

  private updateSessionStorage(): void {
    if (this.sessionStorageKey) {
      this.setTableParams({
        first: this.first,
        page: this.page,
        size: this.size,
        sort: this.sorting,
      });
    }
  }

  private setProgressData(r: PaginationResponse): void {
    this.pageProgressData$.next({
      fromVal:
        typeof r?.size === 'number' && typeof r?.number === 'number'
          ? r.size * r.number + 1
          : 0,
      toVal:
        typeof r?.size === 'number' && typeof r?.number === 'number'
          ? r?.size * r?.number + r?.size
          : 0,
      total: r.totalElements,
    });
  }

  getBasicPaginationParams(): GetPageParams {
    return {
      page: this.page,
      size: this.size,
      sort: this.sorting,
    };
  }
}
