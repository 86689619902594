import { Component, HostBinding, Input } from '@angular/core';

import { ChannelPropsalStatus } from '@slc-libs/enums';

import { channelProposalMap, Config } from './config';

@Component({
  selector: 'slice-channel-proposal-status-label',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class ChannelProposalStatusLabelComponent {
  public status: any;
  @Input() set statusVal(val: ChannelPropsalStatus) {
    if (val) {
      this.status = val;
      this.config = channelProposalMap[val];
    }
  }

  public briefPostProposalMap: Record<ChannelPropsalStatus, Config>;

  public config: Config;

  @HostBinding('class') get classes(): string {
    return this.config?.cssColorClass || '';
  }
}
