<div style="cursor: {{ !isHoverPopupShowed ? 'wait' : 'default' }}">
  <ng-content></ng-content>
</div>

<slc-svg-icon-info-circle
  *ngIf="isTouchScreen"
  (click)="showUsusalPopup()"
></slc-svg-icon-info-circle>

<p-dialog
  *ngIf="isUsualPopupShowed"
  [(visible)]="isUsualPopupShowed"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="hideUsusalPopup()"
>
  <ng-template *ngTemplateOutlet="slicePopupInfo"></ng-template>
  <p-footer>
    <button
      class="slc-btn slc-btn-outline-blue"
      (click)="hideUsusalPopup()"
      type="button"
    >
      <span>{{ 'buttons.ok' | transloco }}</span>
    </button>
  </p-footer>
</p-dialog>

<div
  *ngIf="isHoverPopupShowed"
  class="popup-info"
  [ngClass]="{
    'popup-info-is-less-768-px': bS.winWidth < 768,
    'popup-info-is-touch-screen': uiStateS.isTouchScreenDevice
  }"
  slicePopupPosition
  [parentEl]="elRef.nativeElement"
>
  <div class="popup-info-content">
    <main>
      <ng-template *ngTemplateOutlet="slicePopupInfo"></ng-template>
    </main>

    <footer class="slc-flex-c-c">
      <button
        class="slc-btn slc-btn-outline-blue"
        (click)="closeHoverPopup()"
        type="button"
      >
        <span>{{ 'buttons.ok' | transloco }}</span>
      </button>
    </footer>
  </div>
</div>
