import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconCalendar1Component } from './calendar-1.component';

@NgModule({
  declarations: [SvgIconCalendar1Component],
  imports: [CommonModule],
  exports: [SvgIconCalendar1Component],
})
export class SvgIconCalendar1Module {}
