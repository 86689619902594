import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';

import { AppMenuStateService } from '@slice-services/app-menu-state.service';
import { CampaignHttpService } from '@slice-services/campaign-http.service';
import { NavigationService } from '@slice-services/navigation.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@slice-env/environment';

@Injectable()
export class BrandCampaignStatusRedirectionGuard implements CanActivate {
  constructor(
    private navS: NavigationService,
    private router: Router,
    private campaignHttpS: CampaignHttpService,
    private appMenuStateS: AppMenuStateService,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<UrlTree | boolean> {
    // eslint-disable-next-line no-restricted-syntax
    return this.campaignHttpS
      .getCampaignStatus(
        route.params.id,
        environment.mocks?.brand.campaignRedirectionGuard.getCampaignStatus,
      )
      .pipe(
        catchError(() => {
          return of(null);
        }),
        map(res => {
          let campaignId = route.params.id;
          let status = res?.status;
          // mocks
          if (
            environment.mocks?.brand.campaignRedirectionGuard
              .getCampaignStatus &&
            status
          ) {
            campaignId = 'id-test';
            if (
              environment.mocks?.brand.campaignRedirectionGuard.receivedStatus
            ) {
              status =
                environment.mocks?.brand.campaignRedirectionGuard
                  .receivedStatus;
            }
          }
          const currUrl = route.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/');
          const path = this.navS.getPathByCampaignStatusForBrand({
            status: status,
            campaignId: campaignId,
          }).path;
          this.appMenuStateS.openingCampaignId$.next(undefined);
          if (currUrl !== path) {
            return path ? this.router.parseUrl(path) : true;
          } else {
            return true;
          }
        }),
      );
  }
}
