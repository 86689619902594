import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AssetsHttpService } from '@slice-services/assets-http.service';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { AssetsUploadResponse } from '@slice-interfaces/assets/upload-assets-payload';
import { AssetsType } from '@slice-enums/assets/asset-type';
import { ImageCropperTypes } from '@slice-enums/image-cropper';

@Component({
  selector: 'slice-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss'],
})
export class AgencyProfileAvatarComponent
  extends AbstractSubscriberComponent
  implements OnDestroy
{
  @Input() name?: string;
  @Input() imgPath?: string;
  @Input() isEditMode?: boolean;

  public fileForCropper: File | undefined;
  public imageCropperTypes = ImageCropperTypes;
  public cropperType: ImageCropperTypes;
  public loading = false;

  @ViewChild('inputAvatar') inputAvatar: ElementRef<HTMLInputElement>;
  @ViewChild('inputBg') inputBg: ElementRef<HTMLInputElement>;

  @Output() fileUploaded = new EventEmitter<AssetsUploadResponse>();

  constructor(
    private imgHttpS: AssetsHttpService,
    private messageS: MessageService,
    private tS: TranslocoService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  uploadAvatar(e: any): void {
    const file = e.srcElement.files[0] as File;
    this.cropperType = ImageCropperTypes.AVATAR;
    this.fileForCropper = file;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  uploadBackground(e: any): void {
    const file = e.srcElement.files[0] as File;
    this.cropperType = ImageCropperTypes.BACKGROUND;
    this.fileForCropper = file;
  }

  initiateAvatarUpload(): void {
    this.inputAvatar.nativeElement.click();
  }

  initiateBgUpload(): void {
    this.inputBg.nativeElement.click();
  }

  closeCropper(base64?: string): void {
    this.inputAvatar.nativeElement.value = '';
    if (base64) {
      this.loading = true;
      this.imgHttpS
        .uploadAssets({
          base64: base64,
          type: AssetsType.AVATAR,
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          res => {
            this.loading = false;
            this.fileForCropper = undefined;
            this.fileUploaded.emit(res);
          },
          err => {
            this.loading = false;
            console.error(err);
            this.messageS.add({
              severity: 'error',
              summary: this.tS.translate('upload-images.server-err.summary'),
              life: 5 * 1000,
              detail: this.tS.translate('common.error-500'),
            });
          },
        );
    } else {
      this.fileForCropper = undefined;
    }
  }
}
