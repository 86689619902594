<p-autoComplete
  #autocomplete
  class="slc-flex-grow-1"
  [(ngModel)]="selectedUsers"
  [multiple]="true"
  [minLength]="2"
  [disabled]="disabled"
  [delay]="300"
  field="name"
  placeholder="{{ 'clients-selector.placeholder' | transloco }}"
  [suggestions]="suggestions"
  (completeMethod)="search($event)"
  (onSelect)="onSelect($event)"
>
  <ng-template let-user pTemplate="selectedItem">
    <slc-tag
      [defaultName]="user.name"
      [isDeletable]="true"
      [isSelected]="true"
      [isView]="true"
      [beforeTmpl]="avatarTmpl"
      (remove)="removeUser(user)"
    ></slc-tag>
    <ng-template #avatarTmpl>
      <slc-avatar
        [userFullName]="user?.name"
        [imagePath]="user?.avatar"
        class="slc-mr-8 slc-avatar-24"
      ></slc-avatar>
    </ng-template>
  </ng-template>
  <ng-template let-user pTemplate="item">
    <div class="slc-flex-fs-c">
      <slc-avatar
        class="slc-avatar-32 slc-mr-8"
        [userFullName]="user?.name"
        [imagePath]="user.avatar"
      ></slc-avatar>
      <div>{{ user.name }}</div>
    </div>
  </ng-template>
</p-autoComplete>
