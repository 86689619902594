import { Component, Input, OnInit } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AuthStateService } from '@slice-services/auth-state.service';
import { NavigationService } from '@slice-services/navigation.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IsoLanguages } from '@slc-libs/types';

import { AgencyPublicProfile } from '@slice-interfaces/agency-profile/agency-profile';

@Component({
  selector: 'slice-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss'],
})
export class PublicHeaderComponent implements OnInit {
  @Input() agencyInfo?: AgencyPublicProfile = {} as AgencyPublicProfile;
  public lang$: Observable<IsoLanguages>;
  public stream$: Observable<{ isAuthenticated: boolean }>;

  constructor(
    private tS: TranslocoService,
    private navigationS: NavigationService,
    private authStateS: AuthStateService,
  ) {}
  ngOnInit(): void {
    this.stream$ = this.authStateS
      .selectIsAuthenticated()
      .pipe(map(r => ({ isAuthenticated: r })));
    this.lang$ = this.tS.langChanges$ as Observable<IsoLanguages>;
  }

  changedLang(l: IsoLanguages): void {
    this.tS.setActiveLang(l);
  }

  goToSignUp(): void {
    this.navigationS.goToSignUp();
  }
}
