import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { createHttpParams } from '@slc-libs/helpers';
import { GetPageParams } from '@slc-libs/interfaces';

import {
  BankList,
  payloadCustomPayment,
  PayloadCustomPaymentV2,
} from '@slice-interfaces/bulk-payment/campaign';
import { WalletTransactionResponse } from '@slice-interfaces/wallet/get-list';
import {
  AccountInquiryPayload,
  CreatorWalletWithdrawPayload,
} from '@slice-interfaces/wallet/payload';
import {
  AccountInquiry,
  BankCodes,
  CampaignListTopup,
  ListBank,
  payloadExportTransaction,
  payloadMoveBalance,
  PlatformFee,
  WalletBalance,
} from '@slice-interfaces/wallet/wallet-balance';

@Injectable({
  providedIn: 'root',
})
export class WalletBalanceHttpService {
  constructor(private http: HttpClient) {}

  private howToTransferUrl = 'assets/top-up-en.json';
  fetchHowToTransfer(): Observable<any> {
    return this.http.get<any>(this.howToTransferUrl);
  }

  fetchListBank(): Observable<Array<ListBank>> {
    return this.http.get<Array<ListBank>>(
      `/api/v1/financial/basic/topup/fund-source`,
    );
  }
  fetchBankAccount(): Observable<Array<BankCodes>> {
    return this.http.get<Array<BankCodes>>(
      `/api/v1/financial/withdrawal/bank-codes`,
    );
  }
  fetchBankAccountv2(): Observable<Array<BankList>> {
    return this.http.get<Array<BankList>>(
      `/api/v1/financial/flexible-payment/bank-codes`,
    );
  }
  fetchLatestPayment(cId: string): Observable<any> {
    return this.http.get<any>(
      `/api/v1/financial/flexible-payment/latest-payment-info?creatorUserId=${cId}`,
    );
  }

  getListCampaignTopUp(): any {
    const req = this.http.get<Array<CampaignListTopup>>(
      `api/v1/financial/basic/topup/fund-campaign`,
    );
    return req;
  }
  moveBalance(payload: payloadMoveBalance): Observable<any> {
    return this.http.post<AccountInquiry>(
      '/api/v1/financial/basic/balance/transfer',
      payload,
    );
  }

  getBalanceDetail(): any {
    const req = this.http.get<Array<CampaignListTopup>>(
      `api/v1/financial/basic/balance/detail`,
    );
    return req;
  }
  getInvoiceDownloadLink(id: string): any {
    const req = this.http.get<any>(`api/v1/financial/invoice/${id}/download`);
    return req;
  }

  exportTransactionHistory(payload: payloadExportTransaction): any {
    let params = new HttpParams();
    Object.keys(payload).forEach(key => {
      const value = payload[key as keyof payloadExportTransaction];
      if (value) {
        params = params.set(key, value);
      }
    });
    const req = this.http.get<any>(
      `api/v1/financial/basic/transactions/download`,
      { params },
    );
    return req;
  }

  fetchWalletBalance(): Observable<WalletBalance> {
    return this.http.get<WalletBalance>(`/api/v1/financial/basic/balance`);
  }

  fetchAccountInquiry(
    payload: AccountInquiryPayload,
  ): Observable<AccountInquiry> {
    return this.http.post<AccountInquiry>(
      '/api/v1/financial/basic/account-inquiry',
      payload,
    );
  }

  fetchPlatformFee(): Observable<Array<PlatformFee>> {
    return this.http.get<Array<PlatformFee>>(
      `/api/v1/financial/basic/platform-fee`,
    );
  }

  fetchWalletTransactionHistory(
    params: GetPageParams,
  ): Observable<WalletTransactionResponse> {
    return this.http.get<WalletTransactionResponse>(
      `/api/v1/financial/basic/transactions`,
      {
        params: createHttpParams(params),
      },
    );
  }

  postWithdraw(payload: CreatorWalletWithdrawPayload): Observable<void> {
    return this.http.post<void>('/api/v1/financial/withdrawal', payload);
  }

  cancelTopUp(id: string, payload: any): Observable<void> {
    return this.http.patch<void>(
      `/api/v1/financial/basic/topup/${id}/cancel`,
      payload,
    );
  }

  submitTopUp(payload: any): Observable<void> {
    return this.http.post<void>(`/api/v1/financial/basic/topup`, payload);
  }

  postFlexiblePayment(payload: payloadCustomPayment): Observable<boolean> {
    return this.http.post<any>('/api/v1/financial/flexible-payment', payload);
  }
  postFlexiblePaymentV2(payload: PayloadCustomPaymentV2): Observable<boolean> {
    return this.http.post<any>(
      '/api/v1/financial/flexible-payment/custom-recipient',
      payload,
    );
  }
}
