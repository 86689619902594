import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';

import { AuthHttpService } from '@slice-services/auth-http.service';
import { AuthStateService } from '@slice-services/auth-state.service';
import { LocalStorageService } from '@slice-services/local-storage.service';
import { UiStateService } from '@slice-services/ui-state.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DIALOG_RESULT_MODAL_TYPE } from '@slice-shared/components/dialog-result-modal/dialog-result-modal.component';

@Injectable()
export class CreatorProfileCompletedGuard implements CanActivate {
  constructor(
    private lsS: LocalStorageService,
    private uiStateS: UiStateService,
    private authStateS: AuthStateService,
    private authHttpS: AuthHttpService,
  ) {}

  public canActivate(): Observable<boolean> {
    return this.check();
  }

  private check(): Observable<boolean> {
    return this.authHttpS.fetchWhatNext().pipe(
      map(el => {
        if (el?.length > 0) {
          const isConnectSocial = el.find(
            dt => dt.type === ('CONNECT_SOCIAL_MEDIA' as any),
          );

          if (!isConnectSocial) {
            this.uiStateS.setModalRateCard(true);
            return false;
          }
          this.uiStateS.setModalRateCard(false);
          return true;
        }
        if (el?.length <= 0) {
          this.uiStateS.setModalRateCard(true);
          return false;
        }
        this.uiStateS.setModalRateCard(false);
        return true;
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }
}
