import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconInfoSquareComponent } from './info-square.component';

@NgModule({
  declarations: [SvgIconInfoSquareComponent],
  imports: [CommonModule],
  exports: [SvgIconInfoSquareComponent],
})
export class SvgIconInfoSquareModule {}
