<ng-container *ngIf="column.headerTmpl; else titleTmpl">
  <ng-template *ngTemplateOutlet="column.headerTmpl"></ng-template>
</ng-container>

<ng-template #titleTmpl>
  <div
    class="{{
      column.alignment ? alignmnets[column.alignment] : 'slc-flex-fs-c'
    }}"
  >
    <span class="slc-table-head-title slc-text-ellipsis">
      {{ column.titleKey | transloco }}
    </span>
    <slc-tooltip
      class="slc-ml-8"
      *ngIf="column.tooltipKey || column.tooltipMarkupHTML$"
      [tooltipKey]="column.tooltipKey"
      okBtnTitle="{{ 'buttons.close' | transloco }}"
      [markupHTML$]="column.tooltipMarkupHTML$"
      [tooltipCssClass]="column.tooltipCssClass"
      ><slc-svg-icon-question-circle></slc-svg-icon-question-circle
    ></slc-tooltip>

    <p-sortIcon
      *ngIf="column.isSortable"
      field="{{ column.field }}"
    ></p-sortIcon>
  </div>
</ng-template>
