import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';
import { AuthStateService } from '@slice-services/auth-state.service';
import { SearchHttpService } from '@slice-services/search-http.service';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { getEmailMock, getIdMock } from '@slc-libs/mocks';
import { BreakpointsService } from '@slc-libs/services';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { CreatorSignerItem } from '@slice-interfaces/e-signature/xpress-contract.model';
import {
  CreatorSearchDetail,
  CreatorSearchParams,
} from '@slice-interfaces/xpress-report.model';

@Component({
  selector: 'slice-custom-sow-creator',
  templateUrl: './custom-sow-creator.component.html',
  styleUrls: ['./custom-sow-creator.component.scss'],
})
export class CustomSowCreatorComponent
  extends AbstractSubscriberComponent
  implements OnChanges
{
  @ViewChild('ngForm') ngForm: NgForm;
  @ViewChild('input', { static: true }) Input: ElementRef;
  @Input() creatorForm: CreatorSignerItem;
  @Output() closed = new EventEmitter<CreatorSignerItem>();

  private oldForm?: CreatorSignerItem;
  public isVisible = true;
  public isEditSOW = false;
  public isLoading = false;
  public searchForm: any;
  public creatorSearchArray: CreatorSearchDetail[];
  public resize$ = new Subject<any>();
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.resize$.next(event.target.innerWidth);
  }

  constructor(
    private searchHttpS: SearchHttpService,
    private authStateS: AuthStateService,
    public tS: TranslocoService,
    public bS: BreakpointsService,
  ) {
    super();
  }

  ngOnChanges(): void {
    if (this.creatorForm.userId) {
      this.isEditSOW = true;
      this.oldForm = JSON.parse(JSON.stringify(this.creatorForm));
      if (!this.creatorForm.sow?.length) {
        this.addCustomSOW();
      }
    } else {
      this.isEditSOW = false;
      this.oldForm = undefined;
    }
    this.inputEvent();
    // this.ngForm.form.valueChanges
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(() => {
    //     this.calculateAmount();
    //   });
  }

  /* This method associate to filter query from user input */
  inputEvent() {
    fromEvent(this.Input.nativeElement, 'keyup') // event from keyboard
      .pipe(
        map(
          (evt: any) => evt.target.value,
        ) /* map different value from user input */,
        // filter(res => res.length > 2),                   /* text length must be > 2 chars */
        debounceTime(750) /* emit after 1s of silence */,
        distinctUntilChanged() /* emit only if data changes since the last emit */,
      )
      .subscribe((keyword: string) => this.searchCreator(keyword));
  }

  public searchCreator(keyword: string): void {
    this.isLoading = true;
    const params: CreatorSearchParams = {
      page: 0,
      size: 10,
      name: keyword,
      roles: ['creator'],
    };
    this.searchHttpS
      .findCreatorAgencyId(
        this.authStateS.getAuthenticatedUser()?.agencyOwned as string,
        // this.authStateS.getAuthenticatedUser()?.userId as string,
        params,
        false,
      )
      .subscribe(
        res => {
          if (res?.content !== undefined && res?.content !== null) {
            this.creatorSearchArray = res.content;
          }
          this.isLoading = false;
        },
        e => {
          console.log('error', e);
          this.isLoading = false;
        },
      );
  }

  addingCreatorFromSearch(name?: string) {
    if (name) {
      const creator: CreatorSignerItem = {
        avatar: 'http.cat',
        userId: getIdMock(),
        name: name,
        email: getEmailMock(),
        netAmount: 0,
        emeterai: false,
        netAmount_customSOW: 0,
        sow: [],
      };
      this.isEditSOW = true;
      this.creatorForm = creator;
      this.oldForm = JSON.parse(JSON.stringify(creator));
      this.addCustomSOW();
    }
  }

  addingCreatorFromSelect(data: CreatorSearchDetail) {
    const creator: CreatorSignerItem = {
      avatar: data.avatar,
      userId: data.userId,
      name: data.name,
      email: data.email,
      netAmount: 0,
      emeterai: false,
      netAmount_customSOW: 0,
      sow: [],
    };
    this.isEditSOW = true;
    this.creatorForm = creator;
    this.oldForm = JSON.parse(JSON.stringify(creator));
    this.addCustomSOW();
  }

  addCustomSOW() {
    this.creatorForm.sow?.push({
      description: '',
      postName: '',
      sowQty: 1,
      sowRate: 0,
    });
  }

  removeCustomSOW(index: number) {
    if (this.creatorForm.sow) {
      this.creatorForm.sow.splice(index, 1);
    }
  }

  calculateAmount() {
    if (this.creatorForm.sow && this.creatorForm.sow.length > 0) {
      this.creatorForm.netAmount = this.creatorForm.sow.reduce(
        (total, item) => {
          return total + item.sowQty * item.sowRate;
        },
        0,
      );
    } else {
      this.creatorForm.netAmount = 0;
    }
    this.creatorForm.netAmount_customSOW = this.creatorForm.netAmount;
  }

  toggleMeterai() {
    this.creatorForm.emeterai = !this.creatorForm.emeterai;
  }

  onHide(): void {
    this.closed?.emit(this.oldForm);
  }

  onSave(): void {
    if (this.isEditSOW) {
      this.closed?.emit(this.creatorForm);
    }
  }
}
