<form [formGroup]="form">
  <p-dialog
    [(visible)]="isVisible"
    [draggable]="false"
    [resizable]="false"
    [modal]="true"
    (onHide)="closed.emit()"
  >
    <p-header>
      <slice-breadcrumbs-without-links
        [items]="breadcrumbs"
      ></slice-breadcrumbs-without-links>
    </p-header>

    <div class="slc-pos-rel">
      <main>
        <div class="row">
          <div class="col-md-6">
            <div>
              <div class="slc-flex-fs-c">
                <div class="slc-input-title">
                  {{ assetsTitleKeyMap[assetType] | transloco }} <span>*</span>
                </div>

                <slc-tooltip
                  class="slc-ml-16 slc-pb-8"
                  *ngIf="
                    assetType === AssetsTypes.IG_IMAGE || AssetsTypes.IG_VIDEO
                  "
                  okBtnTitle="{{ 'buttons.close' | transloco }}"
                  [markupHTML$]="assetRequirementsHTML$"
                  [tooltipCssClass]="tooltipCssClass"
                  ><slc-svg-icon-question-circle></slc-svg-icon-question-circle
                ></slc-tooltip>
              </div>
              <slice-file-upload
                [assetType]="assetType"
                (filesSelected)="selected($event)"
              >
              </slice-file-upload>

              <!-- <div class="row upload-btns slc-mt-16">
                <div class="col-md-6">
                  <button
                    class="btn-upload-outside dropbox slc-flex-c-c"
                    (click)="dropbox()"
                    type="button"
                  >
                    <slc-svg-icon-dropbox
                      class="slc-mr-16"
                    ></slc-svg-icon-dropbox>
                    <span>{{ 'buttons.choose-from-dropbox' | transloco }}</span>
                  </button>
                </div>
                <div class="col-md-6">
                  <button
                    class="btn-upload-outside google slc-flex-c-c"
                    (click)="googleDrive()"
                    type="button"
                  >
                    <slc-svg-icon-google-drive
                      class="slc-mr-16"
                    ></slc-svg-icon-google-drive
                    ><span>{{
                      'buttons.choose-from-google-drive' | transloco
                    }}</span>
                  </button>
                </div>
              </div> -->

              <slc-input-wrapper
                title="{{ 'inputs.caption.title' | transloco }}"
                [isRequired]="true"
                [formCtrl]="captionCtrl"
              >
                <input
                  type="text"
                  class="slc-input slc-input-with-bg"
                  formControlName="caption"
                  (keyup.enter)="submit()"
                />
              </slc-input-wrapper>

              <slc-input-wrapper
                title="{{ 'inputs.hashtags.title' | transloco }}"
                [isRequired]="true"
                [formCtrl]="hashtagsCtrl"
                patternErrorText="{{
                  'submit-content.hashtags.error-pattern' | transloco
                }}"
              >
                <textarea
                  class="slc-input slc-input-with-bg"
                  rows="5"
                  placeholder="{{
                    'submit-content.hashtags.placeholder' | transloco
                  }}"
                  formControlName="hashtags"
                ></textarea>
              </slc-input-wrapper>
              <div class="slc-success-msg">
                <ng-container *ngIf="hashtagsCtrl.valid"
                  ><b class="slc-fw-600"
                    >{{
                      'submit-content.hashtags.success-pattern' | transloco
                    }}:&nbsp;</b
                  >
                </ng-container>

                <ng-container *ngIf="validHashtags?.length">
                  <span *ngFor="let tag of validHashtags; let l = last"
                    >{{ tag }}<ng-container *ngIf="!l">, </ng-container>
                  </span>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <slice-submit-content-popup-preview
              [fileList]="fileList"
              [assetType]="assetType"
              (delete)="deleteItem($event)"
            ></slice-submit-content-popup-preview>
          </div>
        </div>
      </main>

      <slice-submit-content-popup-date-time
        class="slc-mt-16"
        (changed)="dateTimeSelected($event)"
      ></slice-submit-content-popup-date-time>

      <slice-loader *ngIf="loading" class="slc-fluid-box"></slice-loader>
    </div>

    <p-footer>
      <button
        title="{{ 'buttons.cancel' | transloco }}"
        class="slc-btn slc-btn-outline-grey"
        (click)="closed.emit()"
        type="button"
      >
        <span>{{ 'buttons.cancel' | transloco }}</span>
      </button>

      <button
        title="{{ 'buttons.submit-content' | transloco }}"
        class="slc-btn slc-btn-primary"
        (click)="submit()"
        [ngClass]="{
          'slc-btn-loading': loading
        }"
        [disabled]="form.invalid || !dateTime || !fileList.length"
        type="button"
      >
        <span>{{ 'buttons.submit-content' | transloco }}</span>
      </button>
    </p-footer>
  </p-dialog>
</form>

<slice-submit-content-popup-asset-requirements
  [assetType]="assetType"
  (contentReady)="tooltipContentReady($event)"
></slice-submit-content-popup-asset-requirements>
