export enum TABLES_LIST_AMOUNTS {
  i5 = 5,
  i10 = 10,
  i20 = 20,
  i30 = 30,
  i50 = 50,
  i100 = 100,
  i200 = 200,
  i1000 = 1000,
}
export const TABLES_ROWS_PER_PAGE_LIST = [
  TABLES_LIST_AMOUNTS.i5,
  TABLES_LIST_AMOUNTS.i10,
  TABLES_LIST_AMOUNTS.i20,
  TABLES_LIST_AMOUNTS.i30,
  TABLES_LIST_AMOUNTS.i50,
];
export const TABLES_DEF_ROWS_PER_PAGE = TABLES_LIST_AMOUNTS.i10;
export const TABLES_DEF_SORT = [
  {
    field: 'createdDate',
    order: -1, // -1 for descending, 1 for ascending
  },
];

export const TABLES_MOBILE_BREAKPOINT = 960;

export enum TABLES_CELL_TYPE {
  date = 'date',
  number = 'number',
  dateRange = 'dateRange',
  percent = 'percent',
  expand = 'expand',
}
