import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconBagComponent } from './bag.component';

@NgModule({
  declarations: [SvgIconBagComponent],
  imports: [CommonModule],
  exports: [SvgIconBagComponent],
})
export class SvgIconBagModule {}
