import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconAnalyticsComponent } from './analytics.component';

@NgModule({
  declarations: [SvgIconAnalyticsComponent],
  imports: [CommonModule],
  exports: [SvgIconAnalyticsComponent],
})
export class SvgIconAnalyticsModule {}
