import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';
import { NgOtpInputConfig } from 'ng-otp-input';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

// import { debounceTime, takeUntil } from 'rxjs/operators';
import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

import { ChangePinFacadeService } from './services/change-pin-facade.service';
import { ChangePinStateService } from './services/change-pin-state.service';

// import { AgencyProfileFacadeService } from '../../../../services/agency-profile-facade.service';

@Component({
  selector: 'slice-change-pin-component',
  templateUrl: './change-pin.component.html',
  styleUrls: ['./change-pin.component.scss'],
  providers: [ChangePinStateService, ChangePinFacadeService],
})
export class ChangePinComponent
  extends AbstractSubscriberComponent
  implements OnInit
{
  @Input() isVisible?: any;
  @Output() closed = new EventEmitter<boolean>();
  @ViewChild('ngOtpInputOld', { static: false }) ngOtpInputOld: any;
  @ViewChild('ngOtpInputNew', { static: false }) ngOtpInputNew: any;
  @ViewChild('ngOtpInputConfirm', { static: false }) ngOtpInputConfirm: any;

  errorMessage: string;
  isLoading = false;
  oldPinControl: FormControl;
  newPinControl: FormControl;
  confirmNewPinControl: FormControl;
  form: FormGroup;
  modalForgot = false;
  modalVerif = false;

  constructor(
    private fb: FormBuilder,
    private facadeS: ChangePinFacadeService,
    private tS: TranslocoService,
    private mS: MessageService,
  ) {
    super();
    this.facadeS
      .selectIsModalForgotPin()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => (this.modalForgot = r));
    this.facadeS
      .selectIsModalVerification()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => (this.modalVerif = r));
  }

  otpConfig: NgOtpInputConfig = {
    length: 6,
    isPasswordInput: true,
    placeholder: '_',
    inputStyles: {
      width: '60px',
      'margin-top': '5px',
    },
  };
  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.oldPinControl = new FormControl('', [Validators.required]);
    this.newPinControl = new FormControl('', [Validators.required]);
    this.confirmNewPinControl = new FormControl('', [Validators.required]);

    this.form = this.fb.group({
      oldPin: this.oldPinControl,
      newPin: this.newPinControl,
      confirmNewPin: this.confirmNewPinControl,
    });
  }

  changePin() {
    if (this.form.valid) {
      const oldPin = this.oldPinControl.value;
      const newPin = this.newPinControl.value;
      const confirmNewPin = this.confirmNewPinControl.value;
      const payload = {
        oldPin: oldPin,
        newPin: newPin,
      };
      if (newPin === confirmNewPin) {
        this.isLoading = true;
        this.facadeS
          .editPin(payload)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            () => {
              this.isLoading = false;
              this.mS.add({
                severity: 'success',
                life: 5 * 1000,
                detail: this.tS.translate(
                  'profiles.my.response.modal-pin.success',
                ),
              });
              this.triggerHide();
            },
            err => {
              this.isLoading = false;
              if (err?.status === 403) {
                this.mS.add({
                  severity: 'error',
                  summary: this.tS.translate(
                    'withdraw-response-modal.withdraw-pin-failed.title',
                  ),
                  life: 5 * 1000,
                  detail: this.tS.translate(
                    'withdraw-response-modal.withdraw-pin-failed.response-summary',
                  ),
                });
              } else {
                this.mS.add({
                  severity: 'error',
                  summary: this.tS.translate(
                    'withdraw-response-modal.withdraw-response-failed.title',
                  ),
                  life: 5 * 1000,
                  detail: this.tS.translate(
                    'withdraw-response-modal.withdraw-response-failed.response-summary',
                  ),
                });
              }
            },
          );
      } else {
        this.mS.add({
          severity: 'error',
          life: 5 * 1000,
          detail: this.tS.translate(
            'profiles.my.response.modal-pin.failed-same',
          ),
        });
      }
    }
  }

  triggerHide(): void {
    this.closed?.emit(false);
    this.reset();
  }
  reset(): void {
    this.form.reset();
    this.setVal('');
  }
  setVal(val: any) {
    this.ngOtpInputOld.setValue(val);
    this.ngOtpInputNew.setValue(val);
    this.ngOtpInputConfirm.setValue(val);
  }

  openForgotPin(): void {
    this.triggerHide();
    this.facadeS.setIsModalForgotPin(true);
  }
}
