<svg
  xmlns="http://www.w3.org/2000/svg"
  width="10"
  height="8"
  viewBox="0 0 12 10"
  fill="none"
>
  <path
    d="M12 1.38745L10.6126 0L3.9284 6.68415L1.38745 4.1432L0 5.53065L3.9284 9.45905L12 1.38745Z"
    fill="#fff"
  />
</svg>
