import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { AuthStateService } from '@slice-services/auth-state.service';
import { ChannelMediaHttpService } from '@slice-services/channel-media-http.service';
import { NavigationService } from '@slice-services/navigation.service';
import { PostProposalsHttpService } from '@slice-services/post-proposals-http.service';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

import { Channels, PostTypes } from '@slc-libs/enums';

import { environment } from '@slice-env/environment';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { AuthenticatedUser } from '@slice-interfaces/auth/auth-user';
import { BreadcrumbsWithoutLinksItem } from '@slice-interfaces/breadcrumbs-without-links';
import {
  BriefProposalDetailsModel,
  ChannelProposalModel,
} from '@slice-interfaces/brief-proposal/brief-proposals';
import { ChannelMediaModel } from '@slice-interfaces/media/channel-medias.intf';

@Component({
  selector: 'slice-report-submission-modal',
  templateUrl: './report-submission-modal.component.html',
  styleUrls: ['./report-submission-modal.component.scss'],
})
export class ReportSubmissionModalComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @Input() set offerVal(v: ChannelProposalModel | undefined | null) {
    this.offer = v;
    this.initBreadCrumbs();
  }
  @Input() set briefProposalVal(
    v: BriefProposalDetailsModel | undefined | null,
  ) {
    this.briefProposal = v;
    this.initBreadCrumbs();
  }
  @Input() multiselect = true;

  public isVisible = true;
  public PostTypes = PostTypes;
  public Channels = Channels;
  public offer: ChannelProposalModel | undefined | null;
  public briefProposal: BriefProposalDetailsModel | undefined | null;
  public loading: boolean;
  // public types = [
  //   { label: 'Latest', value: PublishedPostsByPeriod.latest },
  //   { label: 'Relevant', value: PublishedPostsByPeriod.relevant },
  // ];
  public selectedIds: Array<string> = [];
  public size: number;
  public breadcrumbs: Array<BreadcrumbsWithoutLinksItem>;
  public allItems: Array<ChannelMediaModel> = [];
  public viewItems: Array<ChannelMediaModel> = [];

  @Output() closed = new EventEmitter<boolean>();
  constructor(
    private tS: TranslocoService,
    private channelMediaHttpS: ChannelMediaHttpService,
    private authStateS: AuthStateService,
    private mS: MessageService,
    private postProposalsHttpS: PostProposalsHttpService,
    private navigationService: NavigationService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.size = this.offer?.channel === Channels.INSTAGRAM ? 3 : 6;
    this.getList();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private getList(): void {
    let req;
    let isMocked;
    if (this.offer && this.offer?.channelAccountConnected) {
      this.loading = true;
      switch (this.offer.channel) {
        case Channels.YOUTUBE:
          isMocked =
            environment.mocks?.creator.offers.offer.postProposalActions
              .submitContentLink.getYtMedia;
          req = this.channelMediaHttpS.getYtMedia(this.offer.id, isMocked);
          break;
        case Channels.TIKTOK:
          isMocked =
            environment.mocks?.creator.offers.offer.postProposalActions
              .submitContentLink.getTTMedia;
          req = this.channelMediaHttpS.getTtMedia(this.offer.id);
          break;
        default:
          isMocked =
            environment.mocks?.creator.offers.offer.postProposalActions
              .submitContentLink.getIgMedia;
          req = this.channelMediaHttpS.getIgMedia(
            this.offer.id,
            this.offer.postType,
            isMocked,
          );
          break;
      }
    }
    req?.pipe(takeUntil(this.destroy$)).subscribe(
      r => {
        this.loading = false;
        this.allItems = r;
        this.initViewItems(0);
      },
      () => {
        this.loading = false;
      },
    );
  }

  public isContentAvailable(): boolean {
    return this.allItems?.length > 0 && this.viewItems?.length > 0;
  }

  private initBreadCrumbs(): void {
    this.breadcrumbs = [
      { title: this.briefProposal?.brandName },
      { title: this.briefProposal?.campaignName },
      {
        title:
          this.tS.translate(this.offer?.channel as string) +
          ' ' +
          this.tS.translate(this.offer?.postType as string),
      },
    ];
  }

  onHide(): void {
    this.closed?.emit(false);
  }

  toggleItem(item: ChannelMediaModel): void {
    if (this.multiselect) {
      if (this.selectedIds.includes(item.id)) {
        this.selectedIds = this.selectedIds.filter(i => i !== item.id);
      } else {
        this.selectedIds = [...this.selectedIds, item.id];
      }
    } else {
      if (this.selectedIds.includes(item.id)) {
        this.selectedIds = [];
      } else {
        this.selectedIds = [item.id];
      }
    }
  }

  paginate(e: { page: number }): void {
    this.initViewItems(e.page);
  }

  initViewItems(page: number): void {
    this.viewItems = this.allItems.slice(
      page * this.size,
      page * this.size + this.size,
    );
  }
  // this.closed.emit(false);

  submit(): void {
    this.loading = true;
    this.postProposalsHttpS
      .submitContentLink(
        this.offer?.id as string,
        {
          contentId: this.selectedIds[0],
        },
        environment.mocks?.creator.offers.offer.postProposalActions
          .submitContentLink.submit,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.loading = false;
          this.mS.add({
            severity: 'success',
            life: 5 * 1000,
            detail: this.tS.translate('common.submitted'),
          });
          this.closed.emit(true);
        },
        () => {
          this.loading = false;
        },
      );
  }
  gotoProfile(): void {
    if (this.authStateS.getAuthenticatedUser()?.profileCompleted) {
      this.navigationService.goToProfile();
    } else {
      // set onboarding to not finished
      this.authStateS.setUserStatusOnboard(false);
      // return to home and recalled Observable to update the dialog
      this.navigationService.goToAgencyHome().then(() => {
        this.authStateS.setAuthenticatedUser(
          this.authStateS.getAuthenticatedUser() as AuthenticatedUser,
        );
      });
    }
  }
}
