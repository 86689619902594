import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconDropboxComponent } from './dropbox.component';

@NgModule({
  declarations: [SvgIconDropboxComponent],
  imports: [CommonModule],
  exports: [SvgIconDropboxComponent],
})
export class SvgIconDropboxModule {}
