import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import { UsersHttpService } from '@slice-services/users-http.service';
import { AutoComplete } from 'primeng/autocomplete';
import { takeUntil } from 'rxjs/operators';

import { environment } from '@slice-env/environment';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import {
  ClientsSelectorOutput,
  User,
} from '@slice-interfaces/clients-selector/clients-selector';
import { USER_INVITE_STATUS } from '@slice-enums/user';

@Component({
  selector: 'slice-clients-selector',
  templateUrl: './clients-selector.component.html',
  styleUrls: ['./clients-selector.component.scss'],
})
export class ClientsSelectorComponent
  extends AbstractSubscriberComponent
  implements OnDestroy
{
  @Input() statuses?: Array<USER_INVITE_STATUS>;
  @Input() disabled: boolean;

  public suggestions: Array<User>;
  public selectedUsers: Array<User> = [];

  @Output() changed = new EventEmitter<ClientsSelectorOutput>();

  @ViewChild('autocomplete') autocomplete: AutoComplete;
  constructor(private usersHttpS: UsersHttpService) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  search(e: { query: string }): void {
    this.usersHttpS
      .searchUser(
        {
          page: 0,
          size: 10000,
          name: e.query,
          status: this.statuses || [],
        },
        environment.mocks?.agency.clientsRoster.addClents.searchInnerUser,
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        const alreadySelectedIds = this.selectedUsers?.map(i => i.id) || [];
        this.suggestions =
          r?.content?.filter(i => !alreadySelectedIds.includes(i.id)) || [];
      });
  }

  removeUser(user: User): void {
    this.selectedUsers = this.selectedUsers.filter(i => i.id !== user.id);
    this.changed.emit({
      removedItem: user,
      newList: this.selectedUsers,
    });
  }

  onSelect(e: User): void {
    this.changed.emit({
      newList: this.selectedUsers,
      addedItem: e,
    });
  }
}
