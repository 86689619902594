import { Observable, of } from 'rxjs';

import {
  getIdMock,
  getNumberMock,
  getPagebaleResponseMock,
} from '@slc-libs/mocks';
import { getUsernameMock } from '@slc-libs/mocks';

import { DiscoverCreatorsFilters } from '@slice-interfaces/discover-creators/discover-creators-filters';
import { DiscoverCreatorsResponse } from '@slice-interfaces/discover-creators/discover-creators-response';
import { DiscoverCreatorsUser } from '@slice-interfaces/discover-creators/discover-creators-user';
import { USER_INVITE_STATUS } from '@slice-enums/user';

import { getRandomFromArray } from './_helpers/common';

const _getList = (
  params: DiscoverCreatorsFilters,
  res: DiscoverCreatorsResponse,
): Array<DiscoverCreatorsUser> => {
  const list: Array<DiscoverCreatorsUser> = [];
  for (
    let i = 0;
    i < (res.last ? res.totalElements - res.number * res.size : params.size);
    i++
  ) {
    list.push({
      id: getIdMock(),
      name: getUsernameMock(),
      // creatorAvatar: getRandomMockAvatar(),
      reach: Math.random() > 0.5 ? getNumberMock(100) : getNumberMock(4),
      impressions: Math.random() > 0.5 ? getNumberMock(100) : getNumberMock(4),
      views: Math.random() > 0.5 ? getNumberMock(100) : getNumberMock(4),
      likes: Math.random() > 0.5 ? getNumberMock(100) : getNumberMock(4),
      comments: Math.random() > 0.5 ? getNumberMock(100) : getNumberMock(4),
      shares: Math.random() > 0.5 ? getNumberMock(100) : getNumberMock(4),
      clicks: Math.random() > 0.5 ? getNumberMock(100) : getNumberMock(4),
      agencyStatus:
        Math.random() > 0.5
          ? null
          : getRandomFromArray(Object.values(USER_INVITE_STATUS)),
      engagementRate:
        Math.random() > 0.5 ? getNumberMock(100) : getNumberMock(4),
      cpr: Math.random() > 0.5 ? getNumberMock(100) : getNumberMock(4),
      cpv: Math.random() > 0.5 ? getNumberMock(100) : getNumberMock(4),
      cpi: Math.random() > 0.5 ? getNumberMock(100) : getNumberMock(4),
      cpe: Math.random() > 0.5 ? getNumberMock(100) : getNumberMock(4),
    });
  }
  return list;
};

export const getDiscoveryCreatorsMocks = (
  params: DiscoverCreatorsFilters,
): Observable<DiscoverCreatorsResponse> => {
  const res = getPagebaleResponseMock(params);
  res.content = _getList(params, res as DiscoverCreatorsResponse);
  return of(res as DiscoverCreatorsResponse);
};
