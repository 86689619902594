<svg
  xmlns="http://www.w3.org/2000/svg"
  width="15"
  height="10"
  viewBox="0 0 15 10"
  fill="none"
>
  <path
    d="M13.9879 1.56471C13.8232 0.952941 13.3408 0.470588 12.729 0.305882C11.6114 0 7.14081 0 7.14081 0C7.14081 0 2.67022 0 1.55257 0.294118C0.952575 0.458824 0.458457 0.952941 0.293751 1.56471C-0.000366211 2.68235 -0.000366211 5 -0.000366211 5C-0.000366211 5 -0.000366211 7.32941 0.293751 8.43529C0.458457 9.04706 0.94081 9.52941 1.55257 9.69412C2.68199 10 7.14081 10 7.14081 10C7.14081 10 11.6114 10 12.729 9.70588C13.3408 9.54118 13.8232 9.05882 13.9879 8.44706C14.282 7.32941 14.282 5.01176 14.282 5.01176C14.282 5.01176 14.2938 2.68235 13.9879 1.56471Z"
    fill="#FF0000"
  />
  <path
    d="M5.71826 2.85938V7.14173L9.43591 5.00055L5.71826 2.85938Z"
    fill="white"
  />
</svg>
