<form #ngForm="ngForm" (ngSubmit)="onSave()">
  <p-dialog
    [(visible)]="isVisible"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
    [modal]="true"
    [closeOnEscape]="true"
    [dismissableMask]="true"
    position="{{ bS.winWidth > 768 ? 'right' : '' }}"
    (onHide)="onHide()"
  >
    <p-header>
      <button
        class="btn btn-outline-light btn-back"
        type="button"
        (click)="onHide()"
      >
        <i class="pi pi-arrow-left"></i>
        <span>&nbsp;&nbsp;{{ 'common.back' | transloco }}</span>
      </button>
    </p-header>

    <div [hidden]="isEditSOW">
      <slice-search-creators
        hidden
        [multiple]="false"
        (selectedValue)="addingCreatorFromSearch($event)"
        style="width: 100%"
      ></slice-search-creators>
      <div class="slc-search-field-wrap">
        <slc-svg-icon-search></slc-svg-icon-search>
        <input
          type="text"
          class="slc-input"
          autocomplete="off"
          placeholder="Search Creator Roster"
          name="searchForm"
          #input
          [(ngModel)]="searchForm"
          (keyup.enter)="searchCreator(searchForm)"
        />
        <i class="pi pi-spin pi-spinner" *ngIf="isLoading"></i>
      </div>

      <p class="title" *ngIf="creatorSearchArray?.length">
        {{ 'page-titles.client-roster' | transloco }}
      </p>
      <div
        *ngFor="let creator of creatorSearchArray"
        style="margin-bottom: 10px"
      >
        <p-card>
          <div class="float-right">
            <button
              type="button"
              class="slc-btn slc-btn-primary slc-btn-h-36 mt-2"
              (click)="addingCreatorFromSelect(creator)"
            >
              <span>Add</span>
            </button>
          </div>
          <div class="row">
            <div
              class="col-4 d-inline-flex justify-content-center align-items-center"
            >
              <i class="pi pi-star mr-2" style="font-size: 20px"></i>
              <slc-avatar
                class="slc-avatar-56"
                [userFullName]="creator.name"
                [imagePath]="creator.avatar"
              ></slc-avatar>
            </div>
            <div class="col-8 d-flex align-items-center">
              <h5>{{ creator.name }}</h5>
            </div>
          </div>
        </p-card>
      </div>
    </div>
    <ng-container *ngIf="isEditSOW">
      <div class="row">
        <div
          class="col-2 d-inline-flex justify-content-center align-items-center"
        >
          <slc-avatar
            class="slc-avatar-56"
            [userFullName]="creatorForm.name"
            [imagePath]="creatorForm.avatar"
          ></slc-avatar>
        </div>
        <div class="col-8 d-flex align-items-center">
          <h5 class="mr-3">
            {{ creatorForm.name }}
            <br />
            <span class="text-company slc-text-color-light">
              {{ creatorForm.email }}
            </span>
          </h5>
        </div>
      </div>

      <p class="title mt-4">Scope of Work & Description</p>

      <div
        *ngFor="let item of creatorForm.sow; let i = index"
        class="shadow p-3 rounder mt-2"
      >
        <div class="sow-item">
          <slc-input-wrapper class="m-0">
            <input
              type="text"
              class="slc-input slc-input-with-bg pl-3 mb-2"
              name="postName{{ i }}"
              [(ngModel)]="item.postName"
              [required]="true"
              placeholder="Enter a SOW (max 30 char)"
              [minlength]="1"
              [maxlength]="30"
            />
          </slc-input-wrapper>

          <textarea
            [(ngModel)]="item.description"
            [required]="true"
            name="description{{ i }}"
            rows="5"
            class="slc-input slc-input-with-bg mb-3"
            placeholder="Enter SOW description here"
          ></textarea>

          <div class="sow-details">
            <div class="sow-input">
              <label for="quantity{{ i }}">Quantity</label>
              <p-inputNumber
                locale="en-US"
                styleClass="small-input"
                inputId="quantity{{ i }}"
                [(ngModel)]="item.sowQty"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="calculateAmount()"
                [required]="true"
                mode="decimal"
                [min]="1"
                [max]="100"
              >
              </p-inputNumber>
            </div>

            <div class="sow-input">
              <label for="amount{{ i }}">Amount Net</label>
              <p-inputNumber
                locale="en-US"
                styleClass="small-input"
                inputId="amount{{ i }}"
                [(ngModel)]="item.sowRate"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="calculateAmount()"
                [required]="true"
                mode="decimal"
                [min]="0"
              >
              </p-inputNumber>
            </div>
          </div>

          <button
            type="button"
            class="slc-btn-link remove-icon"
            *ngIf="creatorForm.sow && creatorForm.sow.length > 1"
            (click)="removeCustomSOW(i)"
          >
            <i class="pi pi-trash" style="font-size: 1rem"></i>
          </button>
        </div>
      </div>

      <div class="w-100 mt-2 text-right">
        <p-button
          label="Add More"
          icon="pi pi-plus-circle"
          styleClass="p-button-rounded p-button-info p-button-text"
          (onClick)="addCustomSOW()"
        ></p-button>
        <hr class="mt-1 mb-0" />
      </div>

      <p class="title mt-4">E-Meterai</p>

      <div class="d-flex justify-content-start align-items-center mt-2">
        <p-checkbox
          id="emeterai_sow"
          name="emeterai_sow"
          inputId="emeterai_sow"
          styleClass="slc-checkbox-square mr-2"
          [binary]="true"
          [(ngModel)]="creatorForm.emeterai"
        ></p-checkbox>
        <label for="emeterai_sow"> Use E-Meterai </label>
      </div>
    </ng-container>

    <p-footer>
      <div class="slc-flex-sb-c footer-text mb-2" *ngIf="isEditSOW">
        <h5>Total Net</h5>
        <h5>{{ creatorForm.netAmount_customSOW | rupiah }}</h5>
      </div>
      <button
        class="slc-btn slc-btn-h-56 slc-btn-primary w-100 m-0"
        type="submit"
        [disabled]="ngForm.invalid || !isEditSOW"
      >
        <span>Save</span>
      </button>
    </p-footer>
  </p-dialog>
</form>
