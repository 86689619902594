<div
  *ngFor="let item of items; let l = last"
  [ngClass]="{ last: l && highlightLast }"
  class="slc-flex-fs-c item"
  title="{{
    item.title ? item.title : item.titleKey ? (item.titleKey | transloco) : ''
  }}"
>
  <span *ngIf="!item.route">{{
    item.titleKey ? (item.titleKey | transloco) : item.title
  }}</span>
  <a *ngIf="item.route" [routerLink]="item.route">
    {{ item.titleKey ? (item.titleKey | transloco) : item.title }}
  </a>
  <slc-svg-icon-chevron *ngIf="!l" class="slc-rotate-90"></slc-svg-icon-chevron>
</div>
