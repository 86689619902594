import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { createHttpParams } from '@slc-libs/helpers';

import { environment } from '@slice-env/environment';

import { AgencyInvitationsParams } from '@slice-interfaces/agency-invitation/agency-invitations-params';
import { AgencyInvitationsResponse } from '@slice-interfaces/agency-invitation/agency-invitations-response';
import {
  AgencyInvitationPayload,
  InviteCreatorBulkParams,
} from '@slice-interfaces/agency-invitation/agency-invite-payload';

import { getRandomHttpResponseDelay } from '../../_mocks/_helpers/http-response-delay';
import { getAgencyInvitationsResponseMocks } from '../../_mocks/agency/agency-invitations-response.mock';

@Injectable({
  providedIn: 'root',
})
export class AgencyInvitationsHttpService {
  constructor(private http: HttpClient) {}

  getAgencyInvitations(
    params: AgencyInvitationsParams,
    useMocks?: boolean,
  ): Observable<AgencyInvitationsResponse> {
    const mock = of(getAgencyInvitationsResponseMocks(params)).pipe(
      delay(getRandomHttpResponseDelay()),
    );
    const req = this.http.get<AgencyInvitationsResponse>(
      '/api/v1/agency-invitations/',
      {
        params: createHttpParams(params),
      },
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMocks ? mock : req;
      } else {
        return req;
      }
    } else {
      return useMocks ? mock : req;
    }
  }

  inviteUsers(
    agencyUuid: string,
    body: AgencyInvitationPayload,
    useMocks?: boolean,
  ): Observable<void> {
    const mock = of(true as any).pipe(delay(getRandomHttpResponseDelay()));
    const req = this.http.post<void>(
      `/api/v1/agencies/${agencyUuid}/invitations`,
      body,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMocks ? mock : req;
      } else {
        return req;
      }
    } else {
      return useMocks ? mock : req;
    }
  }

  inviteUsersBulk(
    agencyUuid: string,
    body: Array<InviteCreatorBulkParams>,
  ): any {
    const req = this.http.post<void>(
      `/api/v1/agencies/${agencyUuid}/bulk-invite`,
      body,
    );
    return req;
  }

  rejectInvitation(agencyUuid: string, useMocks?: boolean): Observable<void> {
    const mock = of(true as any).pipe(delay(getRandomHttpResponseDelay()));
    const req = this.http.post<void>(
      `/api/v1/agency-invitations/${agencyUuid}/reject`,
      {},
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMocks ? mock : req;
      } else {
        return req;
      }
    } else {
      return useMocks ? mock : req;
    }
  }

  acceptInvitation(agencyUuid: string, useMocks?: boolean): Observable<void> {
    const mock = of(true as any).pipe(delay(getRandomHttpResponseDelay()));
    const req = this.http.post<void>(
      `/api/v1/agency-invitations/${agencyUuid}/accept`,
      {},
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMocks ? mock : req;
      } else {
        return req;
      }
    } else {
      return useMocks ? mock : req;
    }
  }
}
