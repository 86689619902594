import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { CountryISO } from 'ngx-intl-tel-input-gg';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { createHttpParams } from '@slc-libs/helpers';

import { GeographyFilters } from '@slice-interfaces/geography/filters';
import { GeoModel } from '@slice-interfaces/geography/geography';
import { GeographyResponse } from '@slice-interfaces/geography/get-list';
import { Select } from '@slice-interfaces/select/select';
import { GeographyType } from '@slice-enums/geography';

@Injectable({
  providedIn: 'root',
})
export class GeographyHttpService {
  private map: Record<string, string> = {
    id: 'indonesia',
  };

  constructor(private http: HttpClient, private tS: TranslocoService) {}

  getCountryIdByIsoCode(isoCode: CountryISO): Observable<string | undefined> {
    return this.getGeographyBase({
      type: GeographyType.country,
      page: 0,
      size: 1000,
    }).pipe(
      map(r => {
        const uuid = r.content.find(i => i.nameKey === this.map[isoCode])?.id;
        if (!uuid) {
          throw new Error('Country not found by iso code');
        }
        return uuid;
      }),
    );
  }

  getGeographyBase(params: GeographyFilters): Observable<GeographyResponse> {
    return this.http.get<GeographyResponse>('/api/v1/geo', {
      params: createHttpParams(params as any),
    });
  }

  getCountriesForSelect(): Observable<Array<Select>> {
    return this.getGeographyBase({
      type: GeographyType.country,
      page: 0,
      size: 1000,
    }).pipe(
      map(r => {
        return r.content.map(i => {
          return { label: i.defaultName, value: i.id };
        });
      }),
    );
  }

  getCountryByUuid(uuid: string): Observable<GeoModel | null> {
    // return this.http.get<GeoModel>(`/api/v1/geo/${uuid}`);
    return this.getGeographyBase({
      type: GeographyType.country,
      page: 0,
      size: 1000,
    }).pipe(
      map(r => {
        return r.content.find(i => i.id === uuid) || null;
      }),
    );
  }

  getCityByUuid(
    countryUuid: string,
    cityUuid: string,
  ): Observable<GeoModel | null> {
    return this.getGeographyBase({
      parent: countryUuid,
      type: GeographyType.city,
      page: 0,
      size: 1000,
    }).pipe(
      map(r => {
        return r.content.find(i => i.id === cityUuid) || null;
      }),
    );
  }

  getCitiesForSelect(params: GeographyFilters): Observable<Array<Select>> {
    return this.getGeographyBase(params).pipe(
      map(r => {
        return r.content.map(i => {
          const translateKey = 'enums.geography-indonesia.' + i.nameKey;
          return {
            label:
              i.nameKey && translateKey !== this.tS.translate(translateKey)
                ? this.tS.translate(translateKey)
                : i.defaultName,
            value: i.id,
          };
        });
      }),
    );
  }
}
