<svg
  xmlns="http://www.w3.org/2000/svg"
  width="28"
  height="24"
  viewBox="0 0 28 24"
  fill="none"
>
  <path
    d="M26.0006 24H2.00057C1.64695 24 1.30781 23.8595 1.05776 23.6095C0.807712 23.3594 0.667236 23.0203 0.667236 22.6667V12.6493C0.667215 12.4588 0.708024 12.2705 0.786913 12.0971C0.865802 11.9237 0.98094 11.7692 1.12457 11.644L6.00057 7.392V1.33333C6.00057 0.979711 6.14105 0.640573 6.39109 0.390524C6.64114 0.140476 6.98028 0 7.3339 0H26.0006C26.3542 0 26.6933 0.140476 26.9434 0.390524C27.1934 0.640573 27.3339 0.979711 27.3339 1.33333V22.6667C27.3339 23.0203 27.1934 23.3594 26.9434 23.6095C26.6933 23.8595 26.3542 24 26.0006 24ZM10.0006 21.3333H14.0006V13.256L8.66724 8.60533L3.3339 13.256V21.3333H7.3339V16H10.0006V21.3333ZM16.6672 21.3333H24.6672V2.66667H8.66724V5.50267C8.97924 5.50267 9.29257 5.612 9.54323 5.832L16.2099 11.644C16.3535 11.7692 16.4687 11.9237 16.5476 12.0971C16.6264 12.2705 16.6673 12.4588 16.6672 12.6493V21.3333ZM19.3339 10.6667H22.0006V13.3333H19.3339V10.6667ZM19.3339 16H22.0006V18.6667H19.3339V16ZM19.3339 5.33333H22.0006V8H19.3339V5.33333ZM14.0006 5.33333H16.6672V8H14.0006V5.33333Z"
    fill="#434DDB"
  />
</svg>
