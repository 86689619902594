<div class="d-flex justify-content-between w-100">
  <div class="d-inline-flex flex-wrap slc-mb-8">
    <div class="info-limit" *ngFor="let info of infoLimit">
      <h5 [innerHTML]="info.label"></h5>
      <p [innerHTML]="info.description"></p>
    </div>
  </div>
  <div class="special-notes" *ngIf="showSpecialNotes">
    <div class="d-flex flex-row">
      <slc-svg-icon-info-circle class="mx-2"></slc-svg-icon-info-circle>
      <p>
        Please note that reporting numbers are based on creator’s connected
        account
      </p>
    </div>
  </div>
</div>
