import { PostTypes } from '@slc-libs/enums';

import { ReportsInfoWidgets } from '@slice-enums/reports-info-list';

export const createIgPerformanceWidget = (
  postType?: PostTypes,
): Array<Array<ReportsInfoWidgets>> => {
  switch (postType) {
    case PostTypes.POST:
    case PostTypes.REELS:
    case PostTypes.STORIES:
    case PostTypes.CAROUSEL_ALBUM:
      return [
        [ReportsInfoWidgets.reach, ReportsInfoWidgets.impressions],
        [
          ReportsInfoWidgets.engagementRateByReach,
          ReportsInfoWidgets.engagementRateByFollower,
        ],
        [
          ReportsInfoWidgets.costPerReach,
          ReportsInfoWidgets.costPerImpression,
          ReportsInfoWidgets.costPerEngagement,
        ],
      ];
    default:
      return [[]];
  }
};

export const createYtPerformanceWidget = (
  postType?: PostTypes,
): Array<Array<ReportsInfoWidgets>> => {
  switch (postType) {
    case PostTypes.HOST_READ_AD:
    case PostTypes.PRODUCT_PLACEMENT:
    case PostTypes.PRODUCT_REVIEW:
      return [
        [ReportsInfoWidgets.views, ReportsInfoWidgets.engagementRate],
        [ReportsInfoWidgets.costPerView, ReportsInfoWidgets.costPerEngagement],
      ];
    default:
      return [[]];
  }
};

export const createTkPerformanceWidget = (
  postType?: PostTypes,
): Array<Array<ReportsInfoWidgets>> => {
  switch (postType) {
    case PostTypes.TIKTOK_VIDEO:
      return [
        [
          ReportsInfoWidgets.views,
          ReportsInfoWidgets.engagementRate,
          // ReportsInfoWidgets.totalWatchTime,
        ],
        [ReportsInfoWidgets.costPerView, ReportsInfoWidgets.costPerEngagement],
      ];
    default:
      return [[]];
  }
};
