import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { AuthStateService } from '@slice-services/auth-state.service';
import { NavigationService } from '@slice-services/navigation.service';

import { E_ROUTES } from '@slice-enums/routes.enum';

@Injectable()
export class EmailSentGuard implements CanActivate {
  constructor(
    private navigationS: NavigationService,
    private authStateS: AuthStateService,
    private router: Router,
  ) {}

  public canActivate(): boolean | UrlTree {
    const isEmailConfirmed =
      this.authStateS.getAuthenticatedUser()?.emailConfirmed;
    if (isEmailConfirmed) {
      return this.router.parseUrl(this.navigationS.getRoutePath(E_ROUTES.BASE));
    } else {
      return true;
    }
  }
}
