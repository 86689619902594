import { Component, OnInit } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

import { IsoLanguages } from '@slc-libs/types';

interface Item {
  code: IsoLanguages;
  title: string;
}

@Component({
  selector: 'slice-lang',
  templateUrl: './lang.component.html',
  styleUrls: ['./lang.component.scss'],
})
export class LangComponent implements OnInit {
  public lang$: Observable<IsoLanguages>;
  public items: Array<Item> = [
    {
      code: IsoLanguages.ENGLISH_US,
      title: 'ENG',
    },
    {
      code: IsoLanguages.BAHANA_INDONESIA,
      title: 'IDN',
    },
  ];

  constructor(private tS: TranslocoService) {}

  ngOnInit(): void {
    this.lang$ = this.tS.langChanges$ as Observable<IsoLanguages>;
  }

  changeLang(lang: Item): void {
    this.tS.setActiveLang(lang.code);
  }
}
