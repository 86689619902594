<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="false"
  (onHide)="triggerHide()"
>
  <p-header>
    <div class="w-100" style="padding-left: 32px">
      <div class="slc-mt-18 p-1 pt-3 text-center rounded">
        {{ 'bulk-payment.general.balance-detail' | transloco }}
      </div>
    </div>
  </p-header>
  <slice-loader *ngIf="isLoading" class="slice-loader-fluid"></slice-loader>
  <div *ngIf="!isLoading" class="lp-main-content mt-3">
    <div class="box glow-box my-2">
      <div class="d-flex flex-row justify-content-between">
        <p class="text-dark">
          {{ 'bulk-payment.general.cur-balance' | transloco }}
        </p>
        <p class="text-dark">{{ data?.totalBalance | number : '1.0-2' }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between ml-2 mt-2">
        <p class="sub">
          {{ 'bulk-payment.general.general-balance' | transloco }}
        </p>
        <p class="sub">{{ data?.generalBalance | number : '1.0-2' }}</p>
      </div>
      <div class="d-flex flex-row justify-content-between ml-2">
        <p class="sub">
          {{ 'bulk-payment.general.campaign-balance' | transloco }}
        </p>
        <p class="sub">{{ data?.campaignBalance | number : '1.0-2' }}</p>
      </div>
    </div>
    <div class="slc-search-field-wrap align-self-center">
      <slc-svg-icon-search></slc-svg-icon-search>
      <input
        #input1
        type="text"
        class="slc-input"
        [ngModel]="searchData"
        name="search"
        placeholder="Search Campaign Name"
        (keyup.enter)="search(input1.value)"
      />
    </div>
    <div class="box px-0">
      <div class="list-content">
        <div *ngFor="let dt of dataList; let i = index">
          <div class="m-2">
            <div class="d-flex flex-row justify-content-between">
              <p class="light text-dark">
                {{ dt.name }}
              </p>
              <p class="light text-dark">{{ dt.balance | number : '1.0-2' }}</p>
            </div>
            <p class="light small text-secondary">
              {{ dt.brand }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
