import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { Channels, PostTypes } from '@slc-libs/enums';
import { getNumberMock } from '@slc-libs/mocks';

import { environment } from '@slice-env/environment';

import {
  ChannelMediaModel,
  InstagramMediaModel,
  TiktokMediaModel,
  YoutubeMediaModel,
} from '@slice-interfaces/media/channel-medias.intf';

import { getMediaListByChannelMock } from '../_mocks/get-media';

@Injectable({
  providedIn: 'root',
})
export class ChannelMediaHttpService {
  constructor(private http: HttpClient) {}

  getIgMedia(
    id: string,
    postType?: PostTypes,
    useMock?: boolean,
  ): Observable<Array<InstagramMediaModel>> {
    const mediaType =
      postType && postType === PostTypes.STORIES ? 'story' : 'media';
    const mock = of(
      getMediaListByChannelMock(Channels.INSTAGRAM, getNumberMock(100)),
    ).pipe(delay(500));
    const req = this.http.get<Array<InstagramMediaModel>>(
      `/api/v1/integration/instagram/media?igMediaType=${mediaType}`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mock : req;
      } else {
        // prod + stage
        return req;
      }
    } else {
      return useMock ? mock : req;
    }
  }

  getYtMedia(
    id: string,
    useMock?: boolean,
  ): Observable<Array<YoutubeMediaModel>> {
    const mock = of(
      getMediaListByChannelMock(Channels.YOUTUBE, getNumberMock(100)),
    ).pipe(delay(500));
    const req = this.http.get<Array<YoutubeMediaModel>>(
      `/api/v1/integration/youtube/media`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mock : req;
      } else {
        // prod + stage
        return req;
      }
    } else {
      return useMock ? mock : req;
    }
  }

  getTtMedia(
    id: string,
    useMock?: boolean,
  ): Observable<Array<TiktokMediaModel>> {
    const mock = of(
      getMediaListByChannelMock(Channels.TIKTOK, getNumberMock(100)),
    ).pipe(delay(500));
    const req = this.http.get<Array<TiktokMediaModel>>(
      `/api/v1/integration/tiktok/media`,
    );
    if (environment.production) {
      if (environment.isDemo) {
        return useMock ? mock : req;
      } else {
        // prod + stage
        return req;
      }
    } else {
      return useMock ? mock : req;
    }
  }

  getContentMedia(
    channel: Channels,
    mediaId?: string,
    isPublic?: boolean,
  ): Observable<ChannelMediaModel> {
    const baseUrl = isPublic ? '/api/public/v1/' : '/api/v1/integration/';
    const endpoint = `${channel}/media/${mediaId}`;
    return this.http.get<any>(baseUrl + endpoint);
  }
}
