import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconPlusCircleComponent } from './plus-circle.component';

@NgModule({
  declarations: [SvgIconPlusCircleComponent],
  imports: [CommonModule],
  exports: [SvgIconPlusCircleComponent],
})
export class SvgIconPlusCircleModule {}
