import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconLinkedinComponent } from './linkedin.component';

@NgModule({
  declarations: [SvgIconLinkedinComponent],
  imports: [CommonModule],
  exports: [SvgIconLinkedinComponent],
})
export class SvgIconLinkedinModule {}
