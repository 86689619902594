<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="16"
  viewBox="0 0 20 16"
  fill="none"
>
  <path
    d="M18 0H2C0.895 0 0 0.895 0 2V14C0 15.105 0.895 16 2 16H18C19.105 16 20 15.105 20 14V2C20 0.895 19.105 0 18 0ZM17.601 4.249L10.53 8.669C10.206 8.872 9.794 8.872 9.47 8.669L2.399 4.249C2.151 4.094 2 3.822 2 3.53C2 2.864 2.733 2.458 3.297 2.811L10 7L16.703 2.811C17.267 2.458 18 2.864 18 3.53C18 3.822 17.849 4.094 17.601 4.249Z"
    fill="#5D6468"
  />
</svg>
