import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CustomPreloadingStrategy } from '@slice-services/custom-preloading.service';

import { environment } from '@slice-env/environment';

import { adminRoutes } from './app-routing-admin';
import { clientRoutes } from './app-routing-client';

const routes: Routes = environment.isAdminBuild ? adminRoutes : clientRoutes;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategy, // NoPreloading, // PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
