import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ChunkErrorHandler } from '@slice/chunk-error';
import { RolePermissionService } from '@slice/role-user';
import { CustomPreloadingStrategy } from '@slice-services/custom-preloading.service';
import { NavigationService } from '@slice-services/navigation.service';
import { TokenInterceptor } from 'apps/slice/src/app/_interceptors/access-token-interceptor';
import { MessageService } from 'primeng/api';

import { ErrorCatchingInterceptor } from './_interceptors/error-interceptor';
import { AppComponent } from './app.component';
import {
  AppInitializerService,
  initServiceFactory,
} from './app-initializer.service';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { TranslocoRootModule } from './transloco-root.module';
import { OnboardRateCardComponent } from './views/role-creator/new-onboard/component/rate-card/rate-card.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    TranslocoRootModule,
    OnboardRateCardComponent,
  ],
  providers: [
    CustomPreloadingStrategy,
    RolePermissionService,
    NavigationService,
    MessageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initServiceFactory,
      multi: true,
      deps: [AppInitializerService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ChunkErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
