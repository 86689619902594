import { Injectable } from '@angular/core';

import { AuthStateService } from '@slice-services/auth-state.service';
import mixpanel from 'mixpanel-browser';

import { UserTypes } from '@slc-libs/enums';

import { environment } from '@slice-env/environment';

import { AuthenticatedUser } from '@slice-interfaces/auth/auth-user';
import { MIX_PANEL_EVENTS } from '@slice-enums/mix-panel-events.enum';

@Injectable({
  providedIn: 'root',
})
export class MixPanelService {
  private userData: {
    $name: string;
    $email: string;
    $user_id: string;
    $user_type: UserTypes;
  } | null;

  private isClientsProduction(): boolean {
    return (
      environment.production && !environment.isStage && !environment.isDemo
    );
  }

  constructor(private authStateS: AuthStateService) {}

  init(): void {
    if (environment.mixPanelToken) {
      // init mixpanel
      mixpanel.init(environment.mixPanelToken, {
        debug: !this.isClientsProduction(),
      });

      // identify current user
      this.authStateS.selectAuthenticatedUser().subscribe(v => {
        if (v) {
          this.userData = {
            $name: v.name,
            $email: v.email,
            $user_id: v.userId,
            $user_type: v.userType,
          };
        } else {
          this.userData = null;
        }
      });
    }
  }

  userLogin(value: AuthenticatedUser): void {
    this.userData = {
      $name: value.name,
      $email: value.email,
      $user_id: value.userId,
      $user_type: value.userType,
    };
    if (environment.mixPanelToken) {
      // identify current user
      mixpanel.identify(this.userData.$user_id);
      mixpanel.people.set(this.userData);

      // login events
      if (this.userData) {
        switch (this.userData.$user_type) {
          case UserTypes.AGENCY:
            this.track(MIX_PANEL_EVENTS.agency_logs_in);
            break;
          case UserTypes.CREATOR:
            this.track(MIX_PANEL_EVENTS.creator_logs_in);
            break;
          default:
            console.log('Mixpanel login error');
            break;
        }
      }
    }
  }

  userLogout(): void {
    if (environment.mixPanelToken) {
      // logout events
      if (this.userData) {
        switch (this.userData.$user_type) {
          case UserTypes.AGENCY:
            this.track(MIX_PANEL_EVENTS.agency_logs_out);
            break;
          case UserTypes.CREATOR:
            this.track(MIX_PANEL_EVENTS.creator_log_out);
            break;
          default:
            console.log('Mixpanel logout error');
            break;
        }
      }

      // reset mixpanel
      mixpanel.reset();
    }
    this.userData = null;
  }

  track(
    event: MIX_PANEL_EVENTS,
    properties?: {
      [key: string]: any;
    },
  ): void {
    if (environment.mixPanelToken) {
      this.userData === null
        ? mixpanel.track(event, properties)
        : mixpanel.track(event, {
            ...this.userData,
            ...properties,
          });
    }
  }
}
