import { Component } from '@angular/core';

import { AbstractSvgIconComponent } from '../../abstract-svg-icon.class';

@Component({
  selector: 'slc-svg-icon-search-bold',
  styles: [
    ':host { display: flex; justify-content: center; align-items: center; }',
  ],
  templateUrl: './search-bold.component.html',
})
export class SvgIconSearchBoldComponent extends AbstractSvgIconComponent {}
