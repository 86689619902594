import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AvatarModule } from 'primeng/avatar';

import { AvatarComponent } from './avatar.component';

@NgModule({
  imports: [CommonModule, AvatarModule],
  declarations: [AvatarComponent],
  exports: [AvatarComponent],
})
export class AppAvatarModule {}
