import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';

import { NavigationService } from '@slice-services/navigation.service';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { E_ROUTES } from '@slice-enums/routes.enum';

@Injectable()
export class CheckAccessCodeGuard implements CanActivate {
  constructor(private navigationS: NavigationService, private router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> | UrlTree {
    console.log('Mock, check access code - ', route.params.accessCode);
    const isNotExpired = Math.random() > 0.5;
    console.log('Mock, is code not expired - ', isNotExpired);
    return isNotExpired
      ? of(true).pipe(delay(500))
      : this.router.parseUrl(
          this.navigationS.getRoutePath(E_ROUTES.LINK_EXPIRED),
        );
  }
}
