import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TiktokAccount } from '@slice-interfaces/tiktok/tiktok-account';
import { TiktokAuthResponse } from '@slice-interfaces/tiktok/tiktok-auth-response';

@Injectable({
  providedIn: 'root',
})
export class TiktokLoginService {
  public isInited = false;
  public inProcess = false;
  public isSuccess = false;
  public isFailed = false;
  private isSuccessSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  isSuccess$: Observable<boolean> = this.isSuccessSubject.asObservable();

  setIsSuccess(value: boolean) {
    this.isSuccessSubject.next(value);
  }
  constructor(private http: HttpClient, private router: Router) {}

  integrateTiktokUrl(param: string): void {
    const queryParams = new HttpParams().set('source', param);
    this.http
      .get<any>('/api/v1/integration/tiktok/login-url', { params: queryParams })
      .subscribe(
        response => {
          const loginUrl = response.url;
          window.location.href = loginUrl;
        },
        error => {
          throwError(error);
        },
      );
  }
  extractTokenFromURL(): TiktokAuthResponse {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    const authResponse: TiktokAuthResponse = {
      accessToken: token,
    };
    return authResponse;
  }

  setInit(param: boolean) {
    this.isInited = param;
  }

  disconnect(channelId: string, userId: string): Observable<void> {
    return this.http.delete<void>(
      `/api/v1/users/${userId}/tiktok-account/${channelId}`,
    );
  }

  sendTokenToAPI(token: string): Observable<any> {
    const body = {
      token: token,
    };

    return this.http.post('api/v1/integration/tiktok/token', body).pipe(
      map(response => {
        // Process the response if needed
        this.inProcess = false;
        this.isSuccess = true;
        this.isFailed = false;
        return response;
      }),
      catchError(error => {
        // Handle the error here
        this.inProcess = false;
        this.isSuccess = false;
        this.isFailed = true;
        return throwError(error);
      }),
    );
  }
}
