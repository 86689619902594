import { HttpParams } from '@angular/common/http';

import { SortMeta } from 'primeng/api';

export const createHttpParams = (
  obj: Record<string, string | number | boolean | undefined>,
): HttpParams => {
  let httpParams = new HttpParams(obj);
  for (const property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      const val = obj[property];
      if (typeof val !== 'undefined' && val !== null) {
        // sort params  from tables
        if (
          property === 'sort' &&
          Array.isArray(val) &&
          val[0] &&
          val[0].field &&
          typeof val[0].order === 'number'
        ) {
          const sortStr = val
            .map((i: SortMeta) => {
              return `${i.field},${i.order === 1 ? 'asc' : 'desc'}`;
            })
            .join(';');
          httpParams = httpParams.append(property, sortStr);
        } else {
          if (Array.isArray(val)) {
            if (val.length > 0) {
              httpParams = httpParams.append(property, val.join(','));
            }
          } else {
            httpParams = httpParams.append(property, val);
          }
        }
      }
    }
  }
  return httpParams;
};

// A helper function to check if a value is a part of the enum's values
export const isEnumValue = (enumObj: any, value: any): boolean => {
  return Object.values(enumObj).includes(value);
};
