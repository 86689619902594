<ng-container *ngIf="title$ | async as title">
  <button
    title="{{ title?.value }}"
    (click)="isView ? null : change()"
    type="button"
    class="slc-flex-fs-c"
    [ngClass]="{
      'slc-tag-gray': isGray,
      'slc-tag-green': isGreen,
      'slc-tag-selected': isSelected,
      'slc-tag-outlined': isOutlined,
      'slc-tag-view': isView
    }"
  >
    <ng-container *ngIf="beforeTmpl">
      <ng-template *ngTemplateOutlet="beforeTmpl"></ng-template>
    </ng-container>

    <span
      class="slc-text-ellipsis slc-tag-title"
      [ngClass]="{ 'slc-tag-title': !isGray }"
    >
      {{ title?.value }}
    </span>

    <slc-svg-icon-close
      *ngIf="isDeletable"
      (click)="remove.emit()"
    ></slc-svg-icon-close>
  </button>
</ng-container>
