import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslocoModule } from '@ngneat/transloco';

import {
  SvgIconEyeModule,
  SvgIconQuestionCircleModule,
} from '@slc-libs/svg-icons';
import { AppTooltipModule } from '@slc-libs/tooltip';

import { InputWrapperComponent } from './input-wrapper.component';

@NgModule({
  declarations: [InputWrapperComponent],
  imports: [
    CommonModule,
    FormsModule,
    AppTooltipModule,
    ReactiveFormsModule,
    SvgIconEyeModule,
    TranslocoModule,
    SvgIconQuestionCircleModule,
  ],
  exports: [InputWrapperComponent],
})
export class InputWrapperModule {}
