import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconMediaKitComponent } from './svg-icon-media-kit.component';

@NgModule({
  declarations: [SvgIconMediaKitComponent],
  imports: [CommonModule],
  exports: [SvgIconMediaKitComponent],
})
export class SvgIconMediaKitModule {}
