<!-- accept="image/*" -->
<p-fileUpload
  #pfileUpload
  [multiple]="multiple"
  [auto]="false"
  accept="{{ typesRestriction }}"
  [ngClass]="[
    uiStateS.isTouchScreenDevice ? 'slice-is-touch-screen' : '',
    isDragged ? 'slice-is-dragged' : ''
  ]"
  [customUpload]="false"
  [showUploadButton]="false"
  [showCancelButton]="false"
  (onSelect)="selected($event)"
>
  <ng-template pTemplate="toolbar">
    <div class="modal-contract-component mx-lg-0 mx-2" *ngIf="!fileData.length">
      <div class="d-flex flex-column">
        <p class="p-16 font-weight-bold">
          {{ titleMessage }}
          <!-- {{ 'file-upload.drag-drop-or-select-file-pdf' | transloco }} -->
        </p>
        <p class="p-12">
          {{ inputMessage }}
        </p>
      </div>
    </div>
    <p *ngIf="fileData.length">Reupload</p>
  </ng-template>

  <ng-template pTemplate="content">
    <ul *ngIf="fileData.length">
      <li *ngFor="let file of fileData">
        <div class="d-flex flex-row">
          <slc-svg-icon-check-circle
            [width]="18"
            [height]="18"
          ></slc-svg-icon-check-circle>
          <p class="font-weight-bold">
            {{ file.name }}
          </p>
        </div>
      </li>
    </ul>
  </ng-template>
</p-fileUpload>
