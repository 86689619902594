import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Channels } from '@slc-libs/enums';
import { TableColumn } from '@slc-libs/tables';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

@Component({
  selector: 'slice-agency-report-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
})
export class AgencyReportMetricsComponent
  extends AbstractSubscriberComponent
  implements OnChanges
{
  @Input() channel: Channels | undefined;
  @Input() btnClass?: string;
  @Input() hideIcon: boolean;
  public infoChannel: InfoChannelMetrics[] = [];
  public infoMetrics: InfoMetrics[] = [];
  public infoCost?: InfoMetrics[] = [];
  public isVisible = false;
  public columnsMetrics: TableColumn[] = [
    {
      titleKey: 'Metrics',
      field: 'title',
      width: '40%',
    },
    {
      titleKey: 'Description',
      field: 'description',
      width: '60%',
    },
  ];
  public columnsCost: TableColumn[] = [
    {
      titleKey: 'Cost Efficiency',
      field: 'title',
      width: '40%',
    },
    {
      titleKey: 'Description',
      field: 'description',
      width: '60%',
    },
  ];

  constructor() {
    super();
    this.infoChannel = [
      {
        channel: Channels.INSTAGRAM,
        metrics: [
          {
            title: `Reach`,
            description: `Total number unique accounts that have seen the <b>Reel/Story/Post</b> at least once`,
          },
          {
            title: `Impressions`,
            description: `Total number of times the <b>Reel/Story/Post</b> has been seen`,
          },
          {
            title: `Total Engagement`,
            description: `Total number of likes, comments, shares and saves`,
          },
          {
            title: `Potential Reach`,
            description: `Total number of followers which potentially reach the <b>Reel/Story/Post</b>`,
          },
          {
            title: `Average Watch Time`,
            description: `Average duration viewers spend watching a <b>Reel</b>`,
          },
          {
            title: `Reach Rate`,
            description: `Total of reach number divided by <b>Followers</b>`,
          },
          {
            title: `Engagement Rate by <b>Reach</b>`,
            description: `Total number of engagement divided by <b>Reach</b>`,
          },
          {
            title: `Engagement Rate by <b>Followers</b>`,
            description: `Total number of engagement divided by <b>Followers</b>`,
          },
        ],
        cost: [
          {
            title: `Cost per Reach (CPR)`,
            description: `Total Cost / Total Reach (based on creator rate card) \n\n\n Determines how effective the spend is to reaching people`,
          },
          {
            title: `Cost per Impression (CPI)`,
            description: `Total Cost / Total Impression (based on creator rate card) \n\n\n Determines how effective the spend is to achieving viewers`,
          },
          {
            title: `Cost per Engagement (CPE)`,
            description: `Post Type Rate Card / Average Engagement (by reach) per post \n\n\n Determines how effective the spend is to driving engagement`,
          },
        ],
      },
      {
        channel: Channels.YOUTUBE,
        metrics: [
          {
            title: `Views`,
            description: `Total number of times the <b>Video</b> has been seen`,
          },
          {
            title: `Ave View Duration`,
            description: `The average amount of time viewers spent watching a video`,
          },
          // {
          //   title: `Engagement Rate`,
          //   description: `Total number of engagement divided by <b>Subscribers</b>`,
          // },
          // {
          //   title: `Total Engagement`,
          //   description: `Total number of likes, comments, shares and saves`,
          // },
        ],
        // cost: [
        //   {
        //     title: `Cost per View (CPV)`,
        //     description: `Post Type Rate Card/View \n\n\n Determines how effective the spend is to reaching people`,
        //   },
        //   {
        //     title: `Cost per Engagement (CPE)`,
        //     description: `Post Type Rate Card/Ave Engagement (by reach) per post \n\n\n Determines how effective the spend is to driving engagement`,
        //   },
        // ],
      },
      {
        channel: Channels.TIKTOK,
        metrics: [
          {
            title: `Views`,
            description: `Total number of times the <b>Video</b> has been seen`,
          },
          {
            title: `Likes`,
            description: `Total number of <b>Likes</b> received by the video`,
          },
          {
            title: `Comments`,
            description: `Total number of <b>Comments</b> left by viewers on the video`,
          },
          {
            title: `Shares`,
            description: `Total number of times the video has been <b>Shared</b> by viewers`,
          },
          {
            title: `Total Engagement`,
            description: `Total number of likes, comments, shares and saves`,
          },
          {
            title: `Engagement Rate`,
            description: `Total number of engagement divided by <b>Followers</b>`,
          },
        ],
        cost: [
          {
            title: `Cost per View (CPV)`,
            description: `Post Type Rate Card/View \n\n\n Determines how effective the spend is to reaching people`,
          },
          {
            title: `Cost per Engagement (CPE)`,
            description: `Post Type Rate Card/Ave Engagement (by reach) per post \n\n\n Determines how effective the spend is to driving engagement`,
          },
        ],
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.channel) this.changeChannel(this.channel);
  }

  changeChannel(channel: Channels) {
    const matchedData = this.infoChannel.find(v => v.channel === channel);
    if (matchedData) {
      this.channel = matchedData.channel;
      this.infoMetrics = matchedData.metrics;
      this.infoCost = matchedData.cost;
    } else {
      this.channel = undefined;
      this.infoMetrics = [];
      this.infoCost = [];
    }
  }

  showDialog() {
    this.isVisible = true;
  }

  hideDialog() {
    this.isVisible = false;
  }
}

interface InfoChannelMetrics {
  channel: Channels;
  metrics: InfoMetrics[];
  cost?: InfoMetrics[];
}

interface InfoMetrics {
  title: string;
  description: string;
}
