<!-- accept="image/*" -->
<p-fileUpload
  #pfileUpload
  [multiple]="multiple"
  [auto]="false"
  accept="{{ typesRestriction }}"
  [ngClass]="[
    uiStateS.isTouchScreenDevice ? 'slice-is-touch-screen' : '',
    isDragged ? 'slice-is-dragged' : ''
  ]"
  [customUpload]="false"
  [showUploadButton]="false"
  [showCancelButton]="false"
  (onSelect)="selected($event)"
>
  <ng-template pTemplate="toolbar">
    <div *ngIf="!uiStateS.isTouchScreenDevice">
      {{ 'file-upload.drag-drop-or-select-file' | transloco }}
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="file-list" *ngIf="files.length">
      <ul>
        <li *ngFor="let file of files; let i = index">
          <span class="file-name">{{ file.name }}</span>
          <!--|<span class="file-size">{{ file.size }} bytes</span>-->
          <a class="file-remove" (click)="removeFile.emit(i)">
            <i class="pi pi-times"></i>
          </a>
        </li>
      </ul>
    </div>
  </ng-template>
</p-fileUpload>
