<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="onHide()"
>
  <p-header></p-header>

  <div class="title">
    {{ 'how-approve-content.title' | transloco }}
  </div>

  <div class="description slc-mt-16">
    {{ 'how-approve-content.description' | transloco }}
  </div>

  <article class="slc-mt-32">
    <div>{{ 'how-approve-content.why-need.title' | transloco }}</div>
    <p class="slc-mt-12">
      {{ 'how-approve-content.why-need.msg' | transloco }}
    </p>
  </article>

  <article class="slc-mt-40">
    <div>{{ 'how-approve-content.what-happens.title' | transloco }}</div>
    <p class="slc-mt-12">
      {{ 'how-approve-content.what-happens.msg' | transloco }}
    </p>
  </article>

  <p-footer>
    <button
      class="slc-btn slc-btn-primary"
      (click)="approve(false)"
      type="button"
    >
      <span>{{ 'buttons.use-slice' | transloco }}</span>
    </button>
    <button
      class="slc-btn slc-btn-outline-grey"
      (click)="approve(true)"
      type="button"
    >
      <span>{{ 'buttons.approve-offline' | transloco }}</span>
    </button>
  </p-footer>
</p-dialog>
