<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="onHide()"
>
  <p-header> </p-header>

  <ng-container *ngIf="tmpl; else usualTmpl">
    <ng-template *ngTemplateOutlet="tmpl"></ng-template>
  </ng-container>
  <ng-template #usualTmpl>
    <img *ngIf="iconPath" src="{{ iconPath }}" alt="img" />
    <p class="slc-mt-40" *ngIf="text">{{ text }}</p>
  </ng-template>

  <p-footer>
    <button class="slc-btn slc-btn-primary" (click)="onHide()" type="button">
      <span>{{ 'buttons.ok' | transloco }}</span>
    </button>
  </p-footer>
</p-dialog>
