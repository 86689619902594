import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconListComponent } from './list.component';

@NgModule({
  declarations: [SvgIconListComponent],
  imports: [CommonModule],
  exports: [SvgIconListComponent],
})
export class SvgIconListModule {}
