import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import {
  EarningParam,
  EarningResponse,
} from '@slice-interfaces/bulk-payment/campaign';

@Injectable({
  providedIn: 'root',
})
export class TotalEarningStateService {
  public totalEarningModal$ = new BehaviorSubject<boolean>(false);
  public taxtModal$ = new BehaviorSubject<boolean>(false);
  public earningList$ = new BehaviorSubject<EarningResponse | null>(null);
  public earningParam$ = new BehaviorSubject<EarningParam | null>(null);
  public earningId$ = new BehaviorSubject<string>('');
  public paymentHistoryId$ = new BehaviorSubject<string>('');
}
