import { Component, Input, OnInit } from '@angular/core';

import { ContentTypeImgVideo } from '@slice-enums/content-types-img-video';

import { getContentTypeByUrl } from '../../../_helpers/get-content-type-by-url';

@Component({
  selector: 'slice-asset-container',
  templateUrl: './asset-container.component.html',
  styleUrls: ['./asset-container.component.scss'],
})
export class AssetContainerComponent implements OnInit {
  @Input() sourceUrl: string;
  @Input() videoModalTitle: string;

  public TYPES = ContentTypeImgVideo;
  public currType: ContentTypeImgVideo | undefined;
  public isVideoPopupVisible: boolean;
  public isPlayStarted = false;
  ngOnInit(): void {
    this.checkUrl(this.sourceUrl);
  }

  checkUrl(path: string): void {
    this.currType = getContentTypeByUrl(path);
  }

  showVideoPopup(): void {
    this.isVideoPopupVisible = true;
  }

  hideVideoPopup(): void {
    this.isVideoPopupVisible = false;
  }

  playStarted(): void {
    this.isPlayStarted = true;
  }
}
