import { Injectable } from '@angular/core';

import {
  DATE_FORMAT,
  DATE_FORMAT_CALENDAR,
  DATE_TIME_FORMAT,
} from '@slc-libs/constants';

@Injectable({
  providedIn: 'root',
})
export class DataFormatService {
  public DATE_FORMAT_CALENDAR = DATE_FORMAT_CALENDAR;
  public DATE_FORMAT = DATE_FORMAT;
  public DATE_TIME_FORMAT = DATE_TIME_FORMAT;
}
