<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="false"
  (onHide)="triggerHide()"
>
  <p-header> Change Security Code </p-header>
  <slice-loader *ngIf="isLoading" class="slice-loader-fluid"></slice-loader>
  <div *ngIf="!isLoading" class="lp-main-content">
    <div class="mt-3">
      <div class="row">
        <div class="col-lg-12 col-xs-12 px-0 form-container">
          <div class="pin-change-form">
            <form (ngSubmit)="changePin()" [formGroup]="form">
              <div class="form-group">
                <label for="oldPin">Current Security Code</label>
                <ng-otp-input
                  #ngOtpInputOld
                  [config]="otpConfig"
                  [formCtrl]="oldPinControl"
                ></ng-otp-input>
              </div>
              <div class="form-group">
                <label for="newPin">New Security Code</label>
                <ng-otp-input
                  #ngOtpInputNew
                  [config]="otpConfig"
                  [formCtrl]="newPinControl"
                ></ng-otp-input>
              </div>
              <div class="form-group">
                <label for="confirmNewPin">Confirm Security Code</label>
                <ng-otp-input
                  #ngOtpInputConfirm
                  [config]="otpConfig"
                  [formCtrl]="confirmNewPinControl"
                ></ng-otp-input>
              </div>
              <div class="d-flex flex-row-reverse">
                <div class="d-flex flex-column">
                  <a (click)="openForgotPin()">Forget Code</a>
                  <button type="submit" class="slc-btn slc-btn-primary mt-4">
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
<slice-forgot-pin-modal></slice-forgot-pin-modal>
<ng-container *ngIf="modalVerif">
  <slice-forgot-verification-code></slice-forgot-verification-code>
</ng-container>
<slice-input-new-pin></slice-input-new-pin>
