import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { NavigationService } from '@slice-services/navigation.service';
import { UiStateService } from '@slice-services/ui-state.service';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Channels } from '@slc-libs/enums';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { SharedModule } from '@slice-shared/shared.module';
import { CreatorProfile } from '@slice-interfaces/profile/creator-profile';
import { RedirectTiktok } from '@slice-interfaces/redirect';

import { NewOnboardingFacadeService } from '../../services/onboarding-facade.service';
import { NewOnboardingStateService } from '../../services/onboarding-state.service';

@Component({
  selector: 'slice-onboard-rate-card',
  templateUrl: './rate-card.component.html',
  styleUrls: ['./rate-card.component.scss'],
  standalone: true,
  imports: [SharedModule],
  providers: [NewOnboardingStateService, NewOnboardingFacadeService],
})
export class OnboardRateCardComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  @ViewChild('google', { static: false }) google: any;
  @ViewChild('ig', { static: false }) ig: any;
  @ViewChild('tiktok', { static: false }) tiktok: any;
  isVisible = true;
  profileData: CreatorProfile | null;
  listConnectAccount: Array<any> = [];

  public CHANNELS = Channels;

  public dtGoogleConnected: any;
  public dtIgConnected: any;
  public dtTiktokConnected: any;

  public isGoogleConnected = false;
  public isIgConnected = false;
  public isTiktokConnected = false;

  isLoadingGoogle = false;
  isLoadingIg = false;
  isLoadingTiktok = false;

  public redirectTo = RedirectTiktok.HOME;

  constructor(
    private facadeS: NewOnboardingFacadeService,
    private uiStateS: UiStateService,
    private cdr: ChangeDetectorRef,
    private navigationS: NavigationService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    if (window.history.state?.statustiktok) {
      if (window.history.state?.statustiktok === 'success') {
        this.connectedTiktok(true);
      } else {
        this.connectedTiktok(false);
      }
    }
    this.fetchProfile();
  }

  fetchProfile(channel = Channels.TIKTOK) {
    if (channel === Channels.YOUTUBE) {
      this.isLoadingGoogle = true;
    }
    if (channel === Channels.TIKTOK) {
      this.isLoadingTiktok = true;
    }
    if (channel === Channels.INSTAGRAM) {
      this.isLoadingIg = true;
    }
    this.facadeS
      .fetchProfileData()
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.isLoadingGoogle = false;
        this.isLoadingTiktok = false;
        this.isLoadingIg = false;
        this.profileData = res;
        this.listConnectAccount = res?.connectedAccounts as Array<any>;
        this.dtGoogleConnected = this.listConnectAccount?.find(
          dt => dt.channel === Channels.YOUTUBE,
        );
        this.dtIgConnected = this.listConnectAccount?.find(
          dt => dt.channel === Channels.INSTAGRAM,
        );
        this.dtTiktokConnected = this.listConnectAccount?.find(
          dt => dt.channel === Channels.TIKTOK,
        );
        this.isGoogleConnected = Boolean(this.dtGoogleConnected);
        this.isIgConnected = Boolean(this.dtIgConnected);
        this.isTiktokConnected = Boolean(this.dtTiktokConnected);
      });
  }

  hide() {
    if (this.uiStateS.getModalRateCard()) {
      this.uiStateS.setModalRateCard(false);
    } else {
      this.facadeS.setModalRateCard(false);
    }
  }

  connectedGoogle(isConnected: boolean): void {
    if (isConnected) {
      this.fetchProfile(Channels.YOUTUBE);
    }
  }
  disconnectedGoogle(isDisConnected: any): void {
    if (isDisConnected) {
      this.fetchProfile(Channels.YOUTUBE);
    }
  }

  connectedIg(isConnected: boolean): void {
    if (isConnected) {
      this.fetchProfile(Channels.INSTAGRAM);
    }
  }
  disconnectedIg(isDisConnected: any): void {
    if (isDisConnected) {
      this.fetchProfile(Channels.INSTAGRAM);
    }
  }
  connectedTiktok(isConnected: boolean): void {
    if (isConnected) {
      this.fetchProfile(Channels.TIKTOK);
    }
  }
  disconnectedTiktok(isDisConnected: any): void {
    if (isDisConnected) {
      this.fetchProfile(Channels.TIKTOK);
    }
  }

  actionInProgressGoogle(e: boolean): void {
    this.isLoadingGoogle = e;
  }
  actionInProgressIg(e: boolean): void {
    this.isLoadingIg = e;
  }
  actionInProgressTiktok(e: boolean): void {
    this.isLoadingTiktok = e;
  }

  disconnectBtnGoogle() {
    this.google.disconnect();
  }
  disconnectBtnIg() {
    this.ig.disconnect();
  }
  disconnectBtnTiktok() {
    this.tiktok.disconnect();
  }
}
