import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { TranslocoService } from '@ngneat/transloco';
import { NgOtpInputConfig } from 'ng-otp-input';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';

// import { debounceTime, takeUntil } from 'rxjs/operators';
import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';

import { ChangePinFacadeService } from '../services/change-pin-facade.service';

@Component({
  selector: 'slice-input-new-pin',
  templateUrl: './input-new-pin.component.html',
  styleUrls: ['./input-new-pin.component.scss'],
})
export class InputNewPinComponent
  extends AbstractSubscriberComponent
  implements OnInit
{
  // @Input() isVisible?: any;
  @Output() closed = new EventEmitter<boolean>();
  @ViewChild('ngOtpInputNew', { static: false }) ngOtpInputNew: any;
  @ViewChild('ngOtpInputConfirm', { static: false }) ngOtpInputConfirm: any;

  errorMessage: string;
  isLoading = false;
  newPinControl: FormControl;
  confirmNewPinControl: FormControl;
  form: FormGroup;
  isVisible = false;
  verificationCodes: string;

  constructor(
    private fb: FormBuilder,
    private facadeS: ChangePinFacadeService,
    private tS: TranslocoService,
    private mS: MessageService,
  ) {
    super();
    this.facadeS
      .selectIsModalResetPin()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => (this.isVisible = r));
    this.facadeS
      .selectVerificationCode()
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        this.verificationCodes = r;
      });
  }

  otpConfig: NgOtpInputConfig = {
    length: 6,
    isPasswordInput: true,
    placeholder: '_',
    inputStyles: {
      width: '60px',
      'margin-top': '5px',
    },
  };
  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.newPinControl = new FormControl('', [Validators.required]);
    this.confirmNewPinControl = new FormControl('', [Validators.required]);

    this.form = this.fb.group({
      newPin: this.newPinControl,
      confirmNewPin: this.confirmNewPinControl,
    });
  }

  changePin() {
    if (this.form.valid) {
      const newPin = this.newPinControl.value;
      const confirmNewPin = this.confirmNewPinControl.value;
      const payload = {
        newPin: newPin,
        confirmNewPin: confirmNewPin,
        verificationCode: this.verificationCodes,
      };
      if (newPin === confirmNewPin) {
        this.isLoading = true;
        this.facadeS
          .resetPinService(payload)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            () => {
              this.isLoading = false;
              this.mS.add({
                severity: 'success',
                life: 5 * 1000,
                detail: this.tS.translate(
                  'profiles.my.response.modal-pin.success',
                ),
              });
              this.triggerHide();
            },
            err => {
              this.isLoading = false;
              this.mS.clear();
              this.mS.add({
                severity: 'error',
                summary: this.tS.translate(
                  'withdraw-response-modal.withdraw-response-failed.title',
                ),
                life: 5 * 1000,
                detail: this.tS.translate(
                  'withdraw-response-modal.withdraw-response-failed.response-summary',
                ),
              });
            },
          );
      } else {
        this.mS.add({
          severity: 'error',
          life: 5 * 1000,
          detail: this.tS.translate(
            'profiles.my.response.modal-pin.failed-same',
          ),
        });
      }
    }
  }

  triggerHide(): void {
    this.facadeS.setIsModalResetPin(false);
    this.reset();
  }
  reset(): void {
    this.form.reset();
    this.setVal('');
  }
  setVal(val: any) {
    this.ngOtpInputNew.setValue(val);
    this.ngOtpInputConfirm.setValue(val);
  }

  openForgotPin(): void {
    this.triggerHide();
    this.facadeS.setIsModalForgotPin(true);
  }
}
