<div class="slc-mt-12">
  <div class="slc-p-autocomplete-wrap">
    <slc-svg-icon-search></slc-svg-icon-search>
    <p-autoComplete
      [style]="{ width: '100%' }"
      [inputStyle]="{ width: '100%' }"
      [inputStyleClass]="value && isInvalidEmail(value) ? 'email-invalid' : ''"
      [(ngModel)]="value"
      [disabled]="disabled"
      [delay]="500"
      [forceSelection]="false"
      [dropdown]="false"
      [multiple]="false"
      [group]="true"
      [suggestions]="suggestions"
      [minLength]="2"
      field="search"
      [pTooltip]="'Please enter the correct email format'"
      [tooltipDisabled]="hideTooltip"
      tooltipPosition="bottom"
      placeholder="{{ 'clients-selector.label' | transloco }}"
      (completeMethod)="search($event)"
      (onSelect)="onSelect($event)"
    >
      <ng-template let-group pTemplate="group">
        <div class="d-flex align-items-center" style="height: 5px">
          <p
            class="group-title"
            [ngClass]="{
              'green-text':
                group.label === 'This creator is already in Slice Database' ||
                group.label === 'This creator is already in your roster'
            }"
          >
            {{ group.label }}
          </p>
        </div>
      </ng-template>
      <ng-template let-user pTemplate="item">
        <div class="slc-flex-fs-c" style="height: 25px">
          <ng-container *ngIf="user?.label === 'internal'; else external">
            <slc-avatar
              class="slc-avatar-32 slc-mr-8"
              [userFullName]="user?.value?.name"
              [imagePath]="user?.value?.avatar"
            ></slc-avatar>
            <div>
              <p class="item-title">{{ user?.value?.name }}</p>
              <p class="item-subs">{{ user?.value?.email }}</p>
            </div>
          </ng-container>
          <ng-template #external>
            <slc-avatar
              class="slc-avatar-32 slc-mr-8"
              [userFullName]="user?.value?.email"
              [imagePath]="undefined"
            ></slc-avatar>
            <div class="slc-flex-sb-c w-100" *ngIf="user?.label === 'external'">
              <p class="item-title">{{ user?.value?.email }}</p>
              <a class="slc-btn slc-btn-link"><span>Invite</span></a>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </p-autoComplete>
  </div>
</div>
