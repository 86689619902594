import { getPostTypeMock, getUsernameMock } from '@slc-libs/mocks';
import { getPagebaleResponseMock } from '@slc-libs/mocks';
import { getIdMock } from '@slc-libs/mocks';

import { AdminCreator } from '@slice-interfaces/admin/creators/creator';
import { AdminCreatorsFilters } from '@slice-interfaces/admin/creators/filters';
import { AdminCreatorsResponse } from '@slice-interfaces/admin/creators/get-list';

const _getList = (
  params: AdminCreatorsFilters,
  res: AdminCreatorsResponse,
): Array<AdminCreator> => {
  const list: Array<AdminCreator> = [];
  for (
    let i = 0;
    i < (res.last ? res.totalElements - res.number * res.size : params.size);
    i++
  ) {
    list.push({
      id: getIdMock(),
      creatorName: getUsernameMock(),
      postType: getPostTypeMock(),
      dueDate: new Date().toString(),
      status: 'todo',
    });
  }
  return list;
};

export const getAdminCreatorsMocks = (
  params: AdminCreatorsFilters,
): AdminCreatorsResponse => {
  const res = getPagebaleResponseMock(params);
  res.content = _getList(params, res as any);
  return res as AdminCreatorsResponse;
};
