import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { UiStateService } from '@slice-services/ui-state.service';
import videojs from 'video.js';

@Component({
  selector: 'slice-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  constructor(private uiStateS: UiStateService) {}
  //
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() isLocalVideo = false;
  // see options: https://github.com/videojs/video.js/blob/maintutorial-options.html
  @Input() options: {
    fluid: boolean;
    // aspectRatio: string;
    muted: boolean;
    autoplay: boolean;
    controls: boolean;
    sources: Array<{
      src: string;
      type: string;
    }>;
  };
  player: any;

  @Output() playerInited = new EventEmitter<any>();
  @Output() playStarted = new EventEmitter<any>();
  ngOnInit(): void {
    if (this.isLocalVideo) {
      this.player = videojs(this.target.nativeElement, this.options, () => {
        // eslint-disable-next-line no-restricted-syntax
        console.info('VIDEO.JS Playing video ');

        // Optional: Add event listeners or additional setup
        this.player.on('play', () => {
          console.log('Video.js player started playing');
        });
      });
    } else {
      const url = new URL(this.options?.sources[0]?.src);
      const extension = url.pathname.split('.')[1];
      if (this.options?.sources[0] && !this.options?.sources[0]?.type) {
        this.options.sources[0].type = 'video/mp4';
      }
      this.player = videojs(this.target.nativeElement, this.options, () => {
        this.playerInited?.emit(this.player);
        this.player.on('play', (ev: any) => {
          // eslint-disable-next-line no-restricted-syntax
          console.info(
            'VIDEO.JS Playing video - ',
            extension,
            ' ',
            this.options?.sources[0]?.src,
          );
          this.playStarted?.emit(ev);
        });
      });
      this.player.on('error', () => {
        // eslint-disable-next-line no-restricted-syntax
        console.info(
          'VIDEO.JS error with source: ',
          this.options?.sources[0]?.src,
        );
      });
    }
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
  }
}
