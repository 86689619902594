<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="dialogType !== TYPES.DUE"
  [closeOnEscape]="dialogType !== TYPES.DUE"
  [dismissableMask]="dialogType !== TYPES.DUE"
  [ngClass]="['slc-payment-result-modal-' + dialogType]"
  (onHide)="closeDialog()"
>
  <p-header> </p-header>

  <div class="content-wrap" *ngIf="currConfig">
    <h4>
      {{ currConfig.titleKey | transloco
      }}<slc-svg-icon-info-circle
        *ngIf="currConfig.showInfoIcon"
        class="slc-ml-8"
      ></slc-svg-icon-info-circle>
    </h4>

    <p
      class="slc-mt-24"
      [innerHTML]="currConfig.descriptionKey | transloco"
    ></p>

    <button
      class="slc-btn slc-btn-primary slc-mt-32 slc-btn-h-48"
      type="button"
      [disabled]="loadingMain || loadingAdditional"
      [ngClass]="{ 'slc-btn-loading': loadingMain }"
      (click)="mainAction()"
    >
      <span>{{ currConfig.mainBtnKey | transloco }}</span>
    </button>

    <button
      *ngIf="!currConfig.showSupportBtn && currConfig.additionalBtnKey"
      class="slc-btn slc-btn-link slc-mt-24 {{
        !currConfig.additionalBtnKey ? 'invisible-btn' : ''
      }}"
      type="button"
      [disabled]="loadingMain || loadingAdditional"
      (click)="additionalAction()"
    >
      <span>{{ currConfig.additionalBtnKey | transloco }}</span>
      <slice-loader *ngIf="loadingAdditional"></slice-loader>
    </button>

    <a
      *ngIf="currConfig.showSupportBtn && currConfig.additionalBtnKey"
      href="mailto:hello@slice.id"
      class="slc-btn slc-btn-link slc-flex-c-c slc-mt-24"
    >
      <span>{{ currConfig.additionalBtnKey | transloco }}</span>
    </a>

    <button
      *ngIf="currConfig.logout"
      class="logout-btn slc-btn slc-btn-outline-blue slc-mt-32 slc-btn-h-48"
      type="button"
      (click)="logout()"
    >
      <span>{{ 'user-menu.logout' | transloco }}</span>
    </button>
  </div>
</p-dialog>
