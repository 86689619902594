import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconArrowRightComponent } from './arrow-right.component';

@NgModule({
  declarations: [SvgIconArrowRightComponent],
  imports: [CommonModule],
  exports: [SvgIconArrowRightComponent],
})
export class SvgIconArrowRightModule {}
