<svg
  xmlns="http://www.w3.org/2000/svg"
  width="18"
  height="20"
  viewBox="0 0 18 20"
  fill="none"
>
  <path
    d="M13.191 0C16.28 0 18 1.78 18 4.83V15.16C18 18.26 16.28 20 13.191 20H4.81C1.77 20 0 18.26 0 15.16V4.83C0 1.78 1.77 0 4.81 0H13.191ZM5.08 13.74C4.78 13.71 4.49 13.85 4.33 14.11C4.17 14.36 4.17 14.69 4.33 14.95C4.49 15.2 4.78 15.35 5.08 15.31H12.92C13.319 15.27 13.62 14.929 13.62 14.53C13.62 14.12 13.319 13.78 12.92 13.74H5.08ZM12.92 9.179H5.08C4.649 9.179 4.3 9.53 4.3 9.96C4.3 10.39 4.649 10.74 5.08 10.74H12.92C13.35 10.74 13.7 10.39 13.7 9.96C13.7 9.53 13.35 9.179 12.92 9.179ZM8.069 4.65H5.08V4.66C4.649 4.66 4.3 5.01 4.3 5.44C4.3 5.87 4.649 6.22 5.08 6.22H8.069C8.5 6.22 8.85 5.87 8.85 5.429C8.85 5 8.5 4.65 8.069 4.65Z"
    fill="white"
  />
</svg>
