import { Component, HostBinding, Input } from '@angular/core';

import { CampaignModelStatus } from '@slice-enums/campaign-status';

@Component({
  selector: 'slice-campaign-status-label',
  templateUrl: './campaign-status-label.component.html',
  styleUrls: ['./campaign-status-label.component.scss'],
})
export class CampaignStatusLabelComponent {
  @Input() action: CampaignModelStatus;
  @HostBinding('class') get classes(): string {
    if (
      this.action === CampaignModelStatus.BRAND_APPROVAL_PENDING ||
      this.action === CampaignModelStatus.PAID ||
      this.action === CampaignModelStatus.IN_PROGRESS
    ) {
      return 'color-blue';
    } else if (
      this.action === CampaignModelStatus.REPORT_CREATED ||
      this.action === CampaignModelStatus.LOOKING_FOR_CREATORS ||
      this.action === CampaignModelStatus.PAYMENT_PENDING ||
      this.action === CampaignModelStatus.SCORED ||
      this.action === CampaignModelStatus.IG_SCORED ||
      this.action === CampaignModelStatus.YT_SCORED ||
      this.action === CampaignModelStatus.ARCHIVED
    ) {
      return 'color-yellow';
    } else if (this.action === CampaignModelStatus.REPORT_ACTIVE) {
      return 'color-green';
    } else if (this.action === CampaignModelStatus.COMPLETED) {
      return 'color-grey';
    } else {
      return '';
    }
  }
  public readonly actions = CampaignModelStatus;
}
