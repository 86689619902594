<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  (onHide)="onHide()"
>
  <p-header>
    <slice-breadcrumbs-without-links
      [items]="breadcrumbs"
    ></slice-breadcrumbs-without-links>
  </p-header>

  <div *ngIf="offer">
    <span class="title-post text-capitalize">
      {{ offer?.channel }}
      {{ 'enums.post-types.' + offer.postType | transloco }}
    </span>
  </div>

  <div
    class="warning-msg slc-flex-fs-c slc-mt-20 slc-mb-24"
    *ngIf="
      offer?.channel === Channels.INSTAGRAM &&
      offer?.postType === PostTypes.STORIES
    "
  >
    <slc-svg-icon-info-circle></slc-svg-icon-info-circle>

    <p class="slc-ml-10">
      <b> Pay Attention </b>
      <br />
      <span> Your Instagram Story will only last for 24 hours </span>
    </p>
  </div>

  <!-- <div class="slc-flex-fe-c">
    <ng-select
      class="slice-ng-select"
      [items]="types"
      bindLabel="label"
      [multiple]="false"
      [searchable]="false"
      [closeOnSelect]="true"
      bindValue="value"
      [clearable]="false"
    >
    </ng-select>
  </div> -->

  <div class="loader-wrap" *ngIf="loading; else itemsTmpl">
    <slice-loader></slice-loader>
  </div>

  <ng-template #itemsTmpl>
    <div class="row">
      <ng-container *ngFor="let item of viewItems">
        <div
          class="col-sm-4 slc-mt-16"
          [hidden]="
            offer?.channel === Channels.INSTAGRAM &&
            (offer?.postType === PostTypes.POST ||
              offer?.postType === PostTypes.REELS ||
              offer?.postType === PostTypes.CAROUSEL_ALBUM) &&
            offer?.postType !== item?.postType
          "
        >
          <div
            class="item"
            type="button"
            (click)="toggleItem(item)"
            [ngClass]="[
              offer?.channel || '',
              selectedIds.includes(item.id) ? 'selected' : ''
            ]"
          >
            <div class="img-wrap">
              <div
                *ngIf="selectedIds.includes(item.id)"
                class="select-label slc-flex-c-c"
              >
                <slc-svg-icon-checkmark></slc-svg-icon-checkmark>
              </div>
              <div
                class="image-container"
                [ngStyle]="{
                  'background-image': 'url(' + item.mediaUrl + ')'
                }"
              ></div>
            </div>
            <p>
              {{ 'enums.post-types.' + item.postType | transloco }} -
              <span class="text-truncates">
                {{ item.caption }}
              </span>
            </p>
          </div>
        </div>
      </ng-container>
    </div>

    <p-paginator
      class="slc-mt-24"
      *ngIf="isContentAvailable(); else emptyTmpl"
      [rows]="offer?.channel === Channels.INSTAGRAM ? 3 : 6"
      [totalRecords]="allItems.length"
      (onPageChange)="paginate($event)"
    ></p-paginator>
  </ng-template>

  <ng-template #emptyTmpl>
    <div class="no-list" *ngIf="!offer?.channelAccountConnected">
      <img src="assets/images/Group.png" alt="empty" />
      <div>
        <h5>You have not connect your account yet</h5>
        <p>
          In order to submit a content, you need to connect your account first
        </p>
        <button class="btn btn-primary" (click)="gotoProfile()">
          {{ 'onboarding.step-common.connect-btn' | transloco }}
        </button>
      </div>
    </div>
    <div class="no-list" *ngIf="offer?.channelAccountConnected">
      <img src="assets/images/empty_list.png" alt="empty" />
      <ng-container
        *ngIf="offer?.postType === PostTypes.STORIES; else emptyGeneral"
      >
        <h5>Uh oh, there are no recent Stories</h5>
        <p>It looks like you haven't submitted a story in the past 24 hours.</p>
      </ng-container>
      <ng-template #emptyGeneral>
        <h5>Uh oh, there are no recent Posts</h5>
        <p>
          It looks like you haven't posted any content with this format in the
          past 90 days.
        </p>
      </ng-template>
    </div>
  </ng-template>

  <p-footer>
    <button
      class="slc-btn slc-btn-outline-blue"
      (click)="onHide()"
      type="button"
    >
      <span>{{ 'buttons.cancel' | transloco }}</span>
    </button>
    <button
      class="slc-btn slc-btn-primary"
      (click)="submit()"
      type="button"
      [ngClass]="{ 'slc-btn-loading': loading }"
      [disabled]="selectedIds.length === 0 || loading"
    >
      <span>{{ 'buttons.submit' | transloco }}</span>
    </button>
  </p-footer>
</p-dialog>
