<p-dialog
  [(visible)]="isVisible"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
  [closable]="true"
  [closeOnEscape]="true"
  [dismissableMask]="true"
  (onHide)="triggerHide()"
>
  <slice-loader *ngIf="isLoading" class="slice-loader-fluid"></slice-loader>
  <div *ngIf="!isLoading" class="lp-main-content">
    <div class="mt-3">
      <div class="row">
        <div class="col-lg-12 col-xs-12 px-0">
          <form [formGroup]="mainForm">
            <div class="area py-3">
              <div class="slc-input-title label-area">PIN Number</div>
              <div class="input-wrapper">
                <div class="title-value">
                  <span>Please enter your PIN number to proceed</span>
                </div>
                <ng-otp-input
                  #ngOtpInput
                  (onInputChange)="updatePin($event)"
                  [config]="{ length: 6, isPasswordInput: true }"
                  (allowNumbersOnly)="(true)"
                ></ng-otp-input>
              </div>
            </div>
            <div class="mt-2 text-center">
              <slice-forgot-button></slice-forgot-button>
            </div>
            <button
              type="button"
              [disabled]="isLoading"
              [ngClass]="{ 'slc-btn-loading': isLoading }"
              class="slc-btn slc-btn-primary w-100 my-3"
              (click)="submitPin()"
            >
              <span>Confirm Payment</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
