import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconCloseCircleComponent } from './close-circle.component';

@NgModule({
  declarations: [SvgIconCloseCircleComponent],
  imports: [CommonModule],
  exports: [SvgIconCloseCircleComponent],
})
export class SvgIconCloseCircleModule {}
