import { Component, OnInit } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { GeneralSubscriptionService } from '@slice-services/general-subscription.service';
import { StripeService } from '@slice-services/stripe-http.service';
import { UiStateService } from '@slice-services/ui-state.service';
import { SwitcherOptions } from 'libs/switcher/src/lib/switcher-options';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SubscriptionData } from '@slc-libs/interfaces';
import { PricingPlanUi } from '@slc-libs/interfaces';
import { BreakpointsService, PlansService } from '@slc-libs/services';

import { AbstractCommonComponent } from '@slice-shared/abstract-classes/common-component';
import { BillingTypes } from '@slice-enums/plans';

@Component({
  selector: 'slice-see-plan-modal',
  templateUrl: './see-plan-modal.component.html',
  styleUrls: ['./see-plan-modal.component.scss'],
})
export class SeePlanModalComponent
  extends AbstractCommonComponent
  implements OnInit
{
  isVisible: boolean; // from ui-state

  public plans: Array<PricingPlanUi>;
  public billings$: Observable<Array<SwitcherOptions>>;
  public currBilling: BillingTypes;
  // public isVisible = true;
  public billingPeriods: BillingPeriodButton[] = [];
  subscriptionPlan: SubscriptionData;
  errorMessage: string;
  isLoading = false;

  constructor(
    private stripeS: StripeService,
    private uiStateS: UiStateService,
    private plansServices: PlansService,
    private genSubsS: GeneralSubscriptionService,
    private bs: BreakpointsService,
    private tS: TranslocoService,
  ) {
    super(bs, tS);
    this.uiStateS.selectDialogAgencyPricing().subscribe(val => {
      this.isVisible = val;
    });
  }
  ngOnInit(): void {
    this.currBilling = BillingTypes.annual;
    this.initTranslationTrigger();
    this.initBillings();
    this.initPlans();
    this.fetchData();
  }

  fetchData(): void {
    this.isLoading = true;
    this.genSubsS.fetchSubsPlan().subscribe(
      data => {
        this.subscriptionPlan = data;
        this.initPlanPrice(data);
        this.isLoading = false;
      },
      error => {
        this.errorMessage = this.tS.translate('pricing.error-msg');
        console.error(error);
        this.isLoading = false;
      },
    );
  }
  triggerHide(): void {
    this.uiStateS.showAgencyPricing(false);
  }

  billingChanged(val: unknown): void {
    this.currBilling = val as BillingTypes;
  }

  private initBillings(): void {
    this.billings$ = this.translateTrigger$.asObservable().pipe(
      map(() => {
        return [
          {
            title: this.translocoService.translate('annually'),
            value: BillingTypes.annual,
          },
          {
            title: this.translocoService.translate('monthly'),
            value: BillingTypes.monthly,
          },
        ];
      }),
    );
  }

  private initPlans(): void {
    this.plans = this.plansServices.getFullPlans() as Array<PricingPlanUi>;
  }

  private initPlanPrice(apiData: SubscriptionData): void {
    // loop for every object in apiData & assign it to the correct plan
    if (apiData) {
      Object.keys(apiData).forEach(key => {
        this.plans.forEach(plan => {
          if (plan.defaultPlanName === key) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            plan.subsPlanData = apiData[key];
            plan.id = plan.subsPlanData.id;
          }
        });
      });
    }
  }
}

class BillingPeriodButton {
  isCurrent: boolean;
  title: string;
}
