import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconPlaneComponent } from './plane.component';

@NgModule({
  declarations: [SvgIconPlaneComponent],
  imports: [CommonModule],
  exports: [SvgIconPlaneComponent],
})
export class SvgIconPlaneModule {}
