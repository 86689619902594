import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconCheckmarkModule } from '@slc-libs/svg-icons';

import { CheckboxComponent } from './checkbox.component';

@NgModule({
  imports: [CommonModule, SvgIconCheckmarkModule],
  declarations: [CheckboxComponent],
  exports: [CheckboxComponent],
})
export class AppCheckboxModule {}
