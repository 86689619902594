import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SvgIconLinkComponent } from './link.component';

@NgModule({
  declarations: [SvgIconLinkComponent],
  imports: [CommonModule],
  exports: [SvgIconLinkComponent],
})
export class SvgIconLinkModule {}
