<div class="slc-p-autocomplete-wrap">
  <slc-svg-icon-search></slc-svg-icon-search>
  <p-autoComplete
    class="slc-flex-grow-1"
    [(ngModel)]="value"
    [type]="'search'"
    [delay]="500"
    [suggestions]="[]"
    [multiple]="false"
    [minLength]="2"
    field="name"
    placeholder="Search Brands"
    (completeMethod)="search($event)"
    (onSelect)="onSelect($event)"
    (onClear)="onClear()"
  >
  </p-autoComplete>
</div>
