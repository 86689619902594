import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'slice-generate-link',
  templateUrl: './generate-link.component.html',
  styleUrls: ['./generate-link.component.scss'],
})
export class GenerateLinkComponent {
  @Input() link: string | undefined | null;
  @Input() securityPin?: string;
  @Input() hideIcon: boolean;
  @Input() hideTitle: boolean;
  @Output() clicked = new EventEmitter<void>();
  constructor(private mS: MessageService, private tS: TranslocoService) {}
  copied(): void {
    this.clicked?.emit();
    this.mS.add({
      severity: 'success',
      life: 3 * 1000,
      detail: this.tS.translate('common.clipboard'),
    });
  }

  getSecurityPIN(): string {
    return this.securityPin ? `- (PIN ${this.securityPin.toUpperCase()})` : '';
  }
}
