import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppPipesModule } from '@slc-libs/pipes';
import {
  SvgIconInfoCircleModule,
  SvgIconQuestionCircleModule,
} from '@slc-libs/svg-icons';
import { AppTooltipModule } from '@slc-libs/tooltip';

import { WidgetSimpleComponent } from './widget-simple.component';

@NgModule({
  imports: [
    CommonModule,
    AppTooltipModule,
    SvgIconQuestionCircleModule,
    SvgIconInfoCircleModule,
    AppPipesModule,
  ],
  declarations: [WidgetSimpleComponent],
  exports: [WidgetSimpleComponent],
})
export class WidgetSimpleModule {}
