import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { RoleMenuEnum, RolePermissionService } from '@slice/role-user';
import { AuthStateService } from '@slice-services/auth-state.service';
import { MixPanelService } from '@slice-services/mix-panel.service';
import { NavigationService } from '@slice-services/navigation.service';
import { UiStateService } from '@slice-services/ui-state.service';
import { takeUntil } from 'rxjs/operators';

import { environment } from '@slice-env/environment';

import { AbstractSubscriberComponent } from '@slice-shared/abstract-classes/subscriber';
import { AuthenticatedUser } from '@slice-interfaces/auth/auth-user';
import { MIX_PANEL_EVENTS } from '@slice-enums/mix-panel-events.enum';
import { E_ROUTES } from '@slice-enums/routes.enum';
import { UserTypes } from '@slice-enums/user-types.enum';

enum ElemTypes {
  link = 'link',
  button = 'button',
}

interface Item {
  type: ElemTypes;
  translateKey: string;
  routePath?: string;
}
@Component({
  selector: 'slice-menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss'],
})
export class MenuUserComponent
  extends AbstractSubscriberComponent
  implements OnInit, OnDestroy
{
  public items: Array<Item>;
  public isOpen = false;
  public readonly ElemTypes = ElemTypes;
  public appVersion: string;
  public username: string;
  public avatar: string | undefined;
  @HostListener('document:click', ['$event'])
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  clickout(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }
  constructor(
    private eRef: ElementRef,
    private mpS: MixPanelService,
    private navigationS: NavigationService,
    private roleService: RolePermissionService,
    private authStateS: AuthStateService,
    private uiStateS: UiStateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.appVersion = this.uiStateS.appVersion;
    this.authStateS
      .selectAuthenticatedUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe(u => {
        if (u) {
          this.username = u.name;
          this.avatar = u.avatar;
          this.items = [
            u.userType !== UserTypes.MANAGER &&
            u.userType !== UserTypes.ADMIN &&
            u.emailConfirmed
              ? this.getAccountItem(u)
              : null,
            this.getTeamManagement(u),
            this.getPlanBilling(u),
            this.getTaxDocument(u),
            this.getLogoutItem(),
          ].filter(i => Boolean(i)) as Array<Item>;
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private getPlanBilling(u: AuthenticatedUser): Item | null {
    return this.roleService.checkUserType(UserTypes.AGENCY) &&
      (this.roleService.checkUserPermission(RoleMenuEnum.plan_billing) ||
        this.roleService.checkUserPermission(RoleMenuEnum.payment)) &&
      u.emailConfirmed
      ? {
          type: ElemTypes.link,
          translateKey: 'user-menu.plan-billing',
          routePath: this.navigationS.getRoutePath(E_ROUTES.PLAN_BILLING),
        }
      : null;
  }

  private getTeamManagement(u: AuthenticatedUser): Item | null {
    return this.roleService.checkUserType(UserTypes.AGENCY) &&
      (this.roleService.checkUserPermission(RoleMenuEnum.team_management) ||
        this.roleService.checkUserPermission(RoleMenuEnum.invite_by_email)) &&
      u.emailConfirmed
      ? {
          type: ElemTypes.link,
          translateKey: 'user-menu.team-management',
          routePath: this.navigationS.getRoutePath(E_ROUTES.TEAM_MANAGE),
        }
      : null;
  }

  private getAccountItem(u: AuthenticatedUser): Item | null {
    return this.roleService.checkUserType(UserTypes.CREATOR) &&
      !u.profileCompleted &&
      !environment.isDemo
      ? null
      : {
          type: ElemTypes.link,
          translateKey: 'user-menu.account-settings',
          /*  this.roleService.checkUserType(CREATOR) || this.roleService.checkUserType(AGENCY)
              ? 'user-menu.profile'
              : 'user-menu.account-settings',*/
          routePath: this.navigationS.getRoutePath(
            this.roleService.checkUserType(UserTypes.CREATOR) ||
              this.roleService.checkUserType(UserTypes.AGENCY)
              ? E_ROUTES.PROFILE
              : E_ROUTES.ACCOUNT,
          ),
        };
  }

  private getTaxDocument(u: AuthenticatedUser): Item | null {
    return this.roleService.checkUserType(UserTypes.CREATOR) &&
      u.profileCompleted
      ? {
          type: ElemTypes.link,
          translateKey: 'user-menu.tax-document',
          routePath: this.navigationS.getRoutePath(E_ROUTES.KYC),
        }
      : null;
  }

  private getLogoutItem(): Item {
    return { type: ElemTypes.button, translateKey: 'user-menu.logout' };
  }

  toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }

  itemClicked(i: Item): void {
    if (i.type === ElemTypes.link) {
      if (i.routePath === E_ROUTES.PROFILE) {
        this.mpS.track(MIX_PANEL_EVENTS.creator_open_profile_page);
      } else if (i.routePath === E_ROUTES.ACCOUNT) {
        this.mpS.track(MIX_PANEL_EVENTS.agency_open_account_page);
      } else if (i.routePath === E_ROUTES.PLAN_BILLING) {
        this.mpS.track(MIX_PANEL_EVENTS.agency_open_billing_page);
      } else {
        // nothing to do here
      }
    }
    if (i.type === ElemTypes.button) {
      this.roleService.resetRoleData();
      this.mpS.userLogout();
      this.authStateS.logout();
      this.navigationS.goToSignIn(true);
    }
    this.isOpen = false;
  }
}
