import { Component, Input } from '@angular/core';

import { BreadcrumbsWithoutLinksItem } from '@slice-interfaces/breadcrumbs-without-links';

@Component({
  selector: 'slice-breadcrumbs-without-links',
  templateUrl: './breadcrumbs-without-links.component.html',
  styleUrls: ['./breadcrumbs-without-links.component.scss'],
})
export class BreadcrumbsWithoutLinksComponent {
  @Input() items: Array<BreadcrumbsWithoutLinksItem>;
  @Input() highlightLast = true;
}
